import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/User.provider";
import api, { getCompanyBucket, getSignedUrl } from "../../Services/api";

function ImagemEditor({ Imagem, ImagemAWS }) {
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const { userData } = useAuth();
  const [canvas, setCanvas] = useState(null);
  const [ctx, setCtx] = useState(null);
  const [image, setImage] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [isEditor, setIsEditor] = useState(false);

  useEffect(() => {
    if (!canvas) {
      setCanvas(document.createElement("canvas"));
    }
  }, []);

  useEffect(() => {
    if (canvas) {
      setCtx(canvas.getContext("2d"));
    }
  }, [canvas]);

  const setCanvasDimensions = (width, height) => {
    canvas.width = width;
    canvas.height = height;
  };

  const adjustBrightness = (value) => {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      data[i] += value;
      data[i + 1] += value;
      data[i + 2] += value;
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const adjustContrast = (value) => {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const factor = (259 * (value + 255)) / (255 * (259 - value));
    console.log(imageData.data);
    for (let i = 0; i < data.length; i += 4) {
      data[i] = factor * (data[i] - 128) + 128;
      data[i + 1] = factor * (data[i + 1] - 128) + 128;
      data[i + 2] = factor * (data[i + 2] - 128) + 128;
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const adjustSaturation = (value) => {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const max = 255;

    for (let i = 0; i < data.length; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      const gray = 0.2989 * r + 0.587 * g + 0.114 * b;

      data[i] = -gray * value + max * value + r * (1 - value);
      data[i + 1] = -gray * value + max * value + g * (1 - value);
      data[i + 2] = -gray * value + max * value + b * (1 - value);
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const adjustBGR = (values) => {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      data[i] += values[2];
      data[i + 1] += values[1];
      data[i + 2] += values[0];
    }
    ctx.putImageData(imageData, 0, 0);
  };

  async function fetchImage(imgblob) {
    const response = await fetch(imgblob);

    const blob = await response.blob();

    const img = new Image();
    img.src = URL.createObjectURL(blob);
    setImage(img);
    return img;
  }

  async function MostrarImage(imgblob) {
    const reader = new FileReader();
    console.dir(imgblob);
    if (imgblob) {
      const img = new Image(imgblob);
      img.src = imgblob.src;
      setCanvasDimensions(img.width, img.height);
      setImageUrl(img.src);
      //ctx.drawImage(img, 0, 0);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      console.log("Aqui 3");
      console.log(img.src);
    }
  }

  const openImgEditor = () => {
    setIsImgEditorShown(true);
    setIsEditor(true);

    async function fetchLinkDaFoto() {
      const bucket = await getCompanyBucket(userData.empresa_id);
      const response = await getSignedUrl(bucket.data[0].bucket_name, Imagem);

      const imageSrcWithoutRegion = response.data.replace(
        /s3\..*?\.amazonaws\.com/,
        "s3.amazonaws.com"
      );

      MostrarImage(await fetchImage(imageSrcWithoutRegion));
    }

    fetchLinkDaFoto();
  };

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
  };

  const Editor = () => {
    console.log("to aqui");
  };

  return (
    <div>
      <button onClick={openImgEditor}>Open image editor</button>
      {isImgEditorShown && imageUrl && (
        <img src={imageUrl} style={{ width: "100%" }} alt="Original" />
      )}
      {imageUrl && (
        <div>
          <div>
            <button onClick={() => adjustBrightness(10)}>
              Increase Brightness
            </button>
            <button onClick={() => adjustBrightness(-10)}>
              Decrease Brightness
            </button>
          </div>
          <div>
            <button onClick={() => adjustContrast(10)}>
              Increase Contrast
            </button>
            <button onClick={() => adjustContrast(-10)}>
              Decrease Contrast
            </button>
          </div>
          <div>
            <button onClick={() => adjustSaturation(1.2)}>
              Increase Saturation
            </button>
            <button onClick={() => adjustSaturation(0.8)}>
              Decrease Saturation
            </button>
          </div>
          <div>
            <button onClick={() => adjustBGR([10, 0, 0])}>Increase Red</button>
            <button onClick={() => adjustBGR([0, 10, 0])}>
              Increase Green
            </button>
            <button onClick={() => adjustBGR([0, 0, 10])}>Increase Blue</button>
          </div>
          <canvas ref={(ref) => setCanvas(ref)}></canvas>
        </div>
      )}
      {imageUrl && <button onClick={closeImgEditor}>Close image editor</button>}
    </div>
  );
}

export default ImagemEditor;
