import React from "react";
//import api from '../../Services/api';

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import MenuItem from '@material-ui/core/MenuItem';

export default function MateriaisSelect({
  label,
  id,
  value,
  empresaId,
  materiais,
  materialCurrent,
  setMaterialId,
  ...rest
}) {
  const [material, setMaterial] = React.useState({
    id: 0,
    nome: "",
  });

  const [materialSelected, setMaterialSelected] =
    React.useState(materialCurrent);

  const [dataLoaded, setDataLoaded] = React.useState(false);

  return (
    <Autocomplete
      id={id}
      name={id}
      size="small"
      value={materialSelected}
      options={materiais ? materiais : []}
      getOptionLabel={(option) => option.nome}
      getOptionSelected={(option, value) => option.id === value.id}
      //defaultValue={produtos.find(v => v.nome[0])}
      onChange={(event, newValue) => {
        if (newValue) {
          setMaterialSelected(newValue);
          setMaterialId(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
    />
  );
}
