import React, { useContext, useEffect, useRef, useState } from "react";

import api, {
  associateBucket,
  createBucket,
  generateBucketToken,
  getAllBuckets,
  getCompanyById,
} from "../../Services/api";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Title from "../Types/Tittle";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import TextMaskCellPhone from "../../functions/TextMaskCellPhone";
import TextMaskPhone from "../../functions/TextMaskPhone";
import TextMaskCnpj from "../../functions/TextMaskCnpj";

import Copyright from "../../functions/Copyright";

import Snackbar from "../../components/SnackBar/index";
import SubTittle from "../Types/SubTittle";
import UserContext from "../../context/User.context";
import getEmpresaBucketName from "../../functions/getEmpresaBucketName";
import { useAuth } from "../../context/User.provider";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function CompanyForm({ setList, companyId }) {
  const classes = useStyles();

  const ref = useRef(false);

  const history = useHistory();
  const location = useLocation();

  const { userData, setUserData } = useAuth();
  const [bucketName, setBucketName] = useState("");
  // const [userData, setUserData] = useContext(UserContext);
  const [buckets, setBuckets] = useState([]);
  const [bucketSelected, setBucketSelected] = useState({});
  const [token, setToken] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  const [company, setCompany] = useState({
    id: 0,
    cod_empresa: "",
    nome: "",
    email: "",
    razao_social: "",
    cnpj: "",
    ie: "",
    telefone: "",
    celular: "",
    site: "",
    smtp_host: "",
    smtp_port: "",
    smtp_user: "",
    smtp_pass: "",
    smtp_security: "t",
    showPassword: false,
  });

  window.addEventListener("popstate", function (event) {
    setList();
    if (ref.current === false) {
      history.push(`/${process.env.REACT_APP_FRONT_ALIAS}/company`);
      ref.current = true;
      return;
    } else {
      //console.log("passou", history.length);
    }
  });

  async function handleAssociateBucket() {
    try {
      const object = {
        bucketName: bucketSelected,
      };
      const res = await associateBucket(company.id, object);
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getBuckets() {
    try {
      const response = await getAllBuckets();
      setBuckets(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function GenerateToken() {
    try {
      const response = await generateBucketToken(company.id);
      setToken(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleCreateBucket() {
    // const name = await getEmpresaBucketName(companyId);
    try {
      const res = await createBucket(bucketName);
      if (res.data.code === "InvalidBucketName") {
        setSnackbarMessage("Nome do Bucket inválido");
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      if (res.data) {
        setSnackbarMessage("Bucket criado com sucesso.");
        setSnackbarKind("success");
        setOpenSnackbar(true);
        console.log(res.data);
        return;
      }
    } catch (err) {
      console.log("erro", err);

      setSnackbarMessage("Erro ao criar Bucket");
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function getCompany(id) {
    try {
      if (id !== 0) {
        const res = await getCompanyById(id);
        console.log(res.data);
        setCompany({
          id: res.data.id,
          cod_empresa: res.data.cod_empresa,
          nome: res.data.nome,
          email: res.data.email,
          razao_social: res.data.razao_social,
          cnpj: res.data.cnpj || "",
          ie: res.data.ie,
          telefone: res.data.telefone,
          celular: res.data.celular,
          site: res.data.site,
          status: res.data.status,
          smtp_host: res.data.smtp_host,
          smtp_port: res.data.smtp_port,
          smtp_user: res.data.smtp_user,
          smtp_pass: res.data.smtp_pass,
          smtp_security: res.data.smtp_security,
          showPassword: false,
        });
        setToken(res.data.bucket_token);
        setBucketSelected(() => {
          console.log(res.data.bucket_name);
          return { Name: res.data.bucket_name };
        });
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    //setOpen(true);
  }

  useEffect(() => {
    async function getData() {
      await getCompany(companyId);
      await getBuckets();
    }
    getData();
  }, [companyId]);

  async function handleUpdateCompany(event) {
    event.preventDefault();
    if (!company.nome) {
      setSnackbarMessage("Favor informar o nome da empresa");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!company.email) {
      setSnackbarMessage("Favor informar o e-mail da empresa");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      handleAssociateBucket();
      await api
        .get("/empresa/" + company.id + "/empresa/" + company.email)
        .then(async (res) => {
          if (res.data.id) {
            setSnackbarMessage("E-mail já existente.");
            setSnackbarKind("warning");
            setOpenSnackbar(true);
            return;
          }

          try {
            await api.put("/empresa/update", company);
            setSnackbarMessage("Empresa atualizada com sucesso.");
            setSnackbarKind("success");
            setOpenSnackbar(true);
          } catch (err) {
            setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
            setSnackbarKind("error");
            setOpenSnackbar(true);
          }
        });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function handleCreateCompany(event) {
    event.preventDefault();
    if (!company.nome) {
      setSnackbarMessage("Favor informar o nome da empresa");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!company.email) {
      setSnackbarMessage("Favor informar o e-mail da empresa");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      await api
        .get("/empresa/" + company.id + "/empresa/" + company.email)
        .then(async (res) => {
          if (res.data.id) {
            setSnackbarMessage("E-mail já existente.");
            setSnackbarKind("warning");
            setOpenSnackbar(true);
            return;
          }

          try {
            await api.post("/empresa", company).then((ress) => {
              setSnackbarMessage("Empresa criada com sucesso.");
              setSnackbarKind("success");
              setOpenSnackbar(true);
              getCompany(ress.data.id);
            });
          } catch (err) {
            setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
            setSnackbarKind("error");
            setOpenSnackbar(true);
          }
        });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  const handleClickShowPassword = () => {
    setCompany({ ...company, showPassword: !company.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Empresa</Title>

              <FormControl className={classes.formControl}>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <TextField
                      required
                      id="nome"
                      name="nome"
                      label="Nome fantasia"
                      value={company.nome || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, nome: val };
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <TextField
                      required
                      id="razao_social"
                      name="razao_social"
                      label="Razão Social"
                      value={company.razao_social || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, razao_social: val };
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="cod_empresa"
                      name="cod_empresa"
                      label="Cód. empresa"
                      fullWidth
                      value={company.cod_empresa || ""}
                      onChange={(event) => {
                        setCompany((prevState) => {
                          return {
                            ...prevState,
                            cod_empresa: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="celular"
                      name="celular"
                      label="Celular"
                      fullWidth
                      InputProps={{ inputComponent: TextMaskCellPhone }}
                      value={company.celular || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, celular: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="telefone"
                      name="telefone"
                      label="Telefone"
                      fullWidth
                      InputProps={{ inputComponent: TextMaskPhone }}
                      value={company.telefone || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, telefone: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      required
                      id="email"
                      name="email"
                      label="E-mail"
                      fullWidth
                      value={company.email || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, email: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={4}>
                    <TextField
                      id="cnpj"
                      name="cnpj"
                      label="Cnpj"
                      fullWidth
                      InputProps={{ inputComponent: TextMaskCnpj }}
                      value={company.cnpj || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, cnpj: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={4}>
                    <TextField
                      id="ie"
                      name="ie"
                      label="Insc. Estadual"
                      fullWidth
                      value={company.ie || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, ie: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={4}>
                    <TextField
                      id="site"
                      name="site"
                      label="Site"
                      fullWidth
                      value={company.site || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, site: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <SubTittle>Dados para envio de e-mail</SubTittle>
                  </Grid>

                  <Grid item sm={4}>
                    <TextField
                      id="smtp_host"
                      name="smtp_host"
                      label="SMTP Host"
                      fullWidth
                      value={company.smtp_host || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, smtp_host: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={2}>
                    <TextField
                      id="smtp_port"
                      name="smtp_port"
                      label="SMTP Porta"
                      fullWidth
                      value={company.smtp_port || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, smtp_port: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={2}>
                    <TextField
                      id="smtp_user"
                      name="smtp_user"
                      label="SMTP E-mail"
                      fullWidth
                      type="password"
                      disabled
                      value={userData.SMTP_USER || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setUserData((prevState) => {
                          return { ...prevState, smtp_user: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={2}>
                    <TextField
                      select
                      fullWidth
                      label="Segurança"
                      value={company.smtp_security}
                      id="smtp_security"
                      onChange={(event) => {
                        const val = event.target.value;
                        setCompany((prevState) => {
                          return { ...prevState, smtp_security: val };
                        });
                      }}
                    >
                      <MenuItem key="security_1" value="t">
                        TSL
                      </MenuItem>
                      <MenuItem key="security_2" value="s">
                        SSL
                      </MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item sm={2}>
                    <TextField
                      id="smtp_pass"
                      name="smtp_pass"
                      label="SMTP Senha"
                      fullWidth
                      disabled
                      type={company.showPassword ? "text" : "password"}
                      value={userData.SMTP_PASS || ""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              // onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {company.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        const val = event.target.value;
                        setUserData((prevState) => {
                          return { ...prevState, SMTP_PASS: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={12}>
                    <SubTittle>Integração AWS</SubTittle>
                  </Grid>

                  <Grid
                    item
                    align="left"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    sm={2}
                  >
                    <TextField
                      type="text"
                      value={bucketName}
                      onChange={(e) => setBucketName(e.target.value)}
                    />
                    <Button
                      onClick={handleCreateBucket}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                    >
                      Criar Bucket
                    </Button>
                  </Grid>
                  <Grid item align="left" sm={2}>
                    {buckets.length > 0 && bucketSelected && (
                      <>
                        <Autocomplete
                          disablePortal
                          id="buckets"
                          options={buckets}
                          defaultValue={bucketSelected}
                          getOptionLabel={(option) => option.Name}
                          getOptionSelected={(option, value) =>
                            option.Name === value.Name
                          }
                          // sx={{ width: "300px" }}
                          renderInput={(params) => (
                            <TextField {...params} label="Buckets" />
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              setBucketSelected(value);
                            }
                          }}
                          onBlur={() => {
                            handleAssociateBucket();
                          }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item sm={2}></Grid>

                  <Grid item sm={12}>
                    <SubTittle>Integração Robô</SubTittle>
                  </Grid>

                  <Grid item sm={8}>
                    {token && <TextField fullWidth value={token} />}
                    <Button
                      onClick={() => GenerateToken()}
                      className={classes.button}
                      variant="contained"
                      color="primary"
                    >
                      Gerar Token
                    </Button>
                  </Grid>

                  <Grid item align="right" sm={4}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={
                        location.pathname ===
                        `/${process.env.REACT_APP_FRONT_ALIAS}/company`
                          ? setList
                          : history.push(
                              `/${process.env.REACT_APP_FRONT_ALIAS}/company`
                            )
                      }
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    {parseInt(company.id) !== 0 ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdateCompany}
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Salvar
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateCompany}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                      >
                        Nova empresa
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        <Box pt={4}>
          <Copyright />
        </Box>

        <Snackbar
          open={openSnackbar}
          closeBtn={true}
          closeSnackbar={closeSnackbar}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
        ></Snackbar>
      </Container>
    </main>
  );
}
