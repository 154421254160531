import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ProcessoValue from "../../Chapa/Processo";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { getChapasFromBundle, getImagesById } from "../../../Services/api";
import { Box, Button, Modal } from "@material-ui/core";
import ModalDetails from "./ModalDetails";
import { useAuth } from "../../../context/User.provider";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "10%",
    display: "flex",
    width: 300,
  },
  media: {
    paddingTop: 10,
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  card: {
    marginLeft: 5,
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    minHeight: 420,
  },
  graph: {
    color: "blue",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
    paddingLeft: 10,
    width: "100%",
  },
  buttons: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  check: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "25px",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "@media (min-width: 630px) and (max-width: 680px)": {
    root: { width: 325 },
  },
  "@media (min-width: 930px) and (max-width: 980px)": {
    root: { width: 320 },
  },
  "@media (min-width: 1230px) and (max-width: 1280px)": {
    root: { width: 315 },
  },
  "@media (min-width: 1530px) and (max-width: 1580px)": {
    root: { width: 310 },
  },
  "@media (min-width: 1830px) and (max-width: 1880px)": {
    root: { width: 310 },
  },
  "@media (min-width: 2130px) and (max-width: 2180px)": {
    root: { width: 310 },
  },
  "@media (min-width: 2430px) and (max-width: 2480px)": {
    root: { width: 310 },
  },
}));

// function renderImages(chapaobj) {

//   //console.log(chapaobj.nome)

//    return (
// //      <ImageGallery visibility={false} images={chapaobj} height={50} width={160}></ImageGallery>
//     );
//  }

export default function ItemCard({
  bundle,
  index,
  handleChangeCheck,
  userId,
  consultaPedido,
  selectedBundles,
  allBundles,
  setIsOpen,
  showSlides,
  slideIndex,
}) {
  const classes = useStyles();
  const [foto, setFoto] = useState("");
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imageSelected, setImageSelected] = useState(index);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  const { userData } = useAuth();

  function DataHora() {
    var dataAtual = new Date();
    var dia = String(dataAtual.getDate()).padStart(2, "0");
    var mes = String(dataAtual.getMonth() + 1).padStart(2, "0");
    var ano = dataAtual.getFullYear();
    var hora = dataAtual.getHours();
    var minuto = dataAtual.getMinutes();
    var segundo = dataAtual.getSeconds();

    return Date.parse(
      ano +
        "-" +
        mes +
        "-" +
        dia +
        "T" +
        hora +
        ":" +
        minuto +
        ":" +
        segundo +
        ".000Z"
    );
  }

  function tipografia(corTexto, Disponibilidade, Vendedor, cor, cliente) {
    return (
      <div style={{ display: "flex", flexDirection: "column", color: cor }}>
        <Typography
          style={{ color: { cor } }}
          variant="body2"
          color="inherit" //{corTexto}
          component="p"
        >
          <b>{Disponibilidade}!</b>
        </Typography>
        <Typography variant="body2" color="inherit" component="p">
          {cliente ? cliente : " "}
        </Typography>
        <Typography variant="body2" color="inherit" component="p">
          {Vendedor ? Vendedor : " "}
        </Typography>
      </div>
    );
  }
  const pedido = bundle.numero_pedido_cliente
    ? bundle.numero_pedido_cliente
    : null;

  function verificaReserva(res, vendedor) {
    var castigo = 1;

    //console.log(bundle);
    //bundle.processo_status

    //console.log(bundle)
    //  console.log("expira_reserva: "+bundle.expira_reserva)
    //  console.log("expira_reserva_anterior: "+bundle.expira_reserva_anterior)
    //  console.log(".........")
    //  console.log("userId: "+ userId);
    //  console.log("id_vendedor: "+bundle.id_vendedor);
    //  console.log("id_vendedor_anterior: "+ bundle.id_vendedor_anterior);
    //  console.log("+++++++")
    var expira = bundle.expira_reserva == null ? 0 : bundle.expira_reserva;

    if (bundle.emestoque === 0) {
      return tipografia(
        "error",
        "Unavailable!",
        "",
        "red",
        bundle?.cliente_nome ? bundle?.cliente_nome : ""
      );
    } else if (bundle.processo_status == "A") {
      return tipografia(
        "error",
        "Unavailable!",
        "",
        "red",
        (bundle?.cliente_nome ? bundle?.cliente_nome : "") + " EM PROCESSO"
      );
    } else if (pedido !== null) {
      return tipografia(
        "error",
        "Unavailable!",
        "",
        "red",
        bundle?.cliente_nome ? bundle?.cliente_nome : ""
      );
    } else if (bundle.data_aceite !== null) {
      //console.log(bundle)
      //console.log(bundle.cliente_nome)
      return tipografia(
        "error",
        "Approved",
        vendedor,
        "green",
        bundle?.cliente_nome ? bundle?.cliente_nome : ""
      );
    } else if (
      bundle.id_vendedor == null &&
      bundle.id_vendedor_anterior == null
    ) {
      return tipografia(
        "textPrimary",
        "Available!",
        "",
        "blue",
        bundle?.cliente_nome ? bundle?.cliente_nome : ""
      );
    } else if (
      bundle.expira_reserva == null &&
      bundle.expira_reserva_anterior == null
    ) {
      return tipografia(
        "textPrimary",
        "Available!",
        "",
        "blue",
        bundle?.cliente_nome ? bundle?.cliente_nome : ""
      );
    } else if (parseInt(bundle.id_vendedor) === parseInt(userId)) {
      if (expira > 0) {
        return tipografia(
          "textPrimary",
          "Due Date " + expira + " Day(s)",
          vendedor,
          "orange",
          bundle?.cliente_nome ? bundle?.cliente_nome : ""
        );
      } else if (expira <= 0 && expira >= castigo * -1) {
        return tipografia("error", "Expired!", vendedor);
      } else if (expira < castigo * -1) {
        return tipografia(
          "textPrimary",
          "Available!",
          vendedor,
          "blue",
          bundle?.cliente_nome ? bundle?.cliente_nome : ""
        );
      }
    } else if (parseInt(bundle.id_vendedor) !== parseInt(userId)) {
      //primeiro verificar se tem reserva
      if (bundle.expira_reserva !== null) {
        if (expira > 0) {
          return tipografia(
            "textPrimary",
            "Due Date " + expira + " Day(s)",
            vendedor,
            "orange",
            bundle?.cliente_nome ? bundle?.cliente_nome : ""
          );
        } else if (expira <= 0) {
          return tipografia(
            "textPrimary",
            "Available!",
            "",
            "blue",
            bundle?.cliente_nome ? bundle?.cliente_nome : ""
          );
        }
      }
      //segundo verifica se o vendedor anterior é o atual
      else if (parseInt(bundle.id_vendedor_anterior) === parseInt(userId)) {
        if (expira > 0) {
          return tipografia(
            "textPrimary",
            "Due Date ==== " + expira + " Day(s)",
            vendedor,
            "orange",
            bundle?.cliente_nome ? bundle?.cliente_nome : ""
          );
        } else if (expira <= 0) {
          return tipografia(
            "textPrimary",
            "Available!",
            vendedor,
            "blue",
            bundle?.cliente_nome ? bundle?.cliente_nome : ""
          );
        }
      } else if (parseInt(bundle.id_vendedor_anterior) !== parseInt(userId)) {
        if (parseInt(bundle.id_vendedor) !== parseInt(userId)) {
          return tipografia(
            "textPrimary",
            "Available!",
            "",
            "blue",
            bundle?.cliente_nome ? bundle?.cliente_nome : ""
          );
        }
      }
    }
  }

  const checked = React.useMemo(
    () =>
      selectedBundles?.some((item) => {
        return item?.id === bundle.id;
      }),
    [selectedBundles]
  );

  function verificaVendedor() {
    var cai = 7;
    var castigo = 1;
    var expira = bundle.expira_reserva == null ? 0 : bundle.expira_reserva;
    //console.log(userData?.userRole);
    if (
      userData?.userRole === "gerente" ||
      userData?.userRole === "administrador"
    ) {
      return true; //"undefined"
    } else if (
      (userData?.userRole === "" || userData?.userRole === "cliente") &&
      bundle.data_aceite
    ) {
      return false;
    } else if (bundle.emestoque === 0) {
      return false;
    } else if (bundle.processo_status == "A") {
      return false;
    } else if (pedido !== null && consultaPedido === false) {
      return false;
    } else if (pedido !== null && consultaPedido === true) {
      return true;
    } else if (
      bundle.id_vendedor == null &&
      bundle.id_vendedor_anterior == null
    ) {
      return true;
    } else if (
      bundle.expira_reserva == null &&
      bundle.expira_reserva_anterior == null
    ) {
      return true;
    } else if (parseInt(bundle.id_vendedor) === parseInt(userId)) {
      if (expira > 0) {
        return true;
      } else if (expira <= 0 && expira >= castigo * -1) {
        return true;
      } else if (expira < castigo * -1) {
        return true;
      }
    } else if (parseInt(bundle.id_vendedor) !== parseInt(userId)) {
      //primeiro verificar se tem reserva
      if (bundle.expira_reserva !== null) {
        if (expira > 0) {
          return false;
        } else if (expira <= 0) {
          return true;
        }
      }
      //segundo verifica se o vendedor anterior é o atual
      else if (parseInt(bundle.id_vendedor_anterior) === parseInt(userId)) {
        if (expira > 0) {
          return false;
        } else if (expira <= 0) {
          return true;
        }
      } else if (parseInt(bundle.id_vendedor_anterior) !== parseInt(userId)) {
        return true;
      }
    }
  }

  const openImageModal = () => {
    setOpenModal(true);
  };

  const closeImageModal = () => {
    setOpenModal(false);
  };

  // function openDetails() {}

  // const getFotosByid = async (id) => {
  //   const res = await getImagesById(id);
  //   if (res.data[0]) {
  //     //console.log("resfoto", res.data[0].linkS3);
  //     setFoto(res.data[0].linkS3thumb);
  //   } else {
  //     console.log("sem id_foto");
  //   }
  // };

  // useEffect(() => {
  //   const getFotos = async () => {
  //     await getFotosByid(bundle.foto_remoto);
  //   };
  //   getFotos();
  // }, []);
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            onClick={() => {
              setIsOpen(true);
              showSlides(slideIndex);
            }}
          >
            <div>
              {bundle.linkS3Thumb ? (
                <img
                  src={bundle.linkS3Thumb}
                  alt={bundle.bundle}
                  srl_gallery_image="true"
                  loading="lazy"
                  height={180}
                  width={280}
                />
              ) : (
                <div
                  style={{
                    height: 180,
                    width: 280,
                    backgroundColor: "#0182c6",
                  }}
                >
                  {" "}
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "semi-bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      color: "#fff9f9",
                    }}
                  >
                    WITHOUT PICTURE
                  </Typography>
                </div>
              )}
            </div>
            {/* </a> */}
          </CardMedia>
          {/* <CardMedia
          className={classes.media}
          //image={`${process.env.REACT_APP_API_URL}images/${bundle.foto_local}`}
          image={`http://app.ornatec.com.br:3333/images/${bundle.foto_local}`}
          
          //onClick={renderImages(bundle)}
        >
          
        </CardMedia> */}
          <CardContent className={classes.content}>
            <div className={classes.headerContent}>
              <Typography gutterBottom variant="h6" component="h1">
                {bundle.numero_bundle_cliente}
              </Typography>
              {verificaReserva(bundle.expira_reserva, bundle.vendedor_nome)}
            </div>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.nomematerialingles.substr(0, 2).toUpperCase() === "MI"
                ? bundle.nomematerialportugues
                : bundle.nomematerialingles
                ? bundle.nomematerialingles
                : bundle.nome_ingles
                ? bundle.nome_ingles
                : bundle.nome_portugues}
            </Typography>

            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.processo ? ProcessoValue(bundle.processo) + " " : null}
              {bundle.bloco ? "Bloco: " + bundle.bloco : null}
              {bundle.classificacao ? " - " + bundle.classificacao : null}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.material ? "Material: " + bundle.material : null}
              {bundle.espessura
                ? " Espessura: " + bundle.espessura + " Cm"
                : null}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.comprimento_liquido ? bundle.comprimento_liquido : null}m
              x {bundle.altura_liquida ? bundle.altura_liquida : null}m /{" "}
              {bundle.comprimento_liquido
                ? (bundle.comprimento_liquido * 39.3701).toPrecision(5)
                : null}
              " x{" "}
              {bundle.altura_liquida
                ? (bundle.altura_liquida * 39.3701).toPrecision(4)
                : null}
              "
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.chapas.length <= 1
                ? "Total Slabs: " + bundle.total_chapas
                : "Total Slabs: " + bundle.chapas.length}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.chapas.length <= 1
                ? bundle.lista_chapas
                : bundle.chapas.map((c, idx) => c.numerochapa.substr(1) + ` `)}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.numero_pedido_cliente
                ? "Pedido: " + bundle.numero_pedido_cliente
                : null}
              {bundle.vendedor_pedido_cliente
                ? " " + bundle.vendedor_pedido_cliente
                : null}
            </Typography>

            {/* <Button
            variant="contained"
            set="ddd"
            color="default"
            fullWidth
            onClick={() => alert("Teste")}
            className={classes.button}
            startIcon={<LibraryAddIcon />}
          > Visualizar Chapas
          </Button> */}
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.buttons}>
          <div className={classes.check}>
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedBundles"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={checked}
                  color="primary"
                  size="medium"
                  value={JSON.stringify(bundle)}
                />
              }
              label="Selecionar"
              onChange={handleChangeCheck}
              disabled={!verificaVendedor()}
            />
            <Button
              onClick={handleOpenModal}
              size="small"
              variant="outlined"
              color="primary"
            >
              Details
            </Button>
          </div>
        </CardActions>
      </Card>
      <ModalDetails
        chapas={bundle.chapas}
        open={open}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
}
