import React, { useEffect, useRef } from "react";

const UIInfiniteScroll = ({ fetchMore }) => {
  const containerRef = useRef();
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // observer.disconnect();
        fetchMore();
      }
    }, options);
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return <div id="fetchmore" ref={containerRef} />;
};

export default UIInfiniteScroll;
