import React, { useEffect, useState, useContext, useRef } from "react";

import UserContext from "../../context/User.context";

import api from "../../Services/api";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Title from "../Types/Tittle";
import Radio from "@material-ui/core/Radio";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";
import EmailIcon from "@material-ui/icons/Email";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

import NumberFormatCustom from "../../functions/NumberFormatCustom";
import PriceFormatCustom from "../../functions/PriceFormatCustom";

import Copyright from "../../functions/Copyright";

import Snackbar from "../../components/SnackBar/index";

import ListChapasOferta from "../../components/Chapa/ListChapasOferta";
import ListBundles from "../../components/Bundle/ListBundles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SentList from "./SentList";
import ModalResendOffer from "./ModalResendOffer";
import { useAuth } from "../../context/User.provider";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  TextBox: {
    margin: theme.spacing(1),
    marginTop: 0,
    width: "30ch",
  },
  TextField: {
    margin: theme.spacing(1),
    marginRight: 0,
    marginBottom: 0,
  },
}));

export default function OfertaForm({ setList, ofertaId }) {
  const classes = useStyles();

  const ref = useRef(false);

  const history = useHistory();
  const location = useLocation();

  // const [userData] = useContext(UserContext);
  const { userData } = useAuth();

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [ofertsData, setOfertsData] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");

  const [emailData, setEmailData] = useState({});

  const [showEmailModal, setShowEmailModal] = useState(false);

  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  const [oferta, setOferta] = useState({
    id: 0,
    usuario_id: 0,
    empresa_id: 0,
    pessoa_id: 0,
    valor_oferta: 0,
    desconto: 0,
    valor_total: 0,
    status: "0",
    data_oferta: null,
    prazo: 0,
    observacao: "",
    subject: "",
    nome: "",
    usuario: "",
    empresa: {
      nome: "",
      vendedor: "",
      email: "",
    },
    chapas: [],
    bundles: [],
  });

  window.addEventListener("popstate", function (event) {
    setList();
    if (ref.current === false) {
      history.push(`/${process.env.REACT_APP_FRONT_ALIAS}/oferta`);
      ref.current = true;
      return;
    } else {
      console.log("passou", history.length);
    }
  });
  //são os destinatarios das ofertas
  async function getEmailOfertas() {
    try {
      const { response } = await api.get(`/ofertaPessoa/${ofertaId}`);
      //console.log(response);
      setOfertsData(response);
    } catch (err) {
      console.log("Erro ao Emails" + err);
    }
  }

  //  async function getEmailInfo() {
  //    try {
  //     await api
  //        .get(
  //          "/empresa/" + userData.empresa_id
  //            ? userData.empresa_id
  //           : cookieData.empresa_id
  //        )
  //        .then((res) => {
  //          console.log(res);
  //          setEmailData({
  //            empresa: {
  //              nome: res.data.nome,
  //              vendedor: userData.nome,
  //              email: userData.email,
  //           },
  //          });
  //          // setEmailLoaded(true);
  //        });
  //      // console.log(oferta)
  //    } catch (err) {
  //      // setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
  //      // setSnackbarKind("error");
  //      // setOpenSnackbar(true);
  //      console.log(err);
  //    }
  //  }

  async function getOferta(ofertaId) {
    if (ofertaId !== 0) {
      try {
        // console.log(
        //   "ID Oferta: " + ofertaId + " Id Empresa: " + userData.empresa_id
        // );
        api
          .get("/ofertass/oferta/" + userData.empresa_id + "/" + ofertaId)
          .then((response) => {
            setOferta({
              id: response.data.id,
              usuario_id: response.data.usuario_id,
              empresa_id: response.data.empresa_id,
              pessoa_id: response.data.pessoa_id,
              valor_oferta: response.data.valor_oferta,
              desconto: response.data.desconto,
              valor_total: response.data.valor_total,
              status: response.data.status,
              data_oferta: response.data.data_oferta,
              prazo: response.data.prazo,
              observacao: response.data.observacao,
              nome: response.data.usuario,
              usuario: response.data.usuario,
              //empresa: response.data.nome,
              empresa: {
                nome: response.data.nome,
                vendedor: userData.nome,
                email: userData.email,
              },
              chapas: [],
              bundles: [],
            });

            // console.log(oferta);
            // console.log(userData)
            setDataLoaded(true);
          });
        api
          .get(
            "/ofertass/bundlesOferta/" + userData.empresa_id + "/" + ofertaId
          )
          .then((response) => {
            //console.log(res.data);
            setOferta((prevState) => {
              return { ...prevState, bundles: response.data };
            });
          });
        //console.log(oferta);
      } catch (err) {
        setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
        setSnackbarKind("error");
        setOpenSnackbar(true);
      }
    }

    //setOpen(true);
  }

  useEffect(() => {
    setLoading(true);
    async function getDados() {
      await getOferta(ofertaId);

      // await getEmailInfo()

      await getEmailOfertas();

      setLoading(false);
    }
    getDados();
  }, [ofertaId]);

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Oferta</Title>

              <FormControl className={classes.formControl}>
                <Grid container spacing={3}>
                  <Grid item sm={1} className={classes.TextField}>
                    <TextField
                      id="id"
                      name="id"
                      label="Númedo da Oferta"
                      variant="outlined"
                      fullWidth
                      value={oferta.id}
                      onChange={(event) => {
                        setOferta((prevState) => {
                          return {
                            ...prevState,
                            id: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={6} className={classes.TextField}>
                    <TextField
                      id="usuario"
                      name="usuario"
                      label="Vendedor"
                      variant="outlined"
                      fullWidth
                      value={oferta.usuario}
                      onChange={(event) => {
                        setOferta((prevState) => {
                          return { ...prevState, usuario: event.target.value };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={4} className={classes.TextField}>
                    <TextField
                      required
                      id="empresa"
                      name="empresa"
                      label="Empresa"
                      variant="outlined"
                      value={oferta.empresa}
                      //onChange={(event) => {
                      //  setOferta((prevState) => {
                      //    return {
                      //      ...prevState,
                      //      empresa: event.target.value,
                      //     };
                      //   });
                      //}}
                      fullWidth
                    />
                  </Grid>

                  {/* <Grid item sm={3}>
                    <TextField
                      id="valor_oferta"
                      name="valor_oferta"
                      label="Valor da Oferta"
                      fullWidth
                      value={oferta.valor_oferta || ""}
                      onChange={(event) => {
                        setOferta((prevState) => {
                          return {
                            ...prevState,
                            valor_oferta: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid> */}

                  {/* <Grid item sm={3}>
                    <TextField
                      required
                      id="desconto"
                      name="desconto"
                      label="Desconto"
                      value={oferta.desconto || ""}
                      onChange={(event) => {
                        setOferta((prevState) => {
                          return {
                            ...prevState,
                            desconto: event.target.value,
                          };
                        });
                      }}
                      fullWidth
                    />
                  </Grid> */}

                  {/* <Grid item sm={3}>
                    <TextField
                      id="status"
                      name="statis"
                      label="Situação"
                      fullWidth
                      value={oferta.status || ""}
                      onChange={(event) => {
                        setOferta((prevState) => {
                          return {
                            ...prevState,
                            status: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid> */}
                  {/* 
                  <Grid item sm={12} className={classes.TextBox}>
                    <TextField 
                      id="mensagem"
                      name="mensagem"
                      label="Mensagem"
                      fullWidth
                      multiline
                      maxRows={6}
                      variant="outlined"
                      value={oferta.observacao || ""}
                      onChange={(event) => {
                        setOferta((prevState) => {
                          return { ...prevState, observacao: event.target.value };
                        });
                      }}
                    />
                  </Grid> */}

                  {oferta.chapas.length ? (
                    <>
                      <ListChapasOferta
                        chapas={oferta.chapas}
                      ></ListChapasOferta>
                    </>
                  ) : null}

                  {oferta.bundles.length ? (
                    <ListBundles bundles={oferta.bundles}></ListBundles>
                  ) : null}

                  <Grid item align="right" sm={12}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={
                        location.pathname ===
                        `/${process.env.REACT_APP_FRONT_ALIAS}/oferta`
                          ? setList
                          : history.push(
                              `/${process.env.REACT_APP_FRONT_ALIAS}/oferta`
                            )
                      }
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    {/* {parseInt(oferta.id) !== 0 ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {}}
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Salvar
                        </Button>
                      </>
                    ) : null
                    } */}
                    {/* TO-DO //Colocar um botão para enviar e-mail */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setShowEmailModal(true);
                      }}
                      className={classes.button}
                      startIcon={<EmailIcon />}
                    >
                      Reenviar oferta
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
            {showEmailModal == true && (
              <ModalResendOffer
                ofertaId={ofertaId}
                setOfertsData={setOfertsData}
                ofertsData={ofertsData}
                oferta={oferta}
                setOferta={setOferta}
                showEmailModal={showEmailModal}
                setShowEmailModal={setShowEmailModal}
              />
            )}

            {/* AAAAAAA */}
            <SentList
              oferta={oferta}
              showEmailModal={showEmailModal}
              setOferta={setOferta}
              ofertaId={ofertaId}
            />
          </Grid>
        </Grid>

        <Box pt={4}>
          <Copyright />
        </Box>

        <Snackbar
          open={openSnackbar}
          closeBtn={true}
          closeSnackbar={closeSnackbar}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
        ></Snackbar>
      </Container>
    </main>
  );
}
