import React, { useState, useEffect, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";

import api from "../../Services/api";

import UserContext from "../../context/User.context";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Title from "../Types/Tittle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import SearchIcon from "@material-ui/icons/Search";

import TablePaginationActions from "../../functions/TablePaginationActions";
import { useAuth } from "../../context/User.provider";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Products({ setProduct, productId }) {
  const classes = useStyles();

  const { idProduto } = useParams() || "0";

  const { userData } = useAuth();
  // const [userData] = useContext(UserContext);

  const history = useHistory();

  const [produtoData, setProdutoData] = useState([]);
  const [produtoColumns, setProdutoColumns] = useState([
    { field: "nome", headerName: "Nome do Produto", width: 300 },
    { field: "precocusto", headerName: "Preço custo", width: 200 },
    { field: "precovendam2", headerName: "Preço venda", width: 200 },
    { field: "precoporp2", headerName: "Preço venda m2", width: 150 },
  ]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function getProdutos(nameSearch) {
    try {
      if (parseInt(userData.empresa_id) === parseInt(process.env.REACT_APP_EMPRESA_ID)) {
        await api.get("/produtos/" + nameSearch || "").then((res) => {
          setProdutoData(res.data);
        });
      } else {
        await api
          .get("/produtosbyEmpresa/" + userData.empresa_id + "?nomeBusca=" + nameSearch)
          .then((res) => {
            setProdutoData(res.data);
          });
      }
    } catch (err) {
      console.log("Erro ao consultar produtos");
    }
  }

  useEffect(() => {
    if (idProduto) {
      setProduct(idProduto);
      return;
    }

    getProdutos("");
  }, [productId]);

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item align="left" sm={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  history.goBack();
                }}
                className={classes.button}
              >
                Voltar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setProduct(0);
                }}
                className={classes.button}
              >
                Novo Produto
              </Button>
            </Grid>

            <Grid item align="left" sm={8}>
              <TextField
                id="nameSearch"
                size="small"
                placeholder="Procurar..."
                fullWidth
                onChange={(event) => {
                  getProdutos(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Produtos</Title>
                {produtoData && produtoData.length > 0 && (
                  <Grid style={{ height: "75vh", width: "100%", paddingTop: "20px" }}>
                    <DataGrid columns={produtoColumns} rows={produtoData} />
                  </Grid>
                )}
                {/* <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Nome do Produto</TableCell>
                      <TableCell>Preço custo</TableCell>
                      <TableCell>Preço venda</TableCell>
                      <TableCell>Preço venda m2</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(produtoData
              ? produtoData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : produtoData
            ).map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => {setProduct(row.id)}}>
                <TableCell>
                  {row.nome}
                </TableCell>
                <TableCell>
                <b>R$ {row.precocusto}</b>
                </TableCell>
                <TableCell>
                <b>R$ {row.precovendam2}</b>
                </TableCell>
                <TableCell>
                <b>R$ {row.precoporp2}</b>
                </TableCell>
                
              </TableRow>
            ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          20,
                          { label: "Todos", value: -1 },
                        ]}
                        colSpan={5}
                        count={produtoData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Registros por página" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table> */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
