import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Chapas from "../../components/Chapa/List";
import ChapaForm from "../../components/Chapa/Chapa";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Chapa() {
  const classes = useStyles();

  const [chapaId, setChapaId] = useState(0);

  const [showView, setShowView] = useState("list");

  function setChapa(chapaId) {
    setChapaId(chapaId);
    setShowView("chapa");
  }

  function setList() {
    setChapaId(0);
    setShowView("list");
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {showView === "list" ? (
        <Chapas setChapa={setChapa} chapaId={chapaId}></Chapas>
      ) : (
        <ChapaForm setList={setList} chapaId={chapaId}></ChapaForm>
      )}
    </div>
  );
}
