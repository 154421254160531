import React, { useState, useEffect, useContext } from "react";

// import UserContext from "../../context/User.context";

import { useHistory, useParams } from "react-router-dom";

import api from "../../Services/api";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import TableFooter from "@material-ui/core/TableFooter";
// import TablePagination from "@material-ui/core/TablePagination";
import Title from "../Types/Tittle";
import Chip from "@material-ui/core/Chip";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneIcon from "@material-ui/icons/Done";
import SearchIcon from "@material-ui/icons/Search";
import SaveIcon from "@material-ui/icons/Save";

import Snackbar from "../../components/SnackBar/index";
import { useAuth } from "../../context/User.provider";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Users({ setUser, userId }) {
  const classes = useStyles();

  const { userData } = useAuth();
  // const [userData] = useContext(UserContext)
  const { idUsuario } = useParams() || "0";

  const history = useHistory();

  const [usuarioData, setUsuarioData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");

  const [columnsUser, setColumnnsUser] = useState([
    { field: "id", headerName: "ID", width: 120 },
    { field: "nome", headerName: "Usuário", width: 200 },
    {
      field: "empresa_id",
      headerName: "Empresa",
      width: 180,
      // renderCell: (params) => {
      //   // return (
      //   //   <CompanyList
      //   //     size="small"
      //   //     key={params.row.id}
      //   //     value={params.row.empresa_id}
      //   //     id={`empresa_id`}
      //   //     onChange={(event) => {
      //   //       setEmpresaValue(params.row.id, "empresa_id", event.target.value);
      //   //     }}
      //   //   />
      //   // );
      // },
    },
    { field: "email", headerName: "E-mail", width: 200 },
    { field: "celular", headerName: "Telefone", width: 150 },
    {
      field: "status",
      headerName: " ",
      width: 150,
      renderCell: (params) => {
        if (params.row.status === "1") {
          return (
            <Chip
              variant="default"
              size="small"
              icon={<DoneIcon></DoneIcon>}
              label="Desautorizar usuário"
              clickable
              color="secondary"
              onClick={() => handleUnautorizeUser(params.row.id)}
            />
          );
        }
        if (params.row.status === "2" || params.row.status === "0") {
          return (
            <Chip
              variant="default"
              size="small"
              icon={<DoneIcon></DoneIcon>}
              label="Autorizar usuário"
              clickable
              color="primary"
              onClick={() =>
                handleAutorizeUser(params.row.id, params.row.empresa_id)
              }
            />
          );
        }
      },
    },
    {
      field: "empresa",
      headerName: " ",
      width: 180,
      renderCell: (params) => {
        // console.log(params);
        // console.log("usuarioData", usuarioData);
        return (
          <Chip
            variant="outlined"
            size="small"
            icon={<SaveIcon></SaveIcon>}
            label="Savar empresa"
            color="primary"
            clickable
            onClick={() =>
              handleUpdateCompany(params.row.id, params.row.empresa_id)
            }
          />
        );
      },
    },
    /**
     *
     */
  ]);

  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  async function getUsers(nameSearch) {
    try {
      await api.get("/usuarios/" + nameSearch || "").then((res) => {
        setUsuarioData(res.data);
        // console.log(res.data);
      });
    } catch (err) {
      console.log("Erro ao consultar usuarios");
    }
  }

  useEffect(() => {
    if (idUsuario) {
      setUser(idUsuario);
      return;
    }

    getUsers("");
  }, [userId, idUsuario]);

  async function handleAutorizeUser(idUser, idCompany) {
    if (!idCompany || idCompany === "0") {
      setSnackbarMessage("Favor selecionar a empresa do usuário!");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }

    try {
      await api.put("/usuarioStatus/?status=1", { id: idUser }).then((res) => {
        handleUpdateCompany(idUser, idCompany);
      });
    } catch (err) {
      console.log("Erro: " + err);
    }
  }

  async function handleUpdateCompany(idUser, idCompany) {
    if (!idCompany || idCompany === "0") {
      setSnackbarMessage("Favor selecionar a empresa do usuário!");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    } else {
      await await api
        .put("usuario/empresa/" + idUser, { id: idUser, empresa_id: idCompany })
        .then((res) => {
          setSnackbarMessage("Empresa associada ao usuário!");
          setSnackbarKind("success");
          setOpenSnackbar(true);
          getUsers("");
        });
    }
  }

  async function handleUnautorizeUser(idUser) {
    try {
      await api.put("/usuarioStatus/?status=2", { id: idUser }).then((res) => {
        getUsers("");
      });
    } catch (err) {
      console.log("Erro: " + err);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item align="left" sm={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  history.goBack();
                }}
                className={classes.button}
              >
                Voltar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setUser(0);
                }}
                className={classes.button}
              >
                Novo Usuário
              </Button>
            </Grid>

            <Grid item align="left" sm={8}>
              <TextField
                id="nameSearch"
                size="small"
                placeholder="Procurar..."
                fullWidth
                onChange={(event) => {
                  getUsers(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Usuários</Title>
                {usuarioData ? (
                  <Grid
                    style={{ height: 700, width: "100%", paddingTop: "20px" }}
                  >
                    <DataGrid
                      autoHeight
                      onCellClick={(e) => setUser(e.row.id)}
                      columns={columnsUser}
                      rows={usuarioData}
                      // onCellEditCommit={handleCellEditCommit}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={12}>
                    Nenhum Usuário
                  </Grid>
                )}
                {/* <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>ID</TableCell>
                      <TableCell>Usuário</TableCell>
                      <TableCell>Empresa</TableCell>
                      <TableCell>E-mail</TableCell>
                      <TableCell>Telefone</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(usuarioData
                      ? usuarioData.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : usuarioData
                    ).map((row, index) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell
                          onClick={() => {
                            setUser(row.id);
                          }}
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            setUser(row.id);
                          }}
                        >
                          {row.nome}
                          {row.status === "1" && (
                            <CheckCircleIcon
                              color="primary"
                              fontSize="small"
                            ></CheckCircleIcon>
                          )}
                        </TableCell>
                        <TableCell>
                          <CompanyList
                            size="small"
                            key={index}
                            value={usuarioData[index].empresa_id}
                            id={`empresa_id${row.empresa_id}`}
                            onChange={(event) => {
                              setEmpresaValue(
                                index,
                                "empresa_id",
                                event.target.value
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            setUser(row.id);
                          }}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            setUser(row.id);
                          }}
                        >
                          {row.celular}
                        </TableCell>
                        <TableCell>
                          {row.status === "2" &&
                          row.empresa_id &&
                          row.empresa_id !== "0" &&
                          parseInt(userData.empresa_id) ===
                            parseInt(process.env.REACT_APP_EMPRESA_ID) ? (
                            <Chip
                              variant="default"
                              size="small"
                              icon={<DoneIcon></DoneIcon>}
                              label="Autorizar usuário"
                              clickable
                              color="primary"
                              onClick={() =>
                                handleAutorizeUser(row.id, row.empresa_id)
                              }
                            />
                          ) : null}
                          {row.status === "1" &&
                          parseInt(userData.empresa_id) ===
                            parseInt(process.env.REACT_APP_EMPRESA_ID) ? (
                            <Chip
                              variant="default"
                              size="small"
                              icon={<DoneIcon></DoneIcon>}
                              label="Desautorizar usuário"
                              clickable
                              color="secondary"
                              onClick={() => handleUnautorizeUser(row.id)}
                            />
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {parseInt(userData.empresa_id) ===
                          parseInt(process.env.REACT_APP_EMPRESA_ID) ? (
                            <Chip
                              variant="outlined"
                              size="small"
                              icon={<SaveIcon></SaveIcon>}
                              label="Savar empresa"
                              color="primary"
                              clickable
                              onClick={() =>
                                handleUpdateCompany(
                                  row.id,
                                  usuarioData[index].empresa_id
                                )
                              }
                            />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          20,
                          { label: "Todos", value: -1 },
                        ]}
                        colSpan={6}
                        count={usuarioData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Registros por página" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table> */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
    </React.Fragment>
  );
}
