import React, { useEffect, useState, useContext } from "react";

import api from "../../Services/api";

// import UserContext from "../../context/User.context";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Title from "../Types/Tittle";
import SubTitle from "../Types/SubTittle";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";
import EmailIcon from "@material-ui/icons/Email";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import TextMaskCellPhone from "../../functions/TextMaskCellPhone";
import TextMaskCpf from "../../functions/TextMaskCpf";

import Copyright from "../../functions/Copyright";
import AccessList from "../../components/User/accessList";
import CompanyList from "../Company/CompanyList";
import Snackbar from "../../components/SnackBar/index";
import {
  FormGroup,
  InputLabel,
  Modal,
  NativeSelect,
  Select,
  Typography,
} from "@material-ui/core";
import { useAuth } from "../../context/User.provider";
import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 400,
    padding: 10,
    backgroundColor: "white",
    border: "2px solid #000",
    // boxShadow: 12,
  },
}));

export default function UserForm({ setList, userId, setModalPass }) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const { userData, setUserData } = useAuth();
  // const [userData, setUserData] = useContext(UserContext);

  const [sendEmail, setSendEmail] = useState("");
  const [showEmailModal, setShowEmailModal] = useState(false);

  const [userRole, setUserRole] = useState(null);
  const [showSelection, setShowSelection] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  const [company, setCompany] = useState([]);
  const [empresa, setEmpresa] = useState();
  function closeSnackbar() {
    setOpenSnackbar(false);
  }
  const [user, setUser] = useState({
    id: 0,
    empresa_id: 0,
    empresa: "",
    nome: "",
    email: "",
    cpf: "",
    celular: "",
    SMTP_USER: "",
    SMTP_PASS: "",
    showPassword: false,
  });

  const [emailData, setEmailData] = useState({
    SMTP_USER: "",
    SMTP_PASS: "",
    message: "",
    email: sendEmail,
    host: "",
    port: "",
    security: "",
  });
  const handleClickShowPassword = () => {
    setUser({ ...user, showPassword: !user.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  async function getUser(id) {
    if (id !== 0) {
      api
        .get("/usuario/" + id)
        .then((res) => {
          setUser({
            id: res.data.id,
            empresa_id: res.data.empresa_id,
            empresa: res.data.empresa,
            nome: res.data.nome,
            email: res.data.email,
            celular: res.data.celular || "",
            cpf: res.data.cpf || "",
            SMTP_USER: res.data.SMTP_USER || "",
            SMTP_PASS: res.data.SMTP_PASS || "",
            showPassword: false,
          });
          setEmpresa(() => res.empresa_id);

          setUserRole(() => (res.data.role ? res.data.role : null));
          setEmailData({
            ...emailData,
            SMTP_USER: res.data.SMTP_USER,
            SMTP_PASS: res.data.SMTP_PASS,
          });
          setShowSelection(true);
          // console.log(res.data);

          api.get("/empresa/" + res.data.empresa_id).then((res) => {
            setEmailData((previousState) => {
              return {
                ...previousState,
                host: res.data.smtp_host,
                port: res.data.smtp_port,
                security: res.data.smtp_security,
              };
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  async function getCompanys() {
    try {
      await api.get("/empresas").then((res) => {
        setCompany(res.data);
      });
    } catch (err) {
      console.log("Erro ao consultar estados");
    }
  }
  useEffect(() => {
    getUser(userId);
    getCompanys();
  }, [userId]);

  // console.log(emailData)
  async function handleModalPass(event) {
    event.preventDefault();
    setModalPass(true);
  }

  async function handleUpdateUser(event) {
    event.preventDefault();
    if (!user.nome) {
      setSnackbarMessage("Favor informar o nome do usuário");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!user.email) {
      setSnackbarMessage("Favor informar o e-mail do usuário");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      const checkEmail = await api.get(
        "/usuario/" + user.id + "/usuario/" + user.email,
        user
      );

      if (checkEmail.data.id) {
        setSnackbarMessage("E-mail já existente.");
        setSnackbarKind("warning");
        setOpenSnackbar(true);
        return;
      }
      try {
        const userObject = {
          user,
          role: userRole,
        };
        console.log(userObject);
        await api.put("/usuario/" + user.id, userObject);
        setSnackbarMessage("Usuário atualizado com sucesso.");
        setSnackbarKind("success");
        setOpenSnackbar(true);
        setUserData({
          ...userData,
          userRole,
          SMTP_USER: user.SMTP_USER,
          SMTP_PASS: user.SMTP_PASS,
        });
      } catch (err) {
        setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
        setSnackbarKind("error");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  const sendTestEmail = async () => {
    // console.log(emailData)
    try {
      const { data } = await api.post("/email/test", emailData);
      if (data) {
        setSnackbarMessage("E-mail de teste enviado com sucesso");
        setSnackbarKind("success");
        setOpenSnackbar(true);
        setShowEmailModal(false);
      }
    } catch (error) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  };

  async function handleCreateUser(event) {
    event.preventDefault();
    if (!user.nome) {
      setSnackbarMessage("Favor informar o nome do usuário");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!user.email) {
      setSnackbarMessage("Favor informar o e-mail do usuário");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      const checkEmail = await api.get(
        "/usuario/" + user.id + "/usuario/" + user.email,
        user
      );

      if (checkEmail.data.id) {
        setSnackbarMessage("E-mail já existente.");
        setSnackbarKind("warning");
        setOpenSnackbar(true);
        return;
      }
      try {
        await api.post("/usuario", user);
        setSnackbarMessage("Usuário criado com sucesso.");
        setSnackbarKind("success");
        setOpenSnackbar(true);
      } catch (err) {
        setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
        setSnackbarKind("error");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }
  const handleCompanyChange = (event) => {
    const empresaIdUsuario = event.target.value;
    setEmpresa(() => empresaIdUsuario);
    handleUpdateCompany(user.id, empresaIdUsuario);
    getUser(user.id);
  };

  async function handleUpdateCompany(idUser, idCompany) {
    if (!idCompany || idCompany === "0") {
      setSnackbarMessage("Favor selecionar a empresa do usuário!");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    } else {
      api
        .put("usuario/empresa/" + idUser, { id: idUser, empresa_id: idCompany })
        .then((res) => {
          setSnackbarMessage("Empresa associada ao usuário!");
          setSnackbarKind("success");
          setOpenSnackbar(true);
          // getUsers("");
        });
    }
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />

      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={12}>
          {/* Usuarios */}

          <Paper className={classes.paper}>
            <Title>Usuário</Title>

            <FormControl className={classes.formControl}>
              <FormGroup>
                <Grid container spacing={15}>
                  <Grid item sm={12}>
                    <SubTitle>
                      {user.empresa ? "Empresa: " + user.empresa : null}
                    </SubTitle>
                    <SubTitle>
                      ID :{user.id ? "Empresa: " + user.id : null}
                    </SubTitle>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <TextField
                        fullWidth
                        required
                        id="nome"
                        name="nome"
                        label="Nome do usuário"
                        value={user.nome || ""}
                        onChange={(event) => {
                          const val = event.target.value;
                          setUser((prevState) => {
                            return { ...prevState, nome: val };
                          });
                        }}
                      />

                      <TextField
                        required
                        id="email"
                        name="email"
                        label="E-mail"
                        fullWidth
                        value={user.email || ""}
                        onChange={(event) => {
                          const val = event.target.value;
                          setUser((prevState) => {
                            return { ...prevState, email: val };
                          });
                        }}
                      />
                    </Grid>
                    <TextField
                      id="email"
                      name="email"
                      label="E-mail Smtp"
                      fullWidth
                      value={user.SMTP_USER || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setUser((prevState) => {
                          return { ...prevState, SMTP_USER: val };
                        });
                      }}
                    />

                    <TextField
                      id="celular"
                      name="celular"
                      label="Celular"
                      fullWidth
                      InputProps={{ inputComponent: TextMaskCellPhone }}
                      value={user.celular || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setUser((prevState) => {
                          return { ...prevState, celular: val };
                        });
                      }}
                    />

                    <TextField
                      id="cpf"
                      name="cpf"
                      label="Cpf"
                      fullWidth
                      InputProps={{ inputComponent: TextMaskCpf }}
                      value={user.cpf || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setUser((prevState) => {
                          return { ...prevState, cpf: val };
                        });
                      }}
                    />

                    <TextField
                      required
                      id="smtp_pass"
                      name="smtp_pass"
                      label="Senha Smtp"
                      fullWidth
                      type={user.showPassword ? "text" : "password"}
                      value={user.SMTP_PASS || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setUser((prevState) => {
                          return { ...prevState, SMTP_PASS: val };
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {user.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Grid item sm={2}>
                      <p style={{ fontSize: 10, fontWeight: "lighter" }}>
                        Tipo de Usuário
                      </p>

                      <Select
                        defaultValue={userRole}
                        value={userRole}
                        onChange={(e) => setUserRole(e.target.value)}
                      >
                        <option disabled value={""}>
                          Tipo de usuário
                        </option>
                        <option value={"administrador"}>Administrador</option>
                        <option value={"gerente"}>Gerente</option>
                        <option value={"vendedor"}>Vendedor</option>
                        <option value={"assistente"}>Assistente</option>
                        <option value={"cliente"}>Cliente</option>
                      </Select>
                      <Grid item lg={12} sm={12}>
                        <p style={{ fontSize: 10, fontWeight: "lighter" }}>
                          Selecione a Empresa
                        </p>
                        {user ? (
                          <Select
                            select
                            fullWidth
                            labelId="empresasid"
                            id="empresasid"
                            value={user.empresa_id}
                            defaultValue={user.empresa_id}
                            onChange={handleCompanyChange}
                          >
                            <MenuItem value={""}>Selecione a empresa</MenuItem>
                            {company.map((row, index) => (
                              <MenuItem key={index} value={row.id}>
                                {row.nome}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid item align="left" spacing={12} lg={12}>
                      {userId !== 0 ? (
                        <AccessList userId={userId}></AccessList>
                      ) : null}
                    </Grid>
                    <Grid item align="right" sm={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowEmailModal(true)}
                        className={classes.button}
                        startIcon={<EmailIcon />}
                      >
                        Enviar Email Teste
                      </Button>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={
                          location.pathname ===
                          `/${process.env.REACT_APP_FRONT_ALIAS}/usuario`
                            ? setList
                            : () => {
                                history.push(
                                  `/${process.env.REACT_APP_FRONT_ALIAS}/usuario`
                                );
                              }
                        }
                        className={classes.button}
                      >
                        Voltar
                      </Button>

                      {userId !== 0 ? (
                        <>
                          {String(userId).trim() ===
                          String(userData.id).trim() ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleModalPass}
                              className={classes.button}
                            >
                              Alterar Senha
                            </Button>
                          ) : null}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpdateUser}
                            className={classes.button}
                            startIcon={<SaveIcon />}
                          >
                            Salvar
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCreateUser}
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Novo usuário
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  {showEmailModal === true && (
                    <Grid>
                      <Modal
                        open={showEmailModal}
                        onClose={() => setShowEmailModal(false)}
                        aria-labelledby="Email-modal"
                        aria-describedby="Email-modal-description"
                      >
                        <Box className={classes.modal}>
                          <Typography
                            id="Email-modal"
                            variant="h6"
                            component="h2"
                          >
                            E-mail para receber o teste de Email
                          </Typography>
                          <TextField
                            id="Email-test"
                            name="Email-test"
                            label="Email-test"
                            value={emailData.email || ""}
                            onChange={(event) => {
                              const val = event.target.value;
                              setEmailData((prevState) => {
                                return { ...prevState, email: val };
                              });
                            }}
                            fullWidth
                          />
                          <TextField
                            id="Messagem-test"
                            name="Messagem-test"
                            label="Messagem-test"
                            value={emailData.message || ""}
                            onChange={(event) => {
                              const val = event.target.value;
                              setEmailData((prevState) => {
                                return { ...prevState, message: val };
                              });
                            }}
                            fullWidth
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={sendTestEmail}
                            className={classes.button}
                            startIcon={<EmailIcon />}
                          >
                            Enviar E-mail
                          </Button>
                        </Box>
                      </Modal>
                    </Grid>
                  )}
                </Grid>
              </FormGroup>
            </FormControl>
          </Paper>

          {/* Acessos do usuario */}
        </Grid>

        <Box pt={4}>
          <Copyright />
        </Box>

        <Snackbar
          open={openSnackbar}
          closeBtn={true}
          closeSnackbar={closeSnackbar}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
        ></Snackbar>
      </Container>
    </main>
  );
}
