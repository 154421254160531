import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import ListClassificacao from "../Chapa/ListClassificacao";
import ListEspessura from "../Chapa/ListEspessura";
import ListProcesso from "../Chapa/ListProcesso";
import MateriaisSelect from "../Material/MateriaisSelect";
import ListFilial from "../Chapa/ListFilial";

const CreateBundleForm = ({
  bundle,
  setBundle,
  material,
  materiais,
  userData,
  setMaterialId,
  chapa,
}) => {
  return (
    <Grid>
      <Grid item sm={12}>
        <MateriaisSelect
          id="material_id"
          label="Informe o Material"
          value={bundle.material_id}
          defaultValue={material}
          materialCurrent={material}
          materiais={materiais}
          empresaId={userData.empresa_id}
          setMaterialId={setMaterialId}
        />
      </Grid>

      <TextField
        id="nomematerialingles"
        name="nomematerialingles"
        label="Nome Material Inglês"
        fullWidth
        value={bundle.nomematerialingles || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              nomematerialingles: event.target.value,
            };
          });
        }}
      />

      <TextField
        id="nomematerialportugues"
        name="nomematerialportugues"
        label="Nome Material Português"
        fullWidth
        value={bundle.nomematerialportugues || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              nomematerialportugues: event.target.value,
            };
          });
        }}
      />

      {/*<Autocomplete
        //options={filial}
        options={["0101", "0131", "0301"]}
        value={filialSelected}
        label="selecione a filial"
        onChange={(event, newValue) => {
          if (newValue == null) {
            setFilialSelected("");
          }
          if (newValue) {
            setFilialSelected(newValue);
            setBundle((prevState) => {
              return { ...prevState, filialcliente: newValue };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selecione a filial"
            variant="standard"
          />
        )}
        />*/}

      <ListFilial
        required
        id="filial"
        name="filial"
        label="Filial"
        value={bundle.filialcliente || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              filialcliente: event.target.value,
            };
          });
        }}
      />

      {/* <TextField
      id="filialcliente"
      name="filialcliente"
      label="Filial"
      fullWidth
      value={chapa.filialcliente || ""}
      onChange={(event) => {
        setChapa((prevState) => {
          return { ...prevState, filialcliente: event.target.value };
        });
      }}
    /> */}

      <TextField
        id="bloco"
        name="bloco"
        label="Bloco"
        fullWidth
        value={bundle.bloco || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              bloco: event.target.value,
            };
          });
        }}
      />

      <TextField
        id="numero_bundle_cliente"
        name="numero_bundle_cliente"
        label="Numero Bundle Cliente"
        fullWidth
        value={bundle.numero_bundle_cliente || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              numero_bundle_cliente: event.target.value,
            };
          });
        }}
      />

      <TextField
        id="peso_madeira"
        name="peso_madeira"
        label="Peso Madeira"
        fullWidth
        value={bundle.peso_madeira || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              peso_madeira: event.target.value,
            };
          });
          // calculaPesoTotal(event.target.value, bundle.total_chapas);
        }}
      />

      {/* <TextField
        id="total_chapas"
        name="total_chapas"
        label="Total de Chapas"
        fullWidth
        value={bundle.total_chapas || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              total_chapas: event.target.value,
            };
          });
          // calculaPesoTotal(bundle.peso_madeira, event.target.value);
          // calculaValorTotal(event.target.value);
        }}
      /> */}

      <TextField
        id="peso_total"
        name="peso_total"
        label="Peso Total"
        fullWidth
        value={bundle.peso_total || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              peso_total: event.target.value,
            };
          });
        }}
      />

      <Grid container spacing={5}>
        <Grid item sm={3} style={{ display: "flex" }}>
          <TextField
            id="altura_liquida"
            fullWidth
            name="altura_liquida"
            label="Altura Líquida do bundle"
            value={bundle.altura_liquida || ""}
            onChange={(event) => {
              setBundle((prevState) => {
                return {
                  ...prevState,
                  altura_liquida: event.target.value,
                };
              });
            }}
          />
        </Grid>
        <Grid item sm={3} style={{ display: "flex" }}>
          <TextField
            id="comprimento_liquido"
            fullWidth
            name="comprimento_liquido"
            label="Comprimento Líquido do bundle"
            value={bundle.comprimento_liquido || ""}
            onChange={(event) => {
              setBundle((prevState) => {
                return {
                  ...prevState,
                  comprimento_liquido: event.target.value,
                };
              });
            }}
          />
        </Grid>
        <Grid item sm={3} style={{ display: "flex" }}>
          <TextField
            id="altura_bruta"
            fullWidth
            name="altura_bruta"
            label="Altura Bruta do bundle"
            value={bundle.altura_bruta || ""}
            onChange={(event) => {
              setBundle((prevState) => {
                return {
                  ...prevState,
                  altura_bruta: event.target.value,
                };
              });
            }}
          />
        </Grid>
        <Grid item sm={3} style={{ display: "flex" }}>
          <TextField
            id="comprimento_bruto"
            fullWidth
            name="comprimento_bruto"
            label="Comprimento Bruto do bundle"
            value={bundle.comprimento_bruto || ""}
            onChange={(event) => {
              setBundle((prevState) => {
                return {
                  ...prevState,
                  comprimento_bruto: event.target.value,
                };
              });
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={5}>
        <Grid item sm={6} style={{ display: "flex" }}>
          <TextField
            id="total_bruto"
            fullWidth
            name="total_bruto"
            label="Comprimento total bruto"
            value={bundle.total_bruto || ""}
            onChange={(event) => {
              setBundle((prevState) => {
                return {
                  ...prevState,
                  total_bruto: event.target.value,
                };
              });
            }}
          />
        </Grid>
        <Grid item sm={6} style={{ display: "flex" }}>
          <TextField
            id="total_liquido"
            fullWidth
            name="total_liquido"
            label="Comprimento total liquido"
            value={bundle.total_liquido || ""}
            onChange={(event) => {
              setBundle((prevState) => {
                return {
                  ...prevState,
                  total_liquido: event.target.value,
                };
              });
            }}
          />
        </Grid>
      </Grid> */}

      <TextField
        id="totalm2"
        name="totalm2"
        label="Total m2"
        fullWidth
        value={bundle.totalm2 || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              totalm2: event.target.value,
            };
          });
        }}
      />

      <ListEspessura
        required
        id="espessura"
        name="espessura"
        label="Espessura"
        value={bundle.espessura}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              espessura: event.target.value,
            };
          });
        }}
      />

      <ListProcesso
        id="processo"
        name="processo"
        label="Processo"
        value={bundle.processo || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return { ...prevState, processo: event.target.value };
          });
        }}
      />

      <ListClassificacao
        id="classificacao"
        name="classificacao"
        label="Classificaçao"
        value={bundle.classificacao || ""}
        onChange={(event) => {
          setBundle((prevState) => {
            return {
              ...prevState,
              classificacao: event.target.value,
            };
          });
        }}
      />
    </Grid>
  );
};

export default CreateBundleForm;
