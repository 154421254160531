import React from "react";
import { Link, NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

//import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";

import DashboardIcon from "@material-ui/icons/Dashboard";
import ApartmentIcon from "@material-ui/icons/Apartment";
import CardTravelIcon from "@material-ui/icons/CardTravel";

import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import BusinessIcon from "@material-ui/icons/Business";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListAltIcon from "@material-ui/icons/ListAlt";
import NoteIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useAuth } from "../../context/User.provider";
//import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ListItems({ empresaId }) {
  const classes = useStyles();
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openStock, setOpenStock] = React.useState(false);
  const [openOfert, setOpenOfert] = React.useState(false);
  const { userData, setUserData } = useAuth();
  // const [viewSearch, setViewSearch] = React.useState(false);

  const handleClickCreate = () => {
    setOpenCreate(!openCreate);
  };
  const handleClickStock = () => {
    setOpenStock(!openStock);
  };
  const handleClickOfert = () => {
    setOpenOfert(!openOfert);
  };
  // const handleClickSearch = () => {
  //   setViewSearch(!viewSearch);
  //};

  return (
    <List className={classes.root}>
      <ListItem button key="mainList" component={Link} to={`/app`}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText alt="Principal" primary="Principal" />
      </ListItem>

      {/* <ListItem button key="productsList">
        
        <Typography
              variant="body1"
              color="textSecondary"
              component="a"
              href="/dashboard/product"
              >
                <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        Produtos
        </Typography>
      </ListItem> */}
      {userData?.userRole !== "cliente" ? (
        <ListItem button key="create" onClick={handleClickCreate}>
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastro" />
        </ListItem>
      ) : null}
      {userData?.userRole !== "cliente" ? (
        <Collapse in={openCreate} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              key="personsList"
              component={Link}
              to={`/app/person`}
            >
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="Pessoas" />
            </ListItem>
          </List>

          <ListItem
            button
            className={classes.nested}
            key="materiaisList"
            component={Link}
            to={`/app/material`}
          >
            <ListItemIcon>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary="Materiais" />
          </ListItem>
        </Collapse>
      ) : null}
      {userData?.userRole !== "cliente" ? (
        <ListItem button key="stock" onClick={handleClickStock}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Estoque" />
        </ListItem>
      ) : null}
      {userData?.userRole !== "cliente" ? (
        <Collapse in={openStock} timeout="auto" unmountOnExit>
          <ListItem
            button
            className={classes.nested}
            key="productsList"
            component={Link}
            to={`/app/product`}
          >
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Produtos" />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            key="chapasList"
            component={Link}
            to={`/app/chapa`}
          >
            <ListItemIcon>
              <LibraryAddIcon />
            </ListItemIcon>
            <ListItemText primary="Chapas" />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            key="bundleList"
            component={Link}
            to={`/app/bundle`}
          >
            <ListItemIcon>
              <AllInboxIcon />
            </ListItemIcon>
            <ListItemText primary="Bundles" />
          </ListItem>
        </Collapse>
      ) : null}
      <ListItem button key="orderList" onClick={handleClickOfert}>
        <ListItemIcon>
          <LocalOfferIcon />
        </ListItemIcon>
        <ListItemText primary="Ofertas" />
      </ListItem>

      <Collapse in={openOfert} timeout="auto" unmountOnExit>
        {userData?.userRole !== "cliente" ? (
          <ListItem
            button
            className={classes.nested}
            key="ofertChapas"
            component={Link}
            to={`/app/oferta/chapa`}
          >
            <ListItemIcon>
              <LibraryAddIcon />
            </ListItemIcon>
            <ListItemText primary="Chapas" />
          </ListItem>
        ) : null}
        <ListItem
          button
          className={classes.nested}
          key="ofertBundles"
          component={Link}
          to={`/app/oferta/bundle`}
        >
          <ListItemIcon>
            <AllInboxIcon />
          </ListItemIcon>
          <ListItemText primary="Bundles" />
        </ListItem>
        {userData?.userRole !== "cliente" ? (
          <ListItem
            button
            className={classes.nested}
            key="ofertList"
            component={Link}
            to={`/app/oferta`}
          >
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Listar/Reenviar" />
          </ListItem>
        ) : null}
        {userData?.userRole !== "cliente" ? (
          <ListItem
            button
            className={classes.nested}
            key="notificacao"
            component={Link}
            to={`/app/notifications`}
          >
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText primary="Notificações" />
          </ListItem>
        ) : null}
      </Collapse>

      {/*     {(parseInt(empresaId) === parseInt(process.env.REACT_APP_EMPRESA_ID)) ? (
       <> */}
      {userData?.userRole !== "cliente" ? (
        <ListItem button key="companyList" component={Link} to={`/app/company`}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Empresas" />
        </ListItem>
      ) : null}
      {userData?.userRole !== "cliente" ? (
        <ListItem button key="userList" component={Link} to={`/app/usuario`}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Usuários" />
        </ListItem>
      ) : null}
      {/*        </>
      ) : null */}

      <Divider />

      {/* <ListItem>
        
        <ListItemText>
          <Switch
            edge="end"
            checked={checked.indexOf('bluetooth') !== -1}
            onChange={handleToggle('bluetooth')}
            inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
          />
        </ListItemText>
      </ListItem> 
      
      <ListItem id="SearchDivBtn" button key="SearchList" onClick={handleClickSearch} name="SearchListButton">
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText primary="Pesquisar" />
      </ListItem>*/}
    </List>
  );
}
