export default function FilialValue(value) {
  switch (value) {
    case "1":
      return "0101";
    case "2":
      return "0131";
    case "3":
      return "0301";
    default:
      return "";
  }
}

export const Filial = [
  {
    id: "0101",
    valor: FilialValue("1"),
  },
  {
    id: "0131",
    valor: FilialValue("2"),
  },
  {
    id: "0301",
    valor: FilialValue("3"),
  },
];
