import React, { useEffect, useState, useContext, useRef } from "react";

import UserContext from "../../context/User.context";

import api from "../../Services/api";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Title from "../Types/Tittle";
import Radio from "@material-ui/core/Radio";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";

import PriceFormatCustom from "../../functions/PriceFormatCustom";

import Copyright from "../../functions/Copyright";

import Snackbar from "../../components/SnackBar/index";

import TabProduto from "./TabProduto";
import { useAuth } from "../../context/User.provider";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ProductForm({ setList, productId }) {
  const classes = useStyles();

  const ref = useRef(false);

  const history = useHistory();
  const location = useLocation();

  const { userData } = useAuth();
  // const [userData] = useContext(UserContext);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  const [produto, setProduto] = useState({
    id: 0,
    id_prodcliente: "",
    empresa_id: userData.empresa_id,
    usuario_id: userData.id,
    nome: "",
    descricao: "",
    unidade: "",
    precocusto: 0,
    precovendam2: 0,
    precoporp2: 0,
    desativar: false,
  });

  window.addEventListener("popstate", function (event) {
    setList();
    if (ref.current === false) {
      history.push(`/${process.env.REACT_APP_FRONT_ALIAS}/company`);
      ref.current = true;
      return;
    } else {
      //console.log('passou', history.length)
    }
  });

  async function getProduct(id) {
    try {
      if (id !== 0) {
        await api.get("/produto/" + id).then((res) => {
          setProduto({
            id: res.data.id,
            id_prodcliente: res.data.id_prodcliente,
            empresa_id: res.data.empresa_id,
            usuario_id: res.data.usuario_id,
            nome: res.data.nome,
            descricao: res.data.descricao,
            unidade: res.data.unidade,
            precocusto: res.data.precocusto,
            precovendam2: res.data.precovendam2,
            precoporp2: res.data.precoporp2,
            desativar: res.data.desativar,
          });
        });
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    //setOpen(true);
  }

  useEffect(() => {
    getProduct(productId);
  }, [productId]);

  async function handleUpdateProduct(event) {
    event.preventDefault();
    if (!produto.nome) {
      setSnackbarMessage("Favor informar o nome do produto");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!produto.precocusto) {
      setSnackbarMessage("Favor informar o preço de custo do produto.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      await api.put("/produto/", produto);
      setSnackbarMessage("Produto atualizado com sucesso.");
      setSnackbarKind("success");
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function handleCreateProduct(event) {
    event.preventDefault();
    if (!produto.nome) {
      setSnackbarMessage("Favor informar o nome do produto");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!produto.precocusto) {
      setSnackbarMessage("Favor informar o preço de custo do produto.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      await api.post("/produto", produto).then((ress) => {
        setSnackbarMessage("Produto cadastrado com sucesso.");
        setSnackbarKind("success");
        setOpenSnackbar(true);
        getProduct(ress.data.id);
      });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Produto</Title>

              <FormControl className={classes.formControl}>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <TextField
                      required
                      id="nome"
                      name="nome"
                      label="Nome"
                      value={produto.nome || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setProduto((prevState) => {
                          return { ...prevState, nome: val };
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <TextField
                      required
                      id="decricao"
                      name="descricao"
                      label="Descricao"
                      value={produto.descricao || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setProduto((prevState) => {
                          return { ...prevState, descricao: val };
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="unidade"
                      name="unidade"
                      label="Unidade"
                      fullWidth
                      value={produto.unidade || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setProduto((prevState) => {
                          return { ...prevState, unidade: val };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="precocusto"
                      name="precocusto"
                      label="Preço de custo"
                      fullWidth
                      value={produto.precocusto || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setProduto((prevState) => {
                          return { ...prevState, precocusto: val };
                        });
                      }}
                      InputProps={{
                        inputComponent: PriceFormatCustom,
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      required
                      id="precovendam2"
                      name="precovendam2"
                      label="Preço de venda"
                      fullWidth
                      value={produto.precovendam2}
                      onChange={(event) => {
                        const val = event.target.value;
                        setProduto((prevState) => {
                          return { ...prevState, precovendam2: val };
                        });
                      }}
                      InputProps={{
                        inputComponent: PriceFormatCustom,
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="precoporp2"
                      name="precoporp2"
                      label="Preço por p2"
                      fullWidth
                      value={produto.precoporp2 || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setProduto((prevState) => {
                          return { ...prevState, precoporp2: val };
                        });
                      }}
                      InputProps={{
                        inputComponent: PriceFormatCustom,
                      }}
                    />
                  </Grid>

                  <Grid item sm={4}>
                    <Radio
                      checked={parseInt(produto.desativar) === 1}
                      value="1"
                      name="desativar"
                      onChange={(event) => {
                        setProduto((prevState) => {
                          return {
                            ...prevState,
                            desativar: event.target.value,
                          };
                        });
                      }}
                    />
                    Desativar
                    <Radio
                      checked={parseInt(produto.desativar) === 0}
                      value="0"
                      name="desativar"
                      onChange={(event) => {
                        setProduto((prevState) => {
                          return {
                            ...prevState,
                            desativar: event.target.value,
                          };
                        });
                      }}
                    />
                    Ativar
                  </Grid>

                  <Grid item align="right" sm={12}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={
                        location.pathname ===
                        `/${process.env.REACT_APP_FRONT_ALIAS}/product`
                          ? setList
                          : history.push(
                              `/${process.env.REACT_APP_FRONT_ALIAS}/product`
                            )
                      }
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    {parseInt(produto.id) !== 0 ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdateProduct}
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Salvar
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateProduct}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                      >
                        Novo produto
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        {produto.id !== 0 ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.paper2}>
                <TabProduto
                  produtoNome={produto.nome}
                  produtoId={produto.id}
                ></TabProduto>
              </Paper>
            </Grid>
          </Grid>
        ) : null}

        <Box pt={4}>
          <Copyright />
        </Box>

        <Snackbar
          open={openSnackbar}
          closeBtn={true}
          closeSnackbar={closeSnackbar}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
        ></Snackbar>
      </Container>
    </main>
  );
}
