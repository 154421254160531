import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Ofertas from "../../components/Oferta/List";
import OfertaForm from "../../components/Oferta/Oferta";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Oferta() {
  const classes = useStyles();

  const [ofertaId, setOfertaId] = useState(0);

  const [showView, setShowView] = useState("list");

  function setOferta(id) {
    setOfertaId(id);
    setShowView("oferta");
  }

  function setList() {
    setOfertaId(0);
    setShowView("list");
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {/* <OfertaForm setList={setList} ofertaId={ofertaId}></OfertaForm> */}

      {showView === "list" ? (
        <Ofertas setOferta={setOferta} ofertaId={ofertaId}></Ofertas>
      ) : (
        <OfertaForm setList={setList} ofertaId={ofertaId}></OfertaForm>
      )}
    </div>
  );
}
