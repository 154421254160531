import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackBar( { closeSnackbar, closeBtn, message, kind, ...rest } ) {
  
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  function handleClose() {
    setOpenSnackbar(false);
    closeSnackbar();
  };

  return (
    
      <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        {...rest}
        message={message}
        onClose={handleClose}
        action={
          closeBtn
          ?
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          : null
        }
      >
        <Alert onClose={handleClose} severity={kind}>{message}</Alert>
      </Snackbar>
      </React.Fragment>
    
  );
}