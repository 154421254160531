import { createContext, useState } from "react";
export const SelectedChapasContext = createContext();

export const SelectedChapasProvider = (props) => {
  const [selectedChapas, setSelectedChapas] = useState([]);

  return (
    <SelectedChapasContext.Provider value={{ selectedChapas, setSelectedChapas }}>
      {props.children}
    </SelectedChapasContext.Provider>
  );
};
