export default function ProcessoValue(value) {
  switch (value) {
    case "0":
      return "TODOS OS PROCESSOS";
    case "1":
      return "POLISHED";
    case "2":
      return "LEATHER";
    case "3":
      return "RAW";
    case "4":
      return "HONED";
    case "5":
      return "FLAMED";
    case "6":
      return "DUAL FINISH";
    default:
      return "";
  }
}
//--1.POLIDO 2.ESCOVADO 3.BRUTO 4.LEVIGADO 5.FLAMEADO 6.DUAL FINISH
export const Processos = [
  {
    id: "0",
    valor: ProcessoValue("0"),
  },
  {
    id: "1",
    valor: ProcessoValue("1"),
  },
  {
    id: "2",
    valor: ProcessoValue("2"),
  },
  {
    id: "3",
    valor: ProcessoValue("3"),
  },
  {
    id: "4",
    valor: ProcessoValue("4"),
  },
  {
    id: "5",
    valor: ProcessoValue("5"),
  },
  {
    id: "6",
    valor: ProcessoValue("6"),
  },
];
