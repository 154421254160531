import React from 'react';
//import api from '../../Services/api';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import MenuItem from '@material-ui/core/MenuItem';

export default function ProductsSelect({label, id, value, empresaId, produtoNome, produtos, produtoCurrent, setProdutoId, ...rest}) {
    
    const [produto, setProduto] = React.useState({
        id : 0,
        nome : "",
    });

    const [produtoSelected, setProdutoSelected] = React.useState(produtoCurrent)

    const [dataLoaded, setDataLoaded] = React.useState(false);

    //Buscando as instituicoes financeiras
    // useEffect(() => {
    //     async function getProducts() {
    //         try {
    //             await api.get(`/produtosbyEmpresaAC/${empresaId}`).then((res) => {
    //                 setProduto(res.data);
    //             })
    //         }
    //         catch (err) {
    //             console.log("Erro ao consultar Produtos.");
    //             setDataLoaded(false)
    //         }
    //         setDataLoaded(true)
    //     }
    //     getProducts();
    // }, [])
    
    return (
        
        <Autocomplete
        id={id}
        name={id}
        size="small"
        value={produtoSelected}
        options={produtos ? produtos : []}
        getOptionLabel={(option) => option.nome}
        //defaultValue={produtos.find(v => v.nome[0])}
        onChange={(event, newValue) => {
            if (newValue) {
                setProdutoSelected(newValue);
                setProdutoId(newValue.id);
            };
        }}
        renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
        />
    );

}