import React from 'react'
import Logo from '../../assets/img/logo.png'
import './index.css'

const NotFound = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#f9f9f9', height: '100vh'}}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column'}}>
            <h1 className='font'>Ops... Página não encontrada</h1>
            <img className='logo'  src={Logo} alt="logo" />
        </div>
    </div>
  )
}

export default NotFound