import React, { useState } from "react";

import api from "../../Services/api";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Copyright from "../../functions/Copyright";

import TextMaskCellPhone from "../../functions/TextMaskCellPhone";

import Snackbar from "../../components/SnackBar/index";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Register({ setLoginForm }) {
  const classes = useStyles();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [senha, setSenha] = useState("");
  const [passcheck, setPassCheck] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  async function checkLoginHandle(event) {
    event.preventDefault();

    if (!nome) {
      setSnackbarMessage("Favor informar o nome.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    } else if (!email) {
      setSnackbarMessage("Favor informar o email.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    } else if (!senha) {
      setSnackbarMessage("Favor informar a senha.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    } else if (senha !== passcheck) {
      setSnackbarMessage("A senha deve ser igual a sua confirmacao!");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    } else {
      try {
        //Verificando se o e-mail já existe
        // let emailExists = await api.get("/usuario/0/usuario/" + email);
        // if (!emailExists.data.id) {
        let usuario = {
          nome: nome,
          email: email,
          cpf: "",
          celular: celular,
          senha: senha,
        };
        api.post("/user/createUser", { user: usuario }).then((res) => {
          localStorage.setItem("token", res.data?.token);
          setSnackbarMessage(
            "Cadastro realizado com sucesso. Aguarde aprovacao para utilizar o sistema."
          );
          setSnackbarKind("success");
          setOpenSnackbar(true);
        });
        //}  else {
        //   setSnackbarMessage("O e-mail informado já existe!");
        //   setSnackbarKind("warning");
        //   setOpenSnackbar(true)
        // }
      } catch (err) {
        setSnackbarMessage("Erro ao cadastrar usuário. " + err);
        setSnackbarKind("error");
        setOpenSnackbar(true);
      }
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Cadastrar
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                name="nome"
                variant="outlined"
                required
                fullWidth
                size="small"
                id="nome"
                label="Nome"
                onChange={(e) => setNome(e.target.value)}
                autoFocus
                autoComplete="nome"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                size="small"
                label="Email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="celular"
                InputProps={{ inputComponent: TextMaskCellPhone }}
                size="small"
                label="Celular"
                onChange={(e) => setCelular(e.target.value)}
                name="celular"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="senha"
                size="small"
                label="Senha"
                type="password"
                id="senha"
                onChange={(e) => setSenha(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passcheck"
                size="small"
                label="Confirmaçao da senha"
                type="password"
                id="password"
                onChange={(e) => setPassCheck(e.target.value)}
                autoComplete="current-password"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={checkLoginHandle}
          >
            Cadastrar
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="#" variant="body2" onClick={setLoginForm}>
                Já tem conta? Entre
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>

      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
    </Container>
  );
}
