import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import api from "../../Services/api";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../../functions/Copyright';

import logo from "../../assets/img/logo.png";

import Snackbar from "../../components/SnackBar/index";
import { useAuth } from '../../context/User.provider';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'white', //theme.palette.primary.main,
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function Login({ setRegisterForm, setRememberForm }) {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(false)
  const { doLogin } = useAuth()

  // const [userData, setUserData] = useContext(UserContext)
  //const [isLogged, setIsLogged] = useContext(UserContext)
  // const [token, setToken] = useContext(UserContext)
  // const { setStorageId } = useContext(UserContext)
  // const { setStorageNome } = useContext(UserContext)
  // const { setStorageEmpresaId } = useContext(UserContext)

  const [email, setEmail] = useState();
  const [senha, setSenha] = useState('');

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  async function checkLoginHandle(event) {
    event.preventDefault()

    if (!email) {
      setSnackbarMessage('Favor informar seu e-mail')
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    }
    else if (!senha) {
      setSnackbarMessage('Favor informar sua senha')
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    }
    else {
      try {
        const logged = await doLogin(email, senha)
        if(logged) {
          history.push(`/app/oferta/bundle`)
        }
        else {
          setSnackbarMessage('Email ou senha inválido(s).')
          setSnackbarKind("warning");
          setOpenSnackbar(true);
        }

      }catch (e) {
        setSnackbarMessage('Email ou senha inválido(s).')
        setSnackbarKind("warning");
        setOpenSnackbar(true);
      }
    }

  }

  async function checkCookie() {

    try {
      await api.get('/usuarios/checkcookie').then((res) => {

        console.log(res)

      })
    }
    catch (err) {
      console.log("Erro ao cadastrar o acesso" + err.message())
    }

  }

  // useEffect(() => {
  //   // if (ref.current === false) {
  //   //   if (userData.isLogged === true) {
  //   //     history.push(`/${process.env.REACT_APP_FRONT_ALIAS}/oferta/bundle`)
  //   //     ref.current = true;
  //   //   }
  //   // }

  //   //checkCookie()

  // }, [])

  return (

    <Container component="main" maxWidth="xs" className={classes.paper}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={logo} alt="Ornatec Logo" width="120" height="120"></img>
        </Avatar>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            size="small"
            label="Endereço de email"
            name="email"
            autoComplete="email"
            onChange={e => setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            size="small"
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            onChange={e => setSenha(e.target.value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={checkLoginHandle}
          >
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={setRememberForm}>
                Esqueceu a senha?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={setRegisterForm}>
                Ainda nao tem conta? Faça seu cadastro
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Snackbar
        closeSnackbar={closeSnackbar}
        closeBtn={true}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
        open={openSnackbar}
      ></Snackbar>
    </Container>
  );
}
