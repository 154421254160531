import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import ProcessoValue from "../../Chapa/Processo";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { getImagesById } from "../../../Services/api";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "10%",
    display: "flex",
    width: 300,
  },
  media: {
    paddingTop: 10,
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  card: {
    marginLeft: 5,
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    minHeight: 380,
  },
  graph: {
    color: "blue",
  },
  check: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
    paddingLeft: 10,
    width: "100%",
  },
  buttons: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  check: {
    padding: 5,
  },
  headerContent: {
    display: "flex",
    width: 450,
    paddingTop: "10px",
    justifyContent: "space-between",
  },
}));

// function renderImages(chapaobj) {

//   //console.log(chapaobj.nome)

//    return (
// //      <ImageGallery visibility={false} images={chapaobj} height={50} width={160}></ImageGallery>
//     );
//  }

export default function ItemCard({
  bundle,
  index,
  handleChangeCheck,
  userId,
  consultaPedido,
  selectedBundles,
  handleRemoveChapaFromBundle,
  saveImage,
  chapaRefs,
  showButtons,
  handleChapaRefClick,
  openCamera,
}) {
  //console.log(bundle);
  const classes = useStyles();

  function DataHora() {
    var dataAtual = new Date();
    var dia = String(dataAtual.getDate()).padStart(2, "0");
    var mes = String(dataAtual.getMonth() + 1).padStart(2, "0");
    var ano = dataAtual.getFullYear();
    var hora = dataAtual.getHours();
    var minuto = dataAtual.getMinutes();
    var segundo = dataAtual.getSeconds();

    return Date.parse(
      ano +
        "-" +
        mes +
        "-" +
        dia +
        "T" +
        hora +
        ":" +
        minuto +
        ":" +
        segundo +
        ".000Z"
    );
  }

  function tipografia(corTexto, Disponibilidade, Vendedor, cor) {
    return (
      <>
        <Typography
          style={{ color: { cor } }}
          variant="body2"
          color={corTexto}
          component="p"
        >
          <b>{Disponibilidade}!</b>
        </Typography>
        <Typography variant="body2" color="textPrimary" component="p">
          {Vendedor ? Vendedor : " "}
        </Typography>
      </>
    );
  }
  const pedido = bundle.numero_pedido_cliente
    ? bundle.numero_pedido_cliente
    : null;

  function verificaReserva(res, vendedor) {
    var castigo = 1;

    // console.log(bundle)

    //console.log(bundle)
    //  console.log("expira_reserva: "+bundle.expira_reserva)
    //  console.log("expira_reserva_anterior: "+bundle.expira_reserva_anterior)
    //  console.log(".........")
    //  console.log("userId: "+ userId);
    //  console.log("id_vendedor: "+bundle.id_vendedor);
    //  console.log("id_vendedor_anterior: "+ bundle.id_vendedor_anterior);
    //  console.log("+++++++")
    var expira = bundle.expira_reserva == null ? 0 : bundle.expira_reserva;

    if (bundle.emestoque === 0) {
      return tipografia("error", "Unavailable!", "", "#cc0000");
    } else if (pedido !== null) {
      return tipografia("error", "Unavailable!", "", "#cc0000");
    } else if (
      bundle.id_vendedor == null &&
      bundle.id_vendedor_anterior == null
    ) {
      return tipografia("textPrimary", "Available!", "");
    } else if (
      bundle.expira_reserva == null &&
      bundle.expira_reserva_anterior == null
    ) {
      return tipografia("textPrimary", "Available!", "");
    } else if (parseInt(bundle.id_vendedor) === parseInt(userId)) {
      if (expira > 0) {
        return tipografia(
          "textPrimary",
          "Due Date " + expira + " Day(s)",
          vendedor
        );
      } else if (expira <= 0 && expira >= castigo * -1) {
        return tipografia("error", "Expired!", vendedor);
      } else if (expira < castigo * -1) {
        return tipografia("textPrimary", "Available!", vendedor);
      }
    } else if (parseInt(bundle.id_vendedor) !== parseInt(userId)) {
      //primeiro verificar se tem reserva
      if (bundle.expira_reserva !== null) {
        if (expira > 0) {
          return tipografia(
            "textPrimary",
            "Due Date " + expira + " Day(s)",
            vendedor
          );
        } else if (expira <= 0) {
          return tipografia("textPrimary", "Available!", "");
        }
      }
      //segundo verifica se o vendedor anterior é o atual
      else if (parseInt(bundle.id_vendedor_anterior) === parseInt(userId)) {
        if (expira > 0) {
          return tipografia(
            "textPrimary",
            "Due Date ==== " + expira + " Day(s)",
            vendedor
          );
        } else if (expira <= 0) {
          return tipografia("textPrimary", "Available!", "");
        }
      } else if (parseInt(bundle.id_vendedor_anterior) !== parseInt(userId)) {
        if (parseInt(bundle.id_vendedor) !== parseInt(userId)) {
          return tipografia("textPrimary", "Available!", "");
        }
      }
    }
  }

  const checked = selectedBundles.some((item) => {
    return item.id === bundle.id;
  });

  function verificaVendedor() {
    var cai = 7;
    var castigo = 1;
    var expira = bundle.expira_reserva == null ? 0 : bundle.expira_reserva;

    if (bundle.bundlecliente_id !== "") {
      return false;
    } else if (bundle.emestoque === 0) {
      return false;
    } else if (pedido !== null && consultaPedido === false) {
      return false;
    } else if (pedido !== null && consultaPedido === true) {
      return true;
    } else if (
      bundle.id_vendedor == null &&
      bundle.id_vendedor_anterior == null
    ) {
      return true;
    } else if (
      bundle.expira_reserva == null &&
      bundle.expira_reserva_anterior == null
    ) {
      return true;
    } else if (parseInt(bundle.id_vendedor) === parseInt(userId)) {
      if (expira > 0) {
        return true;
      } else if (expira <= 0 && expira >= castigo * -1) {
        return true;
      } else if (expira < castigo * -1) {
        return true;
      }
    } else if (parseInt(bundle.id_vendedor) !== parseInt(userId)) {
      //primeiro verificar se tem reserva
      if (bundle.expira_reserva !== null) {
        if (expira > 0) {
          return false;
        } else if (expira <= 0) {
          return true;
        }
      }
      //segundo verifica se o vendedor anterior é o atual
      else if (parseInt(bundle.id_vendedor_anterior) === parseInt(userId)) {
        if (expira > 0) {
          return false;
        } else if (expira <= 0) {
          return true;
        }
      } else if (parseInt(bundle.id_vendedor_anterior) !== parseInt(userId)) {
        return true;
      }
    }
  }

  return (
    //     <>
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            // image={`${process.env.REACT_APP_API_URL}images/${chapa.foto_local}`}
          >
            <a
              style={{ textDecoration: "none" }}
              href={bundle.linkS3}
              key={bundle.linkS3}
            >
              {bundle.linkS3Thumb ? (
                <img
                  src={bundle.linkS3Thumb}
                  alt={bundle.bundle}
                  srl_gallery_image="true"
                  height={180}
                  width={280}
                />
              ) : (
                <div
                  style={{
                    height: 180,
                    width: 280,
                    backgroundColor: "#0182c6",
                  }}
                >
                  {" "}
                  <Typography
                    variant="string"
                    style={{
                      fontSize: 20,
                      fontWeight: "semi-bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      color: "#fff9f9",
                    }}
                  >
                    WITHOUT PICTURE
                  </Typography>
                </div>
              )}
            </a>
            {/* {console.log([bundle])}       */}
          </CardMedia>
          {/* <CardMedia
          className={classes.media}
          //image={`${process.env.REACT_APP_API_URL}images/${bundle.foto_local}`}
          image={`http://app.ornatec.com.br:3333/images/${bundle.foto_local}`}
          
          //onClick={renderImages(bundle)}
        >
          
        </CardMedia> */}
          <CardContent className={classes.content}>
            <div className={classes.headerContent}>
              <Grid container spacing={3}>
                <Grid item sm={3} style={{ display: "flex" }}>
                  <Typography variant="body2" color="textPrimary" component="p">
                    {bundle.bloco ? "BLOCK:   " : null}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="h2">
                    {bundle.bloco ? "  " + bundle.bloco : null}
                  </Typography>
                </Grid>
                <Grid item sm={2} style={{ display: "flex" }}>
                  <Typography variant="body2" color="textPrimary" component="p">
                    {bundle.numerochapa ? " SLAB " : null}{" "}
                  </Typography>
                  <Typography gutterBottom variant="h6" component="h2">
                    {bundle.numerochapa ? "  " + bundle.numerochapa : null}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={2}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "right",
                  }}
                >
                  {verificaReserva(bundle.expira_reserva, bundle.vendedor_nome)}
                  {
                    //console.log(bundle)
                  }
                </Grid>
              </Grid>
            </div>

            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.nomematerialingles
                ? bundle.nomematerialingles
                : bundle.nomematerialportugues
                ? bundle.nomematerialportugues
                : null}
            </Typography>
            {/* vendedor_nome */}

            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.bundlecliente_id
                ? " BLUNDLE: " + bundle.bundlecliente_id + " "
                : null}
            </Typography>
            <Typography>
              {bundle.processo ? ProcessoValue(bundle.processo) : null}
              {bundle.classificacao ? " - " + bundle.classificacao : null}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.espessura ? " THICK: " + bundle.espessura + " Cm" : null}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.comprimento_liquido ? bundle.comprimento_liquido : null}m
              x {bundle.altura_liquida ? bundle.altura_liquida : null}m /{" "}
              {bundle.comprimento_liquido
                ? (bundle.comprimento_liquido * 39.3701).toPrecision(5)
                : null}
              " x{" "}
              {bundle.altura_liquida
                ? (bundle.altura_liquida * 39.3701).toPrecision(4)
                : null}
              "
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.lista_chapas ? bundle.lista_chapas : null}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="p">
              {bundle.numero_pedido_cliente
                ? "Order: " + bundle.numero_pedido_cliente
                : null}
              {bundle.vendedor_pedido_cliente
                ? " " + bundle.vendedor_pedido_cliente
                : null}
            </Typography>

            {/* <Button
            variant="contained"
            set="ddd"
            color="default"
            fullWidth
            onClick={() => alert("Teste")}
            className={classes.button}
            startIcon={<LibraryAddIcon />}
          > Visualizar Chapas
          </Button> */}
          </CardContent>
        </CardActionArea>
        <CardActions>
          {showButtons === true ? (
            <>
              {bundle.id !== 0 && (
                <Button
                  onClick={() => handleChapaRefClick(index)}
                  // startIcon={<AddAPhotoIcon />}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {bundle.linkS3 ? `Atualizar Foto` : `Adicionar Foto`}
                  <input
                    type="file"
                    multiple
                    ref={chapaRefs[index]}
                    style={{ display: "none" }}
                    onChange={(e) => saveImage(e, true, bundle.id, bundle)}
                  />
                </Button>
              )}
              {/* <Button onClick={openCamera}>Tirar foto</Button> */}
              <Button onClick={() => handleRemoveChapaFromBundle(bundle.id)}>
                Desvincular Chapa
              </Button>
            </>
          ) : (
            <FormControlLabel
              className={classes.check}
              control={
                <Checkbox
                  name="checkedBundles"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={checked}
                  color="primary"
                  value={bundle.id}
                />
              }
              label="Select"
              onChange={handleChangeCheck}
              disabled={!verificaVendedor()}
            />
          )}
        </CardActions>
      </Card>
    </div>
  );
}
