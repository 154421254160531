import React, { useState } from "react";

import api from "../../Services/api";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Copyright from "../../functions/Copyright";

import Snackbar from "../../components/SnackBar/index";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Remeber({ setLoginForm }) {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [user, setUser] = useState();
  const [isMailRight, setIsMailRight] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  async function handleChangePassword(event) {
    event.preventDefault();

    if (password === passwordCheck) {
      try {
        //Verificando se o e-mail já existe
        // await api.get("/usuario/0/usuario/" + email).then((res) => {
        console.log(email);
        // if (res.data.id) {
        setUser((prevState) => {
          return { ...prevState, senha: password };
        });
        const userUpdated = await api.put("/user/" + user.id, { user });
        console.log(userUpdated);
        // api.post("/generatepass/" + res.data.id, {
        // email: email,
        // subject: "www.ornatec.com.br - Nova Senha Sistema",
        // });

        setSnackbarMessage("Senha alterada com sucesso!");
        setSnackbarKind("success");
        setOpenSnackbar(true);
        // } else {
        // setSnackbarMessage("E-mail informado não encontrado.");
        // setSnackbarKind("warning");
        // setOpenSnackbar(true);
        // }
        // });
      } catch (err) {
        setSnackbarMessage("Erro ao enviar e-mail. " + err);
        setSnackbarKind("error");
        setOpenSnackbar(true);
      }
    } else {
      setSnackbarMessage("As senhas não conferem.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    }
  }

  const handleCheckEmail = async () => {
    try {
      const userExists = await api.get("/user/remember/" + email);
      console.log(userExists);
      if (userExists.data.email === email) {
        setUser(userExists.data);
        setIsMailRight(true);
      } else {
        setSnackbarMessage("E-mail informado não encontrado.");
        setSnackbarKind("warning");
        setOpenSnackbar(true);
      }
    } catch (err) {
      setSnackbarMessage("E-mail não existe.");
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Nova senha
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                size="small"
                label="Email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </Grid>
          </Grid>
          {isMailRight ? (
            <>
              <Grid style={{ marginTop: "1rem" }} item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  size="small"
                  label="password"
                  name="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="password"
                />
              </Grid>
              <Grid style={{ marginTop: "1rem" }} item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="passwordConfirm"
                  size="small"
                  label="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  onChange={(e) => setPasswordCheck(e.target.value)}
                  autoComplete="passwordConfirm"
                />
              </Grid>
            </>
          ) : null}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={isMailRight ? handleChangePassword : handleCheckEmail}
          >
            {isMailRight ? "Trocar senha" : "Checar E-mail"}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" variant="body2" onClick={setLoginForm}>
                Já tem conta? Entre
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>

      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
    </Container>
  );
}
