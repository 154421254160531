import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function ListPerson({
  label,
  id,
  labelTittle,
  value,
  pessoas,
  personCurrent,
  handleChangePerson,
  removeWidth,
  ...rest
}) {
  const [personSelected, setPersonSelected] = React.useState(personCurrent);

  return (
    <Autocomplete
      freeSolo
      id={id}
      name={id}
      options={pessoas ? pessoas : []}
      value={personSelected}
      getOptionLabel={(option) => option.nome}
      style={{ width: removeWidth ? "100%" : 250 }}
      // disableClearable
      onChange={(event, newValue) => {
        if (newValue) {
          setPersonSelected(newValue);
          handleChangePerson(newValue.id);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="standard" />
      )}
    />
  );
}
