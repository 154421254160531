import React, { useState, useEffect, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";

import { chapasFilter, getCompanyChapas } from "../../Services/api";

import UserContext from "../../context/User.context";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Title from "../Types/Tittle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import SearchIcon from "@material-ui/icons/Search";
import Filterdrawer from "../Bundle/Drawer.js";

import TablePaginationActions from "../../functions/TablePaginationActions";
import { useAuth } from "../../context/User.provider";
import { DataGrid } from "@mui/x-data-grid";
import api from "../../Services/api";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const initialValues = {
  espessura2: false,
  espessura3: false,
  processo: "",
  classificacao: "",
  empedido: false,
  reservado: false,
  disponivel: false,
  semestoque: false,
};

export default function Chapas({ setChapa, chapaId }) {
  const classes = useStyles();

  const { idChapa } = useParams() || "0";

  const { userData } = useAuth();
  // const [userData] = useContext(UserContext);

  const history = useHistory();

  const [chapaData, setChapaData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [datadecriacao, setDatadecriacao] = useState("");
  const [semestoque, setSemestoque] = useState("");

  const [limit] = useState(9999);
  const [classificacoes, setClassificacoes] = useState();
  const [processos, setProcessos] = useState();
  const [showModal, setShowModal] = useState(false);
  const [nameSearch, setNameSearch] = useState("");
  const [material, setMaterial] = useState("");
  const [vendedorpedidocliente, setVendedorpedidocliente] = useState("");
  const [numeropedidocliente, setNumeropedidocliente] = useState("");
  const [filial, setFilial] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filialSelected, setFilialSelected] = useState("");

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);
  const [checkbox5, setCheckbox5] = useState(false);
  const [checkbox6, setCheckbox6] = useState(false);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(initialValues);

  const orderByOptions = [
    { option: "Bloco", value: "bundle.bloco" },
    { option: "N° Bundle", value: "bundle.id" },
    // {option: 'Slab', value: 'bundle.bloco_id'},
    { option: "Nome Português", value: "material.nome_portugues" },
    { option: "Nome Inglês", value: "material.nome_ingles" },
  ];

  const [orderBy, setOrderBy] = useState(orderByOptions);
  const [orderBySelected, setOrderBySelected] = useState([]);
  //espessura
  const [chapaColumns, setChapaColumns] = useState([
    { field: "filialcliente", headerName: "Store", width: 100 },
    { field: "nomematerialingles", headerName: "Material", width: 300 },
    { field: "bloco", headerName: "Block", width: 110 },
    { field: "bundlecliente_id", headerName: "Bundle", width: 120 },
    { field: "espessura", headerName: "Tick", width: 100 },
    { field: "numerochapa", headerName: "Slab", width: 100 },
    { field: "comprimento_bruto", headerName: "Width", width: 120 },
    { field: "altura_bruta", headerName: "Height", width: 120 },
    { field: "emestoque", headerName: "Stock", width: 110 },
  ]);

  function handleModalClose() {
    setShowModal(!showModal);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //chaparouter/get/AllChapas
  async function getChapas(empresaid, nameSearch) {
    try {
      console.log(empresaid);
      const res = await api.get("/chapas/AllChapas?", {
        params: { empresaid: empresaid },
      });
      if (res) {
        setChapaData(res.data);
      } else {
        console.log("Erro ao consultar chapas");
      }
    } catch (err) {
      console.log("Erro ao consultar chapas");
    }
  }

  function handleChangeFilter(e, newValue) {
    if (e.target?.type === "checkbox") {
      const { name, checked } = e.target;

      // eslint-disable-next-line default-case
      switch (name) {
        case "espessura2":
          if (checked === true) {
            setCheckbox1(true);
          } else {
            setCheckbox1(false);
          }
          break;
        case "espessura3":
          if (checked === true) {
            setCheckbox2(true);
          } else {
            setCheckbox2(false);
          }
          break;
        case "disponivel":
          if (checked === true) {
            setCheckbox3(true);
          } else {
            setCheckbox3(false);
          }
          break;
        case "empedido":
          if (checked === true) {
            setCheckbox4(true);
          } else {
            setCheckbox4(false);
          }
          break;
        case "reservado":
          if (checked === true) {
            setCheckbox5(true);
          } else {
            setCheckbox5(false);
          }
          break;
        case "semestoque":
          if (checked === true) {
            setCheckbox6(true);
            setSemestoque(true);
          } else {
            setCheckbox6(false);
            setSemestoque(false);
          }
          break;
      }

      setFilter({ ...filter, [name]: checked === true ? checked : false });
    }
    setSearch(!search);
  }
  function changeValueProcesso(e, value) {
    setProcessos(value);
    var SQLProcesso = "";
    const nome = "processo";
    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-unused-expressions
    value
      ? value.map((row, index) => {
          if (index === 0) {
            SQLProcesso = `'` + row.id + `'`;
          } else {
            SQLProcesso = SQLProcesso + `,'` + row.id + `'`;
          }
        })
      : null;

    setFilter((prevState) => {
      return {
        ...prevState,
        [nome]: SQLProcesso,
      };
    });
  }

  function changeValueClassificacao(e, value) {
    setClassificacoes(value);
    var SQLClassificacao = "";
    const nome = "classificacao";
    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-unused-expressions
    value
      ? value.map((row, index) => {
          if (index === 0) {
            SQLClassificacao = `'` + row.id + `'`;
          } else {
            SQLClassificacao = SQLClassificacao + `,'` + row.id + `'`;
          }
        })
      : null;

    setFilter((prevState) => {
      return {
        ...prevState,
        [nome]: SQLClassificacao,
      };
    });
  }

  async function changeFilter(Page) {
    setLoading(true);
    const object = {
      ...filter,
      limit,
      page,
      nameSearch,
      material,
      numeropedidocliente,
      vendedorpedidocliente,
      datadecriacao,
      semestoque,
      filialSelected,
      orderBy: orderBySelected,
    };
    try {
      console.log("Filtrando Chapas");
      const res = await api.post(
        `/chapas/filter/${userData.empresa_id}/${page}`,
        object
      );
      //const res = await chapasFilter(userData.empresa_id, page, object);
      if (res.data.data !== undefined && res.data) {
        console.log("chapaData", res.data.data);
        setChapaData(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        console.log("setLoading " + loading);
        return;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  useEffect(() => {
    if (idChapa) {
      setChapa(chapaId);
      return;
    }

    getChapas(userData.empresa_id, "");
  }, [chapaId]);

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item align="left" sm={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  history.goBack();
                }}
                className={classes.button}
              >
                Voltar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setChapa(0);
                }}
                className={classes.button}
              >
                Nova Chapa
              </Button>
            </Grid>

            <Grid item align="left" sm={8}>
              {/* <TextField
                id="nameSearch"
                size="small"
                placeholder="Procurar..."
                fullWidth
                onChange={(event) => {
                  getChapas(userData.empresa_id, event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              /> 
              */}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid spacing={9} align="right">
                  <Button
                    color="inherit"
                    variant="contained"
                    onClick={() => setShowModal(!showModal)}
                  >
                    Filtrar
                  </Button>
                </Grid>
                {loading ? <CircularProgress /> : <Title>Chapas</Title>}
                {chapaData && chapaData.length > 0 && (
                  <Grid
                    style={{
                      height: "75vh",
                      width: "100%",
                      paddingTop: "20px",
                    }}
                  >
                    <DataGrid
                      onCellClick={(e) => setChapa(e.row.id)}
                      columns={chapaColumns}
                      rows={chapaData}
                    />
                  </Grid>
                )}
                {/* <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Código</TableCell>
                      <TableCell>Nome do Produto</TableCell>
                      <TableCell>Comprimento</TableCell>
                      <TableCell>Altura</TableCell>
                      <TableCell>Preço m2</TableCell>
                      <TableCell>Preço pre </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(chapaData
                      ? chapaData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : chapaData
                    ).map((row, index) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => {
                          console.log("row.nome", row.nome);
                          setChapa(row.id);
                        }}
                      >
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.nome}</TableCell>
                        <TableCell>
                          <b>{row.comprimento_bruto}</b>
                        </TableCell>
                        <TableCell>
                          <b>{row.altura_bruta}</b>
                        </TableCell>
                        <TableCell>
                          <b>R$ {row.precom2}</b>
                        </TableCell>
                        <TableCell>
                          <b>R$ {row.precoprequadrado}</b>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, { label: "Todos", value: -1 }]}
                        colSpan={6}
                        count={chapaData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Registros por página" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table> */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Filterdrawer
        showModal={showModal}
        setShowModal={setShowModal}
        filter={filter}
        handleModalClose={handleModalClose}
        setIsFiltered={setIsFiltered}
        changeFilter={changeFilter}
        classificacoes={classificacoes}
        changeValueClassificacao={changeValueClassificacao}
        handleChangeFilter={handleChangeFilter}
        changeValueProcesso={changeValueProcesso}
        processos={processos}
        checkbox1={checkbox1}
        checkbox2={checkbox2}
        checkbox3={checkbox3}
        checkbox4={checkbox4}
        checkbox5={checkbox5}
        checkbox6={checkbox6}
        material={material}
        orderBy={orderBy}
        orderBySelected={orderBySelected}
        setOrderBySelected={setOrderBySelected}
        setMaterial={setMaterial}
        displaynameSearch={nameSearch}
        setNameSearch={setNameSearch}
        datadecriacao={datadecriacao}
        setDatadecriacao={setDatadecriacao}
        semestoque={semestoque}
        setSemestoque={setSemestoque}
        vendedorpedidocliente={vendedorpedidocliente}
        setVendedorpedidocliente={setVendedorpedidocliente}
        setNumeropedidocliente={setNumeropedidocliente}
        numeropedidocliente={numeropedidocliente}
        filial={filial}
        filialSelected={filialSelected}
        setFilialSelected={setFilialSelected}
      />
    </React.Fragment>
  );
}
