import React, { useState, useEffect, useContext, useRef } from "react";
import { debounce } from "lodash";
import api, {
  getBundles,
  incluirNaReserva,
  liberarReserva,
} from "../../../Services/api";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import RemoveShoppingCart from "@material-ui/icons/RemoveShoppingCart";
import FilterListIcon from "@material-ui/icons/FilterList";
import Snackbar from "../../../components/SnackBar/index";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Filter9PlusIcon from "@material-ui/icons/Filter9Plus";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Filter3Icon from "@material-ui/icons/Filter3";
import Filter4Icon from "@material-ui/icons/Filter4";
import Filter5Icon from "@material-ui/icons/Filter5";
import Filter6Icon from "@material-ui/icons/Filter6";
import Filter7Icon from "@material-ui/icons/Filter7";
import Filter8Icon from "@material-ui/icons/Filter8";
import Filter9Icon from "@material-ui/icons/Filter9";
import { useHistory, Redirect } from "react-router-dom";

import * as jwt from "jsonwebtoken";

import ItemCard from "./ItemCard";

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import UIInfiniteScroll from "../../InfiniteScroll/InfiniteScroll";

import Drawer from "@material-ui/core/Drawer";

import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AutoCompleteProcesso from "../Chapa/AutoCompleteProcesso";
import AutoCompleteClassificacao from "../Chapa/AutoCompleteClassificacao";

import { Processos } from "../../Chapa/Processo";
import { Classificacoes } from "../../Chapa/Classificacao";
import { CircularProgress } from "@material-ui/core";
import Loading from "../../Loading/Loading";
import { Autocomplete } from "@material-ui/lab";
import { useAuth } from "../../../context/User.provider";

import Lightbox from "./Lightbox";
import { SelectedBundlesContext } from "../../../context/SelectedBundles.provider";
import LinearProgress from "@material-ui/core/LinearProgress";
const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    //flexGrow: 1,
    //height: "97%",
    // width: "100%",
    //overflow: "auto",
    paddingTop: theme.spacing(4),
    marginTop: "3rem",
    //paddingLeft: "28px",
    //display: "block",
    //background: "#fafa",
    //maxWidth: "100%",
  },
  content2: {
    overflow: "none",
    //flexDirection: "colunn",
    //height: "100vh",
    width: "100%",
    //background: "#fafafa",
    position: "absolute",
    bottom: 0,
  },
  contentCard: {
    // flexGrow: 0,
    display: "inline-block",
    //background: "#aada",
    position: "flex",
    flexDirection: "colunn",
  },

  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  containerFixed: {
    position: "fixed",
    flexGrow: 0,
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    //paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(0),
    overflow: "auto",
  },
  pai: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    overflow_x: "scroll",
    position: "absolute",
  },
  escondePesquisa: {
    display: "none",
    //background: "#fafafa",
  },
  mostraPesquisa: {
    diplay: "inline-block",
  },
  fundo: {
    background: "#fafafa",
  },
  button: {
    display: "flex",
    position: "absolute",
    width: "80px",
    //  height: "75%",
    right: "0",
    top: "0",
    marginRight: "0.4rem",
  },
  submit: {
    position: "fixed",
    bottom: "10px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  submit2: {
    position: "fixed",
    bottom: "80px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  submit3: {
    position: "fixed",
    bottom: "150px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  submit4: {
    position: "fixed",
    bottom: "220px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  submit5: {
    position: "fixed",
    bottom: "290px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  drawer: {
    marginTop: "4rem",
  },
  load: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const initialValues = {
  espessura2: false,
  espessura3: false,
  processo: "",
  classificacao: "",
  empedido: false,
  reservado: false,
  disponivel: false,
  aprovados: false,
};

// const initialdisplayValues = {
//   material: '',
//   numeropedidocliente: '',
//   vendedorpedidocliente:'',
// }

export default function Ofertas({
  setOferta,
  ofertaId,
  setModalOffert,
  finishOffer,
  setEmpresa,
  consultaPedido,
  setConsultaPedido,
}) {
  const classes = useStyles();
  const ref = useRef(null);
  const history = useHistory();
  // const [userData] = useContext(UserContext);
  const { selectedBundles, setSelectedBundles } = useContext(
    SelectedBundlesContext
  );
  const { userData, setUserData } = useAuth();

  const [loading, setLoading] = useState(false);

  const [chapaData, setChapaData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  // const [selectedBundles, setSelectedBundles] = useState([]);
  const [selectedChapas, setSelectedChapas] = useState([]);
  const [oldSelected, setOldSelected] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedBundlesLoaded, setSelectedBundleOffert] = useState(false);
  // const empresa = parseInt(userData.empresa_id) || 0;
  const [checkbox1, setCheckbox1] = useState(false);
  // const [consultaPedido, setConsultaPedido] = useState(false)

  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);
  const [checkbox5, setCheckbox5] = useState(false);
  const [checkbox6, setCheckbox6] = useState(false);
  // const [classificacoes, setClassificacoes] = useState([{id: '', valor: 'TODAS AS CLASSIFICAÇÕES'}])
  // const [processos, setProcessos] = useState([{id: '0', valor: 'TODOS OS PROCESSOS'}])
  const [classificacoes, setClassificacoes] = useState();
  const [processos, setProcessos] = useState();
  const [filial, setFilial] = useState([]);
  const [filialSelected, setFilialSelected] = useState([]);

  const [limit] = useState(24);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [atualPage, setatualPage] = useState(0);
  const [priorPage, setpriorPage] = useState(0);
  const [totalBundles, setTotalBundles] = useState(1);
  const [values, setValues] = useState(initialValues);

  const [openFilter, setopenFilter] = useState(false);
  const [nameSearch, setnameSearch] = useState("");
  const [displaynameSearch, setdisplaynameSearch] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");

  const [material, setmaterial] = useState("");
  const [displaymaterial, setdisplaymaterial] = useState("");

  const [numeropedidocliente, setnumeropedidocliente] = useState("");
  const [displaynumeropedidocliente, setdisplaynumeropedidocliente] =
    useState("");

  const [nomecliente, setnomecliente] = useState("");
  const [displaynomecliente, setdisplaynomecliente] = useState("");

  const [vendedorpedidocliente, setvendedorpedidocliente] = useState("");
  const [displayvendedorpedidocliente, setdisplayvendedorpedidocliente] =
    useState("");
  const orderByOptions = [
    { option: "Bloco", value: "bundle.bloco" },
    { option: "N° Bundle", value: "bundle.numero_bundle_cliente" },
    // {option: 'Slab', value: 'bundle.bloco_id'},
    { option: "Nome Português", value: "material.nome_portugues" },
    { option: "Nome Inglês", value: "material.nome_ingles" },
  ];
  const [orderBy, setOrderBy] = useState(orderByOptions);
  const [orderBySelected, setOrderBySelected] = useState([]);
  const [elements, setElements] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  const delayedQuery = useRef(
    debounce((e) => {
      setnameSearch(e);
    }, 800)
  ).current;

  const delayedMaterialQuery = useRef(
    debounce((e) => {
      setmaterial(e);
    }, 800)
  ).current;

  const delayednumeropedidoclienteQuery = useRef(
    debounce((e) => {
      setnumeropedidocliente(e);
    }, 800)
  ).current;

  const delayedvendedorpedidoclienteQuery = useRef(
    debounce((e) => {
      setvendedorpedidocliente(e);
    }, 800)
  ).current;

  const delayednomeclienteQuery = useRef(
    debounce((e) => {
      setnomecliente(e);
    }, 800)
  ).current;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenFilter(!open);
  };

  //Função para Fazer o Delay na hora da pesquisa pelo bloco.
  function handleNameSearch(ev) {
    setPage(0);
    setdisplaynameSearch(ev.target.value);
    delayedQuery(ev.target.value);
  }
  function handlematerial(ev) {
    setPage(0);
    setdisplaymaterial(ev.target.value);
    delayedMaterialQuery(ev.target.value);
  }

  function handlenumeropedidocliente(ev) {
    if (ev.target.value !== "") {
      setConsultaPedido(true);
    } else {
      setConsultaPedido(false);
    }
    setPage(0);
    setdisplaynumeropedidocliente(ev.target.value);
    delayednumeropedidoclienteQuery(ev.target.value);
  }

  function handlevendedorpedidocliente(ev) {
    setPage(0);
    setdisplayvendedorpedidocliente(ev.target.value);
    delayedvendedorpedidoclienteQuery(ev.target.value);
  }

  function handlenomecliente(ev) {
    setPage(0);
    setdisplaynomecliente(ev.target.value);
    delayednomeclienteQuery(ev.target.value);
  }

  function liberaCarinho() {
    setSelectedBundles([]);
    setSelectedChapas([]);
    setSelectedBundleOffert(false);
    getAllBundles(page);
  }

  function changeValueClassificacao(event, value, reason) {
    // console.log(value);
    setClassificacoes(value);
    var SQLClassificacao = "";

    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-unused-expressions
    value
      ? value.map((row, index) => {
          if (index === 0) {
            SQLClassificacao = `'` + row.id + `'`;
          } else {
            SQLClassificacao = SQLClassificacao + `,'` + row.id + `'`;
          }
        })
      : null;
    // setsearchProcessos(SQLClassificacao);
    // console.log(SQLClassificacao);
    onChange(event, "changeValueClassificacao", SQLClassificacao);
  }

  function changeValueProcesso(event, value, reason) {
    // console.log(value);
    setProcessos(value);
    var SQLProcesso = "";

    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-unused-expressions
    value
      ? value.map((row, index) => {
          if (index === 0) {
            SQLProcesso = row.id;
          } else {
            SQLProcesso = SQLProcesso + "," + row.id;
          }
        })
      : null;
    onChange(event, "changeValueProcesso", SQLProcesso);
  }

  async function getFiliais() {
    try {
      const { data } = await api.get(`/filiais/${userData.empresa_id}`);
      //console.log(data);
      // setFilial(() => []);
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.filialcliente !== null) {
          setFilial((prevState) => {
            // if (prevState.length >= data?.length) return [...prevState];
            return [...prevState, data[i].filialcliente];
          });
        }
        // console.log(filial);
      }
    } catch (err) {
      console.log(err);
    }
  }
  //função que faz a consulta dos bundles e retorna para tela
  async function getAllBundles() {
    setLoading(true);
    let newpage = page;
    setpriorPage(newpage);
    setatualPage(newpage);
    newpage = page + 1;

    const object = {
      ...values,
      limit,
      nameSearch,
      material,
      numeropedidocliente,
      vendedorpedidocliente,
      nomecliente,
      filialSelected,
      orderBy: orderBySelected,
    };

    // console.log(object);
    try {
      const res = await getBundles(object, userData.empresa_id, newpage);
      const { data } = res.data;
      const { total, lastPage } = res?.data?.pagination;
      if (typeof res.data != "undefined" && res.data) {
        if (newpage > 1 && res.data.code !== "ECONNRESET") {
          console.log("Entrei sem econreset");
          setChapaData((prevState) => [...prevState, ...data]);
        } else {
          //console.log("Entrei com econreset");
          if (res.data.code !== "ECONNRESET") {
            setChapaData(data);
          }
        }
        if (res.data.code !== "ECONNRESET") {
          setTotalBundles(() => (total >= 1 ? total : 0));
          setTotalPage(() => (lastPage >= 1 ? lastPage : 0));
          setPage(() => newpage);
          setLoading(false);
        }
      } else {
        setLoading(false);
        return;
      }

      //
    } catch (err) {
      console.log("Erro na getBundle: " + err);
      setLoading(false);
    }
    setDataLoaded(true);
    setLoading(false);
  }

  async function incluiNaReserva() {
    if (consultaPedido === true) {
      setSnackbarMessage("Não é possivel reservar Bundles com Pedido!");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!selectedBundles) {
      setSnackbarMessage("Selecione pelo menos um bundle");
      setSnackbarKind("error");
      setOpenSnackbar(true);
      return;
    }

    var incluir = "true";
    try {
      const res = await incluirNaReserva(userData.id, incluir, selectedBundles);
      if (res.data) {
        // console.log("res.data", res.data);
        setSnackbarKind("success");
        setSnackbarMessage(res.data?.message);
        setOpenSnackbar(true);
        setSelectedBundles([]);
        localStorage.clear();
      } else {
        console.log("res sem data", res);
      }
    } catch (err) {
      // console.log("erro", err.message);
      if (err.message === "Request failed with status code 406") {
        setSnackbarMessage(
          `Os Bundles ${selectedBundles.map(
            (bundle) => bundle.numero_bundle_cliente
          )} não foram reservados!`
        );
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      if (err.message === "Request failed with status code 422") {
        setSnackbarMessage("Selecione pelo menos um bundle");
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      setSnackbarMessage(err.message);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    getAllBundles();
  }

  async function liberaReserva() {
    // console.log(event.target.value);
    if (!selectedBundles) {
      setSnackbarMessage("Selecione pelo menos um bundle");
      setSnackbarKind("error");
      setOpenSnackbar(true);
      return;
    }
    try {
      const res = await liberarReserva(userData.id, selectedBundles);
      if (res.data) {
        // console.log("res.data", res.data);
        setSnackbarKind("success");
        setSnackbarMessage(res.data?.message);
        setOpenSnackbar(true);
        setSelectedBundles([]);
        localStorage.clear();
      } else {
        console.log("res sem data", res);
      }
    } catch (err) {
      if (err.message === "Request failed with status code 406") {
        setSnackbarMessage(
          `Os Bundles ${selectedBundles.map(
            (bundle) => bundle.numero_bundle_cliente
          )} não foram modificados!`
        );
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      if (err.message === "Request failed with status code 422") {
        setSnackbarMessage("Selecione pelo menos um bundle");
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      setSnackbarMessage(err.message);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    getAllBundles();
  }

  function mudaIconeCarrinho() {
    if (selectedBundles.length > 9) {
      return <Filter9PlusIcon />;
    }
    // eslint-disable-next-line default-case
    switch (selectedBundles.length) {
      case 0:
        return <ShoppingCartIcon />;
      case 1:
        return <Filter1Icon />;
      case 2:
        return <Filter2Icon />;
      case 3:
        return <Filter3Icon />;
      case 4:
        return <Filter4Icon />;
      case 5:
        return <Filter5Icon />;
      case 6:
        return <Filter6Icon />;
      case 7:
        return <Filter7Icon />;
      case 8:
        return <Filter8Icon />;
      case 9:
        return <Filter9Icon />;
    }
  }

  async function handleChangeCheck(event) {
    const isChecked = event.target.checked;
    setSelectedBundleOffert(false);
    // alert("Solta 1")
    const bundle = JSON.parse(event.target.value);

    if (isChecked) {
      // setSelectedChapas((prevState) => {
      //   return [...prevState, bundle];
      // });
      setSelectedBundles((prevState) => {
        return [...prevState, bundle];
      });
      setSelectedBundleOffert(true);
    } else {
      setSelectedBundles((prevState) => {
        // const selected = prevState.indexOf(bundle)
        const selected = prevState.filter((item) => {
          return item.id !== bundle.id;
        });
        return selected;
      });
      // setSelectedChapas((prevState) => {
      //   // const selected = prevState.indexOf(bundle)
      //   const selected = prevState.filter((item) => {
      //     return item !== bundle;
      //   });
      //   return selected;
      // });
    }
  }

  function handleSubmitOffer() {
    localStorage.removeItem("ornatec_bundles");
    // console.log(selectedBundles)
    localStorage.setItem("ornatec_bundles", JSON.stringify(selectedBundles));
    // eslint-disable-next-line no-undef
    // setTimeout(() => {
    setModalOffert(userData.id);
    // console.log(consultaPedido)
    // console.log(userData?.rule)
    // }, 2000);
  }

  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  function showSlides(n) {
    setSlideIndex(() => n);

    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("demo");
    var captionText = document.getElementById("caption");
    if (!slides || !dots || !captionText) return;
    if (isOpen === false) return;

    if (n > slides.length) {
      setSlideIndex(() => 1);
    }
    if (n < 1) {
      setSlideIndex(() => slides.length);
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
    captionText.innerHTML = dots[slideIndex - 1].alt;
  }

  function Load() {
    if (loading) {
      return (
        <Grid style={{ paddingTop: "35%", paddingLeft: "50%" }} item xs={12}>
          {/* <CircularProgress /> */}
          <Loading />
        </Grid>
      );
    }
  }
  function Load2() {
    if (loading) {
      return (
        <div className={classes.load}>
          <LinearProgress />
        </div>
      );
    }
  }

  //Função para atualizar os valores dos campos de filtros
  function onChange(ev, origem, valor) {
    //const {name,value} = ev.target

    //  console.log(ev);
    if (ev.type === "click") {
      if (origem === "changeValueProcesso") {
        const name = "processo";
        const value = valor;
        setValues({ ...values, [name]: value });
      } else if (origem === "changeValueClassificacao") {
        const name = "classificacao";
        const value = valor;
        setValues({ ...values, [name]: value });
      }
    } else {
      if (ev.target.type === "checkbox") {
        const { name, checked } = ev.target;

        // eslint-disable-next-line default-case
        switch (name) {
          case "espessura3":
            if (checked === true) {
              setCheckbox1(true);
            } else {
              setCheckbox1(false);
            }
            break;
          case "espessura2":
            if (checked === true) {
              setCheckbox2(true);
            } else {
              setCheckbox2(false);
            }
            break;
          case "empedido":
            if (checked === true) {
              setCheckbox3(true);
            } else {
              setCheckbox3(false);
            }
            break;
          case "reservado":
            if (checked === true) {
              setCheckbox4(true);
            } else {
              setCheckbox4(false);
            }
            break;
          case "disponivel":
            if (checked === true) {
              setCheckbox5(true);
            } else {
              setCheckbox5(false);
            }
            break;
          case "aprovados":
            if (checked === true) {
              setCheckbox6(true);
            } else {
              setCheckbox6(false);
            }
            break;
        }
        setValues({ ...values, [name]: checked === true ? checked : false });
      } else {
        const { name, value } = ev.target;
        setValues({ ...values, [name]: value });
      }
    }
    setPage(() => 0);
    setChapaData([]);
    setpriorPage(0);
    setatualPage(0);
    setTotalBundles(0);
  }

  useEffect(() => {
    if (finishOffer === true) {
      setSelectedBundles([]);
      // getAllBundles()
      // console.log("Use Effect 1")
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishOffer]);
  // useEffect(() => {
  //   if (userData.empresa_id >= 1) {
  //     getFiliais();
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (userData.empresa_id >= 1) {
      setEmpresa(userData.empresa_id);
      //getFiliais();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ofertaId, dataLoaded]);

  useEffect(() => {
    if (
      values ||
      nameSearch ||
      numeropedidocliente ||
      material ||
      vendedorpedidocliente ||
      nomecliente
    ) {
      if (userData?.empresa_id >= 1) {
        setChapaData(() => []);
        setatualPage(() => 0);
        setpriorPage(() => 0);
        setTotalPage(() => 0);
        setPage(() => 0);
        getAllBundles();
      }
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values,
    nameSearch,
    numeropedidocliente,
    material,
    vendedorpedidocliente,
    nomecliente,
    filialSelected,
    orderBySelected,
  ]);

  // console.log("Renderizou");
  // console.log(userData);
  //console.log("Regra do Usuário:"+userData?.userRole);
  return (
    <React.Fragment>
      {/* {Load()} */}
      <Container maxWidth={false} className={classes.content}>
        {Load2()}
        {dataLoaded && chapaData && chapaData.length >= 1 && (
          <Grid
            spacing={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {chapaData.map((row, index) => (
              <Grid key={row.id}>
                <Grid item xs={12} sm={3}>
                  {/* {console.log("linhas: "+ row )} */}
                  <ItemCard
                    allBundles={chapaData}
                    bundle={row}
                    index={row.id}
                    slideIndex={index}
                    selectedBundles={selectedBundles}
                    handleChangeCheck={handleChangeCheck}
                    consultaPedido={consultaPedido}
                    userId={userData.id}
                    setIsOpen={setIsOpen}
                    showSlides={showSlides}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {/* {console.log(
          "Total de Paginas : " + totalPage + " Pagina atual: " + page
        )} */}
        {chapaData && page < totalPage && page - priorPage === 1 && (
          <UIInfiniteScroll fetchMore={getAllBundles} />
        )}
        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit4}
          onClick={incluiNaReserva}
        >
          <LockIcon />
        </Fab>
        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit}
          onClick={liberaCarinho}
        >
          <RemoveShoppingCart />
        </Fab>
        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit3}
          onClick={liberaReserva}
        >
          <LockOpenIcon />
        </Fab>
        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit5}
          onClick={() => setopenFilter(!openFilter)}
        >
          {openFilter ? <FilterListIcon /> : <SearchIcon />}
        </Fab>
        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit2}
          onClick={handleSubmitOffer}
        >
          {mudaIconeCarrinho()}
        </Fab>
      </Container>
      <Drawer
        variant={"temporary"}
        anchor={"right"}
        open={openFilter}
        onClose={toggleDrawer(openFilter)}
      >
        <List>
          <div className={classes.drawer} />
          <p>
            Paginas: {page} de {totalPage} || Bundles: {totalBundles}
          </p>
          <Divider />
          <ListItem>
            <TextField
              id="nameSearch"
              size="medium"
              placeholder="Block/Bundle/Slab"
              type="search"
              name="nameSearch"
              value={displaynameSearch}
              onChange={handleNameSearch} //{OnChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>

          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  name="espessura3"
                  //defaultChecked={values.espessura3}
                  checked={checkbox1 === true ? true : false}
                  value={values.espessura3}
                  onChange={onChange}
                />
              }
              label="3Cm"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="espessura2"
                  checked={checkbox2 === true ? true : false}
                  value={values.espessura2}
                  onChange={onChange}
                />
              }
              label="2Cm"
            />
          </ListItem>

          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  name="disponivel"
                  checked={checkbox5 === true ? true : false}
                  value={values.disponivel}
                  onChange={onChange}
                />
              }
              label="Disponível"
            />
          </ListItem>

          <ListItem>
            {userData?.userRole !== "cliente" ? (
              <FormControlLabel
                control={
                  <Checkbox
                    name="empedido"
                    checked={checkbox3 === true ? true : false}
                    value={values.empedido}
                    onChange={onChange}
                  />
                }
                label="Em pedido"
              />
            ) : null}
            <FormControlLabel
              control={
                <Checkbox
                  name="reservado"
                  checked={checkbox4 === true ? true : false}
                  value={values.reservado}
                  onChange={onChange}
                />
              }
              label="Reservado"
            />
          </ListItem>

          <ListItem>
            <TextField
              id="materialSearch"
              size="medium"
              placeholder="Material"
              type="search"
              name="material"
              value={displaymaterial}
              onChange={handlematerial}
              fullWidth
              InputProps={{
                step: 100,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>

          {userData?.userRole !== "cliente" ? (
            <ListItem>
              <TextField
                id="vendedorpedidocliente"
                size="medium"
                placeholder="Nome do vendedor"
                type="search"
                name="vendedorpedidocliente"
                value={displayvendedorpedidocliente}
                onChange={handlevendedorpedidocliente}
                fullWidth
                InputProps={{
                  step: 100,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
          ) : null}
          {userData?.userRole !== "cliente" ? (
            <ListItem>
              <TextField
                id="numeropedidocliente"
                size="medium"
                placeholder="Número do pedido "
                type="search"
                name="numeropedidocliente"
                value={displaynumeropedidocliente}
                onChange={handlenumeropedidocliente}
                fullWidth
                InputProps={{
                  step: 100,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
          ) : null}
          {userData?.userRole !== "cliente" ? (
            <ListItem>
              <FormControlLabel
                control={
                  <Checkbox
                    name="aprovados"
                    checked={checkbox6 === true ? true : false}
                    value={values.aprovados}
                    onChange={onChange}
                  />
                }
                label="Aprovados por Clientes"
              />
            </ListItem>
          ) : null}
          {userData?.userRole !== "cliente" ? (
            <ListItem>
              <TextField
                id="nomecliente"
                size="medium"
                placeholder="Cliente "
                type="search"
                name="nomecliente"
                value={displaynomecliente}
                onChange={handlenomecliente}
                fullWidth
                InputProps={{
                  step: 100,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
          ) : null}

          <AutoCompleteClassificacao
            classificacao={Classificacoes}
            label="Todos as Classificações"
            changeValueClassificacao={changeValueClassificacao}
            classificacoes={classificacoes}
          ></AutoCompleteClassificacao>

          <AutoCompleteProcesso
            processos={Processos}
            label="Todos os Processos"
            changeValueProcesso={changeValueProcesso}
            processosState={processos}
          ></AutoCompleteProcesso>

          <ListItem>
            <Autocomplete
              options={["0101", "0131", "0301"]}
              value={filialSelected}
              label="selecione a filial"
              multiple
              style={{ width: 250 }}
              onChange={(event, newValue) => {
                if (newValue == null) {
                  setPage(0);
                  setFilialSelected("");
                }
                if (newValue) {
                  setPage(0);
                  setFilialSelected(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione a filial"
                  variant="standard"
                />
              )}
            />
          </ListItem>
          <ListItem>
            <Autocomplete
              options={orderBy}
              value={orderBySelected}
              multiple
              label="Ordernar por"
              getOptionLabel={(options) => options.option}
              style={{ width: 250 }}
              filterSelectedOptions
              onChange={(event, newValue) => {
                if (newValue == null) {
                  setPage(0);
                  setOrderBySelected(() => "");
                  return;
                } else {
                  setPage(0);
                  setOrderBySelected(() => newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ordernar por"
                  variant="standard"
                />
              )}
            />
          </ListItem>
          {/* <ListItem >
            <ListProcesso
              id="processo"
              name="processo"
              label="Processo"
              value={values.processo}
              fullWidth
              onChange={onChange}

            />
          </ListItem> */}

          {/* <ListItem >
            <ListClassifcacao
              id="classificacao"
              name="classificacao"
              label="Classificaçao"
              value={values.classificacao}
              fullWidth
              onChange={onChange}
            />
          </ListItem> */}
        </List>
        {/* {console.log(chapaData)} */}
      </Drawer>
      {isOpen && (
        <Lightbox
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          slideIndex={slideIndex}
          setSlideIndex={setSlideIndex}
          showSlides={showSlides}
          bundles={chapaData}
          selectedBundles={selectedBundles}
          setSelectedBundles={setSelectedBundles}
          userId={userData.id}
        />
      )}
      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
    </React.Fragment>
  );
}
