import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const VincularChapasModal = ({ chapasBloco, closemodal, open, selectChapa }) => {
  const chapasWithoutBundles = chapasBloco.filter((chapa) => chapa.bundle_id === null);

  return (
    <Dialog open={open} onClose={closemodal} aria-labelledby="form-dialog-title" maxWidth="xl">
      <DialogTitle>Vincular Chapas</DialogTitle>
      <DialogContent style={{ width: "50vw", height: "70vh" }}>
        <DataGrid
          rows={chapasWithoutBundles}
          columns={[
            { field: "id", headerName: "Chapa ID", width: 130 },
            { field: "bloco", headerName: "Bloco", width: 130 },
            { field: "chapacliente_id", headerName: "Chapa", width: 130 },
            { field: "nomematerialingles", headerName: "Material", width: 130 },
            { field: "filialcliente", headerName: "Filial", width: 130 },
          ]}
          onRowClick={selectChapa}
        />
      </DialogContent>
    </Dialog>
  );
};

export default VincularChapasModal;
