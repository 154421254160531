import React, { useState } from "react";

import api from "../../Services/api";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Title from "../Types/Tittle";

import Grid from "@material-ui/core/Grid";

import Snackbar from "../../components/SnackBar/index";

export default function ModalPass({ setCloseModalPass, userId }) {
  const [senha, setSenha] = useState("");
  const [passCheck, setPassCheck] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  async function handleUpdatePass(event) {
    event.preventDefault();

    if (senha && passCheck) {
      if (senha === passCheck) {
        const user = {
          id: userId,
          senha: senha,
        };
        await api.put("usuarioSenha", user).then((result) => {
          if (result) {
            setSnackbarMessage("Senha atualizada com sucesso!");
            setSnackbarKind("success");
            setOpenSnackbar(true)
            setTimeout(() => {setCloseModalPass()}, 2000)
          }
          
        });
      } else {
        setSnackbarMessage("Senha nao confere com a confirmaçao");
        setSnackbarKind("warning");
        setOpenSnackbar(true);
      }
    } else {
      setSnackbarMessage("Favor informar a nova senha");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    }
  }

  return (
    <form>
      <Dialog
        open={true}
        onClose={setCloseModalPass}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"><Title>Redefinindo senha</Title></DialogTitle>
        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="senha"
                label="Senha"
                type="password"
                value={senha}
                onChange={(event) => setSenha(event.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="dense"
                id="passCheck"
                label="Confirmaçao da Senha"
                type="password"
                value={passCheck}
                onChange={(event) => setPassCheck(event.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={setCloseModalPass}
            color="primary"
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleUpdatePass}
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
          open={openSnackbar}
          closeBtn={true}
          closeSnackbar={closeSnackbar}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
        ></Snackbar>
    </form>
  );
}
