import React, { useEffect, useState, useContext, useRef } from "react";

import UserContext from "../../context/User.context";

import api, {
  checkBundleOffers,
  createBundle,
  getAllFiliais,
  getChapaPreco,
  getChapasFromBloco,
  getChapasFromBundle,
  getCompanyBucket,
  getMateriaisByCompany,
  postImages,
  getSpecificBundle,
  removeBundleIdFromChapa,
  saveChapaInBundle,
  updateBundle,
  updateBundleNew,
  updateBundlePhoto,
  updateChapaPhoto,
} from "../../Services/api";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Title from "../Types/Tittle";
import Typography from "@material-ui/core/Typography";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

import Copyright from "../../functions/Copyright";

import { getChapa } from "../Chapa/getChapa";

import Snackbar from "../../components/SnackBar/index";
import { useAuth } from "../../context/User.provider";
import { Alert, Autocomplete } from "@material-ui/lab";
import ListEspessura from "../Chapa/ListEspessura";
import CreateBundleForm from "./CreateBundleForm";
import ChapaFormInBundle from "./ChapaFormInBundle";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Input,
} from "@material-ui/core";
import VincularChapasModal from "./VincularChapasModal";
import ItemCard from "../Oferta/Chapa/ItemCard";
import CameraComponent from "./CameraComponent";
import { result } from "lodash";
import Editor from "../../components/ImagemEditor/EditorImagem"; //
import ImagemEditor from "../../components/ImagemEditor/ImagemEditor";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function BundleForm({
  setList,
  bundleId,
  setBundleId,
  bundleData,
}) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const { userData } = useAuth();
  // const [userData] = useContext(UserContext);

  const [chapasInBundle, setChapasInBundle] = useState([]);
  const [chapas, setChapas] = useState([]);
  const [chapasLoaded, setChapasLoaded] = useState(false);
  const [chapasBloco, setChapasBloco] = useState([]);

  const [chapasBundle, setChapasBundle] = useState([]);
  const [chapasBundleLoaded, setChapasBundleLoaded] = useState(false);
  const [chapaIdFoto, setChapaIdFoto] = useState(0);
  const [chapaSelected, setChapaSelected] = useState([]);
  const [totalm2, setTotalm2] = useState(0);

  const [material, setMaterial] = React.useState({
    id: 0,
    nome: "",
  });

  const [filial] = useState({
    id: 0,
    value: "",
  });

  const [chapa, setChapa] = useState({
    bloco: "",
    usuario_id: 0,
    empresa_id: 0,
    nomematerialingles: "",
    nomematerialportugues: "",
    material_id: 0,
    filialcliente: "",
    bundlecliente_id: "",
    classificacao: "",
    processo: "",
    espessura: "",
    altura_bruta: 0,
    altura_liquida: 0,
    comprimento_bruto: 0,
    comprimento_liquido: 0,
    emestoque: 0,
    produto_id: 0,
    status_oferta: "",
    numerochapa: 0,
  });

  const [criarChapas, setCriarChapas] = useState(false);
  const [materiais, setMateriais] = useState([]);
  const [materiaisLoaded, setMateriaisLoaded] = useState(false);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [haveOfert, setHaveOfert] = useState(false);

  const [filialSelected, setFilialSelected] = useState([]);
  const [image, setImage] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [quantidadeChapas, setQuantidadeChapas] = useState();
  const [chapasCreated, setChapasCreated] = useState();

  const imageRef = useRef(null);
  const thumbRef = useRef(null);
  const chapaImageRef = useRef(null);
  const canvasRef = useRef(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  const [cameraView, setCameraView] = useState(false);
  const [chapaCameraView, setChapaCameraView] = useState(false);

  const [valorChapa, setValorChapa] = useState("");
  const [FotoCarregada, setFotoCarregada] = useState("");
  //FotoCarregada

  const [bundle, setBundle] = useState({
    id: 0,
    // chapa_id: 0,
    bloco: "",
    usuario_id: userData.id,
    empresa_id: userData.empresa_id,
    nomematerialingles: "",
    nomematerialportugues: "",
    vinculado: 1,
    filialcliente: "",
    foto_local: "",
    foto_remoto: "",
    emestoque: 1,
    material_id: 0,
    numero_bundle_cliente: "",
    numero_chapa_capa: "",
    peso_madeira: "",
    peso_total: "",
    status_oferta: "",
    classificacao: "",
    processo: "",
    espessura: "",
    chapas: [],
    // data_oferta: "",
    total_chapas: 0,
    totalm2: 0,
    linkS3: "",
  });

  function handleUpdateTotal(total) {
    setTotalm2(total);
  }

  async function getFiliais() {
    try {
      await getAllFiliais(setFilial);
    } catch (err) {
      console.log(err);
    }
  }

  function openFolder() {
    if (imageRef.current) {
      imageRef.current.click();
    }
  }

  function openChapaFolder() {
    if (chapaImageRef.current) {
      chapaImageRef.current.click();
    }
  }

  // async function saveImageTaken(image, isChapa, chapaId) {
  //   console.log("imagetaken", image);
  //   try {
  //     const formImage = new FormData();
  //     formImage.append("photo", image);

  //     const reader = new FileReader();
  //     reader.readAsDataURL(image);

  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.onload = () => {
  //         let canvas = document.createElement("canvas");
  //         canvas.width = 226;
  //         canvas.height = 150;

  //         let ctx = canvas.getContext("2d");

  //         ctx.drawImage(img, 0, 0, 226, 150);

  //         canvas.toBlob(
  //           function (blob) {
  //             const thumbnail = new File([blob], `tmb_${image.name}`, { type: "image/jpeg" });
  //             formImage.append("thumbnail", thumbnail);
  //           },
  //           "image/jpeg",
  //           1
  //         );
  //       };
  //       img.src = e.target.result;
  //     };

  //     formImage.append("empresa_id", userData.empresa_id);
  //     formImage.append("filial_cliente", bundle.filialcliente);
  //     const bucket = await getCompanyBucket(userData.empresa_id);
  //     const imageUploaded = await postImages(bucket.data[0].bucket_name, formImage);
  //     console.log(imageUploaded);
  //     if (isChapa === true) {
  //       const idChapa = chapasBundle.find((c) => c.id === chapaId);
  //       console.log(idChapa.id);
  //       handleAddChapaPhoto(idChapa.id, imageUploaded.data.files[0].key);
  //       getChapasBundle(bundle.numero_bundle_cliente);
  //     } else {
  //       // setBundle((prevState) => {
  //       //   return {
  //       //     ...prevState,
  //       //     foto_remoto: imageUploaded.data.files[0].location,
  //       //     foto_local: imageUploaded.data.files[0].location,
  //       //   };
  //       // });

  //       updateBundlePhoto(bundle.id, imageUploaded.data.files[0].key);
  //       setImageUploaded(() => true);
  //       setBundle((prevState) => {
  //         return {
  //           ...prevState,
  //           foto_remoto: imageUploaded.data.files[0].key,
  //           foto_local: imageUploaded.data.files[0].key,
  //           linkS3: imageUploaded.data.files[0].location,
  //         };
  //       });
  //     }
  //     setSnackbarMessage("Imagem inserida com sucesso.");
  //     setSnackbarKind("success");
  //     setOpenSnackbar(true);
  //     setChapaCameraView(false);
  //     return formImage;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  function getImgSize(image) {
    const newImg = new Image();
    return new Promise((res, rej) => {
      newImg.onload = function () {
        const height = newImg.height;
        const width = newImg.width;
        res({
          width: newImg.width,
          height: newImg.height,
        });
      };
      newImg.src = image.src;
    });
  }

  function toBase64String(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/jpeg");
    return dataURL;
  }

  async function saveImage(e, isChapa, chapaId, chapaIn) {
    try {
      let file = null;

      const numeroChapa = () => {
        if (isChapa) {
          console.log(chapaIn);
          return chapaIn.numerochapa;
        } else {
          return bundle.numero_chapa_capa;
        }
      };

      file = e.target.files[0];
      setImage(() => file);
      let nomeFoto =
        "[" +
        bundle.numero_bundle_cliente +
        "][" +
        bundle.bloco +
        "][" +
        numeroChapa() +
        "]";
      //console.log(numeroChapa());

      //console.log(file);
      const newFile = new File([file], nomeFoto, { type: file.type });
      file = newFile;

      //console.log(file.name);
      const formImage = new FormData();

      const readerPhoto = new FileReader();

      readerPhoto.onload = (e) => {
        const imgPhoto = new Image();
        imgPhoto.onload = () => {
          //const canvas = canvasRef.current;

          let canvas = document.createElement("canvas");
          const scale = imgPhoto.height / imgPhoto.width;
          canvas.width = 1920;
          canvas.height = 1920 * scale;
          let fator = imgPhoto.height / imgPhoto.width;
          // console.log(imgPhoto.height + " x " + imgPhoto.width);
          const ctx = canvas.getContext("2d");

          ctx.drawImage(imgPhoto, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(function (blob) {
            const photo = new File([blob], `${file.name}.jpg`, {
              type: "image/jpeg",
            });
            formImage.append("photo", photo);
          }, "image/jpeg"); //*/
        };
        imgPhoto.src = e.target.result;
      };
      readerPhoto.readAsDataURL(file);

      const reader = new FileReader();

      reader.onload = (e) => {
        const imgTumb = new Image();
        imgTumb.onload = () => {
          let canvas = document.createElement("canvas");
          canvas.width = 226;
          canvas.height = 150;

          let ctx = canvas.getContext("2d");

          ctx.drawImage(imgTumb, 0, 0, 226, 150);

          return canvas.toBlob(function (blob) {
            const thumbnail = new File([blob], `tmb_${file.name}.jpg`, {
              type: "image/jpeg",
            });
            formImage.append("thumbnail", thumbnail);
          }, "image/jpeg");
        };
        imgTumb.src = e.target.result;
      };
      reader.readAsDataURL(file);

      // console.log("thumb", thumb);
      // formImage.append("thumbnail", thumb);
      // }
      if (isChapa) {
        if (chapaIn.filialcliente === "") {
          setChapa((prevState) => {
            return {
              ...prevState,
              filialcliente: bundle.filialcliente,
            };
          });
        }
      }
      formImage.append("empresa_id", userData.empresa_id);
      formImage.append("filial_cliente", bundle.filialcliente);
      const bucket = await getCompanyBucket(userData.empresa_id);
      console.log(bucket.data[0].bucket_name);

      const imageUploaded = await postImages(
        bucket.data[0].bucket_name,
        formImage
      );

      console.log("Imagem que subiu " + imageUploaded.data.files[0].file);
      if (isChapa === true) {
        console.log("chapa ID", chapaId);
        const idChapa = chapasBundle.find((c) => c.id === chapaId);
        //console.log(idChapa);
        handleAddChapaPhoto(idChapa.id, imageUploaded.data.files[0].key);
        getChapasBundle(bundle.numero_bundle_cliente);
      } else {
        // setBundle((prevState) => {
        //   return {
        //     ...prevState,
        //     foto_remoto: imageUploaded.data.files[0].location,
        //     foto_local: imageUploaded.data.files[0].location,
        //   };
        // });

        updateBundlePhoto(bundle.id, imageUploaded.data.files[0].key);
        setImageUploaded(() => true);
        setBundle((prevState) => {
          return {
            ...prevState,
            foto_remoto: imageUploaded.data.files[0].key,
            foto_local: imageUploaded.data.files[0].key,
            linkS3: imageUploaded.data.files[0].location,
          };
        });
      }
      setSnackbarMessage("Imagem inserida com sucesso.");
      setSnackbarKind("success");
      setOpenSnackbar(true);
      return formImage;

      // await api.post("/chapaimage/" + chapa.id, formImage)

      // getChapa(chapaId);
    } catch (err) {
      setSnackbarMessage("Erro ao inserir imagem.");
      setSnackbarKind("error");
      setOpenSnackbar(true);
      console.log(err);
    }
  }

  async function handleAddChapaPhoto(chapaId, photoName) {
    try {
      const chapaUpdated = await updateChapaPhoto(chapaId, photoName);
      console.log("chapaUpdated response", chapaUpdated.data);
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  function setChapaId(id) {
    setChapasBundleLoaded(false);

    //console.log(chapasBundle.findIndex(chapa => parseInt(chapa.id) === parseInt(id)))

    if (
      chapasBundle.findIndex((chapa) => parseInt(chapa.id) === parseInt(id)) < 0
    ) {
      setBundle((prevState) => {
        return {
          ...prevState,
          chapa_id: id,
          total_chapas: chapasBundle.length + 1,
        };
      });
      getPrecoChapa(id);

      getChapa(id).then((novaChapa) => {
        setChapasBundle((prevState) => {
          return [...prevState, novaChapa];
        });
      });
    }

    setChapasBundleLoaded(true);
  }

  async function getPrecoChapa(id) {
    try {
      const res = await getChapaPreco(id);
      // setBundle((prevState) => {
      //   return { ...prevState, valor_chapa: res.data.precom2 };
      // });
      setValorChapa(res.data.precom2);
      // setBundle((prevState) => {
      //   return { ...prevState, preco_chapa: res.data.precom2 };
      // });
    } catch (err) {
      //console.log("Erro ao consultar Preco da Chapa.");
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  function setFilial(filial) {
    setBundle((prevState) => {
      return {
        ...prevState,
        filialcliente: filial,
      };
    });
  }

  function setMaterialId(material) {
    const nomePortugues = material.nome.split(" - ")[0];
    const nomeIngles = material.nome.split(" - ")[1];
    setBundle((prevState) => {
      return {
        ...prevState,
        material_id: material.id,
        nomematerialportugues: nomePortugues,
        nomematerialingles: nomeIngles,
      };
    });
  }

  function setChapaMaterialId(material) {
    const nomePortugues = material.nome.split(" - ")[0];
    const nomeIngles = material.nome.split(" - ")[1];
    setChapasInBundle((prevState) => {
      return {
        ...prevState,
        material_id: material.id,
        nomematerialportugues: nomePortugues,
        nomematerialingles: nomeIngles,
      };
    });
  }

  async function getChapas() {
    try {
      await api
        .get(`/chapas/empresaAC/Dif/${userData.empresa_id}`)
        .then((res) => {
          setChapas(res.data);
        });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
      setChapasLoaded(false);
    }
    setChapasLoaded(true);
  }

  async function getChapasBundle(numeroBundleCliente) {
    try {
      const res = await getChapasFromBundle(
        numeroBundleCliente,
        userData?.empresa_id
      );
      console.log("chapasBundle", res.data);
      setChapasBundle(res.data);
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    setChapasBundleLoaded(true);
  }

  async function getMateriais() {
    try {
      const res = await getMateriaisByCompany(userData.empresa_id);
      setMateriais(res.data);
    } catch (err) {
      //console.log("Erro ao consultar Materiais.");
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
      setMateriaisLoaded(false);
    }
    setMateriaisLoaded(true);
  }

  function calculaPesoTotal(pesoMadeira, totalChapas) {
    if (pesoMadeira && totalChapas) {
      setBundle((prevState) => {
        return { ...prevState, peso_total: pesoMadeira * totalChapas };
      });
    }
  }

  function calculaValorTotal(totalChapas) {
    if (valorChapa && totalChapas) {
      setBundle((prevState) => {
        return { ...prevState, valor_total: valorChapa * totalChapas };
      });
    }
  }

  async function getChapasByBloco(bloco) {
    try {
      const res = await getChapasFromBloco(userData.empresa_id, bloco);
      console.log("chapasBloco", res.data);
      setChapasBloco(res.data);
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
      setMateriaisLoaded(false);
    }
  }

  async function getBundle(id) {
    console.log(bundleData);
    try {
      if (id !== 0) {
        const bundleSelected = bundleData.filter((item) => {
          return item.id === id;
        });
        console.log(bundleSelected[0]);
        setBundle(bundleSelected[0]);
        getChapasByBloco(bundleSelected[0].bloco);
        setFotoCarregada(bundleSelected[0].linkS3);
        console.log(FotoCarregada);
        //   chapa.id = res.data[0].chapa_id;
        //   chapa.nome = res.data[0].nome;

        //   material.id = res.data[0].material_id;
        //   material.nome = res.data[0].material;
        getChapasBundle(bundleSelected[0].numero_bundle_cliente);

        //   setDataLoaded(true);
        // });
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    //setOpen(true);
  }

  useEffect(
    () => {
      getBundle(bundleId);
      // getChapas();
      getMateriais();
      getFiliais();
      checkOfert();
      console.log(chapasBundle);
      //console.log(inventory.find(isCherries));
    },
    [bundleId]
    // [materiaisLoaded],
    // [chapasBundleLoaded],
    // [chapasBundle]
  );

  async function handleUpdateBundle(event) {
    event.preventDefault();
    // if (!bundle.chapa_id) {
    //   setSnackbarMessage("Favor informar a chapa do bundle.");
    //   setSnackbarKind("warning");
    //   setOpenSnackbar(true);
    //   return;
    // }
    try {
      let linkS3 = bundle.linkS3;
      delete bundle.expira_reserva;
      delete bundle.vendedor_nome;
      delete bundle.nome_portugues;
      delete bundle.nome_ingles;
      delete bundle.linkS3;
      delete bundle.linkS3Thumb;
      // delete bundle.created_time;
      bundle.chapas = chapasBundle;
      const updatedBundle = await updateBundle(bundle);
      console.log(updatedBundle);
      bundle.linkS3 = linkS3;
      setSnackbarMessage("Bundle atualizado com sucesso.");
      setSnackbarKind("success");
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function handleSaveChapaInBundle() {
    // let chapas = {};
    setModalOpen(true);
    // chapasBundle.map(
    //   (chapa) => (chapas.chapa_id = !chapas.chapa_id ? [chapa.id] : [...chapas.chapa_id, chapa.id])
    // );
    // chapas.bundle_id = bundle.id;
    // console.log("chapas", chapas);
    // try {
    //   await saveChapaInBundle(chapas);
    //   // await api.post("bundles/chapa/bundle", chapas);
    //   setSnackbarMessage("Chapas vinculadas com sucesso.");
    //   setSnackbarKind("success");
    //   setOpenSnackbar(true);
    // } catch (err) {
    //   setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
    //   setSnackbarKind("error");
    //   setOpenSnackbar(true);
    // }
  }

  async function handleCreateBundle(event) {
    event.preventDefault();

    // if (!(chapasBundle.length > 0)) {
    //   setSnackbarMessage("Favor informar ao menos uma chapa do bundle.");
    //   setSnackbarKind("warning");
    //   setOpenSnackbar(true);
    //   return;
    // }
    // if (chapaIdFoto === 0) {
    //   setSnackbarMessage("Favor informar a foto principal do bundle.");
    //   setSnackbarKind("warning");
    //   setOpenSnackbar(true);
    //   return;
    // }
    if (!bundle.material_id) {
      setSnackbarMessage("Favor informar o material do bundle.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      const ress = await createBundle(bundle);
      console.log(ress);

      // chapasBundle.map(async (res) => {
      //   //Inserindo as relacoes bundle-chapa
      //   (parseInt(res.id) === parseInt(chapaIdFoto)) ? (
      //   await api.post(`/bundle/${ress.data.id}/chapa/${(res.id) ? res.id : 0}/1`)
      //   ) :
      //   await api.post(`/bundle/${ress.data.id}/chapa/${(res.id) ? res.id : 0}/0`)
      // })
      setBundle((prevState) => {
        return { ...prevState, id: ress.data[0] };
      });
      // const createdBundle = await getSpecificBundle(ress.data[0]);
      // console.log(createdBundle);

      setChapasBundleLoaded(false);
      setSnackbarMessage("Bundle Inserido com sucesso.");
      setSnackbarKind("success");
      setOpenSnackbar(true);
      // setChapasBundle(ress.data.id);
      // getBundle(ress.data[0]);
    } catch (err) {
      console.log(err);
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function updateFotoPrincipal(id) {
    //Excluir a Chapa do Bundle
    try {
      await api.put(`/bundle/fotoprincipal/${bundle.id}`).then(async (res) => {
        await api
          .put(`/bundle/${bundle.id}/fotoprincipal/${id}`)
          .then((result) => {
            setSnackbarMessage("Foto do bundle alterado com sucesso.");
            setSnackbarKind("success");
            setOpenSnackbar(true);
            getChapasBundle(bundle.bundlecliente_id);
          });
      });
    } catch (error) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function deleteBundleChapa(id) {
    setChapasBundleLoaded(false);
    //Excluir a Chapa do Bundle
    try {
      await api.delete(`/bundle/chapa/${id}`).then((res) => {
        setBundle((prevState) => {
          return { ...prevState, total_chapas: prevState.total_chapas - 1 };
        });

        setSnackbarMessage("Chapa do bundle excluído com sucesso.");
        setSnackbarKind("success");
        setOpenSnackbar(true);
        // getChapasBundle(bundleId);
      });

      setChapasBundleLoaded(true);
    } catch (error) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function checkOfert() {
    setHaveOfert(false);
    //Verifica se existe alguma oferta do Bundle
    try {
      const res = await checkBundleOffers(bundleId);
      //console.log(res.data)
      if (res.data.length > 0) {
        setHaveOfert(true);
      }
    } catch (error) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  function handleChangeFotoPrincipal(event) {
    setChapaIdFoto(event.target.value);
  }
  const handleRemoveChapaFromBundle = async (id) => {
    try {
      await removeBundleIdFromChapa(id);
      setSnackbarMessage("Chapa desvinculada com sucesso.");
      setSnackbarKind("success");
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarMessage("Erro ao remover chapa do bundle.");
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    const chapaRemoved = chapasBundle.find((chapa) => chapa.id === id);
    console.log(chapaRemoved);
    setBundle((prevState) => {
      return {
        ...prevState,
        total_chapas: prevState.total_chapas - 1,
        lista_chapas: prevState.lista_chapas.replace(
          `${chapaRemoved.numerochapa},`,
          ""
        ),
      };
    });
    setChapasBundle((prev) => prev.filter((c) => c.id !== id));
  };

  async function handleDeleteChapa(id) {
    //Excluir uma determinada chapa do vetor chapasBundle

    setChapasBundleLoaded(false);
    //var chapaSelecionada = (chapasBundle.find(chapa => parseInt(chapa.id) === parseInt(id))) || null
    var chapaSelecionada =
      chapasBundle.findIndex((chapa) => parseInt(chapa.id) === parseInt(id)) ||
      null;
    chapasBundle.splice(chapaSelecionada, chapaSelecionada >= 0 ? 1 : 0);
    setBundle((prevState) => {
      return { ...prevState, total_chapas: prevState.total_chapas - 1 };
    });
    //setChapasBundle(() => )
    await setChapasBundle(chapasBundle);
    setChapasBundleLoaded(true);

    //alert("Códifo da chapa: " + id)
  }

  async function handleOpenChapaModal(chapa) {
    setModalOpen(!modalOpen);
  }

  function listaChapas(start, end) {
    let result = "";
    for (let i = start; i <= end; i++) {
      result += i + (i === end ? "" : ",");
    }
    return result;
  }

  const handleAddChapaToBundle = (event) => {
    //console.log(event);
    setTimeout(() => {
      event.row.bundle_id = bundle.id;
      setChapasBundle((prevState) => [...prevState, event.row]);
      setBundle((prevState) => {
        return {
          ...prevState,
          total_chapas: prevState.total_chapas ? prevState.total_chapas + 1 : 1,
          lista_chapas: prevState.lista_chapas
            ? prevState.lista_chapas + `,${event.row.numerochapa}`
            : `${event.row.numerochapa}`,
        };
      }, []);
    });
  };

  const handleCreateChapas = async () => {
    const firstChapaInList = quantidadeChapas.split("-")[0];
    const lastChapaInList = quantidadeChapas.split("-")[1];
    let [start, end] = quantidadeChapas.split("-").map((n) => parseInt(n, 10));
    let lista_chapas = listaChapas(start, end);
    const arrayQuantity = Number(lastChapaInList) - Number(firstChapaInList);
    const chapas = Array.from({ length: arrayQuantity }, (_, i) => {
      return {
        bloco: bundle.bloco, //"",
        usuario_id: bundle.usuario_id, //0,
        empresa_id: bundle.empresa_id, //0,
        nomematerialingles: bundle.nomematerialingles,
        nomematerialportugues: bundle.nomematerialportugues,
        material_id: bundle.material_id, //0,
        filialcliente: bundle.filialcliente,
        bundlecliente_id: bundle.numero_bundle_cliente,
        classificacao: bundle.classificacao,
        processo: bundle.processo,
        espessura: bundle.espessura,
        altura_bruta: chapasInBundle.altura_bruta, //0,
        altura_liquida: chapasInBundle.altura_liquida, //0,
        comprimento_bruto: chapasInBundle.comprimento_bruto, //0,
        comprimento_liquido: chapasInBundle.comprimento_liquido, //0,
        // total_bruto: chapasInBundle.total_bruto,
        // total_liquido: chapasInBundle.total_liquido,
        emestoque: 1,
        produto_id: 1,
        status_oferta: "3",
        numerochapa: Number(firstChapaInList) + i,
      };
    });
    console.log(chapas);
    setChapasCreated(chapas);
    setBundle((prevState) => {
      return {
        ...prevState,
        chapas: chapas,
        lista_chapas,
        total_chapas: chapas.length + 1,
      };
    });
    setChapasBundle(chapas);
  };

  const openCameraView = (chapa) => {
    setCameraView(true);
    if (chapa) {
      setChapaCameraView(chapa);
    }
  };

  const handleChangeChapaName = (e, chapa) => {
    //console.log(e);
    const chapaChanged = chapasBundle.find((c) => c.id === chapa.id);
    chapaChanged.chapacliente_id = e.target.value;
    setChapasBundle((prev) =>
      prev.map((c) => (c.id === chapa.id ? chapaChanged : c))
    );
    //console.log(chapasBundle);
  };

  const chapaRefs = chapasBundle.map(() => React.createRef());

  const handleChapaRefClick = (idx) => {
    if (chapaRefs[idx].current) {
      chapaRefs[idx].current.click();
    }
  };

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Bundle{bundle.id !== 0 ? " - " + bundle.id : null}</Title>

              <FormControl className={classes.formControl}>
                <CreateBundleForm
                  bundle={bundle}
                  setBundle={setBundle}
                  material={material}
                  materiais={materiais}
                  userData={userData}
                  setMaterialId={setMaterialId}
                  chapa={chapa}
                />
                {/* <input
                    type="file"
                    single
                    id="file"
                    key="file"
                    ref={imageRef}
                    style={{ display: "none" }}
                    onChange={(event) => saveImage(event)}
                  /> */}
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    border: "2px solid #ccc",
                    padding: "1rem",
                    margin: "1rem 0",
                  }}
                  sm={12}
                >
                  {bundle.linkS3 ? (
                    <Grid
                      item
                      sm={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "1rem",
                      }}
                    >
                      <img
                        src={bundle.linkS3 ? bundle.linkS3 : bundle.linkS3Thumb}
                        alt="preview-img"
                        style={{ width: "100%" }}
                        class="preview-img"
                      />
                    </Grid>
                  ) : (
                    <div>Without Picture</div>
                  )}
                  {!chapa.foto_local || !chapa.foto_remoto ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={openFolder}
                      className={classes.button}
                      startIcon={<AddAPhotoIcon />}
                    >
                      {bundle.linkS3 ? `Atualizar Foto` : `Adicionar Foto`}
                      <input
                        type="file"
                        multiple
                        id="file"
                        key="file"
                        ref={imageRef}
                        style={{ display: "none" }}
                        onChange={(event) => saveImage(event)}
                      />
                    </Button>
                  ) : null}
                  <Editor
                    Imagem={bundle.foto_remoto}
                    ImagemAWS={bundle.linkS3}
                  />
                  {/*<ImagemEditor
                    Imagem={bundle.foto_remoto}
                    ImagemAWS={bundle.linkS3}
                  />*/}
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openCameraView(null)}
                    className={classes.button}
                    startIcon={<AddAPhotoIcon />}
                  >
                    Tirar foto
                  </Button> */}
                </Grid>
                <Divider
                  style={{ marginTop: "1.5rem", paddingTop: "0.5rem" }}
                />
                {criarChapas === false && (
                  <Grid
                    item
                    sm={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "1rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCriarChapas(true)}
                    >
                      Criar chapas
                    </Button>
                  </Grid>
                )}
                {criarChapas && (
                  <>
                    {" "}
                    <Grid
                      item
                      sm={12}
                      style={{ display: "flex", marginTop: "2rem" }}
                    >
                      <TextField
                        id="criar_chapas"
                        name="criar_chapas"
                        label="Criar chapas (ex: 1-10)"
                        fullWidth
                        value={quantidadeChapas}
                        onChange={(event) => {
                          setQuantidadeChapas(() => event.target.value);
                        }}
                      />
                      <Button onClick={handleCreateChapas}>Criar chapas</Button>
                    </Grid>
                    <Grid container spacing={5}>
                      <Grid item sm={3} style={{ display: "flex" }}>
                        <TextField
                          id="altura_liquida"
                          fullWidth
                          name="altura_liquida"
                          label="Altura Líquida das chapas"
                          value={chapasInBundle?.altura_liquida || ""}
                          onChange={(event) => {
                            setChapasInBundle((prevState) => {
                              return {
                                ...prevState,
                                altura_liquida: event.target.value,
                                total_liquido:
                                  event.target.value *
                                  chapasInBundle?.comprimento_liquido,
                              };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={3} style={{ display: "flex" }}>
                        <TextField
                          id="comprimento_liquido"
                          fullWidth
                          name="comprimento_liquido"
                          label="Comprimento Líquido das chapas"
                          value={chapasInBundle?.comprimento_liquido || ""}
                          onChange={(event) => {
                            setChapasInBundle((prevState) => {
                              return {
                                ...prevState,
                                comprimento_liquido: event.target.value,
                                total_liquido:
                                  event.target.value *
                                  chapasInBundle?.altura_liquida,
                              };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={3} style={{ display: "flex" }}>
                        <TextField
                          id="altura_bruta"
                          fullWidth
                          name="altura_bruta"
                          label="Altura Bruta das chapas"
                          value={chapasInBundle?.altura_bruta || ""}
                          onChange={(event) => {
                            setChapasInBundle((prevState) => {
                              return {
                                ...prevState,
                                altura_bruta: event.target.value,
                                total_bruto:
                                  event.target.value *
                                  chapasInBundle?.comprimento_bruto,
                              };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={3} style={{ display: "flex" }}>
                        <TextField
                          id="comprimento_bruto"
                          fullWidth
                          name="comprimento_bruto"
                          label="Comprimento Bruto das chapas"
                          value={chapasInBundle?.comprimento_bruto || ""}
                          onChange={(event) => {
                            setChapasInBundle((prevState) => {
                              return {
                                ...prevState,
                                comprimento_bruto: event.target.value,
                                total_bruto:
                                  event.target.value *
                                  chapasInBundle?.altura_bruta,
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                      <Grid item sm={6} style={{ display: "flex" }}>
                        <TextField
                          id="total_bruto"
                          fullWidth
                          name="total_bruto"
                          label="Total Bruto das chapas"
                          value={chapasInBundle?.total_bruto || ""}
                          // onChange={(event) => {
                          //   setChapasInBundle((prevState) => {
                          //     return {
                          //       ...prevState,
                          //       total_bruto: event.target.value,
                          //     };
                          //   });
                          // }}
                        />
                      </Grid>
                      <Grid item sm={6} style={{ display: "flex" }}>
                        <TextField
                          id="total_liquido"
                          fullWidth
                          name="total_liquido"
                          label="Total Liquido das chapas"
                          value={chapasInBundle?.total_liquido || ""}
                          // onChange={(event) => {
                          //   setChapasInBundle((prevState) => {
                          //     return {
                          //       ...prevState,
                          //       total_liquido: event.target.value,
                          //     };
                          //   });
                          // }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {/* <Grid item sm={12} style={{ paddingTop: "2rem" }}>
                  {Array.from({ length: bundle.total_chapas }).map((_, idx) => {
                    if (bundle.chapas.length > idx) return <></>;
                    return <Button onClick={handleOpenChapaModal}>Adicionar Chapa</Button>;
                  })}
                </Grid> */}

                {bundle.id === 0 ? (
                  chapasCreated?.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {chapasCreated?.map((chapa, idx) => {
                        return (
                          <>
                            <div
                              style={{
                                marginTop: "1rem",
                                border: "1px solid black",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "30%",
                                height: "250px",
                              }}
                            >
                              <p>bundle_cliente: {chapa.bundlecliente_id}</p>
                              <p>bloco: {chapa.bloco}</p>
                              <p>material: {chapa.nomematerialingles}</p>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : null
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    {chapasBundle.length > 0 &&
                      chapasBundle.map((chapa, idx) => {
                        return (
                          <>
                            <Grid item xs={12} key={idx} sm={3}>
                              <ItemCard
                                openCamera={() => openCameraView(chapa)}
                                bundle={chapa}
                                index={idx}
                                handleRemoveChapaFromBundle={
                                  handleRemoveChapaFromBundle
                                }
                                saveImage={saveImage}
                                chapaRefs={chapaRefs}
                                handleChapaRefClick={handleChapaRefClick}
                                selectedBundles={[]}
                                handleChangeCheck={() => {}}
                                consultaPedido={() => {}}
                                userId={userData.id}
                                showButtons={true}
                              />
                            </Grid>
                            {/* <div
                              style={{
                                marginTop: "1rem",
                                border: "1px solid black",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "30%",
                                height: "350px",
                              }}
                            >
                              <p>bundle_cliente: {chapa.bundlecliente_id}</p>
                              <p>bloco: {chapa.bloco}</p>
                              <p>material: {chapa.nomematerialingles}</p>
                              <TextField
                                value={chapa.chapacliente_id}
                                type="text"
                                style={{ padding: "0.5rem" }}
                                placeholder="Chapa Name"
                                onChange={(e) => handleChangeChapaName(e, chapa)}
                              />
                              {chapa.linkS3 ? (
                                <img src={chapa.linkS3} alt="chapa" style={{ height: "30%" }} />
                              ) : (
                                <div
                                  style={{
                                    height: 180,
                                    width: "80%",
                                    backgroundColor: "#0182c6",
                                    borderRadius: "5px",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  <Typography
                                    style={{
                                      fontSize: 20,
                                      fontWeight: "semi-bold",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      color: "#fff9f9",
                                    }}
                                  >
                                    WITHOUT PICTURE
                                  </Typography>
                                </div>
                              )} */}
                            {/* </div> */}
                          </>
                        );
                      })}
                  </div>
                )}

                {/* {bundle.total_chapas > 0
                  ? Array.from({ length: bundle.total_chapas }).map((_, idx) => (
                      <ChapaFormInBundle />
                    ))
                  : null} */}

                {/* {bundle.id !== 0 && chapasBundle.length === 0 ? (
                  <Grid item sm={12}>
                    <ChapasSelect
                      id="chapa_id"
                      label="Informe a Chapa"
                      value={chapaSelected}
                      defaultValue={chapa}
                      chapaCurrent={chapa}
                      chapas={chapas}
                      chapaId={userData.empresa_id}
                      setChapaId={setChapaId}
                    />
                  </Grid>
                ) : null} */}

                {/* {chapasBundle.length > 0 && chapasBundleLoaded && bundle.id !== 0
                  ? chapasBundle.map((row, index) => (
                      <Grid key={index} item sm={3}>
                        <RadioGroup
                          aria-label="Foto"
                          name="principal"
                          value={chapaIdFoto}
                          onChange={handleChangeFotoPrincipal}
                        >
                          <ChapaCard chapa={row} handleDeleteChapa={handleDeleteChapa}></ChapaCard>
                        </RadioGroup>
                      </Grid>
                    ))
                  : null} */}

                <Grid item align="right" sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      location.pathname ===
                      `/${process.env.REACT_APP_FRONT_ALIAS}/bundle`
                        ? setList
                        : history.push(
                            `/${process.env.REACT_APP_FRONT_ALIAS}/bundle`
                          )
                    }
                    className={classes.button}
                  >
                    Voltar
                  </Button>
                  {bundle.id !== 0 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveChapaInBundle}
                      className={classes.button}
                      startIcon={<SaveIcon />}
                    >
                      Vincular Chapas
                    </Button>
                  ) : null}

                  {bundle.id !== 0 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdateBundle}
                      className={classes.button}
                      startIcon={<SaveIcon />}
                    >
                      Salvar
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCreateBundle}
                      className={classes.button}
                      startIcon={<SaveIcon />}
                    >
                      Salvar
                    </Button>
                  )}
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        <Box pt={4}>
          <Copyright />
        </Box>

        <Snackbar
          open={openSnackbar}
          closeBtn={true}
          closeSnackbar={closeSnackbar}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
        ></Snackbar>
      </Container>
      {cameraView ? (
        <CameraComponent
          chapa={chapaCameraView}
          bundle={bundle}
          saveImage={saveImage}
          open={cameraView}
          onClose={() => setCameraView(false)}
        />
      ) : null}
      {modalOpen ? (
        <VincularChapasModal
          open={modalOpen}
          closemodal={() => setModalOpen(false)}
          chapasBloco={chapasBloco}
          selectChapa={handleAddChapaToBundle}
        />
      ) : null}
      {/* {modalOpen ? (
        <ChapaFormInBundle
          bundle={bundle}
          chapa={chapasInBundle}
          setChapas={setChapasInBundle}
          setBundle={setBundle}
          material={material}
          materiais={materiais}
          userData={userData}
          setMaterialId={setChapaMaterialId}
          filial={filial}
          filialSelected={filialSelected}
          setFilialSelected={setFilialSelected}
          modalOpen={modalOpen}
          handleOpenChapaModal={handleOpenChapaModal}
        />
      ) : null} */}
    </main>
  );
}
