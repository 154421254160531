import { Box, Button, makeStyles, Modal } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import SaveIcon from "@material-ui/icons/GetApp";
import Close from "@material-ui/icons/Close";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import "./Lightbox.css";
import { SelectedBundlesContext } from "../../../context/SelectedBundles.provider";
import { getCompanyBucket, getSignedUrl } from "../../../Services/api";
import { useAuth } from "../../../context/User.provider";

const useStyles = makeStyles((theme) => ({
  button: {
    top: 0,
    left: "5%",
  },
}));

const Lightbox = ({
  showSlides,
  slideIndex,
  setSlideIndex,
  isOpen,
  setIsOpen,
  bundles,
  userId,
}) => {
  const { selectedBundles, setSelectedBundles } = useContext(SelectedBundlesContext);
  const [secureUrl, setSecureUrl] = useState("");
  const [bucket, setBucket] = useState("");
  const { userData } = useAuth();
  const classes = useStyles();

  async function download() {
    const response = await getSignedUrl(bucket, bundle.foto_remoto);
    const url = response.data;
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = bundle.foto_remoto;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async function toDataURL(url) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  useEffect(() => {
    const getBucket = async () => {
      const bucket = await getCompanyBucket(userData.empresa_id);
      setBucket(bucket.data[0].bucket_name);
    };
    getBucket();
  }, []);

  async function onDownloadClick() {
    download(secureUrl);
  }

  function desativaSelecionar() {
    var expira = bundle.expira_reserva == null ? 0 : bundle.expira_reserva;
    // console.log(bundle);
    if (parseInt(userId) === parseInt(bundle.id_vendedor)) {
      return false;
    }
    if (bundle.expira_reserva !== null) {
      if (expira > 0) {
        return true;
      } else if (expira <= 0) {
        return false;
      }
    }

    if (bundle.emestoque === 0 || bundle.empedido || bundle.numero_pedido_cliente !== "") {
      return true;
    }

    return false;
  }

  const bundle = React.useMemo(
    () => bundles && bundles.find((bundle, idx) => idx === slideIndex),
    [slideIndex, bundles]
  );
  const isSelected = React.useMemo(
    () => selectedBundles && selectedBundles.some((b, idx) => b.id === bundle.id),
    [selectedBundles, bundle.id]
  );

  const handleSelectBundle = () => {
    if (isSelected) {
      setSelectedBundles((prevState) => {
        const selected = prevState.filter((b, idx) => {
          return b.id !== bundle.id;
        });
        return selected;
      });
    } else {
      setSelectedBundles((prevState) => [...prevState, bundle]);
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function nextSlide(n) {
    if (slideIndex + 1 === bundles.length) return;
    setSlideIndex((prev) => prev + 1);
  }

  const prevSlide = (n) => {
    if (slideIndex === 0) return;
    setSlideIndex((prev) => prev - 1);
  };

  return (
    <Modal className="lightboxModal" open={isOpen} onClose={closeModal}>
      <>
        <div className="button-container">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleSelectBundle}
            disabled={desativaSelecionar()}
          >
            {isSelected ? "Remover Bundle" : "Selecionar Bundle"}
          </Button>

          <SaveIcon onClick={onDownloadClick} fontSize="inherit" className="download-button" />

          <Close className="close" fontSize="inherit" onClick={closeModal} />
        </div>
        <div className="modal-content">
          <p className="prev" style={{ fontSize: "48px" }} onClick={prevSlide}>
            &#10094;
          </p>
          <p className="next" style={{ fontSize: "48px" }} onClick={nextSlide}>
            &#10095;
          </p>

          <Box className="main-image-container">
            {bundles &&
              bundles.map((image, index) => {
                if (index === slideIndex) {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      // onWheel={handleZoomChange}
                    >
                      {/* <div>Imagem</div> */}
                      <InnerImageZoom src={image.linkS3} className="main-image" />
                      {/* <img
                      src={image.linkS3}
                      alt="imagem"
                      className="main-image"
                    /> */}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </Box>

          {/* <div className="caption-container">
          <p id="caption">
            {bundles & slideIndex &&
              bundles.find((bundle, idx) => idx === slideIndex)
                .bundlecliente_id}
          </p>
        </div> */}

          <div className="thumbnail-container">
            {bundles &&
              bundles.map((image, index) => {
                if (
                  slideIndex === index - 2 ||
                  slideIndex === index - 1 ||
                  slideIndex === index + 1 ||
                  slideIndex === index + 2 ||
                  slideIndex === index
                ) {
                  return (
                    <div onClick={() => setSlideIndex(() => index)} className="column">
                      <img
                        style={slideIndex === index ? { opacity: 1 } : { opacity: 0.2 }}
                        className="thumbnail-image"
                        src={image.linkS3Thumb}
                        alt="imagem"
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
      </>
    </Modal>
  );
};

export default Lightbox;
