import React from "react";

import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Title from "../Types/Tittle";

import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function generateRandom() {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export default function ListBundles({ bundles }) {
  //console.log(bundles);
  const listBundlesColumn = [
    { field: "numero_bundle_cliente", headerName: "Bundle", width: 200 },
    {
      field: "nomematerialportugues",
      headerName: "Nome do Material",
      width: 200,
    },
    { field: "peso_total", headerName: "Peso Total", width: 200 },
    { field: "preco", headerName: "Preço", width: 200 },
    { field: "total_chapas", headerName: "Total de Chapas", width: 150 },
  ];

  const classes = useStyles();
  //console.log(bundles);
  //console.log("vou gerar a tabela da oferta");
  return (
    <Container maxWidth="lg" className={classes.container}>
      {bundles.length > 0 ? (
        <Grid id={generateRandom()} getRowId container spacing={3}>
          <Grid id={generateRandom()} getRowId item xs={12}>
            <Paper className={classes.paper}>
              <Title>Bundles</Title>
              <Grid
                id={generateRandom()}
                getRowId
                style={{ height: "50vh", width: "100%", paddingTop: "20px" }}
              >
                <DataGrid
                  // onCellClick={handleCellClick}
                  columns={listBundlesColumn}
                  rows={bundles}
                />
              </Grid>
              {/* <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Bundle</TableCell>
                      <TableCell>Nome do Material</TableCell>
                      <TableCell>Peso Total</TableCell>
                      <TableCell>Preço</TableCell>
                      <TableCell>Total de Chapas</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(bundles
              ? bundles
              : []
            ).map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell>
                  {row.bundlecliente_id}
                </TableCell>
                <TableCell>
                {row.nomematerialportugues} || {row.nomematerialingles}
                </TableCell>
                <TableCell>
                {row.peso_total.toFixed(2)}
                </TableCell>
                <TableCell>
                {row.preco.toFixed(2)}
                </TableCell>
                <TableCell>
                {row.total_chapas.toFixed(2)}
                </TableCell>
                
              </TableRow>
            ))}
                  </TableBody>
                  
                </Table> */}
            </Paper>
          </Grid>
        </Grid>
      ) : null}
    </Container>
  );
}
