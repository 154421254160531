import React from 'react';
import './Loading.css';
import logo from "../../assets/img/logo.png";

const Loading = () => {
  return (
      <div>
          <div className='loading-bg'>
              <div className='loading-1'>
                <div className='loading-2'></div>
              </div>
              <img className='loading-img' src={logo} alt="Logo" />
          </div>
      </div>
  )
}

export default Loading