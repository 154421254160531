import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EmailEditor({ text, setText }) {
  const [value, setValue] = useState("");

  return (
    <ReactQuill
      theme="snow"
      value={text}
      style={{ height: "40vh", marginBottom: "3rem" }}
      onChange={setText}
    />
  );
}
export default EmailEditor;
