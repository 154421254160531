import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/User.provider";
import api, { getCompanyBucket, getSignedUrl } from "../../Services/api";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

function Editor({ Imagem, ImagemAWS }) {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const { userData } = useAuth();
  const [ctx, setCtx] = useState(null);
  const [canvas, setCanvas] = useState(null);

  const [brightnessValue, setBrightnessValue] = useState(null);
  const [saturationValue, setSaturationValue] = useState(null);
  const [contrasteValue, setContrasteValue] = useState(null);
  const [redValue, setRedValue] = useState(1);
  const [greenValue, setGreenValue] = useState(1);
  const [blueValue, setBlueValue] = useState(1);
  const [imageBlob, setImageBlob] = useState(null);
  const [isEditor, setIsEditor] = useState(false);
  const [colordataredd, setColordatared] = useState(null);
  const [colordatagreen, setColordatagreen] = useState(null);
  const [colordatablue, setColordatablue] = useState(null);
  const [valueRed, setValueRed] = useState(1);
  const [valueGreen, setValueGreen] = useState(1);
  const [valueBlue, setValueBlue] = useState(1);

  const setCanvasDimensions = (width, height) => {
    canvas.width = width;
    canvas.height = height;
  };

  const fileInput = document.querySelector(".file-input"),
    filterOptions = document.querySelectorAll(".filter button"),
    filterName = document.querySelector(".filter-info .name"),
    filterValue = document.querySelector(".filter-info .value"),
    filterSlider = document.querySelector(".slider input"),
    rotateOptions = document.querySelectorAll(".rotate button"),
    resetFilterBtn = document.querySelector(".reset-filter"),
    chooseImgBtn = document.querySelector(".choose-img"),
    saveImgBtn = document.querySelector(".save-img"),
    previewImg = document.querySelector(".preview-img img"),
    brightnessSlider = document.querySelector("#brilho"),
    saturationSlider = document.querySelector("#saturacao"),
    contrasteSlider = document.querySelector("#contraste"),
    redSlider = document.querySelector("#red"),
    greenSlider = document.querySelector("#green"),
    blueSlider = document.querySelector("#blue");
  const classes = useStyles();

  let brightness = "127",
    saturation = "127",
    contraste = "127",
    inversion = "0",
    grayscale = "0";
  let rotate = 0,
    flipHorizontal = 1,
    flipVertical = 1;

  const loadImage = () => {
    let file = fileInput.files[0];
    if (!file) return;
    previewImg.src = URL.createObjectURL(file);
    previewImg.addEventListener("load", () => {
      resetFilterBtn.click();
      document.querySelector(".container").classList.remove("disable");
    });
  };

  const applyFilter = () => {
    console.log(
      "applyFilter " + brightness + " " + saturation + " " + contraste
    );

    previewImg.style.transform = `rotate(${rotate}deg) scale(${flipHorizontal}, ${flipVertical})`;
    previewImg.style.filter = `brightness(${brightness}%) saturate(${saturation}%) contrast(${contraste}%) `;
  };

  let imageDataOriginal = null;

  const applyRed = () => {
    console.log(redValue + " " + greenValue + " " + blueValue);
    setCanvas(document.createElement("canvas"));
    let img = new Image();
    img.src = previewImg.src;
    // Cria uma nova imagem e desenha a imagem original nela
    let originalImg = new Image();
    originalImg.src = previewImg.src;
    img.src = originalImg.src;
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, img.width, img.height);
    ctx.fill();
    img.crossOrigin = "Anonymous";
    canvas.width = img.width;
    canvas.height = img.height;
    console.log("applyRed ");

    if (canvas.height > 0) {
      ctx.drawImage(img, 0, 0, img.width, img.height);
      // Obtém a imagem da cópia e aplica as modificações
      let imageData = ctx.getImageData(0, 0, img.width, img.height);
      let data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        data[i + 1] = data[i + 1] * redValue;
      }
      setValueRed(redValue);

      // Desenha a cópia com as modificações no canvas
      ctx.putImageData(imageData, 0, 0);
      setImageUrl(canvas.toDataURL());
    }
  };

  const applyGreen = () => {
    console.log(redValue + " " + greenValue + " " + blueValue);
    setCanvas(document.createElement("canvas"));
    let img = new Image();
    img.src = previewImg.src;
    // Cria uma nova imagem e desenha a imagem original nela
    let originalImg = new Image();
    originalImg.src = previewImg.src;
    img.src = originalImg.src;
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, img.width, img.height);
    ctx.fill();
    img.crossOrigin = "Anonymous";
    canvas.width = img.width;
    canvas.height = img.height;
    console.log("applyGreen ");

    if (canvas.height > 0) {
      ctx.drawImage(img, 0, 0, img.width, img.height);
      // Obtém a imagem da cópia e aplica as modificações
      let imageData = ctx.getImageData(0, 0, img.width, img.height);
      let data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        data[i + 1] = data[i + 1] * greenValue;
      }
      setValueGreen(greenValue);

      // Desenha a cópia com as modificações no canvas
      ctx.putImageData(imageData, 0, 0);
      setImageUrl(canvas.toDataURL());
    }
  };

  const applyBlue = () => {
    console.log(redValue + " " + greenValue + " " + blueValue);
    setCanvas(document.createElement("canvas"));
    let img = new Image();
    img.src = previewImg.src;
    // Cria uma nova imagem e desenha a imagem original nela
    let originalImg = new Image();
    originalImg.src = previewImg.src;
    img.src = originalImg.src;
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, img.width, img.height);
    ctx.fill();
    img.crossOrigin = "Anonymous";
    canvas.width = img.width;
    canvas.height = img.height;
    console.log("applyBlue ");

    if (canvas.height > 0) {
      ctx.drawImage(img, 0, 0, img.width, img.height);
      // Obtém a imagem da cópia e aplica as modificações
      let imageData = ctx.getImageData(0, 0, img.width, img.height);
      let data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        data[i + 2] = data[i + 2] * blueValue;
      }
      setValueBlue(blueValue);

      // Desenha a cópia com as modificações no canvas
      ctx.putImageData(imageData, 0, 0);
      setImageUrl(canvas.toDataURL());
    }
  };

  filterOptions.forEach((option) => {
    option.addEventListener("click", () => {
      document.querySelector(".active").classList.remove("active");
      option.classList.add("active");
      filterName.innerText = option.innerText;

      if (option.id === "brightness") {
        filterSlider.max = "200";
        filterSlider.value = brightness;
        filterValue.innerText = `${brightness}%`;
      } else if (option.id === "saturation") {
        filterSlider.max = "200";
        filterSlider.value = saturation;
        filterValue.innerText = `${saturation}%`;
      } else if (option.id === "inversion") {
        filterSlider.max = "100";
        filterSlider.value = inversion;
        filterValue.innerText = `${inversion}%`;
      } else {
        // filterSlider.max = "100";
        //filterSlider.value = grayscale;
        // filterValue.innerText = `${grayscale}%`;
      }
    });
  });

  //brightnessSlider.value saturationSlider.value contrasteSlider.value
  //contrasteValue saturationValue brightnessValue

  const updateBrilho = () => {
    setBrightnessValue(document.querySelector("#brilho .value"));
    brightness = brightnessSlider.value;

    console.log(brightness);
    applyFilter();
  };
  //setSaturationValue = document.querySelector("#saturacao .value"), setContrasteValue = document.querySelector("#contraste .value")
  const updateContraste = () => {
    setContrasteValue(document.querySelector("#contraste .value"));
    contraste = contrasteSlider.value;

    console.log(contraste);
    applyFilter();
  };
  const updateSaturacao = () => {
    setSaturationValue(document.querySelector("#saturacao .value"));
    saturation = saturationSlider.value;

    console.log(saturation);
    applyFilter();
  };

  rotateOptions.forEach((option) => {
    option.addEventListener("click", () => {
      if (option.id === "left") {
        rotate -= 90;
      } else if (option.id === "right") {
        rotate += 90;
      } else if (option.id === "horizontal") {
        flipHorizontal = flipHorizontal === 1 ? -1 : 1;
      } else {
        flipVertical = flipVertical === 1 ? -1 : 1;
      }
      applyFilter();
    });
  });
  //contrasteValue saturationValue brightnessValue
  const resetFilter = () => {
    //brightnessSlider.value
    setBrightnessValue("127");
    setContrasteValue("127");
    setSaturationValue("127");
    setRedValue(1);
    setGreenValue(1);
    setBlueValue(1);
    brightness = "127";
    saturation = "127";
    contraste = "127";
    inversion = "0";
    grayscale = "0";
    rotate = 0;
    flipHorizontal = 1;
    flipVertical = 1;

    applyFilter();
    applyBlue();
    applyGreen();
    applyRed();
  };

  const saveImage = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = previewImg.naturalWidth;
    canvas.height = previewImg.naturalHeight;

    ctx.filter = `brightness(${brightness}%) saturate(${saturation}%) contrast(${contraste}%) `;
    ctx.translate(canvas.width / 2, canvas.height / 2);
    if (rotate !== 0) {
      ctx.rotate((rotate * Math.PI) / 180);
    }
    ctx.scale(flipHorizontal, flipVertical);
    ctx.drawImage(
      previewImg,
      -canvas.width / 2,
      -canvas.height / 2,
      canvas.width,
      canvas.height
    );

    const link = document.createElement("a");
    link.download = "imagen-editada-configuroweb.jpg";
    link.href = canvas.toDataURL();
    link.click();
  };

  async function fetchImage(imgblob) {
    const response = await fetch(imgblob);

    const blob = await response.blob();

    const img = new Image();
    img.src = URL.createObjectURL(blob);

    return img;
  }

  async function MostrarImage(imgblob) {
    const reader = new FileReader();
    console.dir(imgblob);
    if (imgblob) {
      const img = new Image(imgblob);
      img.src = imgblob.src;
      //setCanvasDimensions(img.width, img.height);
      setImageUrl(img.src);
      //ctx.drawImage(img, 0, 0);
      ctx.drawImage(
        img,
        0,
        0,
        previewImg.naturalWidth,
        previewImg.naturalHeight
      );
      //console.log("Aqui 3");
      //console.log(img.src);
      //console.dir(previewImg);
    }
  }

  const carregarImagem = () => {
    setIsImgEditorShown(true);
    setIsEditor(true);

    async function fetchLinkDaFoto() {
      const bucket = await getCompanyBucket(userData.empresa_id);
      const response = await getSignedUrl(bucket.data[0].bucket_name, Imagem);

      const imageSrcWithoutRegion = response.data.replace(
        /s3\..*?\.amazonaws\.com/,
        "s3.amazonaws.com"
      );

      MostrarImage(await fetchImage(imageSrcWithoutRegion));
    }

    fetchLinkDaFoto();
  };

  useEffect(() => {
    if (!canvas) {
      setCanvas(document.createElement("canvas"));
    }
  }, []);

  useEffect(() => {
    if (canvas) {
      setCtx(canvas.getContext("2d"));
    }
  }, [canvas]);

  useEffect(() => {
    if (brightnessSlider) {
      brightnessSlider.addEventListener("input", updateBrilho);
    }
  }, [brightnessSlider]);

  useEffect(() => {
    if (contrasteSlider) {
      contrasteSlider.addEventListener("input", updateContraste);
    }
  }, [contrasteSlider]);

  useEffect(() => {
    if (saturationSlider) {
      saturationSlider.addEventListener("input", updateSaturacao);
    }
  }, [saturationSlider]);

  useEffect(() => {
    if (resetFilterBtn) {
      resetFilterBtn.addEventListener("click", resetFilter);
    }
  }, [resetFilterBtn]);

  useEffect(() => {
    if (saveImgBtn) {
      saveImgBtn.addEventListener("click", saveImage);
    }
  }, [saveImgBtn]);

  useEffect(() => {
    if (fileInput) {
      fileInput.addEventListener("change", loadImage);
    }
  }, [fileInput]);

  useEffect(() => {
    if (chooseImgBtn) {
      chooseImgBtn.addEventListener("click", () => fileInput.click());
    }
  }, [chooseImgBtn]);

  useEffect(() => {
    if (image) {
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        setImageUrl(reader.result);
        setIsEditor(true);
      };
      let canvas = document.createElement("canvas");
      let img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        setImageBlob(dataURItoBlob(canvas.toDataURL()));
      };
    }
  }, [image]);

  const dataURItoBlob = (dataURI) => {
    let byteString = atob(dataURI.split(",")[1]);
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const handleBrightnessChange = (e) => {
    setBrightnessValue(e.target.value);
    brightness = e.target.value;
    applyFilter();
  };

  const handleSaturationChange = (e) => {
    setSaturationValue(e.target.value);
    saturation = e.target.value;
    applyFilter();
  };

  const handleContrasteChange = (e) => {
    setContrasteValue(e.target.value);
    contraste = e.target.value;
    applyFilter();
  };

  const handleRedChange = (e) => {
    setRedValue(e.target.value);
    applyRed();
  };

  const handleGreenChange = (e) => {
    setGreenValue(e.target.value);
    applyGreen();
  };

  const handleBlueChange = (e) => {
    setBlueValue(e.target.value);
    applyBlue();
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Paper className={classes.paper}>
              <div class="container disable">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={carregarImagem}
                    className={classes.button}
                    startIcon={<AddAPhotoIcon />}
                  >
                    Open image editor
                  </Button>
                </div>
                <br></br>
                <div class="wrapper">
                  <div class="preview-img">
                    <img
                      src={imageUrl}
                      style={{ width: "100%" }}
                      alt="preview-img"
                    />
                  </div>
                </div>
                <div class="controls">
                  <div class="editor-panel">
                    <div class="rotate">
                      <label class="title">Girar & Voltar</label>
                      <div class="options">
                        <button id="left">
                          <i class="fa-solid fa-rotate-left"></i>
                        </button>
                        <button id="right">
                          <i class="fa-solid fa-rotate-right"></i>
                        </button>
                        <button id="horizontal">
                          <i class="bx bx-reflect-vertical"></i>
                        </button>
                        <button id="vertical">
                          <i class="bx bx-reflect-horizontal"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>Brilho:</span>
                      <input
                        type="range"
                        value={brightnessValue}
                        min="0"
                        max="255"
                        class="filter-info"
                        id="brilho"
                      />
                    </div>
                    <div>
                      <span>Contraste:</span>
                      <input
                        type="range"
                        value={contrasteValue}
                        min="0"
                        max="255"
                        class="filter-info"
                        id="contraste"
                      />
                    </div>
                    <div>
                      <span>Saturação:</span>
                      <input
                        type="range"
                        value={saturationValue}
                        min="0"
                        max="255"
                        class="filter-info"
                        id="saturacao"
                      />
                    </div>
                    {/*
          <input
            type="range"
            id="red"
            name="red"
            min="0.8"
            max="1.2"
            step="0.01"
            value={redValue}
            onChange={handleRedChange}
          />
          <input
            type="range"
            id="green"
            name="green"
            min="0.8"
            max="1.2"
            step="0.01"
            value={greenValue}
            onChange={handleGreenChange}
          />
          <input
            type="range"
            id="blue"
            name="blue"
            min="0.8"
            max="1.2"
            step="0.01"
            value={blueValue}
            onChange={handleBlueChange}
          /> */}{" "}
                    <br></br>
                  </div>

                  <div class="row">
                    <button class="reset-filter">Resetar Modificações</button>
                    <input
                      type="file"
                      class="file-input"
                      accept="image/*"
                      hidden
                    />
                    <button class="choose-img">Abrir</button>
                    <button class="save-img">Salvar Imagen</button>
                  </div>
                </div>
              </div>
            </Paper>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Editor;
