import {
  Box,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    overflowY: "scroll",
    background: "#fff9f9",
    border: "1px solid #000",
    boxShadow: 24,
    padding: 4,
  },
}));

const ModalDetails = ({ open, handleCloseModal, chapas }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.modal}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              alignSelf: "end",
              paddingRight: "10px",
              fontSize: 20,
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          >
            &times;
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Bundle</TableCell>
                  <TableCell>Block</TableCell>
                  <TableCell>Plate</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Thick</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {chapas.map((chapa) => {
                  // nome do material, Bloco,Numero do Bundle, numero da chapa, comprimento liquido, altura liquida totalm2
                  return (
                    <TableRow key={chapa.id}>
                      <TableCell>{chapa.bundlecliente_id}</TableCell>
                      <TableCell>{chapa.bloco}</TableCell>
                      <TableCell>{chapa.numerochapa}</TableCell>
                      <TableCell>{chapa.nomematerialingles}</TableCell>
                      <TableCell>
                        {chapa.comprimento_liquido}m x {chapa.altura_liquida}m /{" "}
                        {(chapa.comprimento_liquido * 39.3701).toPrecision(5)}"
                        x {(chapa.altura_liquida * 39.3701).toPrecision(4)}"
                      </TableCell>
                      <TableCell>{chapa.espessura}CM</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalDetails;
