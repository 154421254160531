import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

const useStyles = makeStyles({
  root: {
    maxWidth: 280,
  },
  media: {
    height: 500,
  },
});

export default function ChapaImage({image_id, image_url, removeImage}) {
  const classes = useStyles();

  return (
    <Card>
      <CardActionArea>
        {image_url ? (
        <CardMedia
          className={classes.media}
          image={`${image_url}`}
        />
        ) :
        <CardMedia
          className={classes.media}
          style={ { alignItems: 'center', justifyContent: 'center', display: 'flex' } }
        >
        <AddOutlinedIcon style={{ fontSize: 100, color: '#20DBC2' }} ></AddOutlinedIcon>
        </CardMedia>
        }
      </CardActionArea>
      {image_id ? (
      <CardActions style={{backgroundColor: "#D3D3D3", justifyContent: "center"}}>
        <HighlightOffIcon style={{fill: "red"}} onClick={() => removeImage(image_id)}></HighlightOffIcon>
      </CardActions>
      ) : null }
    </Card>
  );
}
