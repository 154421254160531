import React from "react";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
//import Grid from "@material-ui/core/Grid";
//import Container from "@material-ui/core/Container";

export default function Copyright() {

  return (
    
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="http://www.ornatec.com.br">
              Ornatec
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        
  );
}
