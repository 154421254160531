export default function EspessuraValue(value) {
    switch (value) {
        case '1' : return "1.5 cm";
        case '2' : return "2.0 cm";
        case '3' : return "3.0 cm";
        case '4' : return "5.0 cm";
        default : return "";
    }
}

export const Espessuras = [
        {
            id: '1',
            valor: EspessuraValue('1')
        },
        {
            id: '2',
            valor: EspessuraValue('2')
        },
        {
            id: '3',
            valor: EspessuraValue('3')
        },
        {
            id: '4',
            valor: EspessuraValue('4')
        },
    ]