import React, { useState, createContext, useContext } from "react";
import jwt from "jsonwebtoken";

//import useStorage from "../session/useStorage"
import { useEffect } from "react";
import api, { LoginUser } from "../Services/api";

export const UserContext = createContext({});

export function useAuth() {
  const context = useContext(UserContext);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user-SlabOffer"));
  if (token) {
    jwt.verify(token, process.env.REACT_APP_SECRET_KEY, (err, decoded) => {
      if (err?.name === "TokenExpiredError") {
        localStorage.removeItem("token");
        const newToken = jwt.sign(user, process.env.REACT_APP_SECRET_KEY, { expiresIn: "12h" });
        localStorage.setItem("token", token);
        // api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
        api.defaults.headers.Authorization = `Bearer ${newToken}`;
      }
    });
  }
  return context;
}

export function UserProvider(props) {
  const token = localStorage.getItem("token");

  const [userData, setUserData] = useState({
    token: null,
    email: "",
    nome: "",
    id: "",
    SMTP_USER: "",
    SMTP_PASS: "",
    empresa_id: "",
    userRole: "",
  });

  const [isLogged, setIsLogged] = useState(token ? true : false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user-SlabOffer"));
    if (token !== null && user) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      const { email, nome, id, SMTP_USER, SMTP_PASS, empresa_id, role } = user;

      setUserData({ email, empresa_id, id, nome, userRole: role, SMTP_USER, SMTP_PASS });
    } else {
      // setUserData({ isLogged: false })
      setIsLogged(false);
      localStorage.removeItem("token");
    }
  }, [token]);

  async function doLogin(email, senha) {
    const dataUser = await LoginUser(email, senha);
    if (dataUser.data) {
      localStorage.setItem("token", dataUser.data.token);
      localStorage.setItem("user-SlabOffer", JSON.stringify(dataUser.data.user));
      const { id, email, nome, empresa_id, role, SMTP_USER, SMTP_PASS } = dataUser.data.user;
      setUserData({
        token: dataUser.data.token,
        // isLogged: true,
        email,
        nome,
        id,
        empresa_id,
        userRole: role,
        SMTP_USER,
        SMTP_PASS,
      });
      setIsLogged(true);
      return true;
    } else {
      // setUserData({ isLogged: false })
      setIsLogged(false);
      return false;
    }
  }

  const doLogout = () => {
    // setUserData({ isLogged: false })
    setIsLogged(false);
    localStorage.removeItem("token");
    localStorage.removeItem("user-SlabOffer");
  };

  return (
    //<UserContext.Provider value={[{userData, setUserData}, {storageId, setStorageId}, {storageNome, setStorageNome}, {storageEmpresaId, setStorageEmpresaId}]}>
    <UserContext.Provider value={{ userData, setUserData, doLogout, doLogin, isLogged }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserProvider;
