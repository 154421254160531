import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import api from "../../Services/api";

import UserContext from "../../context/User.context";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Title from "../Types/Tittle";

import SearchIcon from "@material-ui/icons/Search";

import TablePaginationActions from "../../functions/TablePaginationActions";
import { useAuth } from "../../context/User.provider";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Persons({ setPerson, personId }) {
  const classes = useStyles();
  const history = useHistory();

  const { idPerson } = useParams() || "0";

  const { userData } = useAuth();
  // const [userData] = useContext(UserContext);

  const [personData, setPersonData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [personColumns, setPersoColumns] = useState([
    { field: "nome", headerName: "Nome", width: 300 },
    { field: "email", headerName: "E-mail.", width: 250 },
    { field: "telefone", headerName: "Telefone", width: 250 },
    { field: "celular", headerName: "Celular", width: 180 },
  ]);
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function getPersons(searchName) {
    try {
      if (parseInt(userData.empresa_id) === parseInt(process.env.REACT_APP_EMPRESA_ID)) {
        await api.get("/persons/" + searchName || "").then((res) => {
          setPersonData(res.data);
        });
      } else {
        await api.get("/personsEmpresa/" + userData.empresa_id).then((res) => {
          setPersonData(res.data);
        });
      }
    } catch (err) {
      console.log("Erro ao consultar clientes");
    }
  }

  useEffect(() => {
    if (idPerson) {
      setPerson(idPerson);
      return;
    }
    getPersons("");
  }, [personId]);

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item align="left" sm={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  history.goBack();
                }}
                className={classes.button}
              >
                Voltar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setPerson(0);
                }}
                className={classes.button}
              >
                Novo Cliente
              </Button>
            </Grid>

            <Grid item align="left" sm={8}>
              <TextField
                id="searchOwner"
                size="small"
                placeholder="Procurar..."
                fullWidth
                onChange={(event) => {
                  getPersons(event.target.value);
                }}
                // label="Procurar..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Pessoas</Title>
                {personData ? (
                  <Grid style={{ height: 700, width: "100%", paddingTop: "20px" }}>
                    <DataGrid
                      onCellClick={(e) => setPerson(e.row.id)}
                      columns={personColumns}
                      rows={personData}
                      // onCellEditCommit={handleCellEditCommit}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={12}>
                    Nenhuma chapa selecionada!
                  </Grid>
                )}
                {/* <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Nome</TableCell>
                      <TableCell>E-mail</TableCell>
                      <TableCell>Telefone</TableCell>
                      <TableCell>Celular</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(personData !== null
                      ? personData.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : personData
                    ).map((row) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell
                          onClick={() => {
                            setPerson(row.id);
                          }}
                        >
                          {row.nome}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            setPerson(row.id);
                          }}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            setPerson(row.id);
                          }}
                        >
                          {row.telefone}
                        </TableCell>
                        <TableCell>{row.celular}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          20,
                          { label: "Todos", value: -1 },
                        ]}
                        colSpan={4}
                        count={personData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Registros por página" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table> */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
