import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Materiais from "../../components/Material/List";
import MaterialForm from "../../components/Material/Material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Material() {
  const classes = useStyles();

  const [materialId, setMaterialId] = useState(0);

  const [showView, setShowView] = useState("list");

  function setMaterial(materialId) {
    setMaterialId(materialId);
    setShowView("chapa");
  }

  function setList() {
    setMaterialId(0);
    setShowView("list");
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {showView === "list" ? (
        <Materiais setMaterial={setMaterial} materialId={materialId}></Materiais>
      ) : (
        <MaterialForm setList={setList} materialId={materialId}></MaterialForm>
      )}
    </div>
  );
}
