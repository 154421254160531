import React, { useState, useContext, useEffect } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import Snackbar from "../../components/SnackBar/index";

import ListPerson from "../Settings/ListPersonOfertaChapa";
import SubTittle from "../Types/SubTittle";
import Title from "../Types/Tittle";

import EmailIcon from "@material-ui/icons/Email";
import api from "../../Services/api";
import Loading from "../Loading/Loading";
import { useAuth } from "../../context/User.provider";

const ModalResendOffer = ({
  showEmailModal,
  setShowEmailModal,
  ofertaId,
  oferta,
  setOferta,
  ofertsData,
  setOfertsData,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");

  const { userData } = useAuth();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [cliente, setCliente] = useState({ nome: "", email: "" });
  const [pessoa, setPessoa] = useState({ id: "", nome: "" });
  const [pessoas, setPessoas] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  async function getPessoas() {
    try {
      await api.get("/personsEmpresa/" + userData.empresa_id).then((res) => {
        setPessoas(res.data);
        setLoading(false);
      });
    } catch (e) {
      // setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      // setSnackbarKind("error");
      // setOpenSnackbar(true);
      // setChapasLoaded(false);
    }
  }

  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  async function getEmailInfo() {
    try {
      await api.get(`/empresa/${userData.empresa_id}`).then((res) => {
        setOferta((prevState) => {
          return {
            ...prevState,
            email: {
              host: res.data.smtp_host,
              port: res.data.smtp_port,
              user: userData.SMTP_USER,
              pass: userData.SMTP_PASS,
              security: res.data.smtp_security,
            },
            empresa: {
              nome: res.data.nome,
              vendedor: userData.nome,
              email: userData.email,
            },
          };
        });
        // setEmailLoaded(true);
      });
      // setLoading(false)
      // console.log(oferta)
    } catch (err) {
      // setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      // setSnackbarKind("error");
      // setOpenSnackbar(true);
      setLoading(false);
      console.log(err);
    }
  }

  async function sendEmail() {
    // console.dir(oferta);
    if (!oferta.email) {
      alert("preencha o e-mail");
    }
    setOfferLoading(true);

    api
      .post("/email/sendmailoffert", oferta)
      .then(async (res) => {
        //console.log(res);
        const ofertaPessoaObj = {
          oferta_id: oferta.id,
          //oferta_id: oferta.id,
          pessoa_id: oferta.pessoa_id === 0 ? 2 : oferta.pessoa_id,
          empresa_id: oferta.empresa_id,
          email_cliente: oferta.cliente.email,
          subject: oferta.subject,
          mensagem: oferta.observacao,
        };

        //console.log(ofertaPessoaObj);

        const Respofertapessoa = await api.post(
          "/ofertaPessoa",
          ofertaPessoaObj
        );
        // console.log(Respofertapessoa);
        setSnackbarKind("success");
        setSnackbarMessage("Oferta enviada com sucesso.");
        setOpenSnackbar(true);
        setTimeout(() => {
          setShowEmailModal(false);
        }, 1000);
        setOfferLoading(false);
        sendOfertaPessoa();
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.status === 401) {
          setSnackbarMessage(`Erro ao enviar email motivo: Login inválido`);
          setSnackbarKind("error");
          setOpenSnackbar(true);
          setOfferLoading(false);
          setTimeout(() => {
            setShowEmailModal(false);
          }, 2000);
        } else {
          setSnackbarMessage(`Erro ao enviar email motivo: ${error}`);
          setSnackbarKind("error");
          setOpenSnackbar(true);
          setOfferLoading(false);
          setTimeout(() => {
            setShowEmailModal(false);
          }, 2000);
        }
      });

    /*
    try {
      await api.post("/oferta/resendOffer", oferta).then((res) => {
        console.log(res);

        setSnackbarKind("success");
        setSnackbarMessage("Oferta enviada com sucesso.");
        setOpenSnackbar(true);
        setTimeout(() => {
          setShowEmailModal(false);
        }, 1000);
        setOfferLoading(false);
        sendOfertaPessoa();
      });
    } catch (error) {
      if (error.response.status === 401) {
        setSnackbarMessage(`Erro ao enviar email motivo: Login inválido`);
        setSnackbarKind("error");
        setOpenSnackbar(true);
        setOfferLoading(false);
        setTimeout(() => {
          setShowEmailModal(false);
        }, 2000);
      } else {
        setSnackbarMessage(`Erro ao enviar email motivo: ${error}`);
        setSnackbarKind("error");
        setOpenSnackbar(true);
        setOfferLoading(false);
        setTimeout(() => {
          setShowEmailModal(false);
        }, 2000);
      }
    }*/
  }

  async function sendOfertaPessoa() {
    const ofertaPessoaObj = {
      oferta_id: oferta.id,
      pessoa_id: oferta.pessoa_id,
      empresa_id: oferta.empresa_id,
      email_cliente: oferta.cliente.email,
      subject: oferta.subject,
      mensagem: oferta.observacao,
    };

    api.post("/ofertaPessoa", ofertaPessoaObj).then((res) => {
      // console.log(res);
    });
  }

  function handleChangePerson(id) {
    var objCliente = pessoas.find((sel) => parseInt(sel.id) === parseInt(id));

    setCliente({
      nome: objCliente.nome,
      email: objCliente.email,
    });

    setEmail((prevState) => {
      return {
        ...prevState,
        email: objCliente.email,
      };
    });

    setOferta((prevState) => {
      return {
        ...prevState,
        cliente: {
          email: objCliente.email,
          nome: objCliente.nome,
        },
      };
    });
  }

  const handleClose = () => {
    setShowEmailModal(false);
  };

  useEffect(() => {
    setLoading(true);
    getPessoas();
    getEmailInfo();
  }, [ofertaId]);

  if (loading)
    return (
      <Grid
        style={{ paddingTop: "150px", paddingLeft: "250px" }}
        item
        xs={12}
        alignItems="center"
      >
        {/* <CircularProgress /> */}
        <Loading />
      </Grid>
    );

  return (
    <form>
      <Dialog
        open={showEmailModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">Reenviar Oferta</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={1}
            style={{ width: 1000 }}
            alignItems="center"
          >
            <Grid item sm={12}>
              <ListPerson
                label="Selecione o Cliente"
                id="pessoa_id"
                name="pessoa_id"
                defaultValue={pessoa}
                personCurrent={pessoa}
                value={oferta.pessoa_id}
                pessoas={pessoas}
                handleChangePerson={handleChangePerson}
              ></ListPerson>
            </Grid>
            <Grid item sm={12}>
              <TextField
                label="E-mail do cliente"
                id="email"
                name="email"
                fullWidth
                value={cliente.email}
                onChange={(event) => {
                  setCliente((prevState) => {
                    return {
                      ...prevState,
                      email: event.target.value,
                    };
                  });
                  // setEmail(event.target.value);
                  setOferta((prevState) => {
                    return {
                      ...prevState,
                      cliente: {
                        ...prevState.cliente,
                        email: event.target.value,
                      },
                    };
                  });
                }}
              ></TextField>
            </Grid>
            <Grid item sm={12}>
              <></>
            </Grid>
            <Grid item sm={12}>
              {offerLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Loading />
                </div>
              ) : (
                <TextField
                  label="Subject"
                  id="subject"
                  name="subject"
                  fullWidth
                  value={oferta.subject ? oferta.subject : subject}
                  onChange={(event) => {
                    setOferta((previousState) => {
                      return {
                        ...previousState,
                        subject: event.target.value,
                      };
                    });
                    setSubject(event.target.value);
                  }}
                  //   onChange={handleSubject}
                  /*{(event) => {
                    const {subject , value} = event.target;      
                    setEmail({...email,[subject]:value}) 
                    console.log(value)
                    console.log(email)                 
                  }}*/
                ></TextField>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={12} style={{ marginTop: "20px" }}>
              <TextField
                id="observacao"
                label="Message"
                name="Message"
                fullWidth
                multiline
                rows={12}
                value={oferta.observacao ? oferta.observacao : message}
                variant="outlined"
                onChange={(event) => {
                  if (oferta.observacao) {
                    setOfertsData((previousState) => {
                      return {
                        ...previousState,
                        observacao: event.target.value,
                      };
                    });
                    setOferta((previousState) => {
                      return {
                        ...previousState,
                        observacao: event.target.value,
                      };
                    });
                  } else {
                    setMessage(event.target.value);
                  }
                }}
                //   onChange={(event) => {
                // setOferta((prevState) => {
                //   return {
                // ...prevState,
                // observacao: event.target.value,
                //   };
                // });
                //   }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            sendEmail();
          }}
          startIcon={<EmailIcon />}
        >
          Reenviar oferta
        </Button>
        <Button
          style={{ marginTop: "15px" }}
          variant="contained"
          color="default"
          onClick={handleClose}
          startIcon={<EmailIcon />}
        >
          Cancelar
        </Button>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
    </form>
  );
};

export default ModalResendOffer;
