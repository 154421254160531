import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3333",
});
let cancel;

const CancelToken = axios.CancelToken;
/**
 * User
 */

export const LoginUser = async (email, senha) => {
  return await api.post("/user/login", { email, senha });
};

// export const updateToken = async (user) => {
//   return await api.post("/user/updateToken", { user });
// };

/**
 * Company
 */
export const getCompanyById = async (id) => {
  return await api.get("/empresa/" + id);
};

/**
 * Bundle
 */
export const getBundles = async (object, empresaId, newpage) => {
  try {
    if (cancel) {
      cancel();
      // console.log("cancelado");
    }
    return await api.post(
      `/bundles/mainFilter/${empresaId}/${newpage}`,
      object,
      {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
      }
    );
  } catch (err) {
    if (axios.isCancel(err)) {
      // console.log("Request canceled", err.message);
    } else {
      throw err;
    }
  }
};
export const bundleFilter = async (empresaId, object) => {
  return await api.post(`/bundles/filter/${empresaId}`, object);
};

export const createBundle = async (bundle) => {
  return await api.post("/bundles/create", { bundle });
};

export const updateBundle = async (bundle) => {
  return await api.post("/bundles/update/" + bundle.id, { bundle });
};

export const updateBundlePhoto = async (bundleId, image) => {
  return await api.post("/bundles/updatePhoto/" + bundleId, { name: image });
};

export const getAllBundlesWithOrderStatus = async () => {
  return await api.get("/bundles/get/AllBundlesInOrder");
};

export const getAllBundlesInOrder = async (orderId) => {
  return await api.get(`/order/bundles/${orderId}`);
};

export const getSpecificBundle = async (bundleId) => {
  return await api.get(`/bundles/getBundle/${bundleId}`);
};

/**
 * Chapas
 */
//falta passar pra nova API

export const getCompanyChapas = async (empresaId, setChapaData, nameSearch) => {
  //const res = await api.get("/chapas/get/AllChapas", { empresaId, nameSearch });
  //setChapaData(res.data);
};

//falta passar pra nova API
export const getChapaById = async (chapaId) => {
  return await api.get("/chapa/" + chapaId);
};

export const saveChapaInBundle = async (chapas) => {
  return await api.post("bundles/chapa/bundle", chapas);
};

export const chapasMainFilter = async (empresaId, page, object) => {
  return await api.post(`/chapas/chapaMainFilter/${empresaId}/${page}`, object);
};

export const chapasFilter = async (empresaId, page, object) => {
  return await api.post(`/chapas/filter/${empresaId}/${page}`, object);
};

export const getChapasFromBloco = async (empresaId, bloco) => {
  return await api.get(`bundles/chapas/empresaAC/${empresaId}/${bloco}`);
};

export const createChapa = async (chapa) => {
  return await api.post("/chapas/create", { chapa });
};

export const updateChapa = async (chapa) => {
  return await api.put("/chapa", chapa);
};

export const getChapaPreco = async (id) => {
  return await api.get(`/chapa/preco/${id}`);
};

export const getChapasFromBundle = async (numeroBundleCliente, empresaId) => {
  // return await api.get(`/bundles/chapa/${numeroBundleCliente}`);
  return await api.get(`/chapas/bundles/${numeroBundleCliente}/${empresaId}`);
};

export const updateChapaPhoto = async (chapaId, photoName) => {
  return await api.post("/chapas/update/photo", { chapaId, photoName });
};

export const removeBundleIdFromChapa = async (chapaId) => {
  return await api.post("/chapas/remove/bundle", { chapaId });
};

/**
 * Filiais
 */
export const getAllFiliais = async (setFilial, empresaId) => {
  const { data } = await api.get(`/filiais/${empresaId}`);
  for (let i = 0; i < data?.length; i++) {
    setFilial((prevState) => {
      if (prevState.length >= data?.length) return [...prevState];
      return [...prevState, data[i]?.filialcliente];
    });
  }
};

//Reservas
export const incluirNaReserva = async (
  userId,
  incluir,
  selectedBundles,
  token
) => {
  return await api.post(
    `/bundles/reserva/${userId}?incluir=` + incluir + `&token=` + token,
    {
      selectedBundles,
    }
  );
};

export const incluirNaReservaChapas = async (
  userId,
  incluir,
  selectedChapas
) => {
  return await api.post(`/chapas/reserva/${userId}?incluir=` + incluir, {
    selectedChapas,
  });
};

export const liberarReserva = async (userId, selectedBundles) => {
  return await api.post(`/bundles/baixaReserva/${userId}`, { selectedBundles });
  // return await api.post(`/ofertabundles/${userId}`, { selectedBundles });
};

export const liberaReservaChapas = async (id, selectedChapas) => {
  return await api.post(`/chapas/baixaReserva/${id}`, { selectedChapas });
};

export const getOfertas = async () => {};

export const getOfertaPessoa = async () => {};

//products
//falta passar pra nova API
export const getProductsByCompany = async (empresaId, setProdutos) => {
  return await api.get(`/produtosbyEmpresaAC/${empresaId}`).then((res) => {
    setProdutos(res.data);
  });
};

/**
 * Materiais
 */
export const getMateriaisByCompany = async (empresaId) => {
  return await api.get(`/materiais/empresaAC/${empresaId}`);
};

/**
 * Imagens
 */
export const postImages = async (bucket, formImage) => {
  return await api.post(`/aws/upload/${bucket}`, formImage);
};
//nova api
export const removeFotoLocal = async (imageId, foto) => {
  return await api.delete("/chapaimage/" + imageId + "/" + foto);
};

export const getImagesById = async (id, empresaId) => {
  return await api.get(`photos/${id}`);
};

export const getSignedUrl = async (bucket, key) => {
  return await api.get(`/aws/signed/${bucket}/${key}`);
};

/**
 * Bucket
 */
export const getAllBuckets = async (empresaId) => {
  return await api.get("/aws/list/buckets");
};

export const createBucket = async (name, empresaId) => {
  return await api.get(`/aws/create/${name}`);
};

export const getCompanyBucket = async (empresaId) => {
  // console.log(empresaId);
  return await api.get(`aws/bucket/${empresaId}`);
};

export const associateBucket = async (companyId, object) => {
  return await api.post(`/company/associateBucket/${companyId}`, object);
};

export const generateBucketToken = async (companyId) => {
  return await api.get(`/company/token/${companyId}`);
};

/**
 * Ofertas
 */
export const checkBundleOffers = async (bundleId) => {
  return await api.get(`/bundle/${bundleId}/oferta`);
};

export const handleDeleteOffer = async (oferta, empresaId) => {
  return await api.post(`/oferta/delete`, { oferta });
};

export const handleSearchOffers = async (query, empresaId) => {
  return await api.get(`/ofertasSearch/${query}?empresa_id=${empresaId}`);
};

export const getSpecificOferr = async (id, empresaId) => {
  return await api.get(`/ofertass/email/${id}?empresa_id=${empresaId}`);
};

export const getBundlesFromOffer = async (offerId) => {
  return await api.get(`ofertass/bundles/email/${offerId}`);
};

export const aceitarOferta = async (offerId, bundles, cliente_id) => {
  return await api.post(`/ofertass/accept/${offerId}`, { bundles, cliente_id });
};

/**
 * Pessoas
 */
export const getPessoaById = async (id) => {
  return await api.get(`/person/${id}`);
};

/**
 * Pedidos
 */
export const createPedido = async (order, bundles) => {
  return await api.post(`/order`, { order, bundles });
};

export const getPedidos = async (empresaId) => {
  return await api.get(`/order/${empresaId}`);
};

export const getSpecificPedido = async (id) => {
  return await api.get(`/order/${id}`);
};

export const sendOrderMail = async (order) => {
  return await api.post(`/order/sendMail`, { order });
};

/**
 * Notificação
 */

export const getNotificacoes = async (userId, empresaId) => {
  return await api.get(`/notification/${empresaId}/${userId}`);
};

export const createNotificacao = async (notification) => {
  return await api.post(`/notification/create`, { notification });
};

export const visualizouNotificacao = async (notificationId) => {
  return await api.get(`/notification/update/${notificationId}`);
};

/**
 * Cliente Externo
 */

export const saveClienteExterno = async (client) => {
  return await api.post(`/clienteexterno`, { client });
};

export default api;
