import React, { useState, useEffect, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";

import api from "../../Services/api";

import UserContext from "../../context/User.context";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Title from "../Types/Tittle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import SearchIcon from "@material-ui/icons/Search";

import TablePaginationActions from "../../functions/TablePaginationActions";
import { useAuth } from "../../context/User.provider";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Materiais({ setMaterial, materialId }) {
  const classes = useStyles();

  const { idMaterial } = useParams() || "0";

  const { userData } = useAuth();
  // const [userData] = useContext(UserContext);

  const history = useHistory();

  const [materialData, setMaterialData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [materiaisColumns, setMateriaisColumns] = useState([
    { field: "nome_portugues", headerName: "Nome do Material", width: 300 },
    { field: "nome_ingles", headerName: "Nome do Material", width: 300 },
  ]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function getMateriais(nameSearch) {
    try {
      if (nameSearch !== "") {
        api.get("/materiaiss/" + nameSearch).then((res) => {
          setMaterialData(res?.data);
        });
        return;
      }
      await api.get("/materiaiss").then((res) => {
        // console.log(res.data);

        setMaterialData(res?.data);
      });

      //  else {
      //   await api.get("/materiais/empresa/" + userData.empresa_id).then((res) => {
      //     setMaterialData(res.data);
      //   });
      // }
    } catch (err) {
      console.log("Erro ao consultar chapas");
    }
  }

  useEffect(() => {
    if (idMaterial) {
      setMaterial(materialId);
      return;
    }

    getMateriais("");
  }, [materialId]);

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item align="left" sm={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  history.goBack();
                }}
                className={classes.button}
              >
                Voltar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setMaterial(0);
                }}
                className={classes.button}
              >
                Novo Material
              </Button>
            </Grid>

            <Grid item align="left" sm={8}>
              <TextField
                id="nameSearch"
                size="small"
                placeholder="Procurar..."
                fullWidth
                onChange={(event) => {
                  getMateriais(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Materiais</Title>
                {materialData?.length && materialData && (
                  <Grid
                    style={{ height: 700, width: "100%", paddingTop: "20px" }}
                  >
                    <DataGrid
                      columns={materiaisColumns}
                      rows={materialData}
                      // onCellEditCommit={handleCellEditCommit}
                    />
                  </Grid>
                )}
                {/* <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Nome do Material</TableCell>
                      <TableCell>Nome do Material</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(materialData
              ? materialData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : materialData
            ).map((row, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => {setMaterial(row.id)}}>
                <TableCell>
                  {row.nome_portugues}
                </TableCell>
                <TableCell>
                <b>{row.nome_ingles}</b>
                </TableCell>
                
              </TableRow>
            ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          20,
                          { label: "Todos", value: -1 },
                        ]}
                        colSpan={6}
                        count={materialData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Registros por página" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table> */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
