import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Dashboard from "../../components/Main/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Main() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      <Dashboard></Dashboard>
    </div>
  );
}
