import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 260,
  },
  media: {
    height: 180,
    width: 240,
  },
  button: {
    margin: theme.spacing(1),
  },
  card: {
    //display:'inline-block',
    paddingTop: 10,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 5,
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    width: 260,
  },
}));

export default function BundlesCard({ bundle }) {
  const classes = useStyles();

  return (
    //     <>
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          // image={`${process.env.REACT_APP_API_URL}images/${chapa.foto_local}`}
        >
          {/* <p>
              {" "}
              Clique aqui para exibir galeria com todas as chapas do filtro.
            </p> */}
          {/* <ImageGallery
              images={bundle}
              height={"150"}
              width={"240"}
            ></ImageGallery> */}
          {/* {console.log(bundle)} */}
        </CardMedia>
      </CardActionArea>
    </Card>
  );
}
