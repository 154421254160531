import React from "react";
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";
import AutoCompleteClassificacao from "../Oferta/Chapa/AutoCompleteClassificacao";
import AutoCompleteProcesso from "../Oferta/Chapa/AutoCompleteProcesso";
import { Classificacoes } from "../Chapa/Classificacao";
import { Processos } from "../Chapa/Processo";
import SearchIcon from "@material-ui/icons/Search";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import { Autocomplete } from "@material-ui/lab";
import TextMaskDate from "../../functions/TextMaskDate";

const Filterdrawer = ({
  handleModalClose,
  showModal,
  filter,
  displaynameSearch,
  setNameSearch,
  material,
  setMaterial,
  numeropedidocliente,
  setNumeropedidocliente,
  vendedorpedidocliente,
  setVendedorpedidocliente,
  changeFilter,
  handleChangeFilter,
  datadecriacao,
  setDatadecriacao,
  semestoque,
  orderBy,
  orderBySelected,
  setOrderBySelected,
  classificacoes,
  processos,
  changeValueClassificacao,
  changeValueProcesso,
  filial,
  filialSelected,
  setFilialSelected,
  checkbox1,
  checkbox2,
  checkbox3,
  checkbox4,
  checkbox5,
  checkbox6,
}) => {
  return (
    <Drawer
      variant={"temporary"}
      anchor={"right"}
      open={showModal}
      onClose={handleModalClose}
    >
      <List style={{ marginTop: "4rem" }}>
        <Divider />
        <ListItem>
          <TextField
            id="nameSearch"
            size="medium"
            placeholder="Block/Bundle/Slab"
            type="search"
            name="nameSearch"
            value={displaynameSearch}
            onChange={(e) => setNameSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>

        <ListItem>
          <FormControlLabel
            control={
              <Checkbox
                name="disponivel"
                checked={checkbox3 === true ? true : false}
                value={filter.disponivel}
                onChange={handleChangeFilter}
              />
            }
            label="Disponível"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="semestoque"
                checked={checkbox6 === true ? true : false}
                value={filter.semestoque}
                onChange={handleChangeFilter}
              />
            }
            label="Sem estoque"
          />
        </ListItem>

        <ListItem>
          <FormControlLabel
            control={
              <Checkbox
                name="empedido"
                checked={checkbox4 === true ? true : false}
                value={filter.empedido}
                onChange={handleChangeFilter}
              />
            }
            label="Em pedido"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="reservado"
                checked={checkbox5 === true ? true : false}
                value={filter.reservado}
                onChange={handleChangeFilter}
              />
            }
            label="Reservado"
          />
        </ListItem>

        <ListItem>
          <FormControlLabel
            control={
              <Checkbox
                name="espessura2"
                checked={checkbox1 === true ? true : false}
                value={filter.espessura2}
                onChange={handleChangeFilter}
              />
            }
            label="2Cm"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="espessura3"
                checked={checkbox2 === true ? true : false}
                value={filter.espessura3}
                onChange={handleChangeFilter}
              />
            }
            label="3Cm"
          />
        </ListItem>

        <ListItem>
          <TextField
            id="materialSearch"
            size="medium"
            placeholder="Material"
            type="search"
            name="material"
            value={material}
            onChange={(e) => setMaterial(e.target.value)}
            fullWidth
            InputProps={{
              step: 100,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            id="vendedorpedidocliente"
            size="medium"
            placeholder="Nome do vendedor"
            type="search"
            name="vendedorpedidocliente"
            value={vendedorpedidocliente}
            onChange={(e) => setVendedorpedidocliente(e.target.value)}
            fullWidth
            InputProps={{
              step: 100,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            id="numeropedidocliente"
            size="medium"
            placeholder="Número do pedido "
            type="search"
            name="numeropedidocliente"
            value={numeropedidocliente}
            onChange={(e) => setNumeropedidocliente(e.target.value)}
            fullWidth
            InputProps={{
              step: 100,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            id="datadecriacao"
            size="medium"
            placeholder="Só números DD-MM-AAAA"
            type="search"
            name="datadecriacao"
            value={datadecriacao}
            inputProps={{
              maxLength: 10
            }}
            onChange={(e) => {
              let value = e.target.value;
              if (value.match(/^\d{2}$/) !== null) {
                value = value + "/";
              } else if (value.match(/^\d{2}\/\d{2}$/) !== null) {
                value = value + "/";
              }
              setDatadecriacao(value)
            }}
            fullWidth
            InputProps={{
              step: 100,
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
        <AutoCompleteClassificacao
          classificacao={Classificacoes}
          label="Todos as Classificações"
          changeValueClassificacao={changeValueClassificacao}
          classificacoes={classificacoes}
        ></AutoCompleteClassificacao>

        <AutoCompleteProcesso
          processos={Processos}
          label="Todos os Processos"
          changeValueProcesso={changeValueProcesso}
          processosState={processos}
        ></AutoCompleteProcesso>

        <ListItem>
          <Autocomplete
            options={filial}
            value={filialSelected}
            label="selecione a filial"
            style={{ width: 250 }}
            onChange={(event, newValue) => {
              if (newValue == null) {
                setFilialSelected("");
              }
              if (newValue) {
                setFilialSelected(newValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione a filial"
                variant="standard"
              />
            )}
          />
        </ListItem>
        <ListItem>
            <Autocomplete
              options={orderBy}
              value={orderBySelected}
              multiple
              label="Ordernar por"
              getOptionLabel={(options) => options.option}
              style={{ width: 250 }}
              filterSelectedOptions
              onChange={(event, newValue) => {
                if (newValue == null) {
                  setOrderBySelected(() => '')
                  return
                }else {
                  setOrderBySelected(() => newValue)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ordernar por"
                  variant="standard"
                />
              )}
            />
          </ListItem>
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Button color="inherit" variant="contained" onClick={changeFilter}>
            Filtrar
          </Button>
        </div>
      </List>
    </Drawer>
  );
};

export default Filterdrawer;
