/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    marginLeft: '15px',    
  },
}));

export default function AutoCompleteProcesso({processos, label, changeValueProcesso, processosState}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      
      <Autocomplete
        multiple
        id="tags-standard"
        options={processos}
        getOptionLabel={(option) => option.valor}
        defaultValue={processosState}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}           
          />
        )}
        onChange={changeValueProcesso}
      />
      
    </div>
  );
}