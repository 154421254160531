export default function ClassificacaoValue(value) {
    switch (value) {
        //case '1' : return "Standard";
        //case '2' : return "Comercial";
        //case '3' : return "Primeira";
        case '0' : return "TODAS AS CLASSIFICAÇÕES";
        case 'A' : return "A";
        case 'B' : return "B";
        case 'C' : return "C";
        case 'D' : return "D";
        case 'E' : return "E";
        case 'F' : return "F";
        default : return "";
    }
}

export const Classificacoes = [
        {
            id: '0',
            valor: ClassificacaoValue('0')
        },
        {
            id: 'A',
            valor: ClassificacaoValue('A')
        },
        {
            id: 'B',
            valor: ClassificacaoValue('B')
        },
        {
            id: 'C',
            valor: ClassificacaoValue('C')
        },
        {
            id: 'D',
            valor: ClassificacaoValue('D')
        },
        {
            id: 'E',
            valor: ClassificacaoValue('E')
        },
        {
            id: 'F',
            valor: ClassificacaoValue('F')
        },
    ]