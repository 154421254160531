import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../context/User.context";

import { useHistory, useParams } from "react-router-dom";

import api from "../../Services/api";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Title from "../Types/Tittle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { DataGrid } from "@mui/x-data-grid";

import SearchIcon from "@material-ui/icons/Search";

import TablePaginationActions from "../../functions/TablePaginationActions";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Companys({ setCompany, companyId }) {
  const classes = useStyles();

  const { idEmpresa } = useParams() || "0";

  const history = useHistory();

  const [empresaData, setEmpresaData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [columnsCompany, setColumnsCompany] = useState([
    { field: "nome", headerName: "Nome Fantasia", width: 200 },
    { field: "razao_social", headerName: "Razao Social", width: 340 },
    { field: "cnpj", headerName: "CNPJ", width: 150 },
    { field: "email", headerName: "E-mail", width: 200 },
    { field: "telefone", headerName: "Telefone", width: 200 },
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function getCompanys(nameSearch) {
    try {
      await api.get("/empresas/" + nameSearch || "").then((res) => {
        setEmpresaData(res.data);
      });
    } catch (err) {
      console.log("Erro ao consultar usuarios");
    }
  }

  useEffect(() => {
    if (idEmpresa) {
      setCompany(idEmpresa);
      return;
    }

    getCompanys("");
  }, [companyId]);

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item align="left" sm={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  history.goBack();
                }}
                className={classes.button}
              >
                Voltar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setCompany(0);
                }}
                className={classes.button}
              >
                Nova Empresa
              </Button>
            </Grid>

            <Grid item align="left" sm={8}>
              <TextField
                id="nameSearch"
                size="small"
                placeholder="Procurar..."
                fullWidth
                onChange={(event) => {
                  getCompanys(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Empresas</Title>
                {/* {empresaData ? (
                  <Grid style={{ height: 300, width: "100%", paddingTop: "20px" }}>
                    <DataGrid
                      onCellClick={(e) => {
                        setCompany(e.row.id);
                      }}
                      columns={columnsCompany}
                      rows={empresaData}
                      // onCellEditCommit={handleCellEditCommit}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={12}>
                    Nenhuma Empresa selecionada!
                  </Grid>
                )} */}
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Nome Fantasia</TableCell>
                      <TableCell>Razao Social</TableCell>
                      <TableCell>CNPJ</TableCell>
                      <TableCell>E-mail</TableCell>
                      <TableCell>Telefone</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(empresaData
                      ? empresaData.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : empresaData
                    ).map((row, index) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => {
                          setCompany(row.id);
                        }}
                      >
                        <TableCell>{row.nome}</TableCell>
                        <TableCell>{row.razao_social}</TableCell>
                        <TableCell>{row.cnpj}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.telefone}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          20,
                          { label: "Todos", value: -1 },
                        ]}
                        colSpan={5}
                        count={empresaData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Registros por página" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
