import React, { useEffect, useState, useContext, useRef } from "react";

import UserContext from "../../context/User.context";

import api, {
  createChapa,
  getAllFiliais,
  getChapaById,
  getCompanyBucket,
  getProductsByCompany,
  postImages,
  removeFotoLocal,
  updateChapa,
} from "../../Services/api";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Title from "../Types/Tittle";
import Radio from "@material-ui/core/Radio";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

import NumberFormatCustom from "../../functions/NumberFormatCustom";
import PriceFormatCustom from "../../functions/PriceFormatCustom";

import Copyright from "../../functions/Copyright";

import Snackbar from "../../components/SnackBar/index";

import ListProcesso from "./ListProcesso";
import ListClassifcacao from "./ListClassificacao";
import ListEspessura from "./ListEspessura";

import ChapaImage from "./ChapaImage";

import ProductsSelect from "../../components/Product/ProductsSelect";
import { useAuth } from "../../context/User.provider";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function ChapaForm({ setList, chapaId }) {
  const classes = useStyles();

  const ref = useRef(false);

  const history = useHistory();
  const location = useLocation();

  // const [userData] = useContext(UserContext);
  const { userData } = useAuth();

  const [produto] = useState({
    id: 0,
    nome: "",
  });

  const [produtos, setProdutos] = useState([]);
  const [produtosLoaded, setProdutosLoaded] = useState(false);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [image, setImage] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [filial, setFilial] = useState([]);
  const [filialSelected, setFilialSelected] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  const [chapa, setChapa] = useState({
    id: 0,
    produto_id: 0,
    empresa_id: userData.empresa_id,
    usuario_id: userData.id,
    bundle_id: 0,
    chapacliente_id: 0,
    nomematerial_id: 0,
    // nome: "",
    emestoque: 0,
    foto_local: "",
    foto_remoto: "",
    comprimento_bruto: 0,
    comprimento_liquido: 0,
    altura_bruta: 0,
    altura_liquida: 0,
    espessura: 0,
    peso: 0,
    precom2: 0,
    nomematerialportugues: "",
    nomematerialingles: "",
    filialcliente: "",
    precoprequadrado: 0,
    status_oferta: "3",
    bloco: "",
    numerochapa: "",
    processo: "",
    classificacao: "",
    data_oferta: null,
  });

  const imageRef = useRef(null);

  function setProdutoId(id) {
    setChapa((prevState) => {
      return { ...prevState, produto_id: id };
    });
  }

  async function getFiliais() {
    try {
      await getAllFiliais(setFilial);
    } catch (err) {
      console.log(err);
    }
  }

  async function getProducts() {
    try {
      await getProductsByCompany(userData.empresa_id, setProdutos);
    } catch (err) {
      console.log("Erro ao consultar Produtos.");
      setProdutosLoaded(false);
    }
    setProdutosLoaded(true);
  }

  window.addEventListener("popstate", function (event) {
    setList();
    if (ref.current === false) {
      history.push(`/${process.env.REACT_APP_FRONT_ALIAS}/chapa`);
      ref.current = true;
      return;
    } else {
      // console.log("passou", history.length);
    }
  });

  async function getChapa(id) {
    try {
      if (id !== 0) {
        const res = await getChapaById(id);
        setChapa({
          id: res.data.id,
          produto_id: res.data.produto_id,
          empresa_id: res.data.empresa_id,
          usuario_id: res.data.usuario_id,
          bundle_id: res.data.bundle_id,
          chapacliente_id: res.data.chapacliente_id,
          nomematerial_id: res.data.nomematerial_id,
          nome: res.data.nome,
          nomematerialingles: res.data.nomematerialingles,
          nomematerialportugues: res.data.nomematerialportugues,
          filialcliente: res.data.filialcliente,
          foto_local: res.data.foto_local,
          foto_remoto: res.data.foto_remoto,
          comprimento_bruto: res.data.comprimento_bruto,
          comprimento_liquido: res.data.comprimento_liquido,
          altura_bruta: res.data.altura_bruta,
          altura_liquida: res.data.altura_liquida,
          espessura: res.data.espessura,
          peso: res.data.peso,
          precom2: res.data.precom2,
          precoprequadrado: res.data.precoprequadrado,
          status_oferta: res.data.status_oferta,
          bloco: res.data.bloco,
          numerochapa: res.data.numerochapa,
          processo: res.data.processo,
          classificacao: res.data.classificacao,
          data_oferta: res.data.data_oferta,
        });

        produto.id = res.data.produto_id;
        produto.nome = res.data.nome;

        setDataLoaded(true);
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    //setOpen(true);
  }

  useEffect(() => {
    getChapa(chapaId);
    getFiliais();
    getProducts();
    // console.log(chapa);
  }, [chapaId, produtosLoaded]);

  async function handleUpdateProduct(event) {
    event.preventDefault();
    if (!chapa.produto_id) {
      setSnackbarMessage("Favor informar o produto da chapa.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!chapa.precom2) {
      setSnackbarMessage("Favor informar o preço do metro quadrado da chapa.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      await updateChapa(chapa);
      setSnackbarMessage("Produto atualizado com sucesso.");
      setSnackbarKind("success");
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function handleCreateProduct(event) {
    event.preventDefault();
    if (!chapa.produto_id) {
      setSnackbarMessage("Favor informar o produto da chapa.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!chapa.precom2) {
      setSnackbarMessage("Favor informar o preço do m2 da chapa.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!imageUploaded) {
      setSnackbarMessage("Favor Adicionar Imagem.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      await saveImage();
      const ress = await createChapa(chapa);
      setSnackbarMessage("Chapa cadastrado com sucesso.");
      setSnackbarKind("success");
      setOpenSnackbar(true);
      // console.log(ress);
      getChapa(ress.data[0]);
      setImageUploaded(false);
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  //Upload Imagem
  function openFolder() {
    if (imageRef.current) {
      imageRef.current.click();
    }
  }

  async function saveImage(e) {
    try {
      //const files = e.target.files;
      const file = e.target.files[0];
      setImage(() => file);
      const formImage = new FormData();
      formImage.append("photo", file);
      if (e.target.files[1]) {
        const file2 = e.target.files[1];
        formImage.append("thumbnail", file2);
      }
      // console.log(formImage)
      // console.log(file)
      if (chapa.filialcliente === "") {
        setSnackbarMessage("Preencha a filial.");
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      formImage.append("empresa_id", userData.empresa_id);
      formImage.append("filial_cliente", chapa.filialcliente);
      const bucket = await getCompanyBucket(userData.empresa_id);
      const imageUploaded = await postImages(bucket, formImage);
      // console.log(imageUploaded);
      setChapa((prevState) => {
        return {
          ...prevState,
          foto_remoto: imageUploaded.data.files[0].location,
        };
      });
      setImageUploaded(() => true);
      return formImage;
    } catch (err) {
      console.log("Erro ao salvar imagem");
    }
  }

  async function removeImage(imageId) {
    try {
      const res = await removeFotoLocal(imageId, chapa.foto_local);
      setSnackbarMessage("A imagem foi excluída com sucesso!");
      setSnackbarKind("success");
      setOpenSnackbar(true);
      getChapa(chapaId);
    } catch (err) {
      setSnackbarMessage("Erro ao excluir imagem");
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Chapa</Title>

              <FormControl className={classes.formControl}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    {dataLoaded || chapa.id === 0 ? (
                      <ProductsSelect
                        id="produto_id"
                        label="Informe o Produto"
                        value={chapa.produto_id}
                        defaultValue={produto}
                        produtoNome={chapa.nome}
                        produtoCurrent={produto}
                        produtos={produtos}
                        empresaId={userData.empresa_id}
                        setProdutoId={setProdutoId}
                      />
                    ) : null}
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="nomematerialingles"
                      name="nomematerialingles"
                      label="Nome Material Inglês"
                      fullWidth
                      value={chapa.nomematerialingles || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            nomematerialingles: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="nomematerialportugues"
                      name="nomematerialportugues"
                      label="Nome Material Português"
                      fullWidth
                      value={chapa.nomematerialportugues || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            nomematerialportugues: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <Autocomplete
                      options={filial}
                      value={filialSelected}
                      label="selecione a filial"
                      style={{ width: 250 }}
                      onChange={(event, newValue) => {
                        if (newValue == null) {
                          setFilialSelected("");
                        }
                        if (newValue) {
                          setFilialSelected(newValue);
                          setChapa((prevState) => {
                            return { ...prevState, filialcliente: newValue };
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecione a filial"
                          variant="standard"
                        />
                      )}
                    />
                    {/* <TextField
                      id="filialcliente"
                      name="filialcliente"
                      label="Filial"
                      fullWidth
                      value={chapa.filialcliente || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return { ...prevState, filialcliente: event.target.value };
                        });
                      }}
                    /> */}
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="bundleId"
                      name="bundleId"
                      label="Bundle Cliente"
                      fullWidth
                      value={chapa.bundle_id || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            bundle_id: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="numerochapa"
                      name="numerochapa"
                      label="Número chapa"
                      fullWidth
                      value={chapa.numerochapa || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            numerochapa: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="bloco"
                      name="bloco"
                      label="Bloco"
                      fullWidth
                      value={chapa.bloco || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return { ...prevState, bloco: event.target.value };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <ListProcesso
                      id="processo"
                      name="processo"
                      label="Processo"
                      fullWidth
                      value={chapa.processo || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return { ...prevState, processo: event.target.value };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <ListClassifcacao
                      id="classificacao"
                      name="classificacao"
                      label="Classificaçao"
                      fullWidth
                      value={chapa.classificacao || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            classificacao: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      required
                      id="comprimento_bruto"
                      name="comprimento_bruto"
                      label="Comprimento Bruto"
                      value={chapa.comprimento_bruto || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            comprimento_bruto: event.target.value,
                          };
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="altura_bruta"
                      name="altura_bruta"
                      label="Altura bruta"
                      fullWidth
                      value={chapa.altura_bruta || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            altura_bruta: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      required
                      id="comprimento_liquido"
                      name="comprimento_liquido"
                      label="Comprimento Líquido"
                      value={chapa.comprimento_liquido || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            comprimento_liquido: event.target.value,
                          };
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="altura_liquida"
                      name="altura_liquida"
                      label="Altura líquida"
                      fullWidth
                      value={chapa.altura_liquida || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            altura_liquida: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <ListEspessura
                      required
                      id="espessura"
                      name="espessura"
                      label="Espessura"
                      fullWidth
                      value={chapa.espessura}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            espessura: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="peso"
                      name="peso"
                      label="Peso"
                      fullWidth
                      value={chapa.peso || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return { ...prevState, peso: event.target.value };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="precom2"
                      name="precom2"
                      label="Preço M²"
                      fullWidth
                      value={chapa.precom2 || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return { ...prevState, precom2: event.target.value };
                        });
                      }}
                      InputProps={{
                        inputComponent: PriceFormatCustom,
                      }}
                    />
                  </Grid>

                  <Grid item sm={3}>
                    <TextField
                      id="precoprequadrado"
                      name="precoprequadrado"
                      label="Preço P²"
                      fullWidth
                      value={chapa.precoprequadrado || ""}
                      onChange={(event) => {
                        setChapa((prevState) => {
                          return {
                            ...prevState,
                            precoprequadrado: event.target.value,
                          };
                        });
                      }}
                      InputProps={{
                        inputComponent: PriceFormatCustom,
                      }}
                    />
                  </Grid>

                  {chapa.foto_local ? (
                    <Grid item sm={12}>
                      <ChapaImage
                        key={chapa.id}
                        image_id={chapa.id}
                        image_url={`${process.env.REACT_APP_API_URL}images/${chapa.foto_local}`}
                        removeImage={removeImage}
                      ></ChapaImage>
                      <img
                        width="400"
                        height="300"
                        alt="oi"
                        id={`image` + chapa.id}
                        src={
                          process.env.REACT_APP_API_URL +
                          `images/` +
                          chapa.foto_local
                        }
                      ></img>
                    </Grid>
                  ) : chapa.foto_remoto ? (
                    <Grid item sm={12}>
                      <ChapaImage
                        key={chapa.id}
                        image_id={chapa.id}
                        image_url={chapa.foto_remoto}
                        removeImage={removeImage}
                      ></ChapaImage>
                      <img
                        src={chapa.foto_remoto}
                        height="500px"
                        width="500px"
                        alt="chapa"
                      />
                    </Grid>
                  ) : null}

                  <input
                    type="file"
                    single
                    id="file"
                    key="file"
                    ref={imageRef}
                    style={{ display: "none" }}
                    onChange={(event) => saveImage(event)}
                  />

                  <Grid item align="right" sm={12}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={
                        location.pathname ===
                        `/${process.env.REACT_APP_FRONT_ALIAS}/chapa`
                          ? setList
                          : history.push(
                              `/${process.env.REACT_APP_FRONT_ALIAS}/chapa`
                            )
                      }
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    {parseInt(chapa.id) !== 0 ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdateProduct}
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Salvar
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateProduct}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                      >
                        Nova chapa
                      </Button>
                    )}

                    {!chapa.foto_local ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={openFolder}
                        className={classes.button}
                        startIcon={<AddAPhotoIcon />}
                      >
                        Adicionar Foto
                      </Button>
                    ) : null}
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        <Box pt={4}>
          <Copyright />
        </Box>

        <Snackbar
          open={openSnackbar}
          closeBtn={true}
          closeSnackbar={closeSnackbar}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
        ></Snackbar>
      </Container>
    </main>
  );
}
