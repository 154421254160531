import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { bundleFilter, getAllFiliais } from "../../Services/api";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ReactExport from "react-data-export";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Title from "../Types/Tittle";
import Button from "@material-ui/core/Button";

import TablePaginationActions from "../../functions/TablePaginationActions";
import { CircularProgress } from "@material-ui/core";
import Filterdrawer from "./Drawer";
import { useAuth } from "../../context/User.provider";
import { DataGrid } from "@mui/x-data-grid";

const initialValues = {
  espessura2: false,
  espessura3: false,
  processo: "",
  classificacao: "",
  empedido: false,
  reservado: false,
  disponivel: false,
  semestoque: false,
};

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Materiais({
  setBundle,
  bundleId,
  bundleData,
  setBundleData,
}) {
  const classes = useStyles();

  const { idBundle } = useParams() || "0";

  // const [userData] = useContext(UserContext);
  const { userData } = useAuth();

  const history = useHistory();

  // const [bundleData, setBundleData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [classificacoes, setClassificacoes] = useState();
  const [processos, setProcessos] = useState();

  const [datadecriacao, setDatadecriacao] = useState("");
  const [semestoque, setSemestoque] = useState("");

  const [limit] = useState(9999);

  const [nameSearch, setNameSearch] = useState("");
  const [material, setMaterial] = useState("");
  const [vendedorpedidocliente, setVendedorpedidocliente] = useState("");
  const [numeropedidocliente, setNumeropedidocliente] = useState("");
  const [filial, setFilial] = useState([]);
  const [filialSelected, setFilialSelected] = useState("");

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);
  const [checkbox5, setCheckbox5] = useState(false);
  const [checkbox6, setCheckbox6] = useState(false);
  const [search, setSearch] = useState(false);
  const [filter, setFilter] = useState(initialValues);
  const [isFiltered, setIsFiltered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const orderByOptions = [
    { option: "Bloco", value: "bundle.bloco" },
    { option: "N° Bundle", value: "bundle.id" },
    // {option: 'Slab', value: 'bundle.bloco_id'},
    { option: "Nome Português", value: "bundle.nomematerialportugues" },
    { option: "Nome Inglês", value: "bundle.nomematerialingles" },
  ];

  const [bundleColumns, setBundleColumns] = useState([
    { field: "nomematerialingles", headerName: "Material", width: 200 },
    { field: "classificacao", headerName: "Category", width: 120 },
    { field: "bloco", headerName: "Block", width: 110 },
    { field: "numero_bundle_cliente", headerName: "Bundle", width: 110 },
    { field: "total_chapas", headerName: "Total Slabs", width: 130 },
    { field: "lista_chapas", headerName: "Slab List", width: 160 },
    { field: "espessura", headerName: "Tick", width: 100 },
    { field: "comprimento_liquido", headerName: "Witdh", width: 120 },
    { field: "altura_liquida", headerName: "Height", width: 120 },
    { field: "peso_total", headerName: "Weight.", width: 120 },
    { field: "numero_pedido_cliente", headerName: "Order", width: 120 },
  ]);

  //EXPORTANDO PRA CSV
  const headers = [
    { label: "Material", key: "nomematerialingles" },
    { label: "Category", key: "classificacao" },
    { label: "Block", key: "bloco" },
    { label: "Bundle", key: "numero_bundle_cliente" },
    { label: "Total Slabs", key: "total_chapas" },
    { label: "Slab List", key: "lista_chapas" },
    { label: "Tick", key: "espessura" },
    { label: "Witdh", key: "comprimento_liquido" },
    { label: "Height", key: "altura_liquida" },
    { label: "Weight", key: "peso_total" },
    { label: "Order", key: "numero_pedido_cliente" },
  ];

  const [orderBy, setOrderBy] = useState(orderByOptions);
  const [orderBySelected, setOrderBySelected] = useState([]);
  // const [showModal2, setShowModal2] = useState(false);

  function handleModalClose() {
    setShowModal(!showModal);
  }
  function changeValueClassificacao(e, value) {
    setClassificacoes(value);
    var SQLClassificacao = "";
    const nome = "classificacao";
    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-unused-expressions
    value
      ? value.map((row, index) => {
          if (index === 0) {
            SQLClassificacao = `'` + row.id + `'`;
          } else {
            SQLClassificacao = SQLClassificacao + `,'` + row.id + `'`;
          }
        })
      : null;

    setFilter((prevState) => {
      return {
        ...prevState,
        [nome]: SQLClassificacao,
      };
    });
  }

  async function changeFilter(Page) {
    setLoading(true);
    const object = {
      ...filter,
      limit,
      page,
      nameSearch,
      material,
      numeropedidocliente,
      vendedorpedidocliente,
      datadecriacao,
      semestoque,
      filialSelected,
      orderBy: orderBySelected,
    };
    try {
      const res = await bundleFilter(userData.empresa_id, object);
      if (res.data.data !== undefined && res.data) {
        setBundleData(res.data.data);
        console.log(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);

        return;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  // function changeFilter(Page) {

  //   setLoading(true)
  //   try {
  //     // console.log(semestoque)
  //     // console.log(`/estoque/bundle/${userData.empresa_id}?limit=${limit}&page=${page}&nameSearch=${nameSearch}&material=${material}&numeropedidocliente=${numeropedidocliente}&vendedorpedidocliente=${vendedorpedidocliente}&datadecriacao=${datadecriacao}`, {params: filter});
  //     api.get(`/estoque/bundle/${userData.empresa_id}?limit=${limit}&page=${page}&nameSearch=${nameSearch}&material=${material}&numeropedidocliente=${numeropedidocliente}&vendedorpedidocliente=${vendedorpedidocliente}&datadecriacao=${datadecriacao}&semestoque=${semestoque}&filial=${filialSelected}`, {params:filter})

  //     .then( response => {
  //       setBundleData(response.data.bundles);
  //       setLoading(false)
  //      }
  //     );
  //   } catch (err) {
  //     setLoading(false)
  //     console.log("Erro ao consultar chapas" + err);
  //   }
  // }

  async function getFiliais() {
    try {
      await getAllFiliais(setFilial);
    } catch (err) {
      console.log(err);
    }
  }

  function changeValueProcesso(e, value) {
    setProcessos(value);
    var SQLProcesso = "";
    const nome = "processo";
    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-unused-expressions
    value
      ? value.map((row, index) => {
          if (index === 0) {
            SQLProcesso = `'` + row.id + `'`;
          } else {
            SQLProcesso = SQLProcesso + `,'` + row.id + `'`;
          }
        })
      : null;

    setFilter((prevState) => {
      return {
        ...prevState,
        [nome]: SQLProcesso,
      };
    });
  }

  function handleChangeFilter(e, newValue) {
    if (e.target?.type === "checkbox") {
      const { name, checked } = e.target;

      // eslint-disable-next-line default-case
      switch (name) {
        case "espessura2":
          if (checked === true) {
            setCheckbox1(true);
          } else {
            setCheckbox1(false);
          }
          break;
        case "espessura3":
          if (checked === true) {
            setCheckbox2(true);
          } else {
            setCheckbox2(false);
          }
          break;
        case "disponivel":
          if (checked === true) {
            setCheckbox3(true);
          } else {
            setCheckbox3(false);
          }
          break;
        case "empedido":
          if (checked === true) {
            setCheckbox4(true);
          } else {
            setCheckbox4(false);
          }
          break;
        case "reservado":
          if (checked === true) {
            setCheckbox5(true);
          } else {
            setCheckbox5(false);
          }
          break;
        case "semestoque":
          if (checked === true) {
            setCheckbox6(true);
            setSemestoque(true);
          } else {
            setCheckbox6(false);
            setSemestoque(false);
          }
          break;
      }

      setFilter({ ...filter, [name]: checked === true ? checked : false });
    }
    setSearch(!search);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const csvReport = {
    filename: "Estoque.xlsx",
    headers: headers,
    data: bundleData,
  };
  //XLSX

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  // EXPORTANDO PRA PDF
  const savePdf = () => {
    setLoading(true);
    const doc = new jsPDF({
      orientation: "landscape",
    });
    const tableArray = bundleData.map((item) => {
      return [
        item.nomematerialingles,
        item.classificacao,
        item.bloco,
        item.numero_bundle_cliente,
        item.imagedate.substring(0, 10),
        item.total_chapas,
        item.lista_chapas,
        item.espessura,
        item.comprimento_liquido,
        item.numero_pedido_cliente,
      ];
    });
    doc.setFontSize(12);
    autoTable(doc, {
      tableWidth: "auto",
      styles: { overflow: "linebreak" },
      head: [
        [
          "Nome do Material",
          "Classif",
          "Bloco",
          "Bundle",
          "Data Fabricacao",
          "Total Chapas",
          "Lista de Chapas",
          "Espessura",
          "Comprimento",
          "Pedido",
        ],
      ],
      body: tableArray,
    });
    const blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
    setLoading(false);
  };

  useEffect(() => {
    if (idBundle) {
      setBundle(bundleId);
      return;
    }

    getFiliais();
  }, [bundleId]);

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item align="left" sm={3}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  history.goBack();
                }}
                className={classes.button}
              >
                Voltar
              </Button>
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setBundle(0);
                }}
                className={classes.button}
              >
                Novo Bundle
              </Button>
            </Grid>

            <Grid item align="right" sm={9}>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => setShowModal(!showModal)}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {loading ? <CircularProgress /> : <Title>Bundles</Title>}
                  <Grid>
                    <>
                      <ExcelFile
                        filename="Estoque"
                        element={
                          <Button
                            variant="contained"
                            className={classes.button}
                            style={{ marginRight: "5px" }}
                            color="primary"
                          >
                            Exportar para Excel
                          </Button>
                        }
                      >
                        <ExcelSheet data={bundleData} name="Estoque">
                          <ExcelColumn
                            label="Nome"
                            value="nomematerialingles"
                          />
                          <ExcelColumn
                            label="Classificao"
                            value="classificacao"
                          />
                          <ExcelColumn label="Bloco" value="bloco" />
                          <ExcelColumn
                            label="Bundle"
                            value="numero_bundle_cliente"
                          />
                          <ExcelColumn
                            label="Data Fabricacao"
                            value="imagedate"
                          />
                          <ExcelColumn
                            label="Total Chapas"
                            value="lista_chapas"
                          />
                          <ExcelColumn label="Espessura" value="espessura" />
                          <ExcelColumn
                            label="Comprimento"
                            value="comprimento_liquido"
                          />
                          <ExcelColumn label="Altura" value="altura_liquida" />
                          <ExcelColumn label="Kilo" value="peso_total" />
                          <ExcelColumn
                            label="Pedido"
                            value="numero_pedido_cliente"
                          />
                        </ExcelSheet>
                      </ExcelFile>
                      <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={() => savePdf()}
                      >
                        Exportar para PDF
                      </Button>
                    </>
                  </Grid>
                </Grid>
                {bundleData ? (
                  <Grid
                    style={{ height: 700, width: "100%", paddingTop: "20px" }}
                  >
                    <DataGrid
                      onCellClick={(e) => {
                        setBundle(e.row.id);
                      }}
                      columns={bundleColumns}
                      rows={bundleData}
                      // onCellEditCommit={handleCellEditCommit}
                    />
                  </Grid>
                ) : (
                  <Grid item sm={12}>
                    Nenhum bundle selecionado!
                  </Grid>
                )}

                {/* <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Nome do Material</TableCell>
                      <TableCell>Classif.</TableCell>
                      <TableCell>Bloco</TableCell>
                      <TableCell>Bundle</TableCell>
                      <TableCell>Data Fab.</TableCell>
                      <TableCell>Total de Chapas</TableCell>
                      <TableCell>Lista de Chapas</TableCell>
                      <TableCell>Espessura</TableCell>
                      <TableCell>CxA</TableCell>
                      <TableCell>Kg.</TableCell>
                      <TableCell>Pedido</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(bundleData
                      ? bundleData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      : bundleData
                    ).map((row, index) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => {
                          setBundle(row.id);
                        }}
                      >
                        <TableCell>{row.nome_ingles}</TableCell>
                        <TableCell>{row.classificacao}</TableCell>
                        <TableCell>{row.bloco}</TableCell>
                        <TableCell>{row.numero_bundle_cliente}</TableCell>
                        <TableCell>{row.imagedate}</TableCell>
                        <TableCell>{row.total_chapas}</TableCell>
                        <TableCell>{row.lista_chapas}</TableCell>
                        <TableCell>{row.espessura}</TableCell>
                        <TableCell>
                          {row.comprimento_liquido}m x{row.altura_liquida}m
                        </TableCell>
                        <TableCell>{row.peso_total}</TableCell>
                        <TableCell>{row.numero_pedido_cliente}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, { label: "Todos", value: -1 }]}
                        colSpan={6}
                        count={bundleData.length}
                        rowsPerPage={rowsPerPage || 20}
                        page={page || 0}
                        SelectProps={{
                          inputProps: { "aria-label": "Registros por página" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table> */}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Filterdrawer
        showModal={showModal}
        setShowModal={setShowModal}
        filter={filter}
        handleModalClose={handleModalClose}
        setIsFiltered={setIsFiltered}
        changeFilter={changeFilter}
        classificacoes={classificacoes}
        changeValueClassificacao={changeValueClassificacao}
        handleChangeFilter={handleChangeFilter}
        changeValueProcesso={changeValueProcesso}
        processos={processos}
        checkbox1={checkbox1}
        checkbox2={checkbox2}
        checkbox3={checkbox3}
        checkbox4={checkbox4}
        checkbox5={checkbox5}
        checkbox6={checkbox6}
        material={material}
        orderBy={orderBy}
        orderBySelected={orderBySelected}
        setOrderBySelected={setOrderBySelected}
        setMaterial={setMaterial}
        displaynameSearch={nameSearch}
        setNameSearch={setNameSearch}
        datadecriacao={datadecriacao}
        setDatadecriacao={setDatadecriacao}
        semestoque={semestoque}
        setSemestoque={setSemestoque}
        vendedorpedidocliente={vendedorpedidocliente}
        setVendedorpedidocliente={setVendedorpedidocliente}
        setNumeropedidocliente={setNumeropedidocliente}
        numeropedidocliente={numeropedidocliente}
        filial={filial}
        filialSelected={filialSelected}
        setFilialSelected={setFilialSelected}
      />
    </React.Fragment>
  );
}
