import React, { useEffect, useState, useContext } from "react";

import api from "../../Services/api";

import UserContext from "../../context/User.context";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Title from "../Types/Tittle";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";

import SaveIcon from "@material-ui/icons/Save";

import TextMaskCellPhone from "../../functions/TextMaskCellPhone";
import TextMaskPhone from "../../functions/TextMaskPhone";
import TextMaskCpf from "../../functions/TextMaskCpf";
import TextMaskCnpj from "../../functions/TextMaskCnpj";

import CPF from "cpf";
import { validate } from "cnpj";

import { KeyboardDatePicker } from "@material-ui/pickers";

import Copyright from "../../functions/Copyright";

import Snackbar from "../SnackBar/index";
import { CircularProgress, MenuItem, Select } from "@material-ui/core";
import Loading from "../Loading/Loading";
import { useAuth } from "../../context/User.provider";

//import TabOwner from "./tabOwner";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Person({ pessoaId, setList }) {
  const classes = useStyles();

  // const [userData] = useContext(UserContext);
  const { userData, setUserData } = useAuth();

  const history = useHistory();
  const location = useLocation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [optionsData, setOptionsData] = useState();

  const [pessoa, setPessoa] = useState({
    id: 0,
    pessoa_id: 0,
    empresa_id: userData.empresa_id,
    usuario_id: userData.id,
    nome: "",
    email: "",
    telefone: "",
    celular: "",
    tipo: "p",
    data_nascimento: null,
    cpf: "",
    cep: "",
    bairro: "",
    cidade: "",
    logradouro: "",
    complemento: "",
    estado: "",
    numero: "",
    complemento: "",
    referencia: "",
    identidade: "",
    nome_estado_ex: "",
    codpais: "",
    razao_social: "",
    cnpj: "",
    ie: "",
  });

  async function getData() {
    setLoading(true);
    try {
      await api.get("/personsData").then((res) => {
        setOptionsData(res.data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  async function getPessoa(id) {
    try {
      if (id && id !== 0) {
        await api.get("/person/" + id).then(async (res) => {
          setPessoa({
            id: res.data.id,
            nome: res.data.nome,
            email: res.data.email,
            telefone: res.data.telefone || "",
            celular: res.data.celular || "",
            tipo: res.data.tipo,
            razao_social: res.data.razao_social || "",
            cnpj: res.data.cnpj || "",
            ie: res.data.ie || "",
          });
          if (res.data.tipo === "F") {
            await api.get("/physical/" + id).then((result) => {
              setPessoa((prevState) => {
                return {
                  ...prevState,
                  data_nascimento: result.data.data_nascimento,
                  cpf: result.data.cpf || "",
                  identidade: result.data.identidade || "",
                };
              });
            });

            //setSelectedDate(physicalData.data.data_nascimento ? format(physicalData.data.data_nascimento, 'dd/MM/yyyy') : null)
          }
          // else if (res.data.tipo === "J") {
          //   const legalData = await api.get("/legal/" + id);

          //   setPessoa((prevState) => {
          //     return {
          //       ...prevState,
          //       razao_social: legalData.data.razao_social || "",
          //       cnpj: legalData.data.cnpj || "",
          //       ie: legalData.data.ie || "",
          //     };
          //   });
          // }
        });

        setDataLoaded(true);
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  useEffect(() => {
    getData();
    getPessoa(pessoaId);
  }, [pessoaId]);

  async function handleUpdatePerson(event) {
    event.preventDefault();

    if (!pessoa.nome) {
      setSnackbarMessage("Favor informar o nome da pessoa");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }

    try {
      if (pessoa.tipo === "F") {
        try {
          // if ((pessoa.cpf) && (!CPF.isValid(pessoa.cpf))) {
          //   setSnackbarMessage("Não foi possível atualizar o cliente. O CPF informado nao é válido.");
          //   setSnackbarKind("warning");
          //   setOpenSnackbar(true);
          //   return
          // }

          //Verifica se ja existe o mesmo cpf cadastrado
          //physicalCpf/:cpfSearch/:physicalId
          await api
            .get(
              "physicalCpfAndEmpresa/" +
                (pessoa.cpf || "0") +
                "/" +
                pessoa.id +
                "/" +
                userData.empresa_id
            )
            .then(async (res) => {
              if (res.data.length > 0) {
                setSnackbarMessage(
                  "Não foi possível atualizar o cliente. O CPF informado já existe."
                );
                setSnackbarKind("warning");
                setOpenSnackbar(true);
              } else {
                await api.put("/person/" + pessoa.id, pessoa);
                await api.put("/physical/" + pessoa.id, pessoa);

                //Snackbar(true, true, 'teste', 'success', true, onClose={onClose={() => closeSnackbar}})
                setSnackbarKind("success");
                setSnackbarMessage("Pessoa física atualizada com sucesso.");
                setOpenSnackbar(true);
                // <Snackbar closeSnackbar={closeSnackbar} closeBtn={true} message="Teste" kind="sucess" openSnack={openSnack} onClose={() => closeSnackbar} />
                // setOpenSnackbar(true)
              }
            });
        } catch (err) {
          setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
          setSnackbarKind("error");
          setOpenSnackbar(true);
        }

        //Pessoa Juridica
      } else if (pessoa.tipo === "J") {
        // if ((pessoa.cnpj) && (!validate(pessoa.cnpj))) {
        //   setSnackbarMessage("Não foi possível atualizar o cliente. O CNPJ informado nao é válido.");
        //   setSnackbarKind("warning");
        //   setOpenSnackbar(true);
        //   return
        // }

        //Verifica se ja existe o mesmo cnpj cadastrado
        // await api.post("/legalCnpj/" + userData.empresa_id, pessoa).then(async (res) => {

        // if (res.data.length > 0) {

        //   setSnackbarMessage("Não foi possível atualizar o cliente. O CNPJ informado já existe.");
        //   setSnackbarKind("warning");
        //   setOpenSnackbar(true);

        // }
        // else {
        //Atualiza pessoa juridica
        await api.put("/legal/" + pessoa.id, pessoa);
        setSnackbarMessage("Pessoa jurídica atualizada com sucesso.");
        setSnackbarKind("success");
        setOpenSnackbar(true);
        // }

        // })
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }
  const estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  function Filtrados(array) {
    const novoArray = array.filter(function (a) {
      return !array[JSON.stringify(a.codpais)] && (array[JSON.stringify(a.codpais)] = true);
    });
    return novoArray;
  }

  async function handleCreatePerson(event) {
    event.preventDefault();

    try {
      if (!pessoa.nome) {
        setSnackbarKind("warning");
        setSnackbarMessage("Favor informar o nome do cliente.");
        setOpenSnackbar(true);
        return;
      }

      if (pessoa.tipo === "F") {
        // console.log("Entrou aqui")

        // console.log("Testando: cpf = " + pessoa.cpf + " id = " + pessoa.id + " empresa = " + userData.empresa_id)

        //Verificar se ja existe um cpf da mesma empresa cadastrado
        // await api.get("/physicalCpfAndEmpresa/" + pessoa.cpf + "/" + pessoa.id + "/" + userData.empresa_id).then(async (res) => {

        //   if (res.data.length > 0) {

        //     setSnackbarKind("warning");
        //     setSnackbarMessage("Nao foi possível realizar o cadastro. O CPF informado já existe.");
        //     setOpenSnackbar(true);
        //     return

        //   }
        //   else {

        console.log(pessoa);

        await api.post("/person/", pessoa).then(async (ress) => {
          setPessoa((prevData) => {
            return {
              ...prevData,
              id: ress.data.id,
              pessoa_id: ress.data.id,
            };
          });

          await api.post("/physical/" + ress.data.id, pessoa).then((result) => {
            setSnackbarKind("success");
            setSnackbarMessage("Pessoa física inserida com sucesso.");
            setOpenSnackbar(true);
          });
        });
      }

      // });

      // }
      if (pessoa.tipo === "J") {
        //Verificar se ja existe um cnpj da mesma empresa cadastrado
        // await api.post("/legalCnpj/" + userData.empresa_id, pessoa).then(async (res) => {

        //   if (res.data.length > 0) {

        //     setSnackbarKind("warning");
        //     setSnackbarMessage("Nao foi possível realizar o cadastro. O CNPJ informado já existe.");
        //     setOpenSnackbar(true);
        //     return

        //   }
        //   else {

        await api.post("/person/", pessoa).then(async (ress) => {
          setPessoa((prevData) => {
            return {
              ...prevData,
              id: ress.data.id,
              pessoa_id: ress.data.id,
            };
          });

          await api.post("/legal/" + ress.data.id, pessoa).then((result) => {
            setSnackbarKind("success");
            setSnackbarMessage("Pessoa jurídica inserida com sucesso.");
            setOpenSnackbar(true);
          });
        });
      }

      //   })

      // }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {/* Pessoas */}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Cliente</Title>
              {
                loading === true && <CircularProgress />
                // <Loading />
              }
              <div>
                <Radio
                  checked={pessoa.tipo === "F"}
                  value="F"
                  name="tipo"
                  onChange={(event) => {
                    setPessoa({
                      ...pessoa,
                      tipo: event.target.value,
                    });
                  }}
                />
                Pessoa Física
                <Radio
                  checked={pessoa.tipo === "J"}
                  value="J"
                  name="tipo"
                  onChange={(event) => {
                    setPessoa({
                      ...pessoa,
                      tipo: event.target.value,
                    });
                  }}
                />
                Pessoa Jurídica
              </div>

              <FormControl className={classes.formControl}>
                <Grid container spacing={3}>
                  <Grid item sm={6}>
                    <TextField
                      required
                      id="nome"
                      name="nome"
                      label="Nome da Pessoa"
                      value={pessoa.nome || ""}
                      onChange={(event) => {
                        setPessoa({
                          ...pessoa,
                          nome: event.target.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <TextField
                      id="email"
                      name="email"
                      label="E-mail"
                      fullWidth
                      value={pessoa.email || ""}
                      onChange={(event) => {
                        setPessoa((prevState) => {
                          return { ...prevState, email: event.target.value };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <TextField
                      id="telefone"
                      name="telefone"
                      label="Telefone"
                      fullWidth
                      InputProps={{ inputComponent: TextMaskPhone }}
                      value={pessoa.telefone || ""}
                      onChange={(event) => {
                        setPessoa((prevState) => {
                          return { ...prevState, telefone: event.target.value };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <TextField
                      id="celular"
                      name="celular"
                      label="Celular"
                      fullWidth
                      InputProps={{ inputComponent: TextMaskCellPhone }}
                      value={pessoa.celular || ""}
                      onChange={(event) => {
                        setPessoa((prevState) => {
                          return {
                            ...prevState,
                            celular: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      id="CEP"
                      name="CEP"
                      label="CEP"
                      fullWidth
                      value={pessoa.cep || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPessoa((prevState) => {
                          return { ...prevState, cep: val };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      id="bairro"
                      name="rf"
                      label="Bairro"
                      fullWidth
                      value={pessoa.bairro || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPessoa((prevState) => {
                          return { ...prevState, bairro: val };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      id="cidade"
                      name="rf"
                      label="Cidade"
                      fullWidth
                      value={pessoa.cidade || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPessoa((prevState) => {
                          return { ...prevState, cidade: val };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <div
                      style={{
                        fontSize: 12,
                        position: "absolute",
                        fontWeight: "lighter",
                      }}
                    >
                      Estado
                    </div>
                    <Select
                      id="estado"
                      name="estado"
                      label="Estado"
                      style={{ marginTop: "16px" }}
                      fullWidth
                      value={pessoa.estado || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPessoa((prevState) => {
                          return { ...prevState, estado: val };
                        });
                      }}
                    >
                      {estados?.map((item, idx) => {
                        return (
                          <MenuItem value={item} key={idx}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="logradouro"
                      name="logradouro"
                      label="Logradouro"
                      fullWidth
                      value={pessoa.logradouro || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPessoa((prevState) => {
                          return { ...prevState, logradouro: val };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <TextField
                      id="numero"
                      name="numero"
                      label="Número"
                      fullWidth
                      value={pessoa.numero || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPessoa((prevState) => {
                          return { ...prevState, numero: val };
                        });
                      }}
                    />
                  </Grid>
                  {optionsData !== undefined && (
                    <>
                      <Grid item sm={2}>
                        <div
                          style={{
                            fontSize: 12,
                            position: "absolute",
                            fontWeight: "lighter",
                          }}
                        >
                          Estado Exterior
                        </div>
                        <FormControl fullWidth>
                          <Select
                            id="estadoExterior"
                            name="estado"
                            label="Estado Exterior"
                            style={{ marginTop: "16px" }}
                            fullWidth
                            value={pessoa.nome_estado_ex || ""}
                            onChange={(event) => {
                              const val = event.target.value;
                              setPessoa((prevState) => {
                                return { ...prevState, nome_estado_ex: val };
                              });
                            }}
                          >
                            {optionsData?.map((item, idx) => {
                              if (
                                item.nome_estado_ex !== "" &&
                                item.nome_estado_ex !== undefined &&
                                item.nome_estado_ex !== null
                              ) {
                                return (
                                  <MenuItem value={item.nome_estado_ex} key={idx}>
                                    {item.nome_estado_ex}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sm={2}>
                        <TextField
                          id="codpais"
                          name="codpais"
                          label="Código País"
                          fullWidth
                          value={pessoa.codpais || ""}
                          onChange={(event) => {
                            const val = event.target.value;
                            setPessoa((prevState) => {
                              return { ...prevState, codpais: val };
                            });
                          }}
                        />
                        {/* <div style={{fontSize: 12, position: 'absolute', fontWeight: 'lighter'}}>Código País</div>
                      <Select
                        id="Codigo Pais"
                        name="Codigo Pais"
                        label="Codigo Pais"
                        style={{marginTop: '16px'}}
                        fullWidth
                        value={pessoa.codpais || ""}
                        onChange={(event) => {
                          const val = event.target.value;
                          setPessoa((prevState) => {
                            return { ...prevState, codpais: val };
                          });
                        }}
                        >
                        {Filtrados(optionsData).map((item, idx)=>{
                          if(item.codpais !== "" && item.codpais !== null) {
                            return <MenuItem value={item.codpais} key={idx}>{item.codpais}</MenuItem>

                          }
                        })}
                      </Select> */}
                      </Grid>
                    </>
                  )}
                  <Grid item sm={3}>
                    <TextField
                      id="complemento"
                      name="complemento"
                      label="Complemento"
                      fullWidth
                      value={pessoa.complemento || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPessoa((prevState) => {
                          return { ...prevState, complemento: val };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <TextField
                      id="referencia"
                      name="referencia"
                      label="Referência"
                      fullWidth
                      value={pessoa.referencia || ""}
                      onChange={(event) => {
                        const val = event.target.value;
                        setPessoa((prevState) => {
                          return { ...prevState, referencia: val };
                        });
                      }}
                    />
                  </Grid>

                  {pessoa.tipo === "F" ? (
                    <>
                      <Grid item sm={4}>
                        <KeyboardDatePicker
                          autoOk
                          disableFuture
                          id="data_nascimento"
                          label="Data de nascimento"
                          format="dd/MM/yyyy"
                          value={pessoa.data_nascimento}
                          onChange={(date) => {
                            setPessoa((prevState) => {
                              return {
                                ...prevState,
                                data_nascimento: date,
                              };
                            });
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <TextField
                          id="cpf"
                          name="cpf"
                          label="CPF"
                          fullWidth
                          value={pessoa.cpf || ""}
                          InputProps={{ inputComponent: TextMaskCpf }}
                          onChange={(event) => {
                            const val = event.target.value;
                            setPessoa((prevState) => {
                              return { ...prevState, cpf: val };
                            });
                          }}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <TextField
                          id="rg"
                          name="rf"
                          label="RG"
                          fullWidth
                          value={pessoa.identidade || ""}
                          onChange={(event) => {
                            const val = event.target.value;
                            setPessoa((prevState) => {
                              return { ...prevState, identidade: val };
                            });
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item sm={6}>
                        <TextField
                          id="razao_social"
                          label="Razao Social"
                          fullWidth
                          value={pessoa.razao_social}
                          onChange={(event) => {
                            setPessoa({
                              ...pessoa,
                              razao_social: event.target.value,
                            });
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <TextField
                          id="cnpj"
                          name="cnpj"
                          label="CNPJ"
                          fullWidth
                          value={pessoa.cnpj || ""}
                          InputProps={{ inputComponent: TextMaskCnpj }}
                          onChange={(event) => {
                            setPessoa({
                              ...pessoa,
                              cnpj: event.target.value,
                            });
                          }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item sm={2}>
                        <TextField
                          id="ie"
                          name="ie"
                          label="Inscriçao Estadual"
                          fullWidth
                          value={pessoa.ie || ""}
                          onChange={(event) => {
                            setPessoa({
                              ...pessoa,
                              ie: event.target.value,
                            });
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item align="right" sm={12}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={
                        location.pathname === `/${process.env.REACT_APP_FRONT_ALIAS}/person`
                          ? setList
                          : history.push(`/${process.env.REACT_APP_FRONT_ALIAS}/person`)
                      }
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    {pessoa.id === 0 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreatePerson}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                      >
                        Novo Cliente
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdatePerson}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                      >
                        Salvar
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        {/* <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classes.paper2}>
              {dataLoaded ? <TabOwner personName={person.name} pessoaId={person.id}></TabOwner> : null}
            </Paper>
          </Grid>
        </Grid> */}

        <Box pt={4}>
          <Copyright />
        </Box>

        <Snackbar
          closeSnackbar={closeSnackbar}
          closeBtn={true}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
          open={openSnackbar}
        ></Snackbar>
      </Container>
    </main>
  );
}
