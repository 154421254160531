function showTime(date) {
    if (date) {

        const newDate = new Date(date);
        //return newDate;
        //'pt-BR'
        //'ko-KR'
        //'sv-SE'
        //return `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
        return String(newDate.getHours()) + ":" + String(newDate.getMinutes()).padStart(2, "0");;
        

    }
    else {
        return date;
    }
}

export default showTime;