import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Bundles from "../../components/Bundle/List";
import BundleForm from "../../components/Bundle/Bundle";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Bundle() {
  const classes = useStyles();

  const [bundleId, setBundleId] = useState(0);
  const [bundleData, setBundleData] = useState([]);
  const [showView, setShowView] = useState("list");

  function setBundle(bundleId) {
    setBundleId(bundleId);
    setShowView("bundle");
  }

  function setList() {
    setBundleId(0);
    setShowView("list");
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {showView === "list" ? (
        <Bundles
          bundleData={bundleData}
          setBundleData={setBundleData}
          setBundle={setBundle}
          bundleId={bundleId}
        ></Bundles>
      ) : (
        <BundleForm
          setList={setList}
          bundleId={bundleId}
          setBundleId={setBundleId}
          bundleData={bundleData}
        ></BundleForm>
      )}
    </div>
  );
}
