import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Products from "../../components/Product/List";
import ProductForm from "../../components/Product/Product";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Product() {
  const classes = useStyles();

  const [productId, setProductId] = useState(0);

  const [showView, setShowView] = useState("list");

  function setProduct(productId) {
    setProductId(productId);
    setShowView("product");
  }

  function setList() {
    setProductId(0);
    setShowView("list");
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {showView === "list" ? (
        <Products setProduct={setProduct} productId={productId}></Products>
      ) : (
        <ProductForm setList={setList} productId={productId}></ProductForm>
      )}
    </div>
  );
}
