import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Users from "../../components/User/list";
import UserForm from "../../components/User/user";
import ModalPass from "../../components/User/modalPass";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function User() {
  const classes = useStyles();

  const [userId, setUserId] = useState(0);

  const [showView, setShowView] = useState("list");

  const [isModalPass, setIsModalPass] = useState(false);

  function setUser(userId) {
    setUserId(userId);
    setShowView("user");
  }

  function setList() {
    setUserId(0);
    setShowView("list");
  }

  function setModalPass() {
    setIsModalPass(true);
  }

  function setCloseModalPass() {
    setIsModalPass(false);
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {showView === "list" ? (
        <Users setUser={setUser} userId={userId}></Users>
      ) : (
        <UserForm userId={userId} setList={setList} setModalPass={setModalPass}></UserForm>
      )}

      {isModalPass ? (
        <ModalPass setCloseModalPass={setCloseModalPass} userId={userId}></ModalPass>
      ) : null}
    </div>
  );
}
