import React, { useState } from 'react';

import LoginForm from '../../components/Login/Login';
import Register from '../../components/Login/Register';
import Remember from '../../components/Login/Remember';

export default function Login() {

    const [isLoginForm, setIsLoginForm] = React.useState(true);

    const [pageName, setPageName] = useState("login")

    function setRegisterForm() {
        setPageName("register")
        setIsLoginForm(false)
    }

    function setLoginForm() {
        setPageName("login")
        setIsLoginForm(true)
    }

    function setRememberForm() {
        setPageName("remember")
    }

    const page = () => {
        switch(pageName) {
    
          case "login": return <LoginForm setRegisterForm={setRegisterForm} setRememberForm={setRememberForm}></LoginForm>;
          case "register": return <Register setLoginForm={setLoginForm}></Register>;
          case "remember": return <Remember setLoginForm={setLoginForm}></Remember>
    
          default: <LoginForm></LoginForm>
        }
      }

    return (        
        <>
        {page()}
        </> 
    )
}