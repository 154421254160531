import { Grid, makeStyles, Paper } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Title from "../../components/Types/Tittle";
import { useAuth } from "../../context/User.provider";
import showDate from "../../functions/showDate";
import format from "date-fns/format";
import showTime from "../../functions/showTime";
import { getNotificacoes } from "../../Services/api";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    width: "80%",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

const Notification = () => {
  const classes = useStyles();

  const { userData } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [notificationsColumns, setNotificationsColumns] = useState([
    { field: "mensagem", headerName: "Mensagem", width: 1000 },
    { field: "cliente_externo_id", headerName: "cliente_id", width: 200 },
    {
      field: "created_at",
      headerName: "Criada",
      width: 200,
      renderCell: (params) => {
        return (
          <p>
            {params.row.created_at.substring(0, 10)} {" - "}
            {params.row.created_at.substring(11, 16)}
          </p>
        );
      },
    },
  ]);
  const handleGetNotifications = async () => {
    try {
      //console.log(userData);
      const response = await getNotificacoes(userData.id, userData.empresa_id);
      //console.log(response.data);
      setNotifications(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetNotifications();
  }, [userData]);

  return (
    <div style={{ paddingTop: "6rem" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper className={classes.paper}>
            <Title>Notifications</Title>
            {notifications && notifications.length > 0 && (
              <Grid
                style={{ height: "75vh", width: "100%", paddingTop: "20px" }}
              >
                <DataGrid columns={notificationsColumns} rows={notifications} />
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Notification;
