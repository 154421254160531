import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../../components/AppBar";
import Ofertas from "../../../components/Oferta/Bundle/List";

import ModalOffert from "../../../components/Oferta/Bundle/ModalOffert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Oferta() {
  const classes = useStyles();

  const [ofertaId, setOfertaId] = useState(0);

  const [showView, setShowView] = useState("list");

  const [finishOffer, setFinishOffer] = useState(false);

  const [consultaPedido, setConsultaPedido] = useState(false);

  const [isModalOffert, setIsModalOffert] = useState(false);

  const [chapasOffert, setChapasOffert] = useState([]);

  const [empresaId, setEmpresaId] = useState("");

  const [user, setUser] = useState(0);

  function setOferta(ofertaId) {
    setOfertaId(ofertaId);
    setShowView("chapa");
  }

  function setList() {
    setOfertaId(0);
    setShowView("list");
  }

  function setModalOffert(userId) {
    setUser(userId);
    setIsModalOffert(true);
  }

  function setCloseModalOffert() {
    setIsModalOffert(false);
  }

  function setEmpresa(id) {
    setEmpresaId(id);
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {showView === "list" ? (
        <Ofertas
          finishOffer={finishOffer}
          setOferta={setOferta}
          consultaPedido={consultaPedido}
          setConsultaPedido={setConsultaPedido}
          ofertaId={ofertaId}
          isModalOffert={isModalOffert}
          setModalOffert={setModalOffert}
          setEmpresa={setEmpresa}
        ></Ofertas>
      ) : null}

      {isModalOffert ? (
        <ModalOffert
          setFinishOffer={setFinishOffer}
          setCloseModalOffert={setCloseModalOffert}
          empresaId={empresaId}
          userId={user}
          consultaPedido={consultaPedido}
        ></ModalOffert>
      ) : null}
    </div>
  );
}
