import React from 'react';

import Container from "@material-ui/core/Container";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Title from "../Types/Tittle";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function ListChapasOferta({chapas}) {

  const classes = useStyles();
  
//   (!chapas) ? chapas = [] : null;

    return (
      <Container maxWidth="lg" className={classes.container}>
          {chapas.length > 0 ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Chapas</Title>
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow hover>
                    <TableCell width="5%">Código</TableCell>
                    <TableCell width="15%">N. Chapa</TableCell>
                    <TableCell width="10%">Bloco</TableCell>
                    <TableCell width="10%">Comprimento</TableCell>
                    <TableCell width="10%">Altura</TableCell>
                    <TableCell width="15%">Total m2</TableCell>
                    <TableCell width="15%">Preço m2</TableCell>
                    <TableCell width="15%">Preço p2</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(chapas
              ? chapas
              : []
            ).map((chapa, index) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={chapa.id}>
                <TableCell>{`${chapa.id}`}</TableCell>
                  {/* <TableCell>{(row.foto_local !== "") ? <Avatar src={`${process.env.REACT_APP_API_URL}images/${row.foto_local}`} className={classes.small} ></Avatar> : null}</TableCell> */}
                  <TableCell>{chapa.numerochapa}</TableCell>
                  <TableCell>{chapa.bloco}</TableCell>
                  <TableCell>{chapa.comprimento_liquido}</TableCell>
                  <TableCell>{chapa.altura_liquida}</TableCell>
                  <TableCell>{chapa.TotalM2}</TableCell>
                  <TableCell>{(chapa.precom2)}</TableCell>
                  <TableCell>{chapa.precoprequadrado}</TableCell>
                </TableRow>
            ))}
                  </TableBody>
                  
                </Table>
              </Paper>
            </Grid>
          </Grid>
          ) : null }
        </Container>
    )

}