import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Companys from "../../components/Company/List";
import CompanyForm from "../../components/Company/Company";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function Company() {
  const classes = useStyles();

  const [companyId, setCompanyId] = useState(0);

  const [showView, setShowView] = useState("list");

  function setCompany(companyId) {
    setCompanyId(companyId);
    setShowView("company");
  }

  function setList() {
    setCompanyId(0);
    setShowView("list");
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {showView === "list" ? (
        <Companys setCompany={setCompany} companyId={companyId}></Companys>
      ) : (
        <CompanyForm setList={setList} companyId={companyId}></CompanyForm>
      )}
    </div>
  );
}
