import React, { useContext, useEffect, useState } from 'react'

import { CircularProgress, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import UserContext from '../../context/User.context';

import EmailIcon from '@material-ui/icons/Email';

import showDate from '../../functions/showDate';
import showTime from '../../functions/showTime';

import api from '../../Services/api';

import Title from '../Types/Tittle';
import Loading from '../Loading/Loading';
import { useAuth } from '../../context/User.provider';

const useStyles = makeStyles((theme) => ({
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      marginTop: "30px"
    },
    fixedHeight: {
      height: 240,
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      height: 400,
      padding: 10,
      backgroundColor: 'white',
      border: '2px solid #000',
      // boxShadow: 12,
    }
  }));
  

const SentList = ({ofertaId, oferta, setOferta, showEmailModal}) => {
    const classes = useStyles();
    
    // const [userData] = useContext(UserContext);
    const { userData } = useAuth()

    const [ofertsData, setOfertsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emailData, setEmailData] = useState([])
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("")


    async function getOfertas() {
      try {
          const { data } = await api.get(`/ofertaPessoa/${ofertaId}`);
          // console.log(data);

          setOfertsData(data)
      }
      catch (err) {
        console.log("Erro ao Emails" + err);
      }
    }
  

    async function getEmailInfo() {
      try {
        await api.get("/empresa/" + userData.empresa_id).then((res) => {
          setEmailData((prevState) => {
            return { ...prevState, email: {
              host: res.data.smtp_host,
              port: res.data.smtp_port,
              user: userData.SMTP_USER,
              pass: userData.SMTP_PASS,
              security: res.data.smtp_security
            }, empresa : {
              nome: res.data.nome,
              vendedor: userData.nome,
              email: userData.email
            }
          };
          });
          // console.log(oferta)
          // setEmailLoaded(true);
        });
        // console.log(oferta)
      } catch (err) {
        // setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
        // setSnackbarKind("error");
        // setOpenSnackbar(true);
      }
    }
  
    // console.log(oferta)

    useEffect(() => {
      setLoading(true);

      async function getDados() {
        await getOfertas();
        await getEmailInfo()
      }
      
      getDados();
      setLoading(false);
    }, [ofertaId, showEmailModal])
    
    if(loading) return (
      <Grid style={{paddingTop: "150px", paddingLeft: "250px"}} item xs={12} alignItems="center">
        {/* <CircularProgress /> */}
        <Loading />
      </Grid>
    );

  return (
    <Grid item xs={6}>
    <Paper className={classes.paper}>

          <Title>Ofertas enviadas</Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Hora</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

          
            {(ofertsData === null
                    ? null
                    : ofertsData
                    ).map((row) => (
                   <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                   <TableCell onClick={() => {}}>
                     {row.email_cliente}
                   </TableCell>
                   <TableCell onClick={() => {}}>
                     {showDate(row.created_time, 'dd/MM/yyyy')}
                   </TableCell>
                   <TableCell onClick={() => {}}>
                     {showTime(row.created_time)}
                   </TableCell>
                 </TableRow>
                ))}
                
            </TableBody>

          </Table>
        </Paper>
      </Grid>
  )
}

export default SentList