import React from "react";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { Filial } from "./Filial";

export default function ListFilial({ label, id, value, ...rest }) {
  return (
    <TextField select fullWidth label={label} value={value} id={id} {...rest}>
      <MenuItem value="0" disabled hidden>
        Selecione a Filial
      </MenuItem>
      {Filial
        ? Filial.map((row, index) => (
            <MenuItem key={index + 1} value={row.id}>
              {row.valor}
            </MenuItem>
          ))
        : null}
    </TextField>
  );
}
