import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import TopBar from "../../components/AppBar";
import Persons from "../../components/Person/list";
import Person from "../../components/Person/Person";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

export default function PersonPage() {
  const classes = useStyles();

  const [pessoaId, setPessoaId] = useState(0);

  const [showView, setShowView] = useState("list");

  function setPerson(pessoaId) {
    setPessoaId(pessoaId);
    setShowView("person");
  }

  function setList() {
    setPessoaId(0);
    setShowView("list");
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline />

      <TopBar></TopBar> */}

      {showView === "list" ? (
        <Persons setPerson={setPerson} pessoaId={pessoaId}></Persons>
      ) : (
        <Person pessoaId={pessoaId} setList={setList}></Person>
      )}
    </div>
  );
}
