import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useRef, useState, useEffect } from "react";

function CameraComponent({ open, onClose, saveImage, chapa, bundle }) {
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleCaptureClick = () => {
    if (stream) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const dataUrl = canvas.toBlob(
        function (blob) {
          if (chapa) {
            const image = new File(
              [blob],
              `[${chapa.bundlecliente_id}][${chapa.bloco}][${chapa.numerochapa}]`,
              { type: "image/jpeg" }
            );
            console.log("chapaid", chapa.id);
            saveImage(image, true, chapa.id, true);
          } else {
            const image = new File([blob], `[${bundle.numero_bundle_cliente}][${bundle.bloco}]`, {
              type: "image/jpeg",
            });
            saveImage(image, false, null, true);
          }
        },
        "image/jpeg",
        1
      );

      // Now you can send the dataUrl to the API
      console.log("dataUrl", dataUrl);
    }
  };

  const getUserMedia = async () => {
    try {
      const streamVideo = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: "environment",
        },
      });
      videoRef.current.srcObject = streamVideo;
      setStream(streamVideo);
    } catch (error) {
      alert(
        "Erro ao acessar a câmera, verifique se ela está conectada e se o navegador tem permissão para acessá-la."
      );
      console.error("Error accessing camera:", error);
    }
  };

  useEffect(() => {
    getUserMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="xl">
      <Button onClick={onClose}>Close Modal</Button>
      <DialogTitle>Tirar Foto</DialogTitle>
      <DialogContent
        style={{
          width: "100%",
          height: "50%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <video ref={videoRef} style={{ width: "100%", height: "70%" }} autoPlay></video>
        <Button variant="contained" color="primary" onClick={handleCaptureClick}>
          Capture
        </Button>
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      </DialogContent>
    </Dialog>
  );
}

export default CameraComponent;
