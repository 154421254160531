import React, { useState, useEffect, useContext, useRef } from "react";
import { debounce } from "lodash";
import {
  chapasMainFilter,
  getAllFiliais,
  incluirNaReservaChapas,
  liberaReservaChapas,
} from "../../../Services/api";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import RemoveShoppingCart from "@material-ui/icons/RemoveShoppingCart";
import FilterListIcon from "@material-ui/icons/FilterList";
import Snackbar from "../../../components/SnackBar/index";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Filter9PlusIcon from "@material-ui/icons/Filter9Plus";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Filter3Icon from "@material-ui/icons/Filter3";
import Filter4Icon from "@material-ui/icons/Filter4";
import Filter5Icon from "@material-ui/icons/Filter5";
import Filter6Icon from "@material-ui/icons/Filter6";
import Filter7Icon from "@material-ui/icons/Filter7";
import Filter8Icon from "@material-ui/icons/Filter8";
import Filter9Icon from "@material-ui/icons/Filter9";

import ItemCard from "./ItemCard";
import BundlesCard from "../Bundle/BundlesCard";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import UIInfiniteScroll from "../../InfiniteScroll/InfiniteScroll";

import Drawer from "@material-ui/core/Drawer";

import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AutoCompleteProcesso from "../Chapa/AutoCompleteProcesso";
import AutoCompleteClassificacao from "../Chapa/AutoCompleteClassificacao";

import { Processos } from "../../Chapa/Processo";
import { Classificacoes } from "../../Chapa/Classificacao";
import Loading from "../../Loading/Loading";
import { Autocomplete } from "@material-ui/lab";
import { useAuth } from "../../../context/User.provider";
import { SelectedChapasContext } from "../../../context/SelectedChapas.provider";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    //flexGrow: 1,
    //height: "97%",
    // width: "100%",
    //overflow: "auto",
    paddingTop: theme.spacing(4),
    marginTop: "3rem",
    //paddingLeft: "28px",
    //display: "block",
    //background: "#fafa",
    //maxWidth: "100%",
  },
  content2: {
    overflow: "none",
    //flexDirection: "colunn",
    //height: "100vh",
    width: "100%",
    //background: "#fafafa",
    position: "absolute",
    bottom: 0,
  },
  contentCard: {
    // flexGrow: 0,
    display: "inline-block",
    //background: "#aada",
    position: "flex",
    flexDirection: "colunn",
  },

  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  containerFixed: {
    position: "fixed",
    flexGrow: 0,
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    //paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(0),
    overflow: "auto",
  },
  pai: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    overflow_x: "scroll",
    position: "absolute",
  },
  escondePesquisa: {
    display: "none",
    //background: "#fafafa",
  },
  mostraPesquisa: {
    diplay: "inline-block",
  },
  fundo: {
    background: "#fafafa",
  },
  button: {
    display: "flex",
    position: "absolute",
    width: "80px",
    //  height: "75%",
    right: "0",
    top: "0",
    marginRight: "0.4rem",
  },
  submit: {
    position: "fixed",
    bottom: "10px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  submit2: {
    position: "fixed",
    bottom: "80px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  submit3: {
    position: "fixed",
    bottom: "150px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  submit4: {
    position: "fixed",
    bottom: "220px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  submit5: {
    position: "fixed",
    bottom: "290px",
    left: "0px",
    marginLeft: "0.1rem",
    //left:"50%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  drawer: {
    marginTop: "4rem",
  },
}));

const initialValues = {
  espessura2: false,
  espessura3: false,
  processo: "",
  classificacao: "",
  empedido: false,
  reservado: false,
  disponivel: false,
};

// const initialdisplayValues = {
//   material: '',
//   numeropedidocliente: '',
//   vendedorpedidocliente:'',
// }

export default function Ofertas({
  setOferta,
  ofertaId,
  setModalOffert,
  finishOffer,
  setEmpresa,
  consultaPedido,
  setConsultaPedido,
}) {
  const classes = useStyles();
  // const [userData] = useContext(UserContext);
  const { userData, setUserData } = useAuth();

  const [loading, setLoading] = useState(false);

  const [chapaData, setChapaData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [oldSelected, setOldSelected] = useState([]);
  const { selectedChapas, setSelectedChapas } = useContext(SelectedChapasContext);

  // eslint-disable-next-line no-unused-vars
  const [selectedChapasLoaded, setSelectedBundleOffert] = useState(false);
  // const empresa = parseInt(userData.empresa_id) || 0;
  const [checkbox1, setCheckbox1] = useState(false);
  // const [consultaPedido, setConsultaPedido] = useState(false)

  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false);
  const [checkbox4, setCheckbox4] = useState(false);
  const [checkbox5, setCheckbox5] = useState(false);
  // const [classificacoes, setClassificacoes] = useState([{id: '', valor: 'TODAS AS CLASSIFICAÇÕES'}])
  // const [processos, setProcessos] = useState([{id: '0', valor: 'TODOS OS PROCESSOS'}])
  const [classificacoes, setClassificacoes] = useState();
  const [processos, setProcessos] = useState();
  const [filial, setFilial] = useState([]);
  const [filialSelected, setFilialSelected] = useState([]);

  const [limit] = useState(24);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [atualPage, setatualPage] = useState(0);
  const [priorPage, setpriorPage] = useState(0);
  const [totalChapas, setTotalChapas] = useState(1);
  const [values, setValues] = useState(initialValues);

  const [openFilter, setopenFilter] = useState(false);
  const [nameSearch, setnameSearch] = useState("");
  const [displaynameSearch, setdisplaynameSearch] = useState("");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");

  const [material, setmaterial] = useState("");
  const [displaymaterial, setdisplaymaterial] = useState("");

  const [numeropedidocliente, setnumeropedidocliente] = useState("");
  const [displaynumeropedidocliente, setdisplaynumeropedidocliente] = useState("");

  const [vendedorpedidocliente, setvendedorpedidocliente] = useState("");
  const [displayvendedorpedidocliente, setdisplayvendedorpedidocliente] = useState("");
  const orderByOptions = [
    { option: "Bloco", value: "chapa.bloco" },
    { option: "N° Chapa", value: "chapa.id" },
    // {option: 'Slab', value: 'bundle.bloco_id'},
    { option: "Nome Português", value: "material.nome_portugues" },
    { option: "Nome Inglês", value: "material.nome_ingles" },
  ];
  const [orderBy, setOrderBy] = useState(orderByOptions);
  const [orderBySelected, setOrderBySelected] = useState([]);

  const delayedQuery = useRef(
    debounce((e) => {
      setnameSearch(e);
    }, 800)
  ).current;

  const delayedMaterialQuery = useRef(
    debounce((e) => {
      setmaterial(e);
    }, 800)
  ).current;

  const delayednumeropedidoclienteQuery = useRef(
    debounce((e) => {
      setnumeropedidocliente(e);
    }, 800)
  ).current;

  const delayedvendedorpedidoclienteQuery = useRef(
    debounce((e) => {
      setvendedorpedidocliente(e);
    }, 800)
  ).current;

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setopenFilter(!open);
  };

  //Função para Fazer o Delay na hora da pesquisa pelo bloco.
  function handleNameSearch(ev) {
    setPage(0);
    setdisplaynameSearch(ev.target.value);
    delayedQuery(ev.target.value);
  }
  function handlematerial(ev) {
    setPage(0);
    setdisplaymaterial(ev.target.value);
    delayedMaterialQuery(ev.target.value);
  }

  function handlenumeropedidocliente(ev) {
    if (ev.target.value !== "") {
      setConsultaPedido(true);
    } else {
      setConsultaPedido(false);
    }
    setPage(0);
    setdisplaynumeropedidocliente(ev.target.value);
    delayednumeropedidoclienteQuery(ev.target.value);
  }

  function handlevendedorpedidocliente(ev) {
    setPage(0);
    setdisplayvendedorpedidocliente(ev.target.value);
    delayedvendedorpedidoclienteQuery(ev.target.value);
  }

  function liberaCarinho() {
    setSelectedChapas([]);
    setSelectedChapas([]);
    setSelectedBundleOffert(false);
    getChapas(page);
  }

  function changeValueClassificacao(event, value, reason) {
    // console.log(value);
    setClassificacoes(value);
    var SQLClassificacao = "";

    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-unused-expressions
    value
      ? value.map((row, index) => {
          if (index === 0) {
            SQLClassificacao = `'` + row.id + `'`;
          } else {
            SQLClassificacao = SQLClassificacao + `,'` + row.id + `'`;
          }
        })
      : null;
    // setsearchProcessos(SQLClassificacao);
    // console.log(SQLClassificacao);
    onChange(event, "changeValueClassificacao", SQLClassificacao);
  }

  function changeValueProcesso(event, value, reason) {
    // console.log(value);
    setProcessos(value);
    var SQLProcesso = "";

    // eslint-disable-next-line array-callback-return
    // eslint-disable-next-line no-unused-expressions
    value
      ? value.map((row, index) => {
          if (index === 0) {
            SQLProcesso = row.id;
          } else {
            SQLProcesso = SQLProcesso + "," + row.id;
          }
        })
      : null;
    // setsearchProcessos(SQLProcesso);
    // console.log(SQLProcesso);
    onChange(event, "changeValueProcesso", SQLProcesso);
  }

  async function getFiliais() {
    try {
      await getAllFiliais(setFilial);
    } catch (err) {
      console.log(err);
    }
  }

  async function getChapas() {
    // setLoading(true);
    let newpage = page;
    setpriorPage(newpage);
    setatualPage(newpage);
    newpage = page + 1;

    const object = {
      ...values,
      limit,
      nameSearch,
      material,
      numeropedidocliente,
      vendedorpedidocliente,
      filialSelected,
      orderBy: orderBySelected,
    };
    console.log(object);
    try {
      const res = await chapasMainFilter(userData.empresa_id, newpage, object);
      if (res.data.length !== 0 && res.data) {
        if (newpage > 1) {
          setChapaData((prevState) => [...prevState, ...res.data.data]);
        } else {
          setChapaData(res.data.data);
        }
        setTotalChapas(res.data.pagination.total);
        setTotalPage(res.data.pagination.lastPage);
        setPage(() => newpage);
        // setLoading(false);
      } else {
        return;
      }
      //
    } catch (err) {
      // setLoading(false);
      console.log(err);
    }
    setDataLoaded(true);
    // console.log("data loaded", chapaData);
    // console.log("pagina atual", page);
  }

  async function incluiNaReserva() {
    if (consultaPedido === true) {
      setSnackbarMessage("Não é possivel reservar Chapas com Pedido!");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }

    try {
      var incluir = "true";
      const res = await incluirNaReservaChapas(userData.id, incluir, selectedChapas);
      if (res.data) {
        console.log("res.data", res.data);
        setSnackbarKind("success");
        setSnackbarMessage(res.data?.message);
        setOpenSnackbar(true);
        localStorage.clear();
        setTimeout((e) => {
          setSelectedChapas([]);
          getChapas();
        }, 1000);
      } else {
        console.log("res sem data", res);
      }
    } catch (err) {
      console.log("erro", err.message);
      if (err.message === "Request failed with status code 406") {
        setSnackbarMessage(
          `Os Bundles ${selectedChapas.map(
            (bundle) => bundle.numero_bundle_cliente
          )} não foram modificados!`
        );
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      if (err.message === "Request failed with status code 422") {
        setSnackbarMessage("Selecione pelo menos um bundle");
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      setSnackbarMessage(err.message);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }
  async function liberaReserva() {
    // console.log(event.target.value);
    try {
      const res = await liberaReservaChapas(userData.id, selectedChapas);

      if (res.data) {
        console.log("res.data", res.data);
        setSnackbarKind("success");
        setSnackbarMessage(res.data?.message);
        setOpenSnackbar(true);
        localStorage.clear();
        setTimeout((e) => {
          getChapas();
          setSelectedChapas([]);
        }, 1000);
      } else {
        console.log("res sem data", res);
      }
    } catch (err) {
      if (err.message === "Request failed with status code 406") {
        setSnackbarMessage(
          `Os Bundles ${selectedChapas.map(
            (bundle) => bundle.numero_bundle_cliente
          )} não foram modificados!`
        );
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      if (err.message === "Request failed with status code 422") {
        setSnackbarMessage("Selecione pelo menos um bundle");
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      setSnackbarMessage(err.message);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    //   console.log(err)
  }

  function mudaIconeCarrinho() {
    if (selectedChapas.length > 9) {
      return <Filter9PlusIcon />;
    }
    // eslint-disable-next-line default-case
    switch (selectedChapas.length) {
      case 0:
        return <ShoppingCartIcon />;
      case 1:
        return <Filter1Icon />;
      case 2:
        return <Filter2Icon />;
      case 3:
        return <Filter3Icon />;
      case 4:
        return <Filter4Icon />;
      case 5:
        return <Filter5Icon />;
      case 6:
        return <Filter6Icon />;
      case 7:
        return <Filter7Icon />;
      case 8:
        return <Filter8Icon />;
      case 9:
        return <Filter9Icon />;
    }
  }

  async function handleChangeCheck(event) {
    const isChecked = event.target.checked;
    setSelectedBundleOffert(false);
    const chapa = chapaData.find((bundle) => {
      return bundle.id == event.target.value;
    });

    if (isChecked) {
      try {
        setSelectedChapas((prevState) => {
          return [...prevState, chapa];
        });
        setSelectedBundleOffert(true);
      } catch (err) {
        console.log(err);
      }
    } else {
      setSelectedChapas((prevState) => {
        const selected = prevState.filter((item) => {
          return item !== chapa;
        });
        return selected;
      });
    }
    console.log(selectedChapas);
  }

  function handleSubmitOffer() {
    localStorage.removeItem("ornatec_Chapas");
    localStorage.setItem("ornatec_Chapas", JSON.stringify(selectedChapas));
    // eslint-disable-next-line no-undef

    setModalOffert(userData.id);
  }
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  function Load() {
    if (loading) {
      return (
        <Grid style={{ paddingTop: "35%", paddingLeft: "50%" }} item xs={12}>
          <Loading />
        </Grid>
      );
    }
  }

  //Função para atualizar os valores dos campos de filtros
  function onChange(ev, origem, valor) {
    //const {name,value} = ev.target

    //  console.log(ev);
    if (ev.type === "click") {
      if (origem === "changeValueProcesso") {
        const name = "processo";
        const value = valor;
        setValues({ ...values, [name]: value });
      } else if (origem === "changeValueClassificacao") {
        const name = "classificacao";
        const value = valor;
        setValues({ ...values, [name]: value });
      }
    } else {
      if (ev.target.type === "checkbox") {
        const { name, checked } = ev.target;

        // eslint-disable-next-line default-case
        switch (name) {
          case "espessura3":
            if (checked === true) {
              setCheckbox1(true);
            } else {
              setCheckbox1(false);
            }
            break;
          case "espessura2":
            if (checked === true) {
              setCheckbox2(true);
            } else {
              setCheckbox2(false);
            }
            break;
          case "empedido":
            if (checked === true) {
              setCheckbox3(true);
            } else {
              setCheckbox3(false);
            }
            break;
          case "reservado":
            if (checked === true) {
              setCheckbox4(true);
            } else {
              setCheckbox4(false);
            }
            break;
          case "disponivel":
            if (checked === true) {
              setCheckbox5(true);
            } else {
              setCheckbox5(false);
            }
            break;
        }
        setValues({ ...values, [name]: checked === true ? checked : false });
      } else {
        const { name, value } = ev.target;
        setValues({ ...values, [name]: value });
      }
    }
    setPage(() => 0);
    setChapaData([]);
    setpriorPage(0);
    setatualPage(0);
    setTotalChapas(0);
  }
  useEffect(() => {
    if (finishOffer === true) {
      setSelectedChapas([]);
      // getChapas()
      // console.log("Use Effect 1")
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishOffer]);

  useEffect(() => {
    getFiliais();
    if (userData.empresa_id >= 1) {
      setEmpresa(userData.empresa_id);
    }
    //console.log("Use Effect 2")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ofertaId, dataLoaded]);

  useEffect(() => {
    setChapaData(() => []);
    setatualPage(() => 0);
    setpriorPage(() => 0);
    setTotalPage(() => 0);
    setPage(() => 0);
    if (values || nameSearch || numeropedidocliente || material || vendedorpedidocliente) {
      if (userData.empresa_id >= 1) {
        console.log("pageEffect", page);
        getChapas();
      }
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values,
    nameSearch,
    numeropedidocliente,
    material,
    vendedorpedidocliente,
    filialSelected,
    orderBySelected,
  ]);

  return (
    <React.Fragment>
      {Load()}
      <Container maxWidth={false} className={classes.content}>
        <Grid spacing={2} container direction="row" justifyContent="center" alignItems="center">
          {/* {console.log(chapaData)} */}
          {dataLoaded && chapaData && chapaData.length >= 1
            ? chapaData?.map((row, index) => (
                <Grid>
                  <Grid item xs={12} key={index} sm={3}>
                    {/* {console.log("linhas: "+ row )} */}
                    <ItemCard
                      bundle={row}
                      index={row.id}
                      selectedBundles={selectedChapas}
                      handleChangeCheck={handleChangeCheck}
                      consultaPedido={consultaPedido}
                      userId={userData.id}
                    />
                  </Grid>
                </Grid>
              ))
            : null}
        </Grid>

        {chapaData && page < totalPage && page - priorPage === 1 && (
          <UIInfiniteScroll fetchMore={getChapas} />
        )}
        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit4}
          onClick={incluiNaReserva}
        >
          <LockIcon />
        </Fab>
        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit}
          onClick={liberaCarinho}
        >
          <RemoveShoppingCart />
        </Fab>

        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit3}
          onClick={liberaReserva}
        >
          <LockOpenIcon />
        </Fab>

        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit5}
          onClick={() => setopenFilter(!openFilter)}
        >
          {openFilter ? <FilterListIcon /> : <SearchIcon />}
        </Fab>

        <Fab
          type="submit"
          size="large"
          variant="circular"
          color="primary"
          className={classes.submit2}
          onClick={handleSubmitOffer}
        >
          {mudaIconeCarrinho()}
        </Fab>
      </Container>
      <Drawer
        variant={"temporary"}
        anchor={"right"}
        open={openFilter}
        onClose={toggleDrawer(openFilter)}
      >
        <List>
          <div className={classes.drawer} />
          <p>
            Paginas: {page} de {totalPage} || Chapas: {totalChapas}
          </p>
          <Divider />
          <ListItem>
            <TextField
              id="nameSearch"
              size="medium"
              placeholder="Block/Bundle/Slab"
              type="search"
              name="nameSearch"
              value={displaynameSearch}
              onChange={handleNameSearch} //{OnChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>

          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  name="espessura3"
                  //defaultChecked={values.espessura3}
                  checked={checkbox1 === true ? true : false}
                  value={values.espessura3}
                  onChange={onChange}
                />
              }
              label="3Cm"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="espessura2"
                  checked={checkbox2 === true ? true : false}
                  value={values.espessura2}
                  onChange={onChange}
                />
              }
              label="2Cm"
            />
          </ListItem>

          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  name="disponivel"
                  checked={checkbox5 === true ? true : false}
                  value={values.disponivel}
                  onChange={onChange}
                />
              }
              label="Disponível"
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  name="empedido"
                  checked={checkbox3 === true ? true : false}
                  value={values.empedido}
                  onChange={onChange}
                />
              }
              label="Em pedido"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="reservado"
                  checked={checkbox4 === true ? true : false}
                  value={values.reservado}
                  onChange={onChange}
                />
              }
              label="Reservado"
            />
          </ListItem>

          <ListItem>
            <TextField
              id="materialSearch"
              size="medium"
              placeholder="Material"
              type="search"
              name="material"
              value={displaymaterial}
              onChange={handlematerial}
              fullWidth
              InputProps={{
                step: 100,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
          <ListItem>
            <TextField
              id="vendedorpedidocliente"
              size="medium"
              placeholder="Nome do vendedor"
              type="search"
              name="vendedorpedidocliente"
              value={displayvendedorpedidocliente}
              onChange={handlevendedorpedidocliente}
              fullWidth
              InputProps={{
                step: 100,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
          <ListItem>
            <TextField
              id="numeropedidocliente"
              size="medium"
              placeholder="Número do pedido "
              type="search"
              name="numeropedidocliente"
              value={displaynumeropedidocliente}
              onChange={handlenumeropedidocliente}
              fullWidth
              InputProps={{
                step: 100,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
          <AutoCompleteClassificacao
            classificacao={Classificacoes}
            label="Todos as Classificações"
            changeValueClassificacao={changeValueClassificacao}
            classificacoes={classificacoes}
          ></AutoCompleteClassificacao>

          <AutoCompleteProcesso
            processos={Processos}
            label="Todos os Processos"
            changeValueProcesso={changeValueProcesso}
            processosState={processos}
          ></AutoCompleteProcesso>

          <ListItem>
            <Autocomplete
              options={filial}
              value={filialSelected}
              label="selecione a filial"
              multiple
              style={{ width: 250 }}
              onChange={(event, newValue) => {
                if (newValue == null) {
                  setPage(0);
                  setFilialSelected("");
                }
                if (newValue) {
                  setPage(0);
                  setFilialSelected(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Selecione a filial" variant="standard" />
              )}
            />
          </ListItem>
          <ListItem>
            <Autocomplete
              options={orderBy}
              value={orderBySelected}
              multiple
              label="Ordernar por"
              getOptionLabel={(options) => options.option}
              style={{ width: 250 }}
              filterSelectedOptions
              onChange={(event, newValue) => {
                if (newValue == null) {
                  setPage(0);
                  setOrderBySelected(() => "");
                  return;
                } else {
                  setPage(0);
                  setOrderBySelected(() => newValue);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Ordernar por" variant="standard" />
              )}
            />
          </ListItem>
          {/* <ListItem >
            <ListProcesso
              id="processo"
              name="processo"
              label="Processo"
              value={values.processo}
              fullWidth
              onChange={onChange}

            />
          </ListItem> */}

          {/* <ListItem >
            <ListClassifcacao
              id="classificacao"
              name="classificacao"
              label="Classificaçao"
              value={values.classificacao}
              fullWidth
              onChange={onChange}
            />
          </ListItem> */}
        </List>
        {/* {console.log(chapaData)} */}
        {chapaData ? (
          <Grid>
            <Grid item xs={12} sm={3}>
              {/* {console.log("linhas: "+ row )} */}
              {/* <BundlesCard bundle={chapaData} /> */}
            </Grid>
          </Grid>
        ) : null}
      </Drawer>
      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
    </React.Fragment>
  );
}
