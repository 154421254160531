
import Cookies from 'universal-cookie';

const cookies = new Cookies()

export const isAuthenticated = () => cookies.get("slaboffer-user") ? true : false


export const logout = () => {
  cookies.remove("slaboffer-user");
};