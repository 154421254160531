import React, { useEffect, useState } from "react";

import api from "../../Services/api";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "../Types/Tittle";

import showDate from "../../functions/showDate";
import showTime from "../../functions/showTime";
import { useAuth } from "../../context/User.provider";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function AccessList({ userId }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // Não precisa do hook useAuth porque este state é diferente do userID se alterar da caca na tela do usuario
  const [userData, setUserData] = useState([]);

  async function getUsers() {
    try {
      await api.get("/usuarioAcessoLista/" + userId).then((res) => {
        setUserData(res.data);
      });
      //console.log(userData)
      //console.log("Id do usuário: " + userId)
    } catch (err) {
      console.log("Erro ao consultar usuarios" + err);
    }
  }

  useEffect(() => {
    getUsers();
  }, [userId]);

  return (
    // <Grid item sm={12} lg={12}>
    <Paper className={classes.paper}>
      <Title>Últimos acessos</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Ip acesso</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Hora</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData &&
            userData.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell onClick={() => {}}>{row.enderecoip}</TableCell>
                <TableCell onClick={() => {}}>
                  {showDate(row.created_time, "dd/MM/yyyy")}
                </TableCell>
                <TableCell onClick={() => {}}>
                  {showTime(row.created_time)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
    // {/* </Grid> */}
  );
}
