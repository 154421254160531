export default function OfertaStatusValue(value) {
    switch (value) {
        case '1' : return "Em oferta";
        case '2' : return "Em aceite";
        case '3' : return "Concluída/faturada";
        case '4' : return "Indisponível";
        default : return "";
    }
}

export function OfertaStatusColor(value) {
    switch (String(value).trim()) {
        case '1' : return "primary";
        case '2' : return "secondary";
        case '3' : return "default";
        case '4' : return "default";
        default : return "";
    }
}

export const OfertaStatus = [
        {
            id: '1',
            valor: OfertaStatusValue('1')
        },
        {
            id: '2',
            valor: OfertaStatusValue('2')
        },
        {
            id: '3',
            valor: OfertaStatusValue('3')
        },
        {
            id: '4',
            valor: OfertaStatusValue('4')
        },
    ]