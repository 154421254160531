import React from "react";

import Routes from "./routes";

import Login from "./Pages/Login";
import Main from "./Pages/Main";

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import { UserProvider } from "./context/User.provider";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import { SelectedBundlesProvider } from "./context/SelectedBundles.provider";
import { SelectedChapasProvider } from "./context/SelectedChapas.provider";

//import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
//import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

function App() {
  const [themeMode, setThemeMode] = React.useState("light");

  function setMode(theme) {
    setThemeMode(theme);
  }

  const hist = createBrowserHistory();

  const theme = createTheme(
    {
      palette: {
        type: themeMode,
      },
      typography: {
        fontSize: 12,
      },
    },
    ptBR
  );

  const themeTest = createTheme(
    {
      palette: {
        primary: {
          //light: '#803c3c',
          main: "#0082C6",
          //dark: '#d46666',
          //contrastText: '#fff',
        },
        type: themeMode,
        secondary: {
          //light: '#ff7961',
          main: "#253776",
          //dark: '#d46666',
          //contrastText: '#fff',
        },
      },
      typography: {
        fontFamily: [
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        fontSize: 12,
      },
    },
    ptBR
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={themeTest}>
          <SelectedBundlesProvider>
            <SelectedChapasProvider>
              <UserProvider>
                <Routes></Routes>
              </UserProvider>
            </SelectedChapasProvider>
          </SelectedBundlesProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
}

export default App;
