import React, { useEffect, useState, useContext, useRef } from "react";

import UserContext from "../../context/User.context";

import api from "../../Services/api";

import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Title from "../Types/Tittle";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SaveIcon from '@material-ui/icons/Save';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import NumberFormatCustom from "../../functions/NumberFormatCustom";

import Copyright from "../../functions/Copyright";

import Snackbar from "../../components/SnackBar/index";
import { useAuth } from "../../context/User.provider";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paper2: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },

  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function MaterialForm({ setList, materialId }) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const { userData } = useAuth()
  // const [userData] = useContext(UserContext);

  const [dataLoaded, setDataLoaded] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  const [material, setMaterial] = useState({
    id: 0,
    usuario_id: userData.id,
    empresa_id: userData.empresa_id,
    id_materialcliente: "",
    nome_portugues: "",
    nome_ingles: "",
  });

  async function getMaterial(id) {
    try {
      if (id !== 0) {
        await api.get("/materiais/" + id).then((res) => {
          setMaterial({
            id: res.data.id,
            usuario_id: res.data.usuario_id,
            empresa_id: res.data.empresa_id,
            id_materialcliente: res.data.id_materialcliente,
            nome_portugues: res.data.nome_portugues,
            nome_ingles: res.data.nome_ingles,
          });

          setDataLoaded(true);
        });
      }
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    //setOpen(true);
  }

  useEffect(() => {
    getMaterial(materialId);
  }, [materialId]);

  async function handleUpdateMaterial(event) {
    event.preventDefault();
    if (!material.nome_portugues) {
      setSnackbarMessage("Favor informar o nome do material.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      await api.put("/material", material);
      setSnackbarMessage("Material atualizado com sucesso.");
      setSnackbarKind("success");
      setOpenSnackbar(true);
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  async function handleCreateMaterial(event) {
    event.preventDefault();
    if (!material.nome_portugues) {
      setSnackbarMessage("Favor informar o nome do material.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    try {
      
      await api.post("/material", material).then((ress) => {
        setSnackbarMessage("Material cadastrado com sucesso.");
        setSnackbarKind("success");
        setOpenSnackbar(true);
        getMaterial(ress.data.id);
      });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Title>Material</Title>

              <FormControl className={classes.formControl}>
                <Grid container spacing={3}>

                  <Grid item sm={6}>
                    <TextField
                      id="nome_portugues"
                      name="nome_portugues"
                      label="Nome Portugues"
                      fullWidth
                      value={material.nome_portugues || ""}
                      onChange={(event) => {
                        setMaterial((prevState) => {
                          return {
                            ...prevState,
                            nome_portugues: event.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <TextField
                      id="nome_ingles"
                      name="nome_ingles"
                      label="Nome em Ingles"
                      fullWidth
                      value={material.nome_ingles || ""}
                      onChange={(event) => {
                        setMaterial((prevState) => {
                          return { ...prevState, nome_ingles: event.target.value };
                        });
                      }}
                    />
                  </Grid>

                  <Grid item align="right" sm={12}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={
                        location.pathname === `/${process.env.REACT_APP_FRONT_ALIAS}/material`
                          ? setList
                          : history.push(`/${process.env.REACT_APP_FRONT_ALIAS}/material`)
                      }
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    {parseInt(material.id) !== 0 ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpdateMaterial}
                          className={classes.button}
                          startIcon={<SaveIcon />}
                        >
                          Salvar
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateMaterial}
                        className={classes.button}
                        startIcon={<SaveIcon />}
                      >
                        Novo Material
                      </Button>
                    )}

                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        <Box pt={4}>
          <Copyright />
        </Box>

        <Snackbar
          open={openSnackbar}
          closeBtn={true}
          closeSnackbar={closeSnackbar}
          message={snackbarMessage}
          kind={snackbarKind}
          onClose={() => closeSnackbar}
        ></Snackbar>
      </Container>
    </main>
  );
}
