import React from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { Processos } from './Processo'

export default function ListProcesso({label, id, value, ...rest}) {
    
    return (
        <TextField
            select
            fullWidth
            label={label}
            value={value}
            id={id}
            {...rest}
        >
            <MenuItem value="0" disabled hidden>Selecione o Processo</MenuItem>
            {Processos ? Processos.map((row, index) => (
                <MenuItem key={index+1} value={row.id}>{row.valor}</MenuItem>
            )) : null}
        </TextField>
    );

}