import React, { useState, useEffect } from 'react';

import api from "../../Services/api";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function ListChapasByProduct({produtoNome, produtoId}) {

  const [chapas, setChapas] = useState([])

  async function getChapas() {

    try{
        await api.get("/chapasProduto/" + produtoId).then((res) => {

            setChapas(res.data)

        })
    }
    catch(err) {
        alert("Erro ao consultar imóveis.")
    }

  }

  useEffect(() => {

    getChapas()

  }, [produtoId])

  return (
    <React.Fragment>
        {(chapas.length > 0) ? (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>N. Chapa</TableCell>
            <TableCell>Bloco</TableCell>
            <TableCell>Preço m2</TableCell>
            <TableCell>Preço pre2</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chapas.map((row) => (
            <TableRow key={row.id}>
              {/* <TableCell component={Link} to={`/dashboard/realty/${row.id}`}>{row.name}</TableCell> */}
              <TableCell>{row.numerochapa}</TableCell>
              <TableCell>{row.bloco}</TableCell>
              <TableCell>{(row.precom2)}</TableCell>
              <TableCell>{row.precoprequadrado}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
        )
        :
        <div align="center"><b>{produtoNome}</b> não possui nenhuma chapa cadastrada</div>
        }
    </React.Fragment>
  );
}