import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./functions/Auth";
import { useAuth } from "./context/User.provider";

//import Site from './Pages/Site';
import Login from "./Pages/Login";
import Main from "./Pages/Main";
import User from "./Pages/User";
import Person from "./Pages/Person";
import Product from "./Pages/Product";
import Chapa from "./Pages/Chapa";
import OfertaChapa from "./Pages/Oferta/Chapa";
import OfertaBundle from "./Pages/Oferta/Bundle";
import Material from "./Pages/Material";
import Company from "./Pages/Company";
import Bundle from "./Pages/Bundle";
import OfertaPage from "./Pages/Oferta";
import NotFound from "./Pages/404/NotFound";
import OrderPage from "./Pages/Order/OrderPage";
import { CssBaseline } from "@material-ui/core";
import TopBar from "./components/AppBar";
import Notification from "./Pages/Notifications/Notification";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { userData, isLogged } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default function Routes() {
  return (
    <BrowserRouter>
      <CssBaseline />

      <TopBar></TopBar>
      <Switch>
        <Route path="/" exact>
          <Redirect to={`/login`} />
        </Route>

        <Route path={`/login`} exact component={Login}></Route>

        <PrivateRoute path={`/app`} exact component={Main} />

        <PrivateRoute path={`/app/product`} exact component={Product} />

        <PrivateRoute path={`/app/chapa`} exact component={Chapa} />

        <PrivateRoute path={`/app/bundle`} exact component={Bundle} />

        <PrivateRoute path={`/app/material`} exact component={Material} />

        <PrivateRoute path={`/app/person`} exact component={Person} />

        <PrivateRoute path={`/app/oferta/chapa`} exact component={OfertaChapa} />

        <PrivateRoute path={`/app/oferta/bundle`} exact component={OfertaBundle} />

        <PrivateRoute path={`/app/oferta/`} exact component={OfertaPage} />

        <PrivateRoute path={`/app/notifications`} exact component={Notification} />

        <Route
          path={`/app/ofertacliente/:idPedido/:token/:empresaId`}
          exact
          component={OrderPage}
        />

        <PrivateRoute path={`/app/company`} exact component={Company} />

        <PrivateRoute path={`/app/company/:idEmpresa`} component={Company} />

        <PrivateRoute path={`/app/usuario`} exact component={User} />

        <PrivateRoute path={`/app/usuario/:idUsuario`} component={User} />

        <Route path={`/*`} component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
