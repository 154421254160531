import React from 'react'

import NumberFormat from 'react-number-format';

import PropTypes from 'prop-types';

export default function PriceFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator="."
        isNumericString
        decimalPrecision="2"
        decimalSeparator=","
        // prefix="R$"
        decimalScale={2}
        fixedDecimalScale={false}
      />
    );
  }
  
  PriceFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };