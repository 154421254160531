import React, { useState, useContext } from "react";
import "./topbar.css";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { Link, useHistory, useLocation } from "react-router-dom";

import ListItems from "../../components/List/index";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

import logo2 from "../../assets/img/logo2.png";
import { useAuth } from "../../context/User.provider";
import Avatar from "@material-ui/core/Avatar";
import { useEffect } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import { getNotificacoes, visualizouNotificacao } from "../../Services/api";
import { prepareEvent } from "@sentry/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const history = useHistory();

  const { pathname } = useLocation();
  const isLocationOfertacliente = pathname.includes("app/ofertacliente/");
  const isLoginOrRegister =
    pathname.includes("login") || pathname.includes("register");
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorNotEl, setAnchorNotEl] = useState(false);
  const openNotMenu = Boolean(anchorNotEl);
  const { userData, doLogout } = useAuth();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleNotificationMenu = (event) => {
    setAnchorNotEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorNotEl(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleGetNotifications = async () => {
    try {
      console.log(userData?.id + " " + userData?.empresa_id);
      const response = await getNotificacoes(
        userData?.id,
        userData?.empresa_id
      );
      setNotifications(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateNotification = async (notificationId) => {
    try {
      const response = await visualizouNotificacao(notificationId);

      setNotifications((prevState) => {
        const notification = prevState.find(
          (notification) => notification.id === notificationId
        );
        notification.visualizado = true;
        return [...prevState];
      });
      history.push(`/app/notifications`);
    } catch (err) {
      console.log(err);
    }
  };

  function getNotificationCount() {
    if (notifications) {
      // console.log(notifications);
      return notifications?.filter((not, idx) => {
        if (idx > 10) return 0;
        return not.visualizado === 0;
      }).length;
    } else return 0;
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (isLocationOfertacliente || isLoginOrRegister) {
      return;
    } else {
      handleGetNotifications();
    }
  }, [userData]);

  return (
    <>
      {isLoginOrRegister ? (
        <></>
      ) : (
        <>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, open && classes.appBarShift)}
          >
            <Toolbar className={classes.toolbar}>
              {isLocationOfertacliente ? (
                <div>
                  <img
                    src={logo2}
                    alt="SlabOffer"
                    width="200"
                    height="40"
                  ></img>
                </div>
              ) : (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    className={clsx(
                      classes.menuButton,
                      open && classes.menuButtonHidden
                    )}
                  >
                    <MenuIcon />
                  </IconButton>
                  <img
                    src={logo2}
                    alt="SlabOffer"
                    width="200"
                    height="40"
                  ></img>
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                  ></Typography>
                </>
              )}
              {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
              {/* <Typography component="caption" display="block">
            Bem vindo, {userData.nome}
          </Typography> */}

              {isLocationOfertacliente ? (
                <></>
              ) : (
                <>
                  <IconButton
                    aria-label="notifications of current user"
                    aria-controls="primary-notifications-menu"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleNotificationMenu}
                    style={{
                      marginRight: "1rem",
                    }}
                  >
                    <Badge
                      badgeContent={getNotificationCount() || 0}
                      color="secondary"
                    >
                      <NotificationsIcon fontSize="large" />
                    </Badge>
                    {/* <AccountCircle /> */}
                  </IconButton>

                  <Menu
                    id="notifications-menu"
                    anchorEl={anchorNotEl}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={openNotMenu}
                    onClose={handleNotificationClose}
                    style={{ marginTop: "2rem" }}
                  >
                    {notifications?.length > 0 && notifications ? (
                      notifications?.map((not, idx) => {
                        if (idx > 10) return null;
                        return (
                          <MenuItem
                            key={`${not.mensagem}${not.id}`}
                            className="notification-div"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderBottom: "1px solid #090909",
                              whiteSpace: "pre-line",
                            }}
                            onClick={() => handleUpdateNotification(not.id)}
                          >
                            <p
                              style={
                                not.visualizado === 0
                                  ? {
                                      width: "100%",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }
                                  : { width: "100%", textAlign: "center" }
                              }
                            >
                              {not.visualizado === 0
                                ? `*` + not.mensagem
                                : not.mensagem}
                            </p>
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem>Nenhuma notificação</MenuItem>
                    )}
                  </Menu>
                </>
              )}

              {isLocationOfertacliente ? (
                <></>
              ) : (
                <>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => setAnchorEl(!anchorEl)}
                    //component={Link}
                    //to={'/dashboard/usuario/' + userData.id}
                  >
                    <Avatar
                      alt={userData.nome}
                      src="/broken-image.jpg"
                      //className={classes.orange}
                    >
                      {userData?.nome[0]}
                    </Avatar>
                    {/* <AccountCircle /> */}
                  </IconButton>

                  <Menu
                    id="menu-appbar"
                    //anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={anchorEl}
                    onClose={() => setAnchorEl(!anchorEl)}
                  >
                    <MenuItem
                      component={Link}
                      to={`/app/usuario/${userData.id}`}
                    >
                      Meus dados
                    </MenuItem>
                    {/* <MenuItem component={Link} to={`/${process.env.REACT_APP_FRONT_ALIAS}`}>Sair</MenuItem> */}
                    <MenuItem
                      button={true}
                      onClick={doLogout}
                      component={Link}
                      to={`/login`}
                    >
                      Sair
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Toolbar>
          </AppBar>

          <Drawer
            variant="temporary"
            anchor={"left"}
            classes={{
              paper: clsx(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
            onClose={toggleDrawer(open)}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <ListItems empresaId={userData.empresa_id}></ListItems>
          </Drawer>
        </>
      )}
    </>
  );
}
