import React, { useState, useEffect, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";

import api, { handleDeleteOffer, handleSearchOffers } from "../../Services/api";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Title from "../Types/Tittle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import Chip from "@material-ui/core/Chip";

import Snackbar from "../../components/SnackBar/index";

import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";

import OfertaStatusValue from "../Chapa/OfertaStatus";
import { OfertaStatusColor } from "../Chapa/OfertaStatus";
import ShowDate from "../Settings/ShowDate";

import TablePaginationActions from "../../functions/TablePaginationActions";
import { IconButton } from "@material-ui/core";
import { useAuth } from "../../context/User.provider";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default function Ofertas({ setOferta, ofertaId }) {
  const classes = useStyles();

  const { idOferta } = useParams() || "0";

  // const [userData] = useContext(UserContext);
  const { userData } = useAuth();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user-SlabOffer"));

  const [chapaData, setOfertaData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  const [offertsColumn, setOffertsColumn] = useState([
    { field: "id", headerName: "Código", width: 200 },
    { field: "subject", headerName: "Subject", width: 200 },
    { field: "usuario", headerName: "Vendedor", width: 200 },
    { field: "empresa", headerName: "Empresa", width: 200 },
    { field: "status", headerName: "Situação", width: 150 },
    {
      field: "Deletar",
      headerName: "Deletar",
      width: 120,
      renderCell: (params) => (
        <Button
          onClick={(e) => handleDeleteClick(params.row?.id)}
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
        >
          Deletar
        </Button>
      ),
    },
  ]);
  function closeSnackbar() {
    setOpenSnackbar(false);
  }

  const handleChangePage = (event, newPage) => {
    if (newPage) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function searchOfertas(queryBusca) {
    try {
      if (queryBusca == "") {
        getOfertas("");
        return;
      }
      const res = await handleSearchOffers(queryBusca, userData.empresa_id);
      // console.log(res.data);
      setOfertaData(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function getOfertas(queryBusca) {
    try {
      if (
        userData.userRole === "administrador" ||
        userData.userRole === "gerente"
      ) {
        api.get("/ofertass/" + userData.empresa_id).then((res) => {
          setOfertaData(res.data);
          console.log(res.data);
        });
      } else {
        api
          .get(`/ofertass/${userData.empresa_id}/${userData.id}`)
          .then((res) => {
            setOfertaData(res.data);
            console.log("res", res.data);
          });
      }
    } catch (err) {
      console.log("Erro ao consultar ofertas");
    }
  }
  const handleDeleteClick = async (ofertaId) => {
    if (!user) {
      setSnackbarMessage("Erro ao Deletar oferta.");
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
    const oferta = {
      id: ofertaId,
      role: user.role,
    };
    // console.log(oferta);
    //console.log(userData);
    var confirmou = window.confirm(`Deseja apagar a oferta ${ofertaId}? `);
    if (confirmou === true) {
      try {
        const res = await handleDeleteOffer(oferta);
        setSnackbarMessage(res.data.message);
        setSnackbarKind("success");
        setOpenSnackbar(true);
        getOfertas("");
      } catch (err) {
        console.log(err.message);
        if (err.message === "Request failed with status code 401") {
          setSnackbarMessage("Usuário sem Permissão.");
          setSnackbarKind("error");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage("Erro ao Deletar oferta.");
          setSnackbarKind("error");
          setOpenSnackbar(true);
        }
      }
    } else {
      console.log("cancelou");
    }
  };

  const handleCellClick = (e) => {
    if (e.field === "Deletar") return;
    setOferta(e.row.id);
  };

  useEffect(() => {
    if (idOferta) {
      setOferta(ofertaId);
      return;
    }

    getOfertas("");
  }, [ofertaId]);

  return (
    <React.Fragment>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item align="left" sm={4}>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  history.goBack();
                }}
                className={classes.button}
              >
                Voltar
              </Button>
              &nbsp;
            </Grid>

            <Grid item align="left" sm={8}>
              <TextField
                id="nameSearch"
                size="small"
                placeholder="Procurar..."
                fullWidth
                onChange={(event) => {
                  searchOfertas(event.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Title>Ofertas</Title>
                {chapaData && chapaData.length > 0 ? (
                  <Grid
                    style={{
                      height: "75vh",
                      width: "100%",
                      paddingTop: "20px",
                    }}
                  >
                    <DataGrid
                      onCellClick={handleCellClick}
                      columns={offertsColumn}
                      rows={chapaData}
                    />
                  </Grid>
                ) : null}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Snackbar
        closeSnackbar={closeSnackbar}
        closeBtn={true}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
        open={openSnackbar}
      ></Snackbar>
    </React.Fragment>
  );
}
