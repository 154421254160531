import { format } from "date-fns";
import add from "date-fns/add";

export default function showDate(date, formatString) {
  try {
    if (date && date !== undefined) {
      var newDate = new Date(date);
      //newDate = add(newDate, { days:1 })
      //return newDate;
      //'pt-BR'
      //'ko-KR'
      //'sv-SE'
      //return `${newDate.getDay()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
      //return Intl.DateTimeFormat(format).format(newDate);
      return format(newDate, formatString);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
