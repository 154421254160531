import React, { useEffect, useState, useContext } from "react";

import api from "../../../Services/api";
import UserContext from "../../../context/User.context";
import Typography from "@material-ui/core/Typography";
import ProcessoValue from "../../Chapa/Processo";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Title from "../../Types/Tittle";
import SubTittle from "../../Types/SubTittle";
import { KeyboardDatePicker } from "@material-ui/pickers";

import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Snackbar from "../../../components/SnackBar/index";
import { id } from "date-fns/locale";
import { parse } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";

import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/RemoveCircleOutline";

import PriceFormatCustom from "../../../functions/PriceFormatCustom";

import ListPerson from "../../Settings/ListPersonOfertaChapa";
import { Checkbox, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "../../Loading/Loading";
import { useAuth } from "../../../context/User.provider";
import { DataGrid } from "@mui/x-data-grid";
import { SelectedChapasContext } from "../../../context/SelectedChapas.provider";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function ModalOffert({
  setCloseModalOffert,
  getChapa,
  empresaId,
  setFinishOffer,
  userId,
  consultaPedido,
}) {
  const [total, setTotal] = useState(0);
  const classes = useRowStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  const [loading, setLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);

  const [ofertaId, setOfertaId] = useState("");

  const [chapas, setChapas] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [chapasLoaded, setChapasLoaded] = useState(false);

  const [reservaCheckbox, setReservaCheckbox] = useState(false);

  const [updatedBundlePrice, setUpdatedBundlePrice] = useState([]);
  // const [priceBundle, setPriceBundle] = useState([{
  //   bundleId : 0,
  //   price : 0
  // }]);

  const [priceBundle, setPriceBundle] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [clientesLoaded, setClientesLoaded] = useState(false);

  const [vendedores, setVendedores] = useState([]);
  const [vendedorSelected, setVendedorSelected] = useState();
  const [pessoas, setPessoas] = useState([]);
  const [pessoasLoaded, setPessoasLoaded] = useState(false);
  const [bundlesLoaded, setBundlesLoaded] = useState(false);

  const [open, setOpen] = useState(false);

  const [columnsOffer, setColumnsOffer] = useState([
    {
      field: "exclude",
      headerName: "Exclude",
      width: 120,
      renderCell: (params) => (
        <Button variant="contained" color="primary" size="small" style={{ marginLeft: 16 }}>
          Delete
        </Button>
      ),
    },
    { field: "block", headerName: "Block", width: 120 },
    { field: "slab", headerName: "Slab", width: 120 },
    { field: "Material", headerName: "Material", width: 150 },
    { field: "Processo", headerName: "Processo", width: 150 },
    { field: "Size", headerName: "Size", width: 200 },
    { field: "Thick", headerName: "Thick", width: 100 },
    { field: "Price", headerName: "Price", width: 120, editable: true },
    {
      field: "setPrice",
      headerName: "SetPrice",
      width: 120,
      renderCell: (params) => (
        <Button
          onClick={(e) => handleChangeAllPrices(params)}
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
        >
          Aplly All
        </Button>
      ),
    },
  ]);
  const [columnsOrder, setColumnsOrder] = useState([
    { field: "chapas", headerName: "Chapas", width: 200 },
    { field: "Material", headerName: "Material", width: 200 },
    { field: "Bundle", headerName: "Bundle", width: 200 },
    { field: "quantidade", headerName: "Quantidade", width: 200 },
    { field: "Price", headerName: "Price", width: 100, editable: true },
    {
      field: "setPrice",
      headerName: "SetPrice",
      width: 120,
      renderCell: (params) => (
        <Button
          onClick={(e) => handleChangeAllPrices(params)}
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
        >
          Apply All
        </Button>
      ),
    },
  ]);
  // const [chapasSelected, setChapasSelected] = useState([]);
  const [rowsOffer, setRowsOffer] = useState([]);
  const [rowsOrder, setRowsOrder] = useState([]);
  const [isOrder, setIsOrder] = useState(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  // const [userData] = useContext(UserContext);
  const { selectedChapas, setSelectedChapas } = useContext(SelectedChapasContext);

  const { userData } = useAuth();

  const ofertData = {
    clientEmail: localStorage.getItem("email_cliente") || "",
    clientId: localStorage.getItem("id_cliente") || "",
    clientName: localStorage.getItem("name_cliente") || "",
    bundlePrice: localStorage.getItem("price_bundle") || 0,
    assunto: localStorage.getItem("assunto") || "",
    observacao: localStorage.getItem("observacao") || "",
  };

  function removeLocalOfert() {
    localStorage.removeItem("email_cliente");
    localStorage.removeItem("id_cliente");
    localStorage.removeItem("name_cliente");
    localStorage.removeItem("price_bundle");
    localStorage.removeItem("ornatec_Chapas");
    localStorage.removeItem("assunto");
    localStorage.removeItem("observacao");
  }

  const [cliente, setCliente] = useState({
    id: ofertData.clientId || "",
    nome: ofertData.clientName || "",
    email: ofertData.clientEmail || "",
  });

  const [pessoa, setPessoa] = useState({
    id: "",
    nome: "",
  });
  //trocar o user e pass do state email pra o do vendedor selecionado
  //trocar o vendedor na empresa para o vendedor selecionado
  const [oferta, setOferta] = useState({
    id: 0,
    usuario_id: userId || 0,
    empresa_id: empresaId || 0,
    pessoa_id: 0,
    valor_oferta: 0,
    desconto: 0,
    valor_total: 0,
    status: "1",
    data_oferta: null,
    prazo: 0,
    subject: ofertData.assunto,
    observacao: ofertData.observacao,
    chapas: [],
    email: {},
    cliente: {
      id: ofertData.clientId,
      nome: ofertData.clientName,
      email: ofertData.clientEmail,
    },
    empresa: {},
    reservarBundles: false,
    unidade: "ft²",
  });

  const [ofertaSend, setOfertaSend] = useState(false);

  const [emailLoaded, setEmailLoaded] = useState(false);

  const [email, setEmail] = useState({
    email: "",
    host: "",
    port: "",
    user: "",
    pass: "",
    security: false,
  });

  function handleChangeUnidade(event) {
    const { value } = event.target;
    //console.log(checked)
    setOferta((prevState) => {
      return {
        ...prevState,
        unidade: value,
      };
    });
  }

  function getTotal(items) {
    return items?.map(({ precom2 }) => precom2).reduce((sum, i) => sum + i, 0);
  }

  async function getPessoas() {
    setPessoasLoaded(false);
    try {
      await api.get("/personsEmpresa/" + userData.empresa_id).then((res) => {
        setPessoas(res.data);
      });
    } catch (e) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
      setChapasLoaded(true);
    }

    setPessoasLoaded(true);
  }

  function closeSnackbar() {
    setOpenSnackbar(false);
  }
  // console.log(userData);
  async function getEmailInfo() {
    try {
      await api.get("/empresa/" + empresaId).then((res) => {
        setEmail({
          host: res.data.smtp_host,
          port: res.data.smtp_port,
          user: userData.SMTP_USER,
          pass: userData.SMTP_PASS,
          security: res.data.smtp_security,
        });
        setOferta((prevState) => {
          return {
            ...prevState,
            email: {
              host: res.data.smtp_host,
              port: res.data.smtp_port,
              user: userData.SMTP_USER,
              pass: userData.SMTP_PASS,
              security: res.data.smtp_security,
            },
            empresa: {
              nome: res.data.nome,
              vendedor: userData.nome,
              email: userData.email,
            },
          };
        });
        // console.log(oferta)
        setEmailLoaded(true);
      });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  function DataAtual() {
    var dataAtual = "";
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    dataAtual = ano + "." + mes + "." + dia;
    //console.log(dataAtual);
    setOferta((prevState) => {
      return {
        ...prevState,
        data_oferta: dataAtual,
      };
    });
  }

  async function sendEmail(emailObj, subject, message) {
    //console.log(emailObj)

    try {
      await api
        .post("/oferta/sendemail/", {
          ...emailObj,
          subject: subject,
          message: message,
        })
        .then((res) => {
          setSnackbarKind("success");
          setSnackbarMessage("Oferta enviada com sucesso.");
          setOpenSnackbar(true);

          setTimeout(() => {
            setCloseModalOffert();
          }, 2000);
        });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR + " - " + err);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  // async function incluiNaReserva() {
  //   var incluir = 'true';

  //   const bundlesObj = oferta.bundles;
  //   console.log(`/ofertabundles/${userData.id}?incluir=`+incluir, {bundlesObj});

  //   api.put(`/ofertabundles/${userData.id}?incluir=`+incluir,{bundlesObj})
  //   .then((res)=>{
  //     setSnackbarKind("success");
  //     setSnackbarMessage("Reserva incluida com sucesso!");
  //     setOpenSnackbar(true);
  //   })
  //   .catch((error)=>{
  //     setSnackbarMessage(error.response.data);
  //     setSnackbarKind("error");
  //     setOpenSnackbar(true);
  //   })
  // }

  async function findAdmins() {
    try {
      api.get("/admin/users").then((res) => {
        setVendedores(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function atualizaState() {
    let novaOferta = oferta;
    novaOferta.empresa = {
      vendedor: vendedorSelected.nome,
      email: vendedorSelected.email,
      nome: novaOferta.empresa.nome,
    };
    novaOferta.email = {
      user: vendedorSelected.SMTP_USER,
      pass: vendedorSelected.SMTP_PASS,
      host: novaOferta.email.host,
      port: novaOferta.email.port,
      security: novaOferta.email.security,
    };
    setOferta(novaOferta);
    // console.log(oferta)
  }

  // console.log(email)
  async function handlefinishOffert(event) {
    setOfferLoading(true);
    event.preventDefault();

    if (oferta.chapas.length <= 0) {
      setSnackbarMessage("Favor selecionar ao menos um bundle.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!oferta.email) {
      setSnackbarMessage("Favor informar um E-Mail.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!oferta.data_oferta) {
      setSnackbarMessage("Favor informar a data da oferta.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }

    try {
      // console.log(oferta)
      if (userData.userRole === "assistente") {
        await atualizaState();
      }
      await api.post("/ofertass/create/chapa", { oferta }).then(async (ress) => {
        //   //console.log(ress);

        localStorage.removeItem("ornatec_Chapas");
        removeLocalOfert();
        setSnackbarKind("success");
        setSnackbarMessage("Oferta enviada com sucesso.");
        setOpenSnackbar(true);
        setOfferLoading(false);
        console.log(ress);
        const ofertaPessoaObj = {
          oferta_id: ress.data.novaOferta.id,
          pessoa_id: oferta.pessoa_id === 0 ? 2 : oferta.pessoa_id,
          empresa_id: oferta.empresa_id,
          email_cliente: oferta.cliente.email,
          subject: oferta.subject,
          mensagem: oferta.observacao,
        };
        // setFinishOffer(true);

        await api.post("/ofertaPessoa", ofertaPessoaObj).then((data) => {
          console.log(data);
        });
        setTimeout(() => {
          setCloseModalOffert();
          // setFinishOffer(false);
        }, 2000);
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        setSnackbarMessage(`Erro ao enviar email motivo: Login inválido`);
        setSnackbarKind("error");
        setOpenSnackbar(true);
        setOfferLoading(false);
        setTimeout(() => {
          setCloseModalOffert();
          // setFinishOffer(false);
        }, 2000);
      } else {
        setSnackbarMessage(`Erro ao enviar email motivo: ${error}`);
        setSnackbarKind("error");
        setOpenSnackbar(true);
        setOfferLoading(false);
        setTimeout(() => {
          setCloseModalOffert();
          // setFinishOffer(false);
        }, 2000);
      }
    }
  }

  function handleCheckReserva(event) {
    const { checked } = event.target;
    //console.log(checked)
    setOferta((prevState) => {
      return {
        ...prevState,
        reservarBundles: checked,
      };
    });
  }

  function handleChangePrice(position, field, value) {
    const updateChapaPrice = oferta.chapas?.map((item, index) => {
      if (index === position) {
        return { ...item, [field]: value };
      }
      return item;
    });

    // console.log("Bundle Update", updateChapaPrice)

    setOferta((prevState) => {
      return {
        ...prevState,
        chapas: updateChapaPrice,
      };
    });
  }
  // console.log("oferta", oferta);
  function handleRemoveBundle(position) {
    const arrayToDelete = oferta.chapas;

    const updatedChapas = arrayToDelete.splice(position, 1);

    setOferta((previousState) => {
      return {
        ...previousState,
        chapas: arrayToDelete,
      };
    });
  }

  function handleChangeAllPrices(params) {
    // let copy = oferta.bundles;
    console.log("params", params);
    const updateBundlePrice = selectedChapas?.map((item) => {
      item.preco = params.row?.Price;
      return item;
    });
    // copy.forEach((item)=>{
    //   item.preco = value;
    // })
    setSelectedChapas(() => updateBundlePrice);
    console.log("updated", updateBundlePrice);
    // localStorage.setItem("ornatec_bundles", JSON.stringify(updateBundlePrice));

    handleRowsChange();
  }

  // console.log('oferta', oferta)

  function handleSubject(event) {
    localStorage.setItem("assunto", event.target.value);
    setOferta((prevState) => {
      return {
        ...prevState,
        subject: event.target.value,
      };
    });
    // console.log(oferta);
  }

  function changeSelectedAdmin(id) {
    console.log(id);
  }

  function handleChangePerson(id) {
    var objCliente = pessoas.find((sel) => parseInt(sel.id) === parseInt(id));
    localStorage.setItem("id_cliente", objCliente.id);
    localStorage.setItem("name_cliente", objCliente.nome);
    localStorage.setItem("email_cliente", objCliente.email);
    // handleCliente(objCliente).then(() => {
    //   console.log(cliente)
    // })

    setCliente({
      id: objCliente.id,
      nome: objCliente.nome,
      email: objCliente.email,
    });

    setEmail((prevState) => {
      return {
        ...prevState,
        email: `${objCliente.nome}<${objCliente.email}>`,
      };
    });

    setOferta((prevState) => {
      return {
        ...prevState,
        pessoa_id: id,
        cliente: {
          email: `${objCliente.nome}<${objCliente.email}>`,
          id: objCliente.id,
          nome: objCliente.nome,
        },
      };
    });
  }

  const handleRemoveBundlenew = (event) => {
    // console.log(event);
    const { field, id } = event;
    if (field === "exclude") {
      setTimeout(() => {
        setRowsOffer((prevState) => prevState.filter((row) => row.id !== id));
        setRowsOrder((prevState) => prevState.filter((row) => row.id !== id));
        setOferta((previousState) => {
          return {
            ...previousState,
            chapas: previousState.bundles.filter((row) => row.id !== id),
          };
        });
        localStorage.setItem("ornatec_Chapas", JSON.stringify(oferta.chapas));
      });
    }
    if (field === "chapas") {
      setSelectedChapas(oferta.chapas.find((bundle) => bundle.id === id).chapas);
      setOpen(true);
    }
    return;
  };

  const handleRowsChange = () => {
    let bundlesRowOffer = [];
    let bundlesRowOrder = [];
    const chapasSelected = [...oferta.chapas];
    const bundlesOffer = selectedChapas.map((bundle, idx) => {
      bundlesRowOffer.push({
        id: bundle.id,
        exclude: "",
        block: bundle.bloco,
        slab: bundle.numerochapa,
        Material: bundle.nomematerialingles,
        Processo: ProcessoValue(bundle.processo),
        Size: `${bundle.comprimento_liquido}m x ${bundle.altura_liquida}m /
        ${(bundle.comprimento_liquido * 39.3701).toPrecision(5)}" x"
        ${(bundle.altura_liquida * 39.3701).toPrecision(4)}"`,
        Thick: bundle.espessura,
        Price: bundle.preco,
        chapasData: bundle.chapas,
      });
    });

    setRowsOffer(bundlesRowOffer);

    const quantidadeLiquida = (chapas) => {
      let quantity = 0;
      for (let i = 0; i < chapas.length; i++) {
        quantity += chapas[i].altura_liquida * chapas[i].comprimento_liquido;
      }
      return quantity.toPrecision(4);
    };

    const quantidadeBruta = (chapas) => {
      let quantity = 0;
      for (let i = 0; i < chapas.length; i++) {
        quantity += chapas[i].altura_bruta * chapas[i].comprimento_bruto;
      }
      return quantity.toPrecision(4);
    };

    const bundlesOrder = selectedChapas.map((bundle, idx, bundles) => {
      bundlesRowOrder.push({
        id: bundle.id,
        chapas: bundle.total_chapas,
        Bundle: bundle.chapacliente_id,
        quantidade: bundle.totalm2,
        Price: bundle.preco,
        Material: bundle.nomematerialingles,
        bruto: bundle.altura_bruta * bundle.comprimento_bruto,
        desconto: 0,
        Liquido: bundle.altura_liquida * bundle.comprimento_liquido,
        chapasData: bundle.chapas,
      });
    });
    setRowsOrder(bundlesRowOrder);
  };

  const handleCellEditCommit = ({ id, field, value }) => {
    console.log(id, field, value);
    if (field === "Price") {
      console.log("rowsOrder", rowsOrder);
      const bundlesUpdated = rowsOrder.map((item) => {
        if (item.id === id) {
          item.Price = parseInt(value);
        }
        return item;
      });

      console.log("rowsOrderUpdated", bundlesUpdated);
      setRowsOrder(bundlesUpdated);
      const bundlesUpdatedOffer = rowsOffer.map((item) => {
        if (item.id === id) {
          item.Price = parseInt(value);
        }
        return item;
      });
      console.log("rowsOfferUpdated", bundlesUpdatedOffer);
      setRowsOffer(bundlesUpdatedOffer);

      const updateBundlePrice = oferta.chapas?.map((item) => {
        if (item.id === id) {
          item.preco = value;
        }
        return item;
      });

      console.log(updateBundlePrice);
      setOferta((oferta) => {
        return {
          ...oferta,
          bundles: [...updateBundlePrice],
        };
      });
    }
  };

  const handleOrderChange = () => {
    setIsOrder(!isOrder);
  };
  useEffect(() => {
    handleRowsChange();
    console.log("rowsOrder", rowsOrder);
    console.log("rowsOffer", rowsOffer);
  }, [isOrder]);

  useEffect(() => {
    setLoading(true);
    setChapasLoaded(false);

    if (oferta.chapas.length === 0 && localStorage.getItem("ornatec_Chapas")) {
      // const chapas = JSON.parse(localStorage.getItem("ornatec_Chapas"));
      // setOferta((oferta) => {
      //   return {
      //     ...oferta,
      //     bundles: JSON.parse(localStorage.getItem("ornatec_Chapas"))
      //   }
      // })

      // chapas.forEach((item) => {
      //   item.preco = ofertData.bundlePrice || "0";
      //   return item;
      // });

      // setChapas(() => chapas);
      setOferta((oferta) => {
        return {
          ...oferta,
          chapas: [...selectedChapas],
        };
      });

      // let copy = oferta.bundles;

      // copy.forEach((item) => {
      //   item.preco = ofertData.bundlePrice || 0
      // })
      // console.log(copy)
      // setOferta((oferta) => {
      //   return {
      //     ...oferta,
      //     bundles: copy
      //   }
      // })

      console.log("Entrou");
    }

    // console.log("Storage", JSON.parse(localStorage.getItem("ornatec_Chapas")))

    setChapasLoaded(true);

    getEmailInfo();

    getPessoas();

    findAdmins();

    DataAtual();
    setLoading(false);
    if (chapasLoaded && emailLoaded) {
      setOferta((prevState) => {
        // return { ...prevState, bundles: bundles, valor_oferta: total };
        return {
          ...prevState,
          email: email,
          cliente: cliente,
          valor_oferta: total,
        };
      });
    }
  }, [chapasLoaded, emailLoaded, cliente]);

  if (loading) {
    return (
      <Grid style={{ paddingTop: "35%", paddingLeft: "50%" }} item xs={12}>
        {/* <CircularProgress /> */}
        <Loading />
      </Grid>
    );
  }

  return (
    <form>
      <Dialog
        open={true}
        onClose={setCloseModalOffert}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Title>{isOrder ? "Finalizando Pedido" : "Finalizando Oferta"}</Title>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <FormControlLabel
              label="Pedido"
              control={<Checkbox onChange={handleOrderChange} disabled />}
            />
            <Grid item xs={12} align="center">
              <SubTittle>Dados da Oferta</SubTittle>
            </Grid>
          </Grid>
          {offerLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Loading />
            </div>
          ) : (
            <>
              <Grid alignItems="center">
                {userData.userRole === "assistente" ? (
                  <Grid item sm={12}>
                    <Autocomplete
                      options={vendedores}
                      value={vendedorSelected}
                      label="selecione o vendedor"
                      getOptionLabel={(option) => option.nome}
                      style={{ width: 250 }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setVendedorSelected(newValue);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Selecione o vendedor" variant="standard" />
                      )}
                    />
                  </Grid>
                ) : null}
                <Grid item sm={12}>
                  <ListPerson
                    label="Selecione o Cliente"
                    id="pessoa_id"
                    name="pessoa_id"
                    defaultValue={pessoa}
                    personCurrent={pessoa}
                    value={oferta.pessoa_id}
                    pessoas={pessoas}
                    handleChangePerson={handleChangePerson}
                  ></ListPerson>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    label="Selecione o E-mail"
                    id="email"
                    name="email"
                    fullWidth
                    value={ofertData.clientEmail}
                    onChange={(event) => {
                      localStorage.setItem("email_cliente", event.target.value);
                      setCliente((prevState) => {
                        return {
                          ...prevState,
                          email: event.target.value,
                        };
                      });
                      setEmail((prevState) => {
                        return {
                          ...prevState,
                          email: `${cliente.nome}<${event.target.value}>`,
                        };
                      });
                      setOferta((prevState) => {
                        return {
                          ...prevState,
                          email: {
                            ...prevState.email,
                            email: `${cliente.nome}<${event.target.value}>`,
                          },
                        };
                      });
                    }}
                  ></TextField>
                </Grid>
                <Grid container style={{ width: "100%" }}>
                  <Grid item sm={10}>
                    {/* <KeyboardDatePicker
                autoOk
                disableFuture
                id="data_oferta"
                label="Data da Oferta"
                format="dd/MM/yyyy"
                value={oferta.data_oferta}
                onChange={(date) => {
                  setOferta((prevState) => {
                    return {
                      ...prevState,
                      data_oferta: date,
                    };
                  });
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputLabelProps={{ shrink: true }}
              /> */}
                    <TextField
                      label="Subject"
                      id="subject"
                      name="subject"
                      fullWidth
                      value={ofertData.assunto}
                      onChange={handleSubject}
                      /*{(event) => {
                  const {subject , value} = event.target;      
                  setEmail({...email,[subject]:value}) 
                  console.log(value)
                  console.log(email)                 
                }}*/
                    ></TextField>
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                    sm={2}
                  >
                    <FormControlLabel
                      control={
                        consultaPedido === true ? (
                          <Checkbox
                            disabled
                            name="reservado"
                            checked={oferta.reservarBundles}
                            value={oferta.reservarBundles}
                            onChange={handleCheckReserva}
                          />
                        ) : (
                          <Checkbox
                            name="reservado"
                            checked={oferta.reservarBundles}
                            value={oferta.reservarBundles}
                            onChange={handleCheckReserva}
                          />
                        )
                      }
                      label="Reservar Chapas"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item style={{ paddingTop: "15px", width: "100%" }} sm={12}>
                  <TextField
                    id="observacao"
                    label="Message"
                    fullWidth
                    multiline
                    rows={12}
                    value={ofertData.observacao}
                    variant="outlined"
                    onChange={(event) => {
                      localStorage.setItem("observacao", event.target.value);
                      setOferta((prevState) => {
                        return {
                          ...prevState,
                          observacao: event.target.value,
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid container style={{ width: "70vw" }} spacing={1}>
            {chapasLoaded && oferta.chapas.length > 0 ? (
              <Grid style={{ height: 300, width: "100%", paddingTop: "20px" }}>
                <DataGrid
                  onCellClick={handleRemoveBundlenew}
                  columns={isOrder ? columnsOrder : columnsOffer}
                  rows={isOrder ? rowsOrder : rowsOffer}
                  onCellEditCommit={handleCellEditCommit}
                />
              </Grid>
            ) : (
              <Grid item sm={12}>
                Nenhuma chapa selecionada!
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCloseModalOffert();
              removeLocalOfert();
            }}
            color="primary"
            variant="contained"
          >
            Cancelar
          </Button>
          <Button onClick={setCloseModalOffert} color="primary" variant="contained">
            Continuar Pesquisando
          </Button>
          <Button
            onClick={handlefinishOffert}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
    </form>
  );
}
