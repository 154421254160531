import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams, Switch, useHistory } from "react-router";
import TopBar from "../../components/AppBar";
import Lightbox from "../../components/Oferta/Bundle/Lightbox";
import { SelectedBundlesContext } from "../../context/SelectedBundles.provider";
import { useAuth } from "../../context/User.provider";
import Snackbar from "../../components/SnackBar";
import api from "../../Services/api";

import {
  aceitarOferta,
  createNotificacao,
  getBundlesFromOffer,
  getSpecificBundle,
  getSpecificOferr,
  saveClienteExterno,
  incluirNaReserva,
} from "../../Services/api";
import ItemCard from "../../components/Oferta/Bundle/ItemCard";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "10%",
    display: "flex",
    width: 300,
    widtH: "100vw",
  },
  media: {
    paddingTop: 10,
    textAlign: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  card: {
    marginLeft: 5,
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    minHeight: 420,
  },
  graph: {
    color: "blue",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 5,
    paddingLeft: 10,
    width: "100%",
  },
  buttons: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  check: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "25px",
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "@media (min-width: 630px) and (max-width: 680px)": {
    root: { width: 325 },
  },
  "@media (min-width: 930px) and (max-width: 980px)": {
    root: { width: 320 },
  },
  "@media (min-width: 1230px) and (max-width: 1280px)": {
    root: { width: 315 },
  },
  "@media (min-width: 1530px) and (max-width: 1580px)": {
    root: { width: 310 },
  },
  "@media (min-width: 1830px) and (max-width: 1880px)": {
    root: { width: 310 },
  },
  "@media (min-width: 2130px) and (max-width: 2180px)": {
    root: { width: 310 },
  },
  "@media (min-width: 2430px) and (max-width: 2480px)": {
    root: { width: 310 },
  },
}));

const OrderPage = () => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [oferta, setOferta] = useState();
  const [bundles, setBundles] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  const { selectedBundles, setSelectedBundles } = useContext(
    SelectedBundlesContext
  );
  const [consultaPedido, setConsultaPedido] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const history = useHistory();
  const { idPedido, token, empresaId } = useParams();

  const { userData } = useAuth();
  function closeSnackbar() {
    setOpenSnackbar(false);
  }
  const handleAcceptOffer = async () => {
    try {
      const client = await saveClienteExterno({
        nome: clientName,
        email: clientEmail,
      });
      const cliente_id = client.data[0];

      const bundleAlreadyFromClient = selectedBundles.filter((bundle) => {
        return bundle.cliente_externo_id !== `${cliente_id}`;
      });
      // console.log(bundleAlreadyFromClient);
      if (bundleAlreadyFromClient.length === 0) {
        setSnackbarMessage(`Error, these bundles you already accepted`);
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      const notificationResponse = await handleCreateNotification(cliente_id);
      if (notificationResponse.message === "Notification already exists") {
        setSnackbarMessage(`Error, Maybe you already accepted this offer`);
        setSnackbarKind("error");
        setOpenSnackbar(true);
        return;
      }
      await aceitarOferta(idPedido, bundleAlreadyFromClient, cliente_id);

      var incluir = "true";
      try {
        //console.log(bundleAlreadyFromClient);
        localStorage.setItem("token", oferta.offer_token);
        api.defaults.headers.Authorization = `Bearer ${oferta.offer_token}`;
        const res = await incluirNaReserva(
          oferta.usuario_id,
          incluir,
          bundleAlreadyFromClient,
          oferta.offer_token
        );
        if (res.data) {
          //console.log("Estou tentando reservar", res.data);
          setSnackbarKind("success");
          setSnackbarMessage(res.data?.message);
          setOpenSnackbar(true);
        } else {
          console.log("res sem data", res);
        }
      } catch (err) {
        //localStorage.removeItem("token");
        // console.log("erro", err.message);
        if (err.message === "Request failed with status code 406") {
          setSnackbarMessage(
            `Os Bundles ${bundleAlreadyFromClient.map(
              (bundle) => bundle.numero_bundle_cliente
            )} não foram reservados!`
          );
          setSnackbarKind("error");
          setOpenSnackbar(true);
          return;
        }
        if (err.message === "Request failed with status code 422") {
          setSnackbarMessage("Selecione pelo menos um bundle");
          setSnackbarKind("error");
          setOpenSnackbar(true);
          return;
        }
        setSnackbarMessage(err.message);
        setSnackbarKind("error");
        setOpenSnackbar(true);
      }
      //localStorage.removeItem("token");
      setSnackbarMessage(
        `Offer Accepted Successfully, wait for the seller to confirm`
      );
      setSnackbarKind("success");
      setOpenSnackbar(true);
      setTimeout(() => {
        setSelectedBundles([]);
        handleCloseModal();
        setBundles([]);
      }, 1500);
    } catch (err) {
      setSnackbarMessage(`Error accepting offer, try again later`);
      setSnackbarKind("error");
      setOpenSnackbar(true);
      console.log(err);
    }
  };

  const handleCreateNotification = async (clientId) => {
    console.log(oferta);
    const notification = {
      vendedor_id: oferta.usuario_id,
      mensagem: `O cliente ${clientName} de email ${clientEmail}, da Oferta ${idPedido} aceitou a oferta com os bundles ${selectedBundles.map(
        (b) => b.numero_bundle_cliente
      )}`,
      cliente_externo_id: clientId,
      empresa_id: oferta.empresa_id,
    };
    try {
      const response = await createNotificacao(notification);
      return response.data;
    } catch (err) {
      setSnackbarMessage(`Error, Maybe you already accepted this offer`);
      setSnackbarKind("error");
      setOpenSnackbar(true);
      return err;
    }
  };

  function showSlides(n) {
    setSlideIndex(() => n);

    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("demo");
    var captionText = document.getElementById("caption");
    if (!slides || !dots || !captionText) return;
    if (isOpen === false) return;

    if (n > slides.length) {
      setSlideIndex(() => 1);
    }
    if (n < 1) {
      setSlideIndex(() => slides.length);
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
    captionText.innerHTML = dots[slideIndex - 1].alt;
  }

  const createNotification = async () => {
    try {
      const notification = {
        vendedor_id: oferta.usuario_id,
        mensagem: `O cliente de email ${clientEmail} da Oferta ${idPedido} visualizou a oferta`,
        cliente_externo_id: "",
        visualizada: false,
      };
      const notificationCreated = await createNotificacao(notification);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { data } = await getSpecificOferr(idPedido, empresaId);
      setOferta(() => data);
      /* if (token !== data.offer_token) {
        console.log("diferente");
        history.push("/");
        return;
      }*/
      const response = await getBundlesFromOffer(idPedido);
      let bundlesArray = [];
      const bundles = Promise.all(
        await response.data.map(async (b) => {
          const { data } = await getSpecificBundle(b.bundle_id);
          //     console.log(data);
          bundlesArray.push(...data);
          return data;
        })
      )
        .then(() => {
          bundlesArray.sort(function (a, b) {
            return b.id - a.id;
          });
          setBundles(bundlesArray);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getData();
  }, []);

  // useEffect(() => {
  //   // createNotification();
  // }, [oferta]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  async function handleChangeCheck(event) {
    const isChecked = event.target.checked;
    // alert("Solta 1")
    const bundle = JSON.parse(event.target.value);
    if (isChecked) {
      // setSelectedChapas((prevState) => {
      //   return [...prevState, bundle];
      // });
      setSelectedBundles((prevState) => {
        return [...prevState, bundle];
      });
    } else {
      setSelectedBundles((prevState) => {
        // const selected = prevState.indexOf(bundle)
        const selected = prevState.filter((item) => {
          return item.id !== bundle.id;
        });
        return selected;
      });
      // setSelectedChapas((prevState) => {
      //   // const selected = prevState.indexOf(bundle)
      //   const selected = prevState.filter((item) => {
      //     return item !== bundle;
      //   });
      //   return selected;
      // });
    }
  }

  return (
    <>
      {/* <CssBaseline />

      <TopBar></TopBar> */}
      <div style={{ paddingTop: "4rem" }}>
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Offer {idPedido}
        </h1>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {bundles &&
            bundles.map((bundle, index) => {
              return (
                <Grid>
                  <Grid item xs={12} key={index} sm={3}>
                    <ItemCard
                      allBundles={bundles}
                      bundle={bundle}
                      index={bundle.id}
                      slideIndex={index}
                      selectedBundles={selectedBundles}
                      handleChangeCheck={handleChangeCheck}
                      consultaPedido={consultaPedido}
                      userId={userData.id}
                      setIsOpen={setIsOpen}
                      showSlides={showSlides}
                    />
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
        <Grid
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsModalOpen(true)}
          >
            Accept Bundles
          </Button>
        </Grid>
      </div>

      {isOpen && (
        <Lightbox
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          slideIndex={slideIndex}
          setSlideIndex={setSlideIndex}
          showSlides={showSlides}
          bundles={bundles}
          selectedBundles={selectedBundles}
          setSelectedBundles={setSelectedBundles}
          userId={userData.id}
        />
      )}
      {isModalOpen && (
        <Dialog open={isModalOpen} onClose={handleCloseModal}>
          <DialogTitle>Accept Offer Data</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To accept the offer please enter your name and your e-mail
            </DialogContentText>
            <TextField
              autoFocus
              id="name"
              onChange={(event) => setClientName(event.target.value)}
              label="Your Name"
              type="text"
              fullWidth
            />
            <TextField
              id="email"
              onChange={(event) => setClientEmail(event.target.value)}
              label="Email Address"
              type="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAcceptOffer} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
    </>
  );
};

export default OrderPage;
