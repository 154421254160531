import React, { useEffect, useState, useContext } from "react";

import api, {
  createPedido,
  getPessoaById,
  sendOrderMail,
} from "../../../Services/api";
import ProcessoValue from "../../Chapa/Processo";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Title from "../../Types/Tittle";
import SubTittle from "../../Types/SubTittle";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@material-ui/core/Grid";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Snackbar from "../../../components/SnackBar/index";
import { makeStyles } from "@material-ui/core/styles";

import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/RemoveCircleOutline";

import PriceFormatCustom from "../../../functions/PriceFormatCustom";

import ListPerson from "../../Settings/ListPersonOfertaChapa";
import { Checkbox, CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
// import Loading from "../../Loading/Loading";
import { useAuth } from "../../../context/User.provider";
import ModalDetails from "./ModalDetails";
import { SelectedBundlesContext } from "../../../context/SelectedBundles.provider";
import LinearProgress from "@material-ui/core/LinearProgress";
import EmailEditor from "../../EmailEditor/EmailEditor";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export default function ModalOffert({
  setCloseModalOffert,
  getChapa,
  empresaId,
  setFinishOffer,
  userId,
  consultaPedido,
}) {
  const [total, setTotal] = useState(0);
  const classes = useRowStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKind, setSnackbarKind] = useState("");
  const [loading, setLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);

  const [ofertaId, setOfertaId] = useState("");

  const [chapas, setChapas] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [chapasLoaded, setChapasLoaded] = useState(false);

  const [reservaCheckbox, setReservaCheckbox] = useState(false);

  const [updatedBundlePrice, setUpdatedBundlePrice] = useState([]);
  // const [priceBundle, setPriceBundle] = useState([{
  //   bundleId : 0,
  //   price : 0
  // }]);

  const [priceBundle, setPriceBundle] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [clientesLoaded, setClientesLoaded] = useState(false);

  const [vendedores, setVendedores] = useState([]);
  const [vendedorSelected, setVendedorSelected] = useState();
  const [pessoas, setPessoas] = useState([]);
  const [pessoasLoaded, setPessoasLoaded] = useState(false);
  const [bundlesLoaded, setBundlesLoaded] = useState(false);

  const [isOrder, setIsOrder] = useState(false);
  const [pessoaData, setPessoaData] = useState();
  const [columnsOffer, setColumnsOffer] = useState([
    {
      field: "exclude",
      headerName: "Exclude",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
        >
          Delete
        </Button>
      ),
    },
    { field: "block", headerName: "Block", width: 120 },
    { field: "bundle", headerName: "Bundle", width: 120 },
    { field: "Material", headerName: "Material", width: 150 },
    { field: "Processo", headerName: "Processo", width: 150 },
    { field: "Size", headerName: "Size", width: 200 },
    { field: "Thick", headerName: "Thick", width: 100 },
    { field: "Slabs", headerName: "Slabs", width: 200 },
    { field: "Price", headerName: "Price", width: 120, editable: true },
    {
      field: "setPrice",
      headerName: "SetPrice",
      width: 120,
      renderCell: (params) => (
        <Button
          onClick={() => handleChangeAllPrices(params)}
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
        >
          Aplly All
        </Button>
      ),
    },
  ]);
  const [columnsOrder, setColumnsOrder] = useState([
    { field: "chapas", headerName: "Chapas", width: 200 },
    { field: "Material", headerName: "Material", width: 200 },
    { field: "Bundle", headerName: "Bundle", width: 200 },
    { field: "quantidade", headerName: "Quantidade", width: 200 },
    {
      field: "Price",
      headerName: "Price",
      width: 100,
      editable: true,
      renderCell: () => {
        <input placeholder="Preço.."></input>;
      },
    },
    {
      field: "setPrice",
      headerName: "SetPrice",
      width: 120,
      renderCell: (params) => (
        <Button
          onClick={(e) => handleChangeAllPrices(params)}
          variant="contained"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
        >
          Apply All
        </Button>
      ),
    },
    // { field: "bruto", headerName: "Total Bruto R$", width: 200 },
    // { field: "desconto", headerName: "Desconto R$", width: 200 },
    // { field: "Liquido", headerName: "Total Liquido R$", width: 200 },
  ]);
  const [rowsOffer, setRowsOffer] = useState([]);
  const [rowsOrder, setRowsOrder] = useState([]);
  const [chapasSelected, setChapasSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  // const [userData] = useContext(UserContext);
  const { userData } = useAuth();
  const { selectedBundles, setSelectedBundles } = useContext(
    SelectedBundlesContext
  );
  const ofertData = {
    clientEmail: localStorage.getItem("email_cliente") || "",
    clientId: localStorage.getItem("id_cliente") || "",
    clientName: localStorage.getItem("name_cliente") || "",
    bundlePrice: localStorage.getItem("price_bundle") || 0,
    assunto: localStorage.getItem("assunto") || "",
    observacao: localStorage.getItem("observacao") || "",
  };

  function removeLocalOfert() {
    localStorage.removeItem("email_cliente");
    localStorage.removeItem("id_cliente");
    localStorage.removeItem("name_cliente");
    localStorage.removeItem("price_bundle");
    localStorage.removeItem("assunto");
    localStorage.removeItem("observacao");
  }

  const [cliente, setCliente] = useState({
    id: ofertData.clientId || "",
    nome: ofertData.clientName || "",
    email: ofertData.clientEmail || "",
  });

  const [pessoa, setPessoa] = useState({
    id: "",
    nome: "",
  });
  //trocar o user e pass do state email pra o do vendedor selecionado
  //trocar o vendedor na empresa para o vendedor selecionado
  const [oferta, setOferta] = useState({
    id: 0,
    usuario_id: userId || 0,
    empresa_id: empresaId || 0,
    pessoa_id: 0,
    valor_oferta: 0,
    desconto: 0,
    valor_total: 0,
    status: "1",
    data_oferta: null,
    prazo: 0,
    subject: ofertData.assunto,
    observacao: ofertData.observacao,
    bundles: [],
    email: {},
    cliente: {
      id: ofertData.clientId,
      nome: ofertData.clientName,
      email: ofertData.clientEmail,
    },
    empresa: {},
    reservarBundles: false,
    unidade: "ft²",
  });

  const [ofertaSend, setOfertaSend] = useState(false);

  const [emailLoaded, setEmailLoaded] = useState(false);

  const [email, setEmail] = useState({
    email: "",
    host: "",
    port: "",
    user: "",
    pass: "",
    security: false,
  });

  const [observacao, setObservacao] = useState();

  function handleChangeUnidade(event) {
    const { value } = event.target;
    //console.log(checked)
    setOferta((prevState) => {
      return {
        ...prevState,
        unidade: value,
      };
    });
  }

  const handleRemoveBundlenew = (event) => {
    // console.log(event);
    const { field, id } = event;
    if (field === "exclude") {
      setTimeout(() => {
        setRowsOffer((prevState) => prevState.filter((row) => row.id !== id));
        setRowsOrder((prevState) => prevState.filter((row) => row.id !== id));
        setOferta((previousState) => {
          return {
            ...previousState,
            bundles: previousState.bundles.filter((row) => row.id !== id),
          };
        });
        localStorage.setItem("ornatec_bundles", JSON.stringify(oferta.bundles));
      });
    }
    if (field === "chapas") {
      setChapasSelected(
        oferta.bundles.find((bundle) => bundle.id === id).chapas
      );
      setOpen(true);
    }
    return;
  };

  function getTotal(items) {
    return items?.map(({ precom2 }) => precom2).reduce((sum, i) => sum + i, 0);
  }

  async function getPessoas() {
    setPessoasLoaded(false);
    try {
      await api.get("/personsEmpresa/" + userData.empresa_id).then((res) => {
        setPessoas(res.data);
      });
    } catch (e) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
      setChapasLoaded(false);
    }

    setPessoasLoaded(true);
  }

  function closeSnackbar() {
    setOpenSnackbar(false);
  }
  // console.log(userData);
  async function getEmailInfo() {
    try {
      await api.get("/empresa/" + empresaId).then((res) => {
        setEmail({
          host: res.data.smtp_host,
          port: res.data.smtp_port,
          user: userData.SMTP_USER,
          pass: userData.SMTP_PASS,
          security: res.data.smtp_security,
        });
        setOferta((prevState) => {
          return {
            ...prevState,
            email: {
              host: res.data.smtp_host,
              port: res.data.smtp_port,
              user: userData.SMTP_USER,
              pass: userData.SMTP_PASS,
              security: res.data.smtp_security,
            },
            empresa: {
              nome: res.data.nome,
              vendedor: userData.nome,
              email: userData.email,
            },
          };
        });
        // console.log(oferta)
        setEmailLoaded(true);
      });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  function DataAtual() {
    var dataAtual = "";
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, "0");
    var mes = String(data.getMonth() + 1).padStart(2, "0");
    var ano = data.getFullYear();
    dataAtual = ano + "." + mes + "." + dia;
    //console.log(dataAtual);
    setOferta((prevState) => {
      return {
        ...prevState,
        data_oferta: dataAtual,
      };
    });
  }

  async function sendEmail(emailObj, subject, message) {
    //console.log(emailObj)

    try {
      await api
        .post("/oferta/sendemail/", {
          ...emailObj,
          subject: subject,
          message: message,
        })
        .then((res) => {
          setSnackbarKind("success");
          setSnackbarMessage("Oferta enviada com sucesso.");
          setOpenSnackbar(true);

          setTimeout(() => {
            setCloseModalOffert();
          }, 2000);
        });
    } catch (err) {
      setSnackbarMessage(process.env.REACT_APP_MESSAGE_ERROR + " - " + err);
      setSnackbarKind("error");
      setOpenSnackbar(true);
    }
  }

  // async function incluiNaReserva() {
  //   var incluir = 'true';

  //   const bundlesObj = oferta.bundles;
  //   console.log(`/ofertabundles/${userData.id}?incluir=`+incluir, {bundlesObj});

  //   api.put(`/ofertabundles/${userData.id}?incluir=`+incluir,{bundlesObj})
  //   .then((res)=>{
  //     setSnackbarKind("success");
  //     setSnackbarMessage("Reserva incluida com sucesso!");
  //     setOpenSnackbar(true);
  //   })
  //   .catch((error)=>{
  //     setSnackbarMessage(error.response.data);
  //     setSnackbarKind("error");
  //     setOpenSnackbar(true);
  //   })
  // }

  async function findAdmins() {
    try {
      api.get("/admin/users").then((res) => {
        setVendedores(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function atualizaState() {
    let novaOferta = oferta;
    novaOferta.empresa = {
      vendedor: vendedorSelected.nome,
      email: vendedorSelected.email,
      nome: novaOferta.empresa.nome,
    };
    novaOferta.email = {
      user: vendedorSelected.SMTP_USER,
      pass: vendedorSelected.SMTP_PASS,
      host: novaOferta.email.host,
      port: novaOferta.email.port,
      security: novaOferta.email.security,
    };
    setOferta(novaOferta);
    // console.log(oferta)
  }

  async function handlefinishOrder(event) {
    if (!pessoaData) {
      setSnackbarMessage("Favor Selecionar Cliente");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
    }
    // console.log(pessoaData);
    const totalPrice = rowsOrder.reduce((a, b) => {
      return a.Price + b.Price;
    });

    const unidades_total = rowsOrder.reduce((a, b) => {
      return a.chapas + b.chapas;
    });

    const quantidades_total = rowsOrder.reduce((a, b) => {
      return a.quantidade + b.quantidade;
    });

    let bundlesForMaterial = [];
    const bundlesOrdered = rowsOrder.map((bundle, idx, arr) => {
      const nextIndex = idx + 1;
      const nextBundle = arr[nextIndex];
      if (!nextBundle) return bundle;
      if (bundle.nomematerialportugues === nextBundle.nomematerialportugues) {
        bundlesForMaterial.push({
          id: bundle.id,
          chapas: bundle.chapas + nextBundle.chapas,
          Bundle: bundle.Bundle,
          quantidade: bundle.quantidade + nextBundle.quantidade,
          Price: bundle.Price + nextBundle.Price,
          Material: bundle.Material,
          bruto: bundle.bruto + nextBundle.bruto,
          desconto: 0,
          Liquido: bundle.Liquido + nextBundle.Liquido,
          chapasData: [{ ...bundle.chapasData, ...nextBundle.chapasData }],
        });
      } else {
        return bundle;
      }
    });
    let total = rowsOrder
      .map((i) => i.Price)
      .reduce((a, b) => parseInt(a) + parseInt(b));

    // console.log("total", total);

    const order = {
      idcliente: pessoaData.id,
      id_clienteintegracao: "",
      numero_pedido_integracao: "",
      numero_pedido: "",
      empresa_id: userData.empresa_id,
      filial_cliente: "9999",
      id_vendedor: userData.id,
      idcondicaopagamento: "",
      idFrete: "",
      subtotal: "",
      ipi: "",
      seguro: "",
      frete: "",
      outras_despesas: "",
      desconto: "",
      total_geral: total,
      unidades: "",
      peso_liquido: "",
      peso_bruto: "",
      // total_m2: quantidades_total,
      total_m2: "",
      status_pedido: "Criado",
    };

    try {
      const { data } = await createPedido(order, rowsOrder);
      //console.log(data);

      const emailObj = {
        ...oferta,
        subject: "Pedido de Orçamento",
        observacao: "Pedido de Orçamento",
        orderId: data[0],
      };

      const response = await sendOrderMail(emailObj);
      //console.log(response);
      setSnackbarKind("success");
      setSnackbarMessage("Pedido enviado com sucesso.");
      setOpenSnackbar(true);
      localStorage.removeItem("ornatec_bundles");
      removeLocalOfert();
      setCloseModalOffert();
    } catch (err) {
      console.log(err);
      setSnackbarKind("error");
      setSnackbarMessage("Erro ao criar pedido.");
      setOpenSnackbar(true);
    }
  }

  // console.log(email)
  async function handlefinishOffert(event) {
    setOfferLoading(true);
    event.preventDefault();

    if (oferta.bundles.length <= 0) {
      setSnackbarMessage("Favor selecionar ao menos um bundle.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!oferta.email) {
      setSnackbarMessage("Favor informar um E-Mail.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }
    if (!oferta.data_oferta) {
      setSnackbarMessage("Favor informar a data da oferta.");
      setSnackbarKind("warning");
      setOpenSnackbar(true);
      return;
    }

    // try {
    // console.log(oferta)
    if (userData?.userRole === "assistente") {
      await atualizaState();
    }

    // await api.post("/oferta/", oferta).then(async (ress) => {

    if (oferta?.reservarBundles) {
      try {
        const bundlesreservados = await await api.post(
          `/bundles/reserva/${userId}?incluir=true`,
          {
            selectedBundles,
          }
        );
        if (bundlesreservados.data) {
          // console.log("bundles sem dados", bundlesreservados.data);
          setSnackbarKind("success");
          console.log(bundlesreservados.data?.message);
          setSnackbarMessage(bundlesreservados.data?.message);
          setOpenSnackbar(true);
          // setSelectedBundles([]);
          // localStorage.clear();
        } else {
          console.log("res sem data", bundlesreservados);
        }
      } catch (err) {
        console.log("erro", err);
        if (err.message === "Request failed with status code 406") {
          setSnackbarMessage(
            `Os Bundles ${selectedBundles.map(
              (bundle) => bundle.numero_bundle_cliente
            )} não foram reservados!`
          );
          setSnackbarKind("error");
          setOfferLoading(false);
          setOpenSnackbar(true);
        }
        if (err.message === "Request failed with status code 422") {
          setSnackbarMessage("Selecione pelo menos um bundle");
          setSnackbarKind("error");
          setOfferLoading(false);
          setOpenSnackbar(true);
        }
        setSnackbarMessage(
          `Os Bundles ${selectedBundles.map(
            (bundle) => bundle.numero_bundle_cliente
          )} não foram reservados!`
        );
        setSnackbarKind("error");
        setOpenSnackbar(true);
        setOfferLoading(false);
      }
    }
    setOfferLoading(true);
    //console.log("estou aqui ");

    api
      .post("/ofertass/create", { oferta })
      .then(async (res) => {
        const { novaOferta } = res.data;
        //console.log(novaOferta);
        //novaOferta  = res.data;
        setOferta(() => novaOferta);
        //setOferta(() => res.data);
        if (novaOferta) {
          // console.log("Entrei no email")
          api
            .post("/email/sendmailoffert", novaOferta)
            .then(async (res) => {
              //console.log(res);
              const ofertaPessoaObj = {
                oferta_id: novaOferta.id,
                //oferta_id: oferta.id,
                pessoa_id: oferta.pessoa_id === 0 ? 2 : oferta.pessoa_id,
                empresa_id: oferta.empresa_id,
                email_cliente: oferta.cliente.email,
                subject: oferta.subject,
                mensagem: oferta.observacao,
              };

              //console.log(ofertaPessoaObj);

              const Respofertapessoa = await api.post(
                "/ofertaPessoa",
                ofertaPessoaObj
              );
              console.log(Respofertapessoa);

              localStorage.removeItem("ornatec_bundles");
              removeLocalOfert();
              setSnackbarKind("success");
              setSnackbarMessage("Oferta enviada com sucesso." + res.data);
              setOpenSnackbar(true);
              setOfferLoading(false);
              setFinishOffer(true);
              setSelectedBundles([]);
              setTimeout(() => {
                setCloseModalOffert();
                setFinishOffer(false);
                setSelectedBundles([]);
              }, 2000);
            })
            .catch((error) => {
              console.log(error.response.data);
              setSnackbarMessage(
                `Rotina de Enviar email: ${error.response.data}`
              );
              setSnackbarKind("error");
              setOpenSnackbar(true);
              setOfferLoading(false);
            });
        }
      })
      .catch((error) => {
        setSnackbarMessage(
          `Não foi possivel criar a oferta, na tabela de oferta motivo: ${error.response.data}`
        );
        setSnackbarKind("error");
        setOpenSnackbar(true);
        setOfferLoading(false);
        setTimeout(() => {
          setCloseModalOffert();
          setFinishOffer(false);
        }, 2000);
      });
    console.log(oferta);

    // await api.post("/ofertass/create", { oferta }).then(async (ress) => {
    //   console.log("ress", ress);

    //   localStorage.removeItem("ornatec_bundles");
    //   removeLocalOfert();
    //   setSnackbarKind("success");
    //   setSnackbarMessage("Oferta enviada com sucesso.");
    //   setOpenSnackbar(true);
    //   setOfferLoading(false);

    //   const ofertaPessoaObj = {
    //     oferta_id: ress.data.novaOferta.id,
    //     pessoa_id: oferta.pessoa_id === 0 ? 2 : oferta.pessoa_id,
    //     empresa_id: oferta.empresa_id,
    //     email_cliente: oferta.cliente.email,
    //     subject: oferta.subject,
    //     mensagem: oferta.observacao,
    //   };
    //   setFinishOffer(true);

    //   api.post("/ofertaPessoa", ofertaPessoaObj).then((data) => {
    //     console.log("ofertapessoa", data);
    //   });
    //   setTimeout(() => {
    //     setCloseModalOffert();
    //     setFinishOffer(false);
    //   }, 2000);
    // });
    // } catch (error) {
    //   // if (error.response.status === 401) {
    //   //   setSnackbarMessage(`Erro ao enviar email motivo: Login inválido`);
    //   //   setSnackbarKind("error");
    //   //   setOpenSnackbar(true);
    //   //   setOfferLoading(false);
    //   //   setTimeout(() => {
    //   //     setCloseModalOffert();
    //   //     setFinishOffer(false);
    //   //   }, 2000);
    //   // } else {
    //   //   setSnackbarMessage(`Erro ao enviar email motivo: ${error}`);
    //   //   setSnackbarKind("error");
    //   //   setOpenSnackbar(true);
    //   //   setOfferLoading(false);
    //   //   setTimeout(() => {
    //   //     setCloseModalOffert();
    //   //     setFinishOffer(false);
    //   //   }, 2000);
    //   // }
    // }
  }

  function handleCheckReserva(event) {
    const { checked } = event.target;
    //console.log(checked)
    setOferta((prevState) => {
      return {
        ...prevState,
        reservarBundles: checked,
      };
    });
  }

  function handleChangePrice(position, field, value) {
    const updateBundlePrice = selectedBundles?.map((item, index) => {
      if (index === position) {
        return { ...item, [field]: value };
      }
      return item;
    });
    // console.log("updatebundleprice", updateBundlePrice);
    setSelectedBundles(() => updateBundlePrice);

    // console.log("Bundle Update", updateBundlePrice)
    setOferta((prevState) => {
      return {
        ...prevState,
        bundles: updateBundlePrice,
      };
    });
  }

  function handleRemoveBundle(position) {
    const arrayToDelete = oferta.bundles;

    const updatedBundles = arrayToDelete.splice(position, 1);

    setOferta((previousState) => {
      return {
        ...previousState,
        bundles: arrayToDelete,
      };
    });
  }

  function handleChangeAllPrices(price) {
    // let copy = oferta.bundles;
    const updateBundlePrice = selectedBundles?.map((item) => {
      item.preco = price;
      return item;
    });
    // copy.forEach((item)=>{
    //   item.preco = value;
    // })
    setSelectedBundles(() => updateBundlePrice);
    // console.log("updated", updateBundlePrice);
    // localStorage.setItem("ornatec_bundles", JSON.stringify(updateBundlePrice));

    handleRowsChange();
  }

  // console.log('oferta', oferta)

  function handleSubject(event) {
    localStorage.setItem("assunto", event.target.value);
    setOferta((prevState) => {
      return {
        ...prevState,
        subject: event.target.value,
      };
    });
    // console.log(oferta);
  }

  // function changeSelectedAdmin(id) {
  //   console.log(id);
  // }

  async function handleChangePerson(id) {
    var objCliente = pessoas.find((sel) => parseInt(sel.id) === parseInt(id));
    localStorage.setItem("id_cliente", objCliente.id);
    localStorage.setItem("name_cliente", objCliente.nome);
    localStorage.setItem("email_cliente", objCliente.email);
    // handleCliente(objCliente).then(() => {
    //   console.log(cliente)
    // })

    setCliente({
      id: objCliente.id,
      nome: objCliente.nome,
      email: objCliente.email,
    });

    setEmail((prevState) => {
      return {
        ...prevState,
        email: `${objCliente.nome}<${objCliente.email}>`,
      };
    });

    setOferta((prevState) => {
      return {
        ...prevState,
        pessoa_id: id,
        cliente: {
          email: `${objCliente.nome}<${objCliente.email}>`,
          id: objCliente.id,
          nome: objCliente.nome,
        },
      };
    });

    setPessoaData(objCliente);
  }

  const handleOrderChange = () => {
    setIsOrder(!isOrder);
  };

  useEffect(() => {
    setLoading(true);
    setBundlesLoaded(false);

    if (
      oferta.bundles.length === 0 &&
      localStorage.getItem("ornatec_bundles")
    ) {
      // const bundles = JSON.parse(localStorage.getItem("ornatec_bundles"));
      // setOferta((oferta) => {
      //   return {
      //     ...oferta,
      //     bundles: JSON.parse(localStorage.getItem("ornatec_bundles"))
      //   }
      // })

      // bundles.forEach((item) => {
      //   item.preco = ofertData.bundlePrice || "0";
      //   return item;
      // });

      setOferta((oferta) => {
        return {
          ...oferta,
          bundles: [...selectedBundles],
        };
      });

      // let copy = oferta.bundles;

      // copy.forEach((item) => {
      //   item.preco = ofertData.bundlePrice || 0
      // })
      // console.log(copy)
      // setOferta((oferta) => {
      //   return {
      //     ...oferta,
      //     bundles: copy
      //   }
      // })

      // console.log("Entrou");
    }

    // console.log("Storage", JSON.parse(localStorage.getItem("ornatec_bundles")))

    setBundlesLoaded(true);

    getEmailInfo();

    getPessoas();

    findAdmins();

    handleRowsChange();

    DataAtual();
    setLoading(false);
    if (bundlesLoaded && emailLoaded) {
      setOferta((prevState) => {
        // return { ...prevState, bundles: bundles, valor_oferta: total };
        return {
          ...prevState,
          email: email,
          cliente: cliente,
          valor_oferta: total,
        };
      });
    }
  }, [bundlesLoaded, emailLoaded, cliente]);

  // const handleCellEditCommit = ({ id, field, value }) => {
  //   // console.log("value", value);
  //   if (field === "Price") {
  //     console.log("rowsOrder", rowsOrder);
  //     const bundlesUpdated = rowsOrder.map((item) => {
  //       if (item.id === id) {
  //         item.Price = value;
  //       }
  //       return item;
  //     });

  //     setRowsOrder(bundlesUpdated);
  //     const bundlesUpdatedOffer = rowsOffer.map((item) => {
  //       if (item.id === id) {
  //         item.Price = value;
  //       }
  //       return item;
  //     });
  //     setRowsOffer(bundlesUpdatedOffer);

  //     const updateBundlePrice = oferta.bundles?.map((item) => {
  //       if (item.id === id) {
  //         item.preco = value;
  //       }
  //       return item;
  //     });

  //     setOferta((oferta) => {
  //       return {
  //         ...oferta,
  //         bundles: [...updateBundlePrice],
  //       };
  //     });
  //   }
  // };

  const handleRowsChange = () => {
    let bundlesRowOffer = [];
    let bundlesRowOrder = [];
    const bundlesSelected = [...selectedBundles];
    // console.log("bundlesSelected", bundlesSelected);
    const bundlesOffer = bundlesSelected.map((bundle, idx) => {
      bundlesRowOffer.push({
        id: bundle.id,
        exclude: "",
        block: bundle.bloco,
        bundle: bundle.numero_bundle_cliente,
        Material: bundle.nomematerialingles,
        Processo: ProcessoValue(bundle.processo),
        Size: `${bundle.comprimento_liquido}m x ${bundle.altura_liquida}m /
        ${(bundle.comprimento_liquido * 39.3701).toPrecision(5)}" x"
        ${(bundle.altura_liquida * 39.3701).toPrecision(4)}"`,
        Thick: bundle.espessura,
        Slabs: bundle.lista_chapas,
        Price: bundle.preco,
        chapasData: bundle.chapas,
      });
    });

    setRowsOffer(bundlesRowOffer);

    const quantidadeLiquida = (chapas) => {
      let quantity = 0;
      for (let i = 0; i < chapas.length; i++) {
        quantity += chapas[i].altura_liquida * chapas[i].comprimento_liquido;
      }
      return quantity.toPrecision(4);
    };

    const quantidadeBruta = (chapas) => {
      let quantity = 0;
      for (let i = 0; i < chapas.length; i++) {
        quantity += chapas[i].altura_bruta * chapas[i].comprimento_bruto;
      }
      return quantity.toPrecision(4);
    };

    const bundlesOrder = bundlesSelected.map((bundle, idx, bundles) => {
      bundlesRowOrder.push({
        id: bundle.id,
        chapas: bundle.total_chapas,
        Bundle: bundle.bundlecliente_id,
        quantidade: bundle.totalm2,
        Price: bundle.preco,
        material_id: bundle.nomematerial_id,
        Material: bundle.nomematerialingles,
        bruto: quantidadeBruta(bundle.chapas),
        desconto: 0,
        Liquido: quantidadeLiquida(bundle.chapas),
        chapasData: bundle.chapas,
      });
    });
    setRowsOrder(bundlesRowOrder);
  };

  useEffect(() => {
    handleRowsChange();
    // console.log("rowsOrder", rowsOrder);
    // console.log("rowsOffer", rowsOffer);
  }, [isOrder]);

  const handleChangeObservacao = (e) => {
    setObservacao(() => e);
    setOferta((prevState) => {
      return { ...prevState, observacao: e };
    });
  };

  if (loading) {
    return (
      <Grid style={{ paddingTop: "35%", paddingLeft: "50%" }} item xs={12}>
        <CircularProgress />
        {/* <Loading /> */}
      </Grid>
    );
  }

  return (
    <form>
      <Dialog
        open={true}
        onClose={setCloseModalOffert}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          <Title>{isOrder ? "Finalizando Pedido" : "Finalizando Oferta"}</Title>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <FormControlLabel
              label="Pedido"
              control={<Checkbox onChange={handleOrderChange} />}
            />
            <Grid item xs={12} align="center">
              <SubTittle>Dados da Oferta</SubTittle>
            </Grid>
          </Grid>
          {offerLoading ? (
            <Grid
              style={{ paddingTop: "20px", paddingLeft: "50%" }}
              item
              xs={12}
            >
              <CircularProgress />
              {/* <Loading /> */}
            </Grid>
          ) : (
            <>
              {userData?.userRole === "assistente" ? (
                <Grid item sm={12}>
                  <Autocomplete
                    options={vendedores}
                    value={vendedorSelected}
                    label="selecione o vendedor"
                    getOptionLabel={(option) => option.nome}
                    style={{ width: 250 }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setVendedorSelected(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione o vendedor"
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              ) : null}
              {userData?.userRole !== "cliente" ? (
                <Grid item sm={12}>
                  <ListPerson
                    label="Selecione o Cliente"
                    id="pessoa_id"
                    name="pessoa_id"
                    removeWidth={true}
                    defaultValue={pessoa}
                    personCurrent={pessoa}
                    value={oferta.pessoa_id}
                    pessoas={pessoas}
                    handleChangePerson={handleChangePerson}
                  ></ListPerson>
                </Grid>
              ) : null}
              {
                isOrder === false ? (
                  <Grid container>
                    <Grid item sm={12} style={{ width: "100%" }}>
                      <TextField
                        label={
                          userData?.userRole !== "cliente"
                            ? "Selecione o E-mail"
                            : userData?.email
                        }
                        id="email"
                        name="email"
                        fullWidth
                        value={
                          userData?.userRole !== "cliente"
                            ? ofertData.clientEmail
                            : userData?.email
                        } //{ofertData.clientEmail}
                        onChange={(event) => {
                          localStorage.setItem(
                            "email_cliente",
                            event.target.value
                          );
                          setCliente((prevState) => {
                            return {
                              ...prevState,
                              email: event.target.value,
                            };
                          });
                          setEmail((prevState) => {
                            return {
                              ...prevState,
                              email: `${cliente.nome}<${event.target.value}>`,
                            };
                          });
                          setOferta((prevState) => {
                            return {
                              ...prevState,
                              email: {
                                ...prevState.email,
                                email: `${cliente.nome}<${event.target.value}>`,
                              },
                            };
                          });
                        }}
                      ></TextField>
                    </Grid>
                    <Grid container style={{ width: "100%" }}>
                      <Grid item sm={10}>
                        <TextField
                          label="Subject"
                          id="subject"
                          name="subject"
                          fullWidth
                          value={ofertData.assunto}
                          onChange={handleSubject}
                        ></TextField>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                        sm={2}
                      >
                        <FormControlLabel
                          control={
                            consultaPedido === true ? (
                              <Checkbox
                                disabled
                                name="reservado"
                                checked={oferta.reservarBundles}
                                value={oferta.reservarBundles}
                                onChange={handleCheckReserva}
                              />
                            ) : (
                              <Checkbox
                                name="reservado"
                                checked={oferta.reservarBundles}
                                value={oferta.reservarBundles}
                                onChange={handleCheckReserva}
                              />
                            )
                          }
                          label="Reservar Bundles"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      style={{ paddingTop: "15px", width: "100%" }}
                      sm={12}
                    >
                      <EmailEditor
                        setText={handleChangeObservacao}
                        text={observacao}
                      />
                      {/* <TextField
                        id="observacao"
                        label="Message"
                        fullWidth
                        multiline
                        rows={12}
                        value={ofertData.observacao}
                        variant="outlined"
                        onChange={(event) => {
                          localStorage.setItem(
                            "observacao",
                            event.target.value
                          );
                          setOferta((prevState) => {
                            return {
                              ...prevState,
                              observacao: event.target.value,
                            };
                          });
                        }}
                      /> */}
                    </Grid>
                  </Grid>
                ) : null
                // (
                //   <Grid container>
                //     <Grid item sm={12}>
                //       <TextField
                //         fullWidth
                //         label="Cliente"
                //         value={
                //           pessoaData
                //             ? "Razão Social:" +
                //               pessoaData.razao_social +
                //               " " +
                //               pessoaData.logradouro
                //             : ""
                //         }
                //       />
                //     </Grid>
                //   </Grid>
                // )
              }
            </>
          )}
          <Grid container spacing={1}>
            {bundlesLoaded && oferta.bundles?.length > 0 ? (
              <>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table" size="small">
                    <TableHead>
                      <TableRow className={classes.root}>
                        <TableCell>Exclude</TableCell>
                        <TableCell>Block</TableCell>
                        <TableCell>Bundle</TableCell>
                        <TableCell>Material</TableCell>
                        <TableCell>Processo</TableCell>
                        <TableCell>Size</TableCell>
                        {/* <TableCell>Número bundle Cliente</TableCell> */}
                        <TableCell>Thick</TableCell>
                        <TableCell>Slabs</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    {/* {console.log(oferta.bundles)} */}
                    <TableBody>
                      {oferta.bundles?.map((row, index) => (
                        // <Row bundleRow={row} index={index} key={`oferta_bundles_${index}`}></Row>
                        <TableRow className={classes.root} key={"th" + index}>
                          {/* <TableCell component="th" scope="bundles">{bundleRow.bundle.id}</TableCell> */}
                          <TableCell>
                            <CancelIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveBundle(index)}
                            />
                          </TableCell>
                          <TableCell>{row.bloco}</TableCell>
                          <TableCell>{row.numero_bundle_cliente}</TableCell>
                          <TableCell>
                            {row.nomematerialingles
                              ? row.nomematerialingles
                              : row.nome_ingles}
                          </TableCell>
                          <TableCell>{ProcessoValue(row.processo)}</TableCell>
                          <TableCell>
                            {row.comprimento_liquido}m x {row.altura_liquida}m /{" "}
                            {(row.comprimento_liquido * 39.3701).toPrecision(5)}
                            " x {(row.altura_liquida * 39.3701).toPrecision(4)}"{" "}
                          </TableCell>
                          <TableCell>{row.espessura}CM</TableCell>
                          <TableCell>
                            {row.chapas.length <= 1
                              ? row.lista_chapas
                              : row.chapas.map(
                                  (c, idx) => c.numerochapa.substr(1) + ` `
                                )}
                          </TableCell>
                          <TableCell>
                            <TextField
                              label="U$"
                              id={"preco" + String(row.id)}
                              name="preco"
                              value={row.preco ? row.preco : 0}
                              onChange={(e) =>
                                handleChangePrice(
                                  index,
                                  "preco",
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100px" }}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                inputComponent: PriceFormatCustom,
                              }}
                            ></TextField>
                            {index === 0 && (
                              <Button
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "3px",
                                  width: "80px",
                                  fontSize: 9,
                                }}
                                onClick={(e) => {
                                  handleChangeAllPrices(
                                    oferta.bundles[0].preco
                                  );
                                }}
                                color="secondary"
                                variant="outlined"
                              >
                                Apply All
                              </Button>
                            )}
                          </TableCell>
                          {index === 0 && (
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="unidade"
                                name="unidade1"
                                value={oferta.unidade}
                                onChange={handleChangeUnidade}
                              >
                                <FormControlLabel
                                  value="ft²"
                                  control={<Radio />}
                                  label="ft²"
                                />
                                <FormControlLabel
                                  value="m²"
                                  control={<Radio />}
                                  label="m²"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          {/* {() => setTotal(total + row.precom2)} */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Grid item sm={12}>
                Nenhuma chapa selecionada!
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCloseModalOffert();
              removeLocalOfert();
            }}
            color="primary"
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            onClick={setCloseModalOffert}
            color="primary"
            variant="contained"
          >
            Continuar Pesquisando
          </Button>
          <Button
            onClick={isOrder ? handlefinishOrder : handlefinishOffert}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        closeBtn={true}
        closeSnackbar={closeSnackbar}
        message={snackbarMessage}
        kind={snackbarKind}
        onClose={() => closeSnackbar}
      ></Snackbar>
      <ModalDetails
        chapas={chapasSelected}
        open={open}
        handleCloseModal={handleCloseModal}
      />
    </form>
  );
}
