import { createContext, useState } from "react";
export const SelectedBundlesContext = createContext();

export const SelectedBundlesProvider = (props) => {
  const [selectedBundles, setSelectedBundles] = useState([]);

  return (
    <SelectedBundlesContext.Provider
      value={{ selectedBundles, setSelectedBundles }}
    >
      {props.children}
    </SelectedBundlesContext.Provider>
  );
};
